export const baseUrl = (url) =>{
     //let URL = `http://5.196.4.7:8080/restaurantapplication-api/${url}`;
    let URL = `https://api.skanresto.com/${url}`;
    //let URL = `http://localhost:8081/restaurantapplication-api/${url}`;
//    let URL = `http://192.168.1.2:8080/restaurantapplication-api/${url}`;
    //let URL = `http://localhost:9292/restaurantapplication-api/${url}`;
    return URL
};

// tanfolojoel@gmail.com
// tanfolo

// obv@gmail.com
// azerty

// nadia@gmail.com
// azerty
