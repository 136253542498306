import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import Swal from "sweetalert2";
import {
  enregistrerDelaiService,
  rechercherDelaiService,
} from "../../services/parametre/ParametreRequest";
import { DecodeToken } from "../../DecodeToken";
import { recherchertouslesRestaurants } from "../../services/restaurants/RestaurantRequest";

function DelaiService() {
  const [loading, setloading] = useState(false);
  const [delai, setDelai] = useState("");
  const [messageError, setmessageError] = useState("");
  const [listeRestaurant, setListeRestaurant] = useState([]);
  const [restaurantSelected, setRestaurantSelected] = useState("");

  const getDelaiServiceParRestaurant = (restauId) => {
    setRestaurantSelected(restauId)
    if (restauId) {
      rechercherDelaiService(restauId)
        .then((res) => {
          setDelai(res.data.donnee.delaiService);
        })
        .catch((error) => {
          setmessageError(error.response.data.donnee);
        });
    } else {
      setDelai("")
      rechercherDelaiService(restauId)
        .then((res) => {
          setDelai(res.data.donnee.delaiService);
        })
        .catch((error) => {
          setmessageError(error.response.data.donnee);
        });
    }
  };

  const afficherRestaurant = () => {
    recherchertouslesRestaurants()
      .then((response) => {
        setListeRestaurant(response.data.donnee);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const initialValues = {
    idRestaurant: "",
    delaiService: "",
  };

  const onSubmitValuesForm = (values) => {
    const dataSub = {
      id: restaurantSelected,
      delaiService: delai,
    };
    setmessageError("");
    setloading(true);
    enregistrerDelaiService(dataSub)
      .then(() => {
        setloading(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Délai enregistré avec succès !",
          showConfirmButton: false,
          timer: 1500,
        });
        setmessageError("");
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
        setmessageError(error.response.data.donnee);
      });
  };

  useEffect(() => {
    afficherRestaurant()
    getDelaiServiceParRestaurant("")
    console.log('vne', DecodeToken())
  }, []);

  return (
    <>
      <div className="px-5">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={onSubmitValuesForm}
        >
          <Form>
            <div className="">
              {DecodeToken().profil === "ROLE_ADMIN" ? (
                <div className="mb-3">
                  <label htmlFor="idRestaurant" className="label-control">
                    Restaurant
                  </label>
                  <Field
                    as="select"
                    type="text"
                    name="idRestaurant"
                    id="idRestaurant"
                    className="form-control"
                    value={restaurantSelected}
                    onChange={e => getDelaiServiceParRestaurant(e.target.value)}
                  >
                    <option value="">
                      Sélectionner un restaurant
                    </option>
                    {listeRestaurant.map((restau, index) => (
                      <option value={restau.id} key={index}>
                        {restau.nom}
                      </option>
                    ))}
                  </Field>
                  <span className="text-danger">
                    <ErrorMessage name="idRestaurant"></ErrorMessage>
                  </span>
                </div>
              ) : null}
              <div className="mb-3">
                <label htmlFor="delaiService" className="label-control">
                  Délai (minutes)
                </label>
                <Field
                  type="number"
                  name="delaiService"
                  id="delaiService"
                  value={delai}
                  onChange={e => setDelai(e.target.value)}
                  className="form-control"
                />
                <span className="text-danger">
                  <ErrorMessage name="delaiService"></ErrorMessage>
                </span>
              </div>
              <div className="mb-3 d-flex justify-content-end">
                {loading ? (
                  <Oval
                    width={30}
                    height={30}
                    color="#CE5D1D"
                    visible={loading}
                  />
                ) : (
                  <button type="submit" className="btn btn-rose text-white">
                    Enregistrer
                  </button>
                )}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default DelaiService;
