import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { Autocomplete, TextField } from "@mui/material";
import { enregistrerCaratereSubvention } from "../../services/parametre/ParametreRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";
import { enregistrerNewQuestion } from "../../services/questionnaire/QuestionnaireRequest";

function AddQuestion(props) {
  const [loading, setloading] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [questionLibelle, setQuestionLibelle] = useState(null);
  const [typeQuestion, setTypeQuestion] = useState(null);
  const listeTypeQuestion = [
    {
      label: "Select",
      value: "0",
    },
    {
      label: "Champ de saisi",
      value: "1",
    },
  ];
  const [messageError, setmessageError] = useState("");

  const handleOnSubmit = (values) => {
    setloading(true);
    setmessageError("");

    const dataToSubmit = {
      id: questionId ? questionId : "",
      designation: questionLibelle ? questionLibelle : "",
      type: parseInt(typeQuestion.value),
    };

    enregistrerNewQuestion(dataToSubmit)
      .then((res) => {
        setloading(false);
        setTypeQuestion(null);
        setQuestionLibelle(null)
        setmessageError("");
        props.actualiserliste(1);
        document.getElementById("close-btn").click();
      })
      .catch((error) => {
        setloading(false);
        setmessageError(
          error && error.response
            ? error.response.data.donnee
            : "Une erreur s'est produite"
        );
        console.log("error", error);
      });
  };

  useEffect(() => {
    setQuestionId(
      props.infoQuestion && props.infoQuestion.id !== undefined
        ? props.infoQuestion.id
        : ""
    );
    setQuestionLibelle(
      props.infoQuestion && props.infoQuestion.designation !== undefined
        ? props.infoQuestion.designation
        : null
    );
    setTypeQuestion(
      props.infoQuestion &&
        props.infoQuestion.type !== undefined &&
        props.infoQuestion.type === 0
        ? {
            label: "Select",
            value: "0",
          }
        : props.infoQuestion &&
          props.infoQuestion.type !== undefined &&
          props.infoQuestion.type === 1
        ? {
            label: "Champ de saisi",
            value: "1",
          }
        : null
    );
  }, [props.infoQuestion]);

  return (
    <div
      className="modal fade"
      id="caratereForm"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "30%" }}
      >
        <div className="modal-content bg-rose">
          <div className="p-2 d-flex justify-content-end">
            <button
              id="close-btn"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="fw-bold">Nouvelle question</h6>
            </div>
            <div className="">
              <div className="mb-3 text-danger text-center">
                {messageError !== "" && messageError}
              </div>

              <div className="mb-3">
                <label className="label-control" htmlFor="dateOrdre">
                  Question
                </label>
                <input
                  type="text"
                  placeholder="Que pensez-vous de l'application"
                  className="mt-1 form-control form-control-sm"
                  style={{ height: "40px", fontSize: "17px" }}
                  value={questionLibelle}
                  onChange={(e) => setQuestionLibelle(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="label-control" htmlFor="dateOrdre">
                  Type question
                </label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo-2"
                  options={listeTypeQuestion}
                  getOptionLabel={(option) => option.label ?? option}
                  value={typeQuestion}
                  onChange={(event, value) => {
                    setTypeQuestion(value);
                  }}
                  sx={{ width: "100%", marginTop: 1 }}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} className="bg-white" />
                  )}
                />
              </div>

              <div className="mt-5 d-flex justify-content-end">
                <Oval
                  width={30}
                  height={30}
                  color="#CE5D1D"
                  visible={loading}
                />
                <button
                  disabled={loading}
                  style={{ marginLeft: "16px" }}
                  className="btn btn-rose text-white"
                  onClick={handleOnSubmit}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddQuestion;
