import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { rechercherentreprises, recherchersiteparentreprise } from "../services/entreprises/entrepriseRequest";

import { DecodeToken } from "../DecodeToken";

function ListeEntreprise(props) {
  const [entrepriseSelected, setentrepriseSelected] = useState(null);
  const [siteEntrepriseSelected, setSiteEntrepriseSelected] = useState(null);
  const [listeEntreprise, setlisteEntreprise] = useState([]);
  const [listeSiteEntreprise, setListeSiteEntreprise] = useState([]);

  const handlePlatday = (values) => {
    setentrepriseSelected(values);
    props.setdataSelected(values);
  };

  const handleSitePlatday = (values) => {
    setSiteEntrepriseSelected(values);
    props.setdataSiteSelected(values);
  };

  const afficherEntreprise = () => {
    rechercherentreprises().then((response) => {
      setlisteEntreprise(response.data.donnee);
    });
  };

  const afficherSiteEntreprise = (entreprise) => {
    if (entreprise) {
      recherchersiteparentreprise(entreprise.id).then((res) => {
        setListeSiteEntreprise(res.data.donnee);
      });
    } else {
      setListeSiteEntreprise([])
    }
  };

  useEffect(() => {
    afficherEntreprise();
  }, []);

  return (
    <div className=" d-flex justify-content-end gap-1">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={
          DecodeToken().profil == "ROLE_ADMIN"
            ? listeEntreprise
            : DecodeToken().profil == "ROLE_RESTAURATRICE" &&
            props.listecomandeentreprise
        }
        getOptionLabel={(option) => option.raisonSociale ?? option}
        value={entrepriseSelected || props.entrepriseSelected}
        onChange={(event, value) => {
          afficherSiteEntreprise(value)
          handlePlatday(value);
        }}
        sx={{ width: props.width !== undefined ? props.width : 250 }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} className="bg-white" label="Entreprises" />
        )}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={
          DecodeToken().profil == "ROLE_ADMIN"
            ? listeSiteEntreprise
            : DecodeToken().profil == "ROLE_RESTAURATRICE" &&
            listeSiteEntreprise
        }
        getOptionLabel={(option) => option.nom ?? option}
        value={siteEntrepriseSelected || props.siteEntrepriseSelected}
        onChange={(event, value) => {
          handleSitePlatday(value);
        }}
        sx={{ width: props.width !== undefined ? props.width : 250 }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} className="bg-white" label="Site entreprise" />
        )}
      />
    </div>
  );
}

export default ListeEntreprise;
