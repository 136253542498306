import { baseUrl } from "../BaseUrl";
import axios from "axios";
import { headers } from "../../headers";

let racine = "restauratrice";

export const rechercherlisteplats = async () => {
  return await axios.get(baseUrl("rechercherlisteplats"));
};

export const rechercherlisteplatsentreprise = async (entrepriseId) => {
  return await axios.get(baseUrl(`rechercherlisteplats/${entrepriseId}`));
};

export const rechercherlisteplatsentreprisesite = async (siteId) => {
  return await axios.get(baseUrl(`rechercherlisteplats/${siteId}`));
};

export const fichier = (visuelId) => {
  return baseUrl("fichier/" + visuelId);
};

export const enregistrerrepas = async (values) => {
  return await axios.post(baseUrl(racine + "/enregistrerrepas"), values);
};

export const enregistrerrepasjournalier = async (values) => {
  return await axios.post(
    baseUrl(racine + "/enregistrerrepasjournalier"),
    values
  );
};

export const enregistrerrepasparfichier = async (values) => {
  return await axios.post(
    baseUrl("restauratrice/enregistrerlisterepas"),
    values
  );
};

export const supprimerrepas = async (id) => {
  return axios.delete(baseUrl(racine + "/supprimerrepas/" + id));
};

export const rechercherrepaspardesignation = async (nom) => {
  return await axios.post(
    baseUrl("rechercherrepaspardesignation?nom=" + nom),
    {}
  );
};

export const recherchermenuparmois = async (datePlat) => {
  return await axios.get(
    baseUrl(racine + "/recherchermenuparmois?date=" + datePlat)
  );
};

export const retirerrepasdumenujournalier = async (id) => {
  return await axios.delete(baseUrl("retirerrepasdumenujournalier/" + id));
};
export const dupliquermenu = async (date) => {
  return await axios.post(baseUrl(`restauratrice/dupliquermenu`), date);
};

export const exportdatalisterepas = async () => {
  return await axios.get(baseUrl("restauratrice/telechargercataloguerepas"), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("tokenRestaurant")}`,
    },
    responseType: 'blob'
  });
};