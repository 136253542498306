import React, { useEffect, useState } from "react";
import {
  exportdatastatistique,
  rechercherchiffreaffaire,
} from "../../services/statistiques/StatRequest";
import { returnImageLink } from "../ReturnImageLink";
import Zonefilter from "./Zonefilter";

function StatsRestauratrice() {
  const [infoStatsAdminCommande, setinfoStatsAdminCommande] = useState({});
  const [montantGlobalCommande, setMontantGlobalCommande] = useState(null);
  const [soldeCompteResto, setSoldeCompteResto] = useState(null);
  let [paramsFilter, setparamsFilter] = useState({
    annee: new Date().getFullYear(),
    mois: new Date().getMonth() + 1,
    entrepriseId: "",
  });
  const [listeMois, setlisteMois] = useState([
    { indiceMois: "", libelle: "" },
    { indiceMois: 1, libelle: "Janvier" },
    { indiceMois: 2, libelle: "Février" },
    { indiceMois: 3, libelle: "Mars" },
    { indiceMois: 4, libelle: "Avril" },
    { indiceMois: 5, libelle: "Mai" },
    { indiceMois: 6, libelle: "Juin" },
    { indiceMois: 7, libelle: "Juillet" },
    { indiceMois: 8, libelle: "Août" },
    { indiceMois: 9, libelle: "Septembre" },
    { indiceMois: 10, libelle: "Octobre" },
    { indiceMois: 11, libelle: "Novembre" },
    { indiceMois: 12, libelle: "Décembre" },
  ]);
  const [loadingExport, setloadingExport] = useState(false);
  const [infoStatsRestaurant, setinfoStatsRestaurant] = useState({});
  let [mobileMois, setMobileMois] = useState(null);
  let [mobileGlobale, setMobileGlobale] = useState(null);
  const [commandeAnnulee, setCommandeAnnulee] = useState({
    montantTotal: "",
    montantMensuel: "",
  });

  const afficherchiffreaffaire = () => {
    rechercherchiffreaffaire(paramsFilter).then((res) => {
      mobileMois = res.data.donnee.montantMobileMoneyDuMois;
      mobileGlobale = res.data.donnee.montantMobileMoneyGobal;
      setMobileMois(mobileMois);
      setMobileGlobale(mobileGlobale);
      setinfoStatsRestaurant(res.data.donnee);
      setinfoStatsAdminCommande(res.data.donnee);
      setMontantGlobalCommande(
        res.data.donnee.montantGlobalDesCommandes !== undefined
          ? res.data.donnee.montantGlobalDesCommandes
          : 0
      );
      setSoldeCompteResto(
        res.data.donnee.soldePortefeuille !== undefined
          ? res.data.donnee.soldePortefeuille
          : 0
      );
      setCommandeAnnulee({
        montantTotal: res.data.donnee.montantTotalCommandeAnnulee,
        montantMensuel: res.data.donnee.montantCommandeAnnuleeParMois,
      });
    });
  };

  const exportData = () => {
    setloadingExport(true);
    exportdatastatistique(paramsFilter)
      .then((res) => {
        setloadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Statistique_${
            listeMois.filter((item) => item.indiceMois == paramsFilter.mois)[0]
              .libelle
          }_${paramsFilter.annee}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setloadingExport(false);
      });
  };

  useEffect(() => {
    afficherchiffreaffaire();
  }, []);

  return (
    <>
      <div>
        <div className="d-flex align-items-center">
          <Zonefilter
            paramsFilter={paramsFilter}
            setparamsFilter={setparamsFilter}
            actualiseStat={afficherchiffreaffaire}
          />
          <div style={{ marginLeft: 10, marginTop: 16 }}>
            <button
              className="btn text-white"
              style={{ backgroundColor: "#CE5D1D" }}
              disabled={loadingExport}
              onClick={exportData}
            >
              Exporter
            </button>
          </div>
        </div>

        <div
          className="row p-4 bg-white rounded"
          style={{ marginRight: "50px" }}
        >
          <div
            className={
              localStorage.getItem("process") === "true" ? "col-4" : "col-6"
            }
          >
            <span>Nombre de plats vendus par mois</span>
            <h6>{infoStatsRestaurant.nombre}</h6>
          </div>
          {/* <div className='col-4' style={{ borderRight: " 2px solid #e5e5e5", borderLeft: " 2px solid #e5e5e5" }}>
                    <span>Total de plats subventionnés</span>
                    <h6>{infoStatsRestaurant.nombreSubventionne}</h6>
                </div> */}
          <div
            className={
              localStorage.getItem("process") === "true" ? "col-4" : "col-6"
            }
          >
            <span>Montant à percevoir (mois)</span>
            <h6>{infoStatsRestaurant.montant}F CFA</h6>
          </div>
          {localStorage.getItem("process") === "true" ? (
            <div className="col-4">
              <span>Temps moyen de traitement</span>
              <h6>
                {infoStatsRestaurant.tempsmoyentraitement
                  ? infoStatsRestaurant.tempsmoyentraitement
                  : "Indéfini"}
              </h6>
            </div>
          ) : null}
        </div>

        {/* ======= COMMANDES ======= */}
        <div
          className="row p-4 bg-white rounded"
          style={{ marginRight: "30px", marginTop: "20px", width: "70%" }}
        >
          <div className="col-4">
            <span>Commandes du jour</span>
            <h6>
              {infoStatsAdminCommande.montantdujour
                ? infoStatsAdminCommande.montantdujour
                : "0"}{" "}
              F CFA /{" "}
              <span className="text-danger">
                {infoStatsAdminCommande.nombreCommandeDuJour
                  ? infoStatsAdminCommande.nombreCommandeDuJour
                  : "0"}
              </span>
            </h6>
          </div>
          <div
            className="col-4"
            style={{
              borderRight: " 2px solid #e5e5e5",
              borderLeft: " 2px solid #e5e5e5",
            }}
          >
            <span>Commandes du mois</span>
            <h6>
              {infoStatsAdminCommande.montant
                ? infoStatsAdminCommande.montant
                : "0"}{" "}
              F CFA /{" "}
              <span className="text-danger">
                {infoStatsAdminCommande.nombreCommandeDuMois
                  ? infoStatsAdminCommande.nombreCommandeDuMois
                  : "0"}
              </span>
            </h6>
          </div>
          <div
            className="col-4"
            style={
              {
                // borderRight: " 2px solid #e5e5e5",
              }
            }
          >
            <span>Montant global commandes</span>
            <h6>
              {montantGlobalCommande ? montantGlobalCommande : "0"} F CFA /{" "}
              <span className="text-danger">
                {infoStatsAdminCommande.nombreCommande
                  ? infoStatsAdminCommande.nombreCommande
                  : "0"}
              </span>
            </h6>
          </div>
        </div>

        <div
          className="row p-4 bg-white rounded"
          style={{ marginRight: "30px", marginTop: "20px", width: "70%" }}
        >
          <div
            className="col-6"
            style={{
              borderRight: " 2px solid #e5e5e5",
              // borderLeft: " 2px solid #e5e5e5",
            }}
          >
            <span>Nombre de commandes hors délai du jour</span>
            <h5 className="text-danger">
              {infoStatsAdminCommande.nombreCommandeHorsDelai
                ? infoStatsAdminCommande.nombreCommandeHorsDelai
                : "0"}{" "}
              /
              {infoStatsAdminCommande.pourcentageHorsDelaiJour
                ? ` ${infoStatsAdminCommande.pourcentageHorsDelaiJour.toFixed(
                    2
                  )}%`
                : " 0%"}
            </h5>
          </div>
          <div className="col-6">
            <span>Nombre de commandes dans les délais du jour</span>
            <h5 className="text-danger">
              {infoStatsAdminCommande.nombreCommandeHorsDansLeDelai
                ? infoStatsAdminCommande.nombreCommandeHorsDansLeDelai
                : "0"}{" "}
              /
              {infoStatsAdminCommande.pourcentageDansLeDelaiJour
                ? ` ${infoStatsAdminCommande.pourcentageDansLeDelaiJour.toFixed(
                    2
                  )}%`
                : " 0%"}
            </h5>
          </div>
        </div>

        <div
          className="row p-4 bg-white rounded"
          style={{ marginRight: "30px", marginTop: "20px", width: "70%" }}
        >
          <div className="col-6">
            <span>Nombre de plats vendu jour</span>
            <h5 className="text-danger">
              {infoStatsAdminCommande.nombrePlatVenduDuJour
                ? infoStatsAdminCommande.nombrePlatVenduDuJour
                : "0"}
            </h5>
          </div>
          <div className="col-6">
            <span>Nombre total de plats vendus</span>
            <h5 className="text-danger">
              {infoStatsAdminCommande.nombrePlatVendu
                ? infoStatsAdminCommande.nombrePlatVendu
                : "0"}
            </h5>
          </div>
          {/* <div className="col-4">
            <span>Temps moyen de traitement</span>
            <h5 className="text-danger">
              {infoStatsAdminCommande.tempsmoyentraitement
                ? infoStatsAdminCommande.tempsmoyentraitement
                : "Indéfini"}
            </h5>
          </div> */}
        </div>

        <div className="row mt-3 col-6">
          <span className="fw-bold text-danger">Commandes annulées</span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-5">
              <div className="dropdown">
                <span style={{ marginRight: "10px" }}>
                  Montant global commandes annulées
                </span>
              </div>
              <h5 className="mt-1">
                {commandeAnnulee.montantTotal
                  ? commandeAnnulee.montantTotal
                  : "0"}{" "}
                F CFA
              </h5>
            </div>

            <div className="rounded mx-2 bg-white px-4 py-3 col-5">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant mensuel commandes annulées{" "}
                </span>
              </span>
              <h5 className="mt-1">
                {commandeAnnulee.montantMensuel
                  ? commandeAnnulee.montantMensuel
                  : "0"}
              </h5>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <>
            {" "}
            <span>Stats des menus</span>
            <div
              className="row mt-1 p-4 bg-white rounded"
              style={{ marginRight: "50px" }}
            >
              <div className="col-6">
                <span>
                  <span style={{ marginRight: "10px" }}>
                    Plat le plus achetés{" "}
                  </span>
                  <svg
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.99998 9.5C4.14916 9.5 4.29224 9.44074 4.39773 9.33525C4.50322 9.22976 4.56248 9.08668 4.56248 8.9375V2.42037L6.97673 4.83575C7.02903 4.88805 7.09111 4.92953 7.15945 4.95784C7.22778 4.98614 7.30102 5.00071 7.37498 5.00071C7.44894 5.00071 7.52218 4.98614 7.59051 4.95784C7.65884 4.92953 7.72093 4.88805 7.77323 4.83575C7.82553 4.78345 7.86701 4.72136 7.89532 4.65303C7.92362 4.5847 7.93819 4.51146 7.93819 4.4375C7.93819 4.36354 7.92362 4.2903 7.89532 4.22197C7.86701 4.15364 7.82553 4.09155 7.77323 4.03925L4.39823 0.664248C4.34598 0.611865 4.2839 0.570304 4.21557 0.541947C4.14723 0.513589 4.07397 0.498993 3.99998 0.498993C3.92599 0.498993 3.85273 0.513589 3.78439 0.541947C3.71605 0.570304 3.65398 0.611865 3.60173 0.664248L0.226728 4.03925C0.121106 4.14487 0.0617676 4.28813 0.0617676 4.4375C0.0617676 4.58687 0.121106 4.73013 0.226728 4.83575C0.332351 4.94137 0.475606 5.00071 0.624978 5.00071C0.774351 5.00071 0.917606 4.94137 1.02323 4.83575L3.43748 2.42037V8.9375C3.43748 9.08668 3.49674 9.22976 3.60223 9.33525C3.70772 9.44074 3.85079 9.5 3.99998 9.5Z"
                      fill="#72C465"
                    />
                  </svg>
                </span>
                <div className="d-flex py-2">
                  <div>
                    <img
                      src={
                        infoStatsRestaurant.repaspluscommande != null &&
                        infoStatsRestaurant.repaspluscommande.image != null
                          ? returnImageLink(
                              infoStatsRestaurant.repaspluscommande.image.id
                            )
                          : ""
                      }
                      className="img-fluid"
                      style={{ borderRadius: "12px" }}
                      width="80px"
                      alt=""
                    />
                  </div>
                  <div>
                    <h6 className="mt-3 mx-2">
                      {infoStatsRestaurant.repaspluscommande != null
                        ? infoStatsRestaurant.repaspluscommande.designation
                        : ""}
                    </h6>
                  </div>
                </div>
              </div>
              {/* <div className='col-4' style={{ borderRight: " 2px solid #e5e5e5", borderLeft: " 2px solid #e5e5e5" }}>
                            
                        </div> */}
              <div className="col-6">
                <span>
                  <span style={{ marginRight: "10px" }}>
                    Plat le moins achetés{" "}
                  </span>
                  <svg
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.99978 0.500002C3.85059 0.500002 3.70752 0.559264 3.60203 0.664754C3.49654 0.770243 3.43728 0.913318 3.43728 1.0625L3.43728 7.57963L1.02303 5.16425C0.970729 5.11195 0.908642 5.07047 0.84031 5.04216C0.771978 5.01386 0.69874 4.99929 0.624778 4.99929C0.550817 4.99929 0.477579 5.01386 0.409247 5.04216C0.340915 5.07047 0.278827 5.11195 0.226528 5.16425C0.174229 5.21655 0.132743 5.27864 0.104439 5.34697C0.0761356 5.4153 0.0615683 5.48854 0.0615683 5.5625C0.0615683 5.63646 0.0761356 5.7097 0.104439 5.77803C0.132743 5.84636 0.174229 5.90845 0.226528 5.96075L3.60153 9.33575C3.65378 9.38814 3.71585 9.4297 3.78419 9.45805C3.85253 9.48641 3.92579 9.50101 3.99978 9.50101C4.07377 9.50101 4.14703 9.48641 4.21537 9.45805C4.2837 9.4297 4.34578 9.38814 4.39803 9.33575L7.77303 5.96075C7.87865 5.85513 7.93799 5.71187 7.93799 5.5625C7.93799 5.41313 7.87865 5.26987 7.77303 5.16425C7.66741 5.05863 7.52415 4.99929 7.37478 4.99929C7.2254 4.99929 7.08215 5.05863 6.97653 5.16425L4.56228 7.57963L4.56228 1.0625C4.56228 0.913318 4.50301 0.770243 4.39753 0.664754C4.29204 0.559264 4.14896 0.500002 3.99978 0.500002Z"
                      fill="#F04949"
                    />
                  </svg>
                </span>
                <div className="d-flex py-2">
                  <div>
                    <img
                      src={
                        infoStatsRestaurant.repasmoinscommande != null &&
                        infoStatsRestaurant.repasmoinscommande.image != null
                          ? returnImageLink(
                              infoStatsRestaurant.repasmoinscommande.image.id
                            )
                          : ""
                      }
                      className="img-fluid"
                      style={{ borderRadius: "12px" }}
                      width="80px"
                      alt=""
                    />
                  </div>
                  <div>
                    <h6 className="mt-3 mx-2">
                      {infoStatsRestaurant.repasmoinscommande != null
                        ? infoStatsRestaurant.repasmoinscommande.designation
                        : ""}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="mt-3">
          <span>
            <b>Etats financier</b>
          </span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant global des commandes{" "}
                </span>
              </span>
              <h6>{infoStatsRestaurant.montantGlobalDesCommandes}F CFA</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant global des versements{" "}
                </span>
              </span>
              <h6>{infoStatsRestaurant.montantGlobalDesVersements}F CFA</h6>
            </div>

            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>Reste à percevoir </span>
              </span>
              <h6>{infoStatsRestaurant.resteAVerser}F CFA</h6>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <span>
            <b>Paiement par Mobile Money</b>
          </span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant global Mobile Money{" "}
                </span>
              </span>
              <h6>{mobileGlobale}F CFA</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant Mobile Money du mois{" "}
                </span>
              </span>
              <h6>{mobileMois}F CFA</h6>
            </div>

            {/* <div className='rounded mx-2 bg-white px-4 py-3 col-3'>
                            <span>
                                <span style={{ marginRight: "10px" }} >Reste à verser </span>
                            </span>
                            <h6>{infoStatsRestaurant.resteAVerser}F CFA</h6>
                        </div> */}
          </div>
        </div>
        <div className="mt-3">
          <span>
            <b>Compte restaurant</b>
          </span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant total des paiements{" "}
                </span>
              </span>
              <h6>
                {infoStatsRestaurant.montantGlobalPaiementPortefeuille}F CFA
              </h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant total des versements{" "}
                </span>
              </span>
              <h6>
                {infoStatsRestaurant.montantTotalVersementCompteResto}F CFA
              </h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>Reste à percevoir</span>
              </span>
              <h6>{infoStatsRestaurant.resteAVerserCompteSkanResto}F CFA</h6>
            </div>
          </div>
          {/* <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Rechargement compte restaurant{" "}
                </span>
              </span>
              <h6>
                {
                  infoStatsRestaurant.montantRechargementCompteRestoParMobileMoneyParMois
                }
                F CFA
              </h6>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default StatsRestauratrice;
