import { Autocomplete, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { recherchertouslesRestaurants } from "../services/restaurants/RestaurantRequest";

function ListeRestaurant(props) {
  let [restaurantSelected, setRestaurantSelected] = useState(null);
  let [listeRestaurant, setListeRestaurant] = useState([]);

  const afficherRestaurant = () => {
    recherchertouslesRestaurants()
      .then((response) => {
        setListeRestaurant(response.data.donnee);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const handlePlatdayRestau = (values) => {
    restaurantSelected = values;
    setRestaurantSelected(restaurantSelected);
    props.setdataSelected(restaurantSelected);
  };

  useEffect(() => {
    afficherRestaurant();
  }, []);

  return (
    <div className=" d-flex justify-content-end">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={listeRestaurant}
        getOptionLabel={(option) => option.nom ?? option}
        value={restaurantSelected}
        onChange={(event, value) => handlePlatdayRestau(value)}
        sx={{ width: props.width !== undefined ? props.width : 180 }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} className="bg-white" label="Restaurants" />
        )}
      />
    </div>
  );
}

export default ListeRestaurant;
