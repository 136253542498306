import { toBeChecked } from "@testing-library/jest-dom/dist/matchers";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Oval } from "react-loader-spinner";
import { rechercherentreprisespartenaires } from "../../services/entreprises/entrepriseRequest";
import { enregistrerdelaiderappel } from "../../services/delais/DelaisRequest";
const DelaisRappelle = (props) => {
  const [listeEntreprise, setlisteEntreprise] = useState([]);
  let [checkbox, setCheckbox] = useState(false);
  let [loading, setLoading] = useState(false);
  let [clearentreprise,setClearentreprise]=useState(null)
  let [cleardelais,setCleardelais]=useState(null)
  let [messageErro,setMessageErro]=useState('')


  const validationSchema = Yup.object().shape({
    entrepriseId: Yup.number(),
    jour: Yup.number("Veuillez entre un nombre entier").required(
      "Veuillez entrer une date de rappelle"
    ),
    tous:Yup.boolean()
  });


  const initialValues = {
    entrepriseId: null,
    jour: null,
    tous: false,
  };

  const handleSubmit = (values) => {
    const { entrepriseId, jour, tous } = values;
    setCleardelais('')
    setClearentreprise('')
    const param = {
      entrepriseId: parseInt(entrepriseId),
      jour: jour,
      tous: tous,
    };
    enregistrerdelaiderappel(param).then((res) => {
      setClearentreprise(null)
      setCleardelais(null)
      setCheckbox(false)
      props.actualisation();
    }).catch((err)=>{
      messageErro=err.response.data.donnee
      setMessageErro(messageErro);
      console.log(err);
    })
  };

  const afficherEntreprise = () => {
    rechercherentreprisespartenaires()
      .then((response) => {
        setlisteEntreprise(response.data.donnee);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  useEffect(() => {
    afficherEntreprise();
  }, []);
  return (
    <>
      <div className="container position-relative ">
        <div className="row">
          <div className="col-md-6 offset-md-3 pt-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ resetForm }) => (
                <Form className="form">
                  <span style={{color:"red"}}>{messageErro}</span>
                  <div className="form-group form-check mb-5">
                    <Field
                      name="tous"
                      type="checkbox"
                      className="form-check-input"
                      onClick={(e)=>setCheckbox(!checkbox)}
                      checked={checkbox}
                    />
                    <label htmlFor="acceptTerms" className="form-check-label">
                      Rappel pour toutes les entreprises
                    </label>
                  </div>
                  {
                    !checkbox&&  <div className="form-group mb-5 entreprise">
                    <label htmlFor="entrepriseId" className="mb-1">
                      Entreprises
                    </label>
                    <Field
                      as="select"
                      type="text"
                      id="entrepriseId"
                      name="entrepriseId"
                      className="form-control entre "
                      value={clearentreprise}
                    >
                      <option selected defaultValue={null}>
                        --Selectionner une entreprise--
                      </option>
                      {listeEntreprise.map((entreprise, index) => (
                        <option value={entreprise.id} key={index}>
                          {entreprise.raisonSociale}
                        </option>
                      ))}
                    </Field>
                  </div>
                  }
                 
                  <div className="form-group mb-3">
                    <label htmlFor="jour" className="mb-1 jour">
                      Entrer le jour de rappel
                    </label>
                    <Field
                      type="number"
                      id="jour"
                      name="jour"
                      className="form-control"
                      value={cleardelais}
                    />
                    <ErrorMessage
                      name="jour"
                      component="small"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group d-flex justify-content-end gap-3">
                    {!loading && (
                      <button
                        type="submit"
                        className="btn btn-rose text-white valider"
                      >
                        Enregistrer
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="reinitialiseFormAddDelais"
                      onClick={resetForm}
                    hidden>
                      Annuler
                    </button>
                    <Oval
                      width={40}
                      height={40}
                      color="#CE5D1D"
                      visible={loading}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default DelaisRappelle;
