import React, { useEffect } from "react";
import { DecodeToken } from "../../DecodeToken";
import HeaderNomResto from "../HeaderNomResto";
import Layout from "../layouts/Layout";
import StatsAdmin from "./StatsAdmin";
import StatsComptable from "./StatsComptable";
import StatsRestauratrice from "./StatsRestauratrice";
import { Navigate } from "react-router-dom";

function Statistiques() {
  return (
    <Layout>
      <HeaderNomResto />
      {DecodeToken().profil === "ROLE_COMPTABLE" && <StatsComptable />}
      {DecodeToken().profil !== "ROLE_COMPTABLE" &&
        DecodeToken().profil !== "ROLE_ADMIN" && <StatsRestauratrice />}
      {DecodeToken().profil === "ROLE_ADMIN" && <StatsAdmin />}
      {DecodeToken().profil === "ROLE_CAISSIERE_ENTREPRISE" && (
        <Navigate to="/parametres" replace={true} />
      )}
    </Layout>
  );
}

export default Statistiques;
