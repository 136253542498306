import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import * as yup from "yup";
import generator from "generate-password-browser";
import { DecodeToken } from "../../DecodeToken";
import { enregistreremploye } from "../../services/employes/EmployesRequest";
import {
  rechercherentreprises,
  recherchersiteparentreprise,
  rechercherSites,
} from "../../services/entreprises/entrepriseRequest";

function AddEmployes(props) {
  const [loading, setloading] = useState(false);
  const [isBuventionne, setisBuventionne] = useState(true);
  const [showGenPassword, setShowGenPassword] = useState(false);
  const [showErrorGenPassword, setShowErrorGenPassword] = useState(false);
  const [theGenPassword, setTheGenPassword] = useState("");
  const [theEmail, setTheEmail] = useState("");
  let [infoentreprise, setInfoentreprise] = useState({});
  let [entrepriseId, setEntrepriseId] = useState("");
  let [siteId, setSiteId] = useState("");
  let [modeprelevement, setModeprelevement] = useState([
    { modeid: 1, option: "SALAIRE", titre: "Salaires" },
    { modeid: 2, option: "AU_CHOIX", titre: "Mobile money" },
  ]);
  let [typeDeRepas, setTypeDeRepas] = useState([
    { idrepas: 1, titre: "Petit-déjeuner", value: "PETIT_DEJEUNER" },
    { idrepas: 2, titre: "Déjeuner", value: "DEJEUNER" },
    { idrepas: 3, titre: "Diner", value: "DINER" },
  ]);
  let [typesubvention, setTypesubvention] = useState([
    { typeid: 1, option: "FIXE", titre: "Valeur fixe" },
    { typeid: 2, option: "POURCENTAGE", titre: "Pourcentage" },
  ]);

  let [listesite, setListesite] = useState([]);
  let [Err, setErr] = useState("");
  let [listeEntreprise, setlisteEntreprise] = useState([]);
  let [id, setId] = useState(null);

  const initialValues = {
    email:
      props.infoEmployes.email ? props.infoEmployes.email : "",
    fonction:
      props.infoEmployes.fonction
        ? props.infoEmployes.fonction
        : "",
    id: props.infoEmployes.id ? props.infoEmployes.id : "",
    matricule:
      props.infoEmployes.matricule
        ? props.infoEmployes.matricule
        : "",
    nom: props.infoEmployes.nom ? props.infoEmployes.nom : "",
    numero:
      props.infoEmployes.numero ? props.infoEmployes.numero : "",
    prenoms:
      props.infoEmployes.prenoms ? props.infoEmployes.prenoms : "",
    sexe: props.infoEmployes.sexe ? props.infoEmployes.sexe : "",
    subvention:
      props.infoEmployes.subvention
        ? props.infoEmployes.subvention
        : "",
    modePrelevement:
      props.infoEmployes.modePrelevement
        ? props.infoEmployes.modePrelevement
        : "",
    siteId:
      props.infoEmployes.site ? props.infoEmployes.site.id : "",
    forfait:
      props.infoEmployes.forfait
        ? props.infoEmployes.forfait
        : "",
    typeSubvention:
      props.infoEmployes.typeSubvention
        ? props.infoEmployes.typeSubvention
        : "POURCENTAGE",
    instant:
      props.infoEmployes.instantUtilisationSubvention
        ? props.infoEmployes.instantUtilisationSubvention
        : "",
    nombreutilisation:
      props.infoEmployes.nombreUtilisationSubvention
        ? props.infoEmployes.nombreUtilisationSubvention
        : "",
    forfaitSubventionJournalier:
      props.infoEmployes.entreprise
        ? props.infoEmployes.forfaitSubventionJournalier
        : "",
    entrepriseId:
      props.infoEmployes.entreprise
        ? props.infoEmployes.entreprise.id
        : "",
    forfaitSubventionMensuel:
      props.infoEmployes.entreprise
        ? props.infoEmployes.forfaitSubventionMensuel
        : "",
    entrepriseId:
      props.infoEmployes.entreprise
        ? props.infoEmployes.entreprise.id
        : "",
  };

  const validationSchemaValues = yup.object().shape({
    // fonction: yup.string().required("Veuillez saisir la fonction"),
    matricule: yup.string().required("Veuillez saisir le matricule"),
    nom: yup.string().required("Veuillez saisir le nom"),
    numero: yup.string().required("Veuillez saisir le numéro"),
    prenoms: yup.string().required("Veuillez saisir au moins un prénom"),
    sexe: yup.string().required("Veuillez sélectionner le sexe"),
    subvention: isBuventionne
      ? yup
          .number("Veuillez saisir des chiffres")
          .required("Veuillez saisir la subvention")
      : yup.number("Veuillez saisir des chiffres"),
    modePrelevement: yup
      .string()
      .required("Veuillez choisir un mode de prélèvement"),
    siteId: yup
      .string()
      .required("Veuillez sélectionner le site de votre emplyé"),
    typeSubvention: yup.string(),
  });

  const afficherEntreprise = () => {
    rechercherentreprises()
      .then((response) => {
        setlisteEntreprise(response.data.donnee);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const afficherLaListeSite = () => {
    rechercherSites()
      .then((response) => {
        setloading(false);
        listesite = response.data.donnee;
        setListesite(listesite);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const onSubmitForm = (values) => {
    setloading(true);
    setErr("");
    if (props.infoEmployes.nom === undefined) {
      if (theEmail === "" && !showGenPassword) {
        setShowErrorGenPassword(true);
        setloading(false);
      } else if (theEmail !== "" && !showGenPassword) {
        setShowErrorGenPassword(false);
        values.entrepriseId = id;
        let valuesToSave = Object.assign(values, {
          isBuventionne: isBuventionne,
          email: theEmail,
        });
        valuesToSave.forfait = valuesToSave.forfait;
        enregistreremploye(valuesToSave)
          .then((res) => {
            setloading(false);
            props.actualiserList(props.pageActive);
            setShowGenPassword(false);
            setShowErrorGenPassword(false);
            setTheGenPassword("");
            setTheEmail("");
            document.getElementById("gen-password").removeAttribute("disabled");
            document.getElementById("reinitialiseFormEmploye").click();
            setErr("");
          })
          .catch((error) => {
            console.log(initialValues);
            Err = error.response.data.donnee;
            setErr(Err);
            setShowGenPassword(false);
            setShowErrorGenPassword(false);
            setTheGenPassword("");
            setTheEmail("");
            document.getElementById("gen-password").removeAttribute("disabled");
            setloading(false);
          });
      } else if (theEmail === "" && showGenPassword) {
        setShowErrorGenPassword(false);
        values.entrepriseId = id;
        let valuesToSave = Object.assign(values, {
          isBuventionne: isBuventionne,
          password: theGenPassword,
        });
        valuesToSave.forfait = valuesToSave.forfait;
        enregistreremploye(valuesToSave)
          .then((res) => {
            setloading(false);
            props.actualiserList(props.pageActive);
            setShowGenPassword(false);
            setShowErrorGenPassword(false);
            setTheGenPassword("");
            setTheEmail("");
            document.getElementById("gen-password").removeAttribute("disabled");
            document.getElementById("reinitialiseFormEmploye").click();
            setErr("");
          })
          .catch((error) => {
            console.log(initialValues);
            Err = error.response.data.donnee;
            setErr(Err);
            setShowErrorGenPassword(false);
            setTheEmail("");
            document.getElementById("gen-password").removeAttribute("disabled");
            setloading(false);
          });
      } else {
        setShowErrorGenPassword(false);
        values.entrepriseId = id;
        let valuesToSave = Object.assign(values, {
          isBuventionne: isBuventionne,
          password: theGenPassword,
          email: theEmail,
        });
        valuesToSave.forfait = valuesToSave.forfait;
        enregistreremploye(valuesToSave)
          .then((res) => {
            setloading(false);
            props.actualiserList(props.pageActive);
            setShowGenPassword(false);
            setShowErrorGenPassword(false);
            setTheGenPassword("");
            setTheEmail("");
            document.getElementById("gen-password").removeAttribute("disabled");
            document.getElementById("reinitialiseFormEmploye").click();
            setErr("");
          })
          .catch((error) => {
            console.log(initialValues);
            Err = error.response.data.donnee;
            setErr(Err);
            setShowErrorGenPassword(false);
            setTheEmail("");
            document.getElementById("gen-password").removeAttribute("disabled");
            setloading(false);
          });
      }
    } else {
      values.entrepriseId = id;
      let valuesToSave = Object.assign(values, {
        isBuventionne: isBuventionne,
        email: theEmail,
      });
      valuesToSave.forfait = valuesToSave.forfait;
      enregistreremploye(valuesToSave)
        .then((res) => {
          setloading(false);
          setShowGenPassword(false);
          setShowErrorGenPassword(false);
          setTheGenPassword("");
          setTheEmail("");
          props.actualiserList(props.pageActive);
          document.getElementById("reinitialiseFormEmploye").click();
          // document.getElementById("gen-password").removeAttribute("disabled")
          setErr("");
        })
        .catch((error) => {
          setloading(false);
          Err = error.response.data.donnee;
          setErr(Err);
          setShowGenPassword(false);
          setShowErrorGenPassword(false);
          setTheGenPassword("");
        });
    }
  };

  const recherchersite = (event) => {
    id = event.target.value;
    setEntrepriseId(id);
    setId(id);
    recherchersiteparentreprise(id).then((res) => {
      setListesite(res.data.donnee);
    });
  };

  const showGenPasswordField = () => {
    const genPassword = generator.generate({
      length: 8,
      numbers: true,
      strict: true
    });
    setShowErrorGenPassword(false);
    setTheGenPassword(genPassword);
    setShowGenPassword((prev) => !prev);
  };

  const handleEmailInput = (e) => {
    setShowErrorGenPassword(false);
    setTheEmail(e.target.value);
  };

  useEffect(() => {
    console.log('LES INFO UTILISATEURS', props.infoEmployes)
    setEntrepriseId("");

    if (
      (props.infoEmployes && props.infoEmployes.entreprise)
    ) {
      entrepriseId = props.infoEmployes.entreprise.id;
      siteId = props.infoEmployes.site.id;
      setSiteId(siteId);
      setEntrepriseId(props.infoEmployes.entreprise.id);
      listesite.push(props.infoEmployes.site);
      setListesite(listesite);
      setTheEmail(props.infoEmployes.email);
    }
    // } else if (
    //   DecodeToken().profil === "ROLE_ADMIN" &&
    //   props.infoEmployes.entreprise
    // ) {
    //   entrepriseId = props.infoEmployes.entreprise.id;
    //   siteId = props.infoEmployes.site.id;
    //   setSiteId(siteId);
    //   setEntrepriseId(props.infoEmployes.entreprise.id);
    //   listesite.push(props.infoEmployes.site);
    //   setListesite(listesite);
    //   setTheEmail(props.infoEmployes.email);
    //   console.log('LOG 2', props.infoEmployes.email)
    // }

    props.infoEmployes.id != undefined &&
      setisBuventionne(props.infoEmployes.isSubventionne);
  }, [props.infoEmployes]);

  useEffect(() => {
    setErr("");
    afficherEntreprise();
    DecodeToken().profil !== "ROLE_ADMIN" && afficherLaListeSite();
  }, []);

  return (
    <div
      className="modal fade"
      id="employes"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        style={{ width: "60%" }}
      >
        <div className="modal-content bg-rose">
          <div className="p-2 d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center ">
              <i className="fa-solid fs-1 fa-users"></i>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchemaValues}
              onSubmit={onSubmitForm}
            >
              {({ resetForm, values }) => (
                <Form>
                  <div
                    className="err"
                    style={{ color: "red", marginTop: "1rem" }}
                  >
                    {Err}{" "}
                  </div>

                  <div className="my-4">
                    <h6 className="text-center">
                    {props.infoEmployes.entreprise != null
                        ? "Modifier"
                        : "Ajouter"} {" "} un employé
                    </h6>
                  </div>
                  <div className="row gx-1 ">
                    <div className="mb-3 col-md-6">
                      <Field
                        type="text"
                        name="nom"
                        id="nom"
                        placeholder="Nom"
                        className="form-control"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="nom"></ErrorMessage>
                      </span>
                    </div>
                    <div className="mb-3 col-md-6">
                      <Field
                        type="text"
                        name="prenoms"
                        id="prenoms"
                        placeholder="Prénoms"
                        className="form-control"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="prenoms"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="row gx-1">
                    <div className="mb-3 col-md-6">
                      <Field
                        component="select"
                        name="sexe"
                        id="sexe"
                        className="form-control form-select"
                      >
                        <option value="">Sexe</option>
                        <option value="F">Féminin</option>
                        <option value="M">Masculin</option>
                      </Field>
                    </div>
                    <div className="mb-3 col-md-6">
                      <Field
                        type="text"
                        name="numero"
                        id="numero"
                        placeholder="Numéro : 01 00 000 000"
                        className="form-control"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="numero"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="row gx-1">
                    <div className="mb-3 col-md-6">
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        placeholder="email"
                        className="form-control"
                        value={theEmail}
                        onChange={(e) => handleEmailInput(e)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <Field
                        type="text"
                        name="fonction"
                        placeholder="Fonction"
                        id="fonction"
                        className="form-control"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="fonction"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="row gx-1">
                    <div className="mb-3 col-md-6">
                      <Field
                        type="text"
                        name="matricule"
                        id="matricule"
                        placeholder="Matricule : MAT001"
                        className="form-control"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="matricule"></ErrorMessage>
                      </span>
                    </div>

                    {DecodeToken().profil === "ROLE_ADMIN" && (
                      <div className="mb-3 col-md-6">
                        <select
                          name="entrepriseId"
                          id="entrepriseId"
                          className="form-control"
                          value={entrepriseId}
                          onChange={recherchersite}
                        >
                          <option selected disabled value="">
                            Sélectionner une entreprise
                          </option>
                          <option value=""></option>
                          {listeEntreprise.map((entreprise, index) => (
                            <option value={entreprise.id} key={index}>
                              {entreprise.raisonSociale}{" "}
                            </option>
                          ))}
                        </select>
                        <span className="text-danger">
                          <ErrorMessage name="entrepriseId"></ErrorMessage>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="row gx-1">
                    <div className="mb-3 col-md-6">
                      <Field
                        as="select"
                        name="modePrelevement"
                        id="modePrelevement"
                        className="form-control"
                      >
                        <option selected disabled value="">
                          (Sélectionner un mode de prélèvement.)
                        </option>
                        {modeprelevement.map((item, index) => (
                          <option value={item.option} key={index}>
                            {item.titre}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="modePrelevement"></ErrorMessage>
                      </span>
                    </div>
                    <div className="mb-3 col-md-6">
                      <Field
                        as="select"
                        name="siteId"
                        id="siteId"
                        className="form-control"
                      >
                        <option selected disabled value="">
                          (Sélectionner un site.)
                        </option>
                        {listesite.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.nom}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="siteId"></ErrorMessage>
                      </span>
                    </div>
                  </div>

                  {props.infoEmployes.nom === undefined ? (
                    <div className="row gx-1">
                      <div className="mb-4 col-md-6">
                        <div className="form-check form-switch mb-2">
                          <input
                            onChange={showGenPasswordField}
                            className="form-check-input"
                            type="checkbox"
                            id="gen-password"
                          />
                          <label htmlFor="gen-password">
                            Générer mot de passe
                          </label>
                        </div>
                        {showErrorGenPassword ? (
                          <div>
                            <p className="text-danger">
                              Générez un mot de passe ou entrez une adresse
                              email
                            </p>
                          </div>
                        ) : null}
                        {showGenPassword ? (
                          <div>
                            <Field
                              type="text"
                              value={theGenPassword}
                              name="genPassword"
                              id="gen-password"
                              className="form-control"
                              disabled
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  <div className="mb-3 gx-1">
                    <div className="form-check form-switch mb-2">
                      <input
                        onChange={(e) => setisBuventionne(!isBuventionne)}
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={isBuventionne}
                      />
                      <label htmlFor="flexSwitchCheckChecked">
                        Subventionné{" "}
                      </label>
                    </div>
                    <div className="row">
                      {isBuventionne && (
                        <div className={isBuventionne && "mb-3 col-md-4"}>
                          <Field
                            type="text"
                            name="subvention"
                            id="subvention"
                            placeholder="Valeur subvention"
                            className="form-control"
                          />
                        </div>
                      )}
                      {isBuventionne && (
                        <div className="mb-3 col-md-4">
                          <Field
                            as="select"
                            name="typeSubvention"
                            id="typeSubvention"
                            className="form-control"
                          >
                            <option selected disabled value="">
                              Type de subvention
                            </option>
                            {typesubvention.map((item, index) => (
                              <option value={item.option} key={index}>
                                {item.titre}
                              </option>
                            ))}
                          </Field>
                          <span className="text-danger">
                            <ErrorMessage name="typeSubvention"></ErrorMessage>
                          </span>
                        </div>
                      )}
                      {isBuventionne && (
                        <div className={isBuventionne && "mb-3 col-md-4"}>
                          <Field
                            type="number"
                            name="nombreutilisation"
                            id="nombreutilisation"
                            placeholder="Nombre d'utilisation de la subvention"
                            className="form-control"
                          />
                        </div>
                      )}
                      {isBuventionne && (
                        <div className={isBuventionne && "mb-3 col-md-4"}>
                          <Field
                            as="select"
                            name="instant"
                            id="instant"
                            className="form-control"
                          >
                            <option selected disabled value="">
                              Repas subventionné
                            </option>
                            <option value=""></option>
                            {typeDeRepas.map((item, index) => (
                              <option value={item.value} key={index}>
                                {item.titre}
                              </option>
                            ))}
                          </Field>
                        </div>
                      )}
                      {/* {isBuventionne && (
                        <div className={isBuventionne && "mb-3 col-md-4"}>
                          <Field
                            type="number"
                            name="forfaitSubventionJournalier"
                            id="forfaitSubventionJournalier"
                            placeholder="Forfait subvention journalière"
                            className="form-control"
                          />
                        </div>
                      )} */}
                      {isBuventionne && (
                        <div className={isBuventionne && "mb-3 col-md-4"}>
                          <Field
                            type="number"
                            name="forfaitSubventionMensuel"
                            id="forfaitSubventionMensuel"
                            placeholder="Forfait subvention mensuel"
                            className="form-control"
                          />
                        </div>
                      )}
                      {values.modePrelevement === "SALAIRE" && (
                        <div className={isBuventionne && "mb-3 col-md-4"}>
                          <Field
                            type="number"
                            name="forfait"
                            id="forfait"
                            placeholder="Forfait mensuel"
                            className="form-control"
                          />
                          <span className="text-danger">
                            <ErrorMessage name="forfait"></ErrorMessage>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-3 row mx-2 my-3  ">
                    <button
                      type="button"
                      className="btn"
                      id="reinitialiseFormEmploye"
                      data-bs-dismiss="modal"
                      onClick={resetForm}
                      hidden
                    ></button>
                    <Oval
                      width={40}
                      height={40}
                      color="#CE5D1D"
                      visible={loading}
                    />
                    {!loading && (
                      <button type="submit" className="btn text-white btn-rose">
                        Enregistrer
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEmployes;
