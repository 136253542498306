import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import {
  enregistreVersionDeploiement,
  recupereVersionDeploiement,
} from "../../services/entreprises/entrepriseRequest";
import Swal from "sweetalert2";
import * as yup from "yup";

function Version() {
  const [loading, setloading] = useState(false);
  const [lastVersion, setLastVersion] = useState("");
  const [messageError, setmessageError] = useState("");

  useEffect(() => {
    recupereVersionDeploiement()
      .then((res) => {
        setLastVersion(res.data.donnee)
      })
      .catch((error) => {
        setmessageError(error.response.data.donnee);
      });
  }, []);

  const initialValues = {
    designation: lastVersion,
    id: "VERSION_EN_DEPLOIEMENT",
    typeEtat: "ACTIF",
  };

  const onSubmitValuesForm = (values) => {
    console.log("FORMIK VALUES", values)
    setmessageError("");
    setloading(true);
    enregistreVersionDeploiement(values)
    .then(() => {
        setloading(false);
        Swal.fire({
        position: "center",
        icon: "success",
        title: "Version enregistré avec succès !",
        showConfirmButton: false,
        timer: 1500,
        });
        setmessageError("");
    })
    .catch((error) => {
        setloading(false);
        console.log(error);
        setmessageError(error.response.data.donnee);
    });
  };

  return (
    <>
      <div className="px-5">
        <Formik
          initialValues={initialValues}
          enableReinitialize
        //   validationSchema={validationSchemaValues}
          onSubmit={onSubmitValuesForm}
        >
          <Form>
            <div className="">
              <div className="mb-3">
                <label htmlFor="oldPassword" className="label-control">
                  Version
                </label>
                <Field
                  type="text"
                  name="designation"
                  id="designation"
                  className="form-control"
                />
                <span className="text-danger">
                  <ErrorMessage name="designation"></ErrorMessage>
                </span>
              </div>
              <div className="mb-3 d-flex justify-content-end">
                {loading ? (
                  <Oval
                    width={30}
                    height={30}
                    color="#CE5D1D"
                    visible={loading}
                  />
                ) : (
                  <button type="submit" className="btn btn-rose text-white">
                    Enregistrer
                  </button>
                )}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default Version;
