import React, { useEffect, useState } from "react";
import { rechercherannees } from "../../services/statistiques/StatRequest";
import { rechercherentreprisespartenaire } from "../../services/statistiques/StatRequest";
import { rechercherrestaurantsautorises } from "../../services/statistiques/StatRequest";
import { DecodeToken } from "../../DecodeToken";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";
import {
  rechercherRestaurantsEnfant,
  recherchertouslesRestaurants,
} from "../../services/restaurants/RestaurantRequest";

function Zonefilter(props) {
  const [listeMois, setlisteMois] = useState([
    { indiceMois: "", libelle: "" },
    { indiceMois: 1, libelle: "Janvier" },
    { indiceMois: 2, libelle: "Février" },
    { indiceMois: 3, libelle: "Mars" },
    { indiceMois: 4, libelle: "Avril" },
    { indiceMois: 5, libelle: "Mai" },
    { indiceMois: 6, libelle: "Juin" },
    { indiceMois: 7, libelle: "Juillet" },
    { indiceMois: 8, libelle: "Août" },
    { indiceMois: 9, libelle: "Septembre" },
    { indiceMois: 10, libelle: "Octobre" },
    { indiceMois: 11, libelle: "Novembre" },
    { indiceMois: 12, libelle: "Décembre" },
  ]);

  const [listeannee, setlisteannee] = useState([]);
  let [moisSelected, setmoisSelected] = useState(new Date().getMonth() + 1);
  let [anneeSelected, setanneeSelected] = useState(new Date().getFullYear());
  let [listeEntreprise, setListeEntreprise] = useState([]);
  let [listeEntrepriseAdmin, setListeEntrepriseAdmin] = useState([]);
  let [listeEntrepriseVide, setlisteEntrepriseVide] = useState([
    {
      id: "",
      raisonSociale: "",
      numero: "",
      enabled: "",
      email: "",
      dateCreation: "",
    },
  ]);
  let [entrepriseSelected, setEntrepriseSelected] = useState({});
  let [listeRestaurant, setListeRestaurant] = useState([]);
  let [restaurantSelected, setResataurantSelected] = useState({});
  let [listeresaturantVide, setlisteRestaurantVide] = useState([
    { id: "", nom: "", numero: "", enabled: "", email: "", dateCreation: "" },
  ]);
  const raisonSociale = " ";

  let [istrue, setIstrue] = useState(true);
  let [istrueresto, setIstrueresto] = useState(true);
  const afficherannee = () => {
    rechercherannees().then((res) => {
      setlisteannee(res.data.donnee);
    });
  };
  const afficherlisteentreprise = () => {
    rechercherentreprisespartenaire().then((res) => {
      setListeEntreprise([listeEntrepriseVide, ...res.data.donnee]);
    });
  };
  const affichertouteentreprise = () => {
    rechercherentreprises().then((response) => {
      setListeEntrepriseAdmin([listeEntrepriseVide, ...response.data.donnee]);
    });
  };
  const afficherlisteRestaurant = () => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
      recherchertouslesRestaurants().then((res) => {
        setListeRestaurant([listeresaturantVide, ...res.data.donnee]);
      });
    }
    if (DecodeToken().profil === "ROLE_COMPTABLE") {
      rechercherrestaurantsautorises().then((res) => {
        setListeRestaurant([listeresaturantVide, ...res.data.donnee]);
      });
    }
    if (DecodeToken().profil === "ROLE_RESTAURATRICE") {
      rechercherRestaurantsEnfant().then((res) => {
        setListeRestaurant([listeresaturantVide, ...res.data.donnee]);
      });
    }
  };

  const handleMois = (indice) => {
    moisSelected = indice;
    setmoisSelected(moisSelected);
    props.paramsFilter.annee = anneeSelected;
    props.paramsFilter.mois = moisSelected;
    props.setparamsFilter(props.paramsFilter);
    props.actualiseStat();
  };

  const handleAnnee = (indiceannee) => {
    anneeSelected = indiceannee;
    setanneeSelected(anneeSelected);
    props.paramsFilter.annee = anneeSelected;
    props.paramsFilter.mois = moisSelected == "" ? "" : moisSelected;
    props.setparamsFilter(props.paramsFilter);
    props.actualiseStat();
  };

  const handleEntrepriseCharge = (item) => {
    setIstrue(false);
    setEntrepriseSelected(item);
    props.paramsFilter.entrepriseId = item.id;
    props.setparamsFilter(props.paramsFilter);
    props.actualiseStat();
  };

  const handleRestaurantCharge = (item) => {
    setIstrueresto(false);
    setResataurantSelected(item);
    props.paramsFilter.restaurantId = item.id;
    props.setparamsFilter(props.paramsFilter);
    props.actualiseStat();
  };

  useEffect(() => {
    afficherannee();
    afficherlisteentreprise();
    affichertouteentreprise();
    afficherlisteRestaurant();
  }, []);

  return (
    <div className="d-flex mt-4">
      <p className="mb-3 fw-bold">Statistiques</p>
      <div className="mx-5 d-flex" style={{ marginLeft: "50px" }}>
        <div>
          <i className="fa fa-filter"></i>
        </div>
        <div>
          {/* <button className='btn btn-sm mx-2 px-4 rounded statsFiltre bg-white'>Jour</button> */}
        </div>
        <div className="dropdown mx-2">
          <button
            type="button"
            id="mois"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-white"
          >
            {
              listeMois.filter((item) => item.indiceMois === moisSelected)[0]
                .libelle
            }{" "}
          </button>
          <ul className="dropdown-menu" aria-labelledby="mois">
            {listeMois.map((item, index) => (
              <li onClick={() => handleMois(item.indiceMois)} key={index}>
                <span
                  className="dropdown-item p-1"
                  style={{ cursor: "pointer" }}
                >
                  {item.libelle}{" "}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown mx-2">
          <button
            type="button"
            id="annee"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-white"
          >
            {anneeSelected}
          </button>
          <ul className="dropdown-menu" aria-labelledby="annee">
            {listeannee.map((item, index) => (
              <li onClick={() => handleAnnee(item)} key={index}>
                <span className="dropdown-item" style={{ cursor: "pointer" }}>
                  {item}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {DecodeToken().profil === "ROLE_ADMIN" && (
          <>
            <div className="dropdown">
              <button
                type="button"
                id="entreprise"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-white"
              >
                {istrue
                  ? "Filtrer par entreprise"
                  : entrepriseSelected != null &&
                    entrepriseSelected.raisonSociale}
              </button>
              <ul
                className="dropdown-menu entreprise"
                aria-labelledby="entreprise"
              >
                {listeEntrepriseAdmin.map((item, index) => (
                  <li onClick={() => handleEntrepriseCharge(item)} key={index}>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {item.raisonSociale}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="dropdown" style={{ marginLeft: "10px" }}>
              <button
                type="button"
                id="restaurant"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-white"
              >
                {istrueresto
                  ? "Filtrer par restaurant"
                  : restaurantSelected !== null && restaurantSelected.nom}
              </button>
              <ul
                className="dropdown-menu entreprise"
                aria-labelledby="restaurant"
              >
                {listeRestaurant.map((item, index) => (
                  <li onClick={() => handleRestaurantCharge(item)} key={index}>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {item.nom}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        {DecodeToken().profil === "ROLE_RESTAURATRICE" && (
          <>
            <div className="dropdown">
              <button
                type="button"
                id="entreprise"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-white"
              >
                {istrue
                  ? "Filtrer par entreprise"
                  : entrepriseSelected != null &&
                    entrepriseSelected.raisonSociale}
              </button>
              <ul
                className="dropdown-menu entreprise"
                aria-labelledby="entreprise"
              >
                {listeEntreprise.map((item, index) => (
                  <li onClick={() => handleEntrepriseCharge(item)} key={index}>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {item.raisonSociale}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dropdown" style={{ marginLeft: "10px" }}>
              <button
                type="button"
                id="restaurant"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-white"
              >
                {istrueresto
                  ? "Filtrer par restaurant enfant"
                  : restaurantSelected !== null && restaurantSelected.nom}
              </button>
              <ul
                className="dropdown-menu entreprise"
                aria-labelledby="restaurant"
              >
                {listeRestaurant.map((item, index) => (
                  <li onClick={() => handleRestaurantCharge(item)} key={index}>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {item.nom}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        {DecodeToken().profil === "ROLE_COMPTABLE" && (
          <div className="dropdown">
            <button
              type="button"
              id="restaurant"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-white"
            >
              {istrueresto
                ? "Filtrer par restauratrice"
                : restaurantSelected !== null && restaurantSelected.nom}
            </button>
            <ul
              className="dropdown-menu entreprise"
              aria-labelledby="restaurant"
            >
              {listeRestaurant.map((item, index) => (
                <li onClick={() => handleRestaurantCharge(item)} key={index}>
                  <span className="dropdown-item" style={{ cursor: "pointer" }}>
                    {item.nom}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Zonefilter;
