import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as yup from 'yup';
import { Oval } from 'react-loader-spinner';
import {enregistrerLivreur} from '../../services/livreurs/livreurRequest'
import { useState } from 'react';
const AddLivreur=(props)=>{
    let [loading,setLaoging]=useState(false);
    let [messageError,setMessageError]=useState("")
    let [Error,setError]=useState("")
    const initialValues = {
        nom:"",
        prenoms:"",
        numero:"",
        email:"",
        password:"",
        passwordConfirm:""
    }
    const validationSchemaValues = yup.object().shape({
        nom: yup.string().required("Veuillez saisir le nom du livreure"),
        prenoms: yup.string().required("Veuillez saisir le prénoms du livreure"),
        numero: yup.string().required("Veuillez saisir le numéro du livreure"),
        password: yup.string().required("Veuillez saisir le mot de passe du livreure"),
        passwordConfirm: yup.string().required("Confirmer le mot de passe du livreure")

    });
   const onSubmitForm=(values)=>{
    setLaoging(false)
   console.log(values);
   setError('')
   if(values.password===values.passwordConfirm){
       enregistrerLivreur(values).then((res)=>{
           setLaoging(false);
           props.actualiserliste()
           document.getElementById('reinitialiseFormLivreur').click();
           setError('')
       }).catch((error)=>{
        Error=error.response.data.donnee
        setError(Error)
           setLaoging(false)
       })
   }else{
    const message="Le mot de passe n'est pas correct";
    setMessageError(message);
   }
   }

    return(
        <>
             <div className="modal fade" id="entreprise" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered ">
                <div className="modal-content bg-rose">

                    <div className="modal-header">
                        <h5 className="modal-title fw-bold">Ajouter un livreur</h5>
                    </div>
                    <div className="modal-body">

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchemaValues}
                            onSubmit={(values)=>onSubmitForm(values)}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <span className="text-danger">{Error}</span>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="nom" className='label-control'>Nom</label>
                                            <Field type="text" name="nom" id="raisonSociale" placeholder="Entrer le nom du livreur" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='nom'></ErrorMessage></span>
                                        </div>

                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="prenoms" className='label-control'>Prénoms</label>
                                            <Field type="text" name="prenoms" id="prenoms" placeholder="Entrer le prénoms du livreur" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='prenoms'></ErrorMessage></span>
                                        </div>

                                    </div>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                                <label htmlFor="numero" className='label-control'>Numéro</label>
                                                <Field type="text" name="numero" id="numero" placeholder="Entrer le numéro du livreur" className='form-control' />
                                                <span className='text-danger'><ErrorMessage name='numero'></ErrorMessage></span>
                                                <div className="err" style={{color:"red"}}>{Error}</div>
                                            </div>
                                            <div className='mb-3 col-md-6'>
                                                <label htmlFor="email" className='label-control'>Email</label>
                                                <Field type="email" name="email" id="email" placeholder="Entrer l'email du livreur" className='form-control' />
                                                <span className='text-danger'><ErrorMessage name='email'></ErrorMessage></span>
                                            </div>
                                            <div className='mb-3 col-md-6'>
                                                <label htmlFor="password" className='label-control'>Mot de passe</label>
                                                <Field type="text" name="password" id="password" placeholder="Entrer le mot de passe du livreur" className='form-control' />
                                                <span className='text-danger'><ErrorMessage name='password'></ErrorMessage></span>
                                            </div>
                                            <div className='mb-3 col-md-6'>
                                                <label htmlFor="passwordConfirm" className='label-control'>Confirmer le mot de passe</label>
                                                <Field type="text" name="passwordConfirm" id="passwordConfirm" placeholder="Entrer à nouveau le mot de passe du livreur" className='form-control' />
                                                <span className='text-danger'><ErrorMessage name='passwordConfirm'></ErrorMessage></span>
                                                <span className='text-danger' id='message'>{messageError} </span>
                                            </div>
                                        </div>
                                    <div className='mb-3 d-flex justify-content-between mx-2 my-3  '>
                                    <button type='button' className='btn btn-dark' id='reinitialiseFormLivreur' data-bs-dismiss="modal" onClick={resetForm} >Annuler</button>
                                        <Oval
                                            width={40}
                                            height={40}
                                            color="#CE5D1D"
                                            visible={loading}
                                        />
                                        {!loading && <button type='submit' className='btn text-white btn-rose' > <i className="bi bi-check-circle-fill"></i>  Enregistrer</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}
export default AddLivreur;