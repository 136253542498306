import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

const NBRE_ELEMENT=7
export const rechercherPortefeuille = async(page, size = NBRE_ELEMENT) =>{
    return await axios.get(baseUrl(`rechercherportefeuilles?page=${page}&size=${size}`));
}

export const enregistrerPortefeuilleCredit = async(values) =>{
    return await axios.post(baseUrl('crediterportefeuille'), values);
}

export const enregistrerPortefeuilleDebit = async(values) =>{
    return await axios.post(baseUrl('admin/debiterportefeuille'), values);
}

export const updatePortefeuilleCredit = async(values) =>{
    return await axios.post(baseUrl('admin/modifiercredit'), values);
}

export const soldePortefeuille = async() =>{
    return await axios.get(baseUrl('entreprise/recherchersoldeportefeuilleentreprise'));
}

export const ajoutDecaissement = async(values) =>{
    return await axios.post(baseUrl('entreprise/enregistrerDecaissement'), values);
}

export const listeSoldeDecaissement = async(page, size = NBRE_ELEMENT) =>{
    return await axios.get(baseUrl(`entreprise/rechercherdecaissements?page=${page}&size=${size}`));
}

export const rechercherPortefeuilleByParam = async(page = 0, values) =>{
    return await axios.post(baseUrl(`rechercherportefeuillesparparam?page=${page}&size=${NBRE_ELEMENT}`), values);
}

export const rechercherPortefeuilleByEntreprise = async(entreprise, page = 0) =>{
    return await axios.post(baseUrl(`rechercherportefeuillesparparam?entrepriseId=${entreprise}&page=${page}&size=${NBRE_ELEMENT}`),{});
}

