import axios from "axios";
import { headers } from "../../headers";
import { baseUrl } from "../BaseUrl";

const NBRE_ELEMENT=7

export const getListeQuestionnaire = async (page) => {
  return await axios.get(
    baseUrl(`admin/rechercherquestionnaires?page=${page}&size=${NBRE_ELEMENT}`),
    {}
  );
};

export const enregistrerNewQuestion = async (values) => {
  return await axios.post(
    baseUrl("admin/enregistrerquestionnaire"),
    values
  );
};

export const deleteQuestionFromList = async (id) => {
  return await axios.delete(
    baseUrl(`admin/supprimerquestionnaire/${id}`),
    {}
  );
};


export const getListeReponse = async (questionId) => {
  return await axios.get(
    baseUrl(`admin/rechercherlistereponseparquestion/${questionId}`),
    {}
  );
};

export const enregistrerNewReponse = async (values) => {
  return await axios.post(
    baseUrl("admin/enregistrerreponse"),
    values
  );
};

export const deleteReponseFromList = async (id) => {
  return await axios.delete(
    baseUrl(`admin/supprimerreponse/${id}`),
    {}
  );
};
