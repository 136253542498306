import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { rechercherentreprises } from "../services/entreprises/entrepriseRequest";

import { DecodeToken } from "../DecodeToken";

function ModeRestauration(props) {
  const [modeRestaurationSelected, setModeRestaurationSelected] =
    useState(null);
  const [listeModeRestauration, setListeModeRestauration] = useState([
    {
      libelle: "Livraison",
      value: "LIVRAISON",
    },
    {
      libelle: "Manger sur place",
      value: "MANGER_SUR_PLACE",
    },
  ]);

  const hadleChoixMode = (values) => {
    setModeRestaurationSelected(values);
    props.setModeRestaurationSelected(values);
    props.rechercherCommandeParModeRestauration(values)
  };

  return (
    <div className=" d-flex justify-content-end">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={listeModeRestauration}
        getOptionLabel={(option) => option.libelle ?? option}
        value={modeRestaurationSelected}
        onChange={(event, value) => {
          hadleChoixMode(value);
        }}
        sx={{ width: props.width !== undefined ? props.width : 250 }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} className="bg-white" label="Mode récupération repas" />
        )}
      />
    </div>
  );
}

export default ModeRestauration;
