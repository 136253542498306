import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { DecodeToken } from "../../DecodeToken";
import { Navigate } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import AddCaractereSubvention from "./AddCaractereSubvention";
import Paginate from "../pagination";
import { rechercherCaractereSubvention, supprimerCaractereSubvention } from "../../services/parametre/ParametreRequest";

function CaractereSubvention() {
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [pageActive, setPageActive] = useState(1);
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [infoCaractere, setInfoCaractere] = useState("");
  const [caractereIdToDelete, setCaractereIdToDelete] = useState("");
  const [listeCaractereSubvention, setListeCaractereSubvention] = useState([]);

  let rowListeLivreurs = listeCaractereSubvention.map((caractere, index) => (
    <tr key={index}>
      <td>{caractere.entreprise ? caractere.entreprise.raisonSociale : ""} </td>
      <td>
        {caractere.caractere && caractere.caractere === "NON_CUMULE"
          ? "Non cumulé"
          : "Cumulé"}{" "}
      </td>
      <td>
        {caractere.periode && caractere.periode === "MOIS"
          ? "Mensuelle"
          : "Journalier"}{" "}
      </td>
      <td>{caractere.dateDebut} </td>
      <td>
        <button
          data-bs-target="#caratereForm"
          data-bs-toggle="modal"
          onClick={() => setInfoCaractere(caractere)}
          className="btn btn-sm"
        >
          <i className="fa fa-pencil"></i>
        </button>
        <button
          onClick={() => suprimerCompteutilisateur(caractere)}
          className="btn bg-danger btn-sm text-white"
        >
          Supprimer
        </button>
      </td>
    </tr>
  ));

  const suprimerCompteutilisateur = (caractere) => {
    setCaractereIdToDelete(caractere.id);
    setShowSweetAlert(true);
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
  };

  const afficherListeCatactere = (currentPage) => {
    setLoading(true);
    rechercherCaractereSubvention(currentPage - 1)
      .then((res) => {
        setLoading(false);
        setListeCaractereSubvention(res.data.donnee.parametres);
        setTotalElementByPage(res.data.donnee.numberOfElements);
        settotalElement(res.data.donnee.totalElements);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteCaractere = (caractereId) => {
    supprimerCaractereSubvention(caractereId).then((res) => {
      setShowSweetAlert(false);
      afficherListeCatactere(pageActive);
    });
  };

  useEffect(() => {
    afficherListeCatactere(pageActive);
  }, [pageActive]);

  useEffect(() => {
    afficherListeCatactere(pageActive);
  }, []);

  return (
    <div className="w-100">
      {DecodeToken().profil !== "ROLE_ADMIN" && (
        <Navigate to="/login" replace />
      )}
      <div className="d-flex justify-content-between">
        <div className="">
          <div className="input-group bg-gris rounded mt-1 mb-3" hidden>
            <div className="input-group-text">
              <i className="fa fa-search text-secondary"></i>
            </div>
            <input
              type="search"
              // onChange={handlFiltre}
              placeholder="Recherche..."
              name=""
              id=""
              className="form-control bg-gris form-control-sm loginborderleft"
            />
          </div>
        </div>
        <div className="mb-2">
          <button
            className="btn btn-sm btn-rose text-white"
            data-bs-target="#caratereForm"
            data-bs-toggle="modal"
            onClick={() => setInfoCaractere("")}
          >
            AJOUTER
          </button>
        </div>
        {
          <AddCaractereSubvention
            infoCaractere={infoCaractere}
            actualiserliste={afficherListeCatactere}
          />
        }
      </div>
      <table className="table">
        <thead>
          <tr className="gray-table rounded">
            <th>Entreprise</th>
            <th width="20%">Caractère</th>
            <th width="20%">Période</th>
            <th width="15%">Date</th>
            <th width="15%">Actions</th>
          </tr>
        </thead>
        <tbody>{!loading && rowListeLivreurs}</tbody>
        <tfoot>
          <tr>
            <th colSpan="5">
              <div className="d-flex justify-content-center">
                <Paginate
                  pageActive={pageActive}
                  handlePageChange={handlePageChange}
                  nbreElementParPage={totalElementByPage}
                  nbreTotalElmentPage={totalElement}
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
      <div className="d-flex justify-content-center">
        <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
      </div>

      <SweetAlert
        show={showSweetAlert}
        title="Confirmer la suppression"
        text="Cette action est irréversible"
        showCancelButton
        onConfirm={() => deleteCaractere(caractereIdToDelete)}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
        onEscapeKey={() => setShowSweetAlert(false)}
        onOutsideClick={() => setShowSweetAlert(false)}
      />
    </div>
  );
}

export default CaractereSubvention;
