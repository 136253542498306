import React, { useState } from 'react'
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as yup from 'yup';
import { enregistrervendeuse } from '../../services/vendeuses/VendeuseRequest';
import { Oval } from 'react-loader-spinner'

function AddVendeur(props) {

    let [messageError, setmessageError] = useState("");
    const [loading, setloading] = useState(false);
    let [file,setFile]=useState([]);
    const initialValues = {
        id: props.infovendeuse.id !== undefined ? props.infovendeuse.id : "",
        nom: props.infovendeuse.nom !== undefined ? props.infovendeuse.nom : "",
        prenoms: props.infovendeuse.prenoms !== undefined ? props.infovendeuse.prenoms : "",
        adresse: props.infovendeuse.adresse !== undefined ? props.infovendeuse.adresse : "",
        numero: props.infovendeuse.numero !== undefined ? props.infovendeuse.numero : "",
    }
    const validationSchemaValues = yup.object().shape({
        nom: yup.string().required("Veuillez saisir le nom"),
        prenoms: yup.string().required("Veuillez saisir au moins un prénom"),
        adresse: yup.string().required("Veuillez saisir l'adresse de la vendeuse"),
        numero: yup.string().required("Veuillez saisir le numéro de la vendeuse")
    });
const handleImage=(e)=>{
    file=e.target.files[0];
    setFile(file);
}
    const onSubmitForm = (values) => {
        setloading(true);
        const data=new FormData();
        data.append("id",values.id)
        data.append("nom",values.nom)
        data.append("prenoms",values.prenoms)
        data.append("adresse",values.adresse)
        data.append("numero",values.numero)
        data.append("email",values.email)
        data.append("visuel",file)
        data.append("visuelId","")
        
        setmessageError('')
        
        enregistrervendeuse(data).then(() => {
            setloading(false);
            props.actualisteListe(props.pageActive);
            document.getElementById('reinitialiseFormVendeuse').click();
            setmessageError('')
        }).catch((error) => {
            setloading(false);
            messageError=error.response.data.donnee
            setmessageError(messageError)
        })
    }

    return (
        <div className="modal fade" id="vendeur" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered ">
                <div className="modal-content bg-rose">

                    <div className="modal-header">
                        <h5 className="modal-title fw-bold">Ajouter une vendeuse</h5>
                    </div>
                    <div className="modal-body">

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchemaValues}
                            onSubmit={onSubmitForm}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <div className="err" style={{color:"red"}}>{messageError}</div>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="nom" className='label-control'>Nom</label>
                                            <Field type="text" name="nom" id="nom" placeholder="Entrer le nom de la vendeuse" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='nom'></ErrorMessage></span>
                                        </div>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="prenoms" className='label-control'>Prénoms</label>
                                            <Field type="text" name="prenoms" id="prenoms" placeholder="Entrer le prénoms de la vendeuse" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='prenoms'></ErrorMessage></span>
                                        </div>


                                    </div>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="adresse" className='label-control'>Adresse</label>
                                            <Field type="text" name="adresse" id="adresse" placeholder="Entrer l'adresse" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='adresse'></ErrorMessage></span>
                                        </div>
                                       
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="numero" className='label-control'>Numéro</label>
                                            <Field type="text" name="numero" id="numero" placeholder="Entrer le numéro" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='numero'></ErrorMessage></span>
                                        </div>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="image" className='label-control'>Image</label>
                                            <Field type="file" name="image" id="image" accept="image/*"  className='form-control' onChange={handleImage} />
                                        </div>

                                    </div>
                                    <div className='mb-3 d-flex justify-content-between mx-2 my-3  '>
                                        <button type='button' className='btn btn-dark' id='reinitialiseFormVendeuse' data-bs-dismiss="modal" onClick={resetForm} >Annuler</button>
                                        <Oval
                                            width={40}
                                            height={40}
                                            color="#CE5D1D"
                                            visible={loading}
                                        />
                                        {!loading && <button type='submit' className='btn text-white btn-rose'> <i className="bi bi-check-circle-fill"></i>  Enregistrer</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddVendeur