
import './App.css';
// import { BrowserRouter } from 'react-router-dom';
import RoutesApp from './RoutesApp';
import { HashRouter } from 'react-router-dom';

function App() {
  return (
    <HashRouter hashType='slash'>
      <RoutesApp></RoutesApp>
    </HashRouter>
    // <BrowserRouter>
    //   <RoutesApp></RoutesApp>
    // </BrowserRouter>

  );
}

export default App;
