import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import * as yup from 'yup'
import ListeVendeuse from '../ListeVendeuse';
import {Oval} from 'react-loader-spinner'
import { enregistrerversementvendeuse } from '../../services/versements/versementRequest';
import moment from 'moment';

function AddVersementVendeuse(props) {

    const [vendeuseSelected, setvendeuseSelected] = useState(null);
    const [loading, setloading] = useState(false);
    let [messageError,setmessageError]=useState('')

    let initialValues = {
        id: props.infoversementvendeuse.id !== undefined ? props.infoversementvendeuse.id : "",
        vendeuseId: "",
        montant:  props.infoversementvendeuse.montant !== undefined ? props.infoversementvendeuse.montant : "",
        date: props.infoversementvendeuse.date !== undefined ? props.infoversementvendeuse.date : ""

    }

    const validationSchemaValues = yup.object().shape({
        montant: yup.number().required("Veuillez saisir un montant"),
        date: yup.date("Veuillez saisir une date valide").required("Veuillez saisir une date"),
    });

    const onSubmitForm = (values) => {
        setloading(true);
        values.vendeuseId = vendeuseSelected !== null ? vendeuseSelected.id : "";
        values.date = moment(values.date).format('DD-MM-yyyy')
        setmessageError('')
        enregistrerversementvendeuse(values).then(()=>{
            setloading(false);
            setvendeuseSelected(null);
            document.getElementById('reinitialiseFormVersementVendeuse').click();
            props.actualisteListe(1);
            setmessageError('')
        }).catch((error)=>{
            setloading(false);
            messageError=error.response.data.donnee
            setmessageError(messageError)
        })
    }

    return (
        <div className="modal fade" id="versementvendeuse" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content bg-rose">

                    <div className="modal-header">
                        <h5 className="modal-title fw-bold">Nouveau versement</h5>
                    </div>
                    <div className="modal-body">

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchemaValues}
                            onSubmit={onSubmitForm}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <span className="text-danger">
                                       {messageError} 
                                    </span>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="Vendeuse" className='label-control my-1'>Vendeuse</label>
                                            <ListeVendeuse setdataSelected={setvendeuseSelected} />
                                        </div>

                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="montant" className='label-control'>Montant</label>
                                            <Field type="text" name="montant" id="montant" placeholder="Entrer le montant" className='form-control my-2' />
                                            <span className='text-danger'><ErrorMessage name='montant'></ErrorMessage></span>
                                        </div>

                                    </div>
                                    <div className='row gx-1'>

                                        <label htmlFor="date" className='label-control'>Date de versement</label>
                                        <Field type="date" name="date" id="date" placeholder="dd/mm/aaaa" className='form-control' />
                                        <span className='text-danger'><ErrorMessage name='date'></ErrorMessage></span>

                                    </div>
                                    <div className='mb-3 d-flex justify-content-between mx-2 my-3  '>
                                        <button type='button' className='btn btn-dark' id='reinitialiseFormVersementVendeuse' data-bs-dismiss="modal" onClick={resetForm} >Annuler</button>
                                        <Oval
                                            width={40}
                                            height={40}
                                            color="#CE5D1D"
                                            visible={loading}
                                        />
                                        {!loading && <button type='submit' className='btn text-white btn-rose'> <i className="bi bi-check-circle-fill"></i>  Enregistrer</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddVersementVendeuse