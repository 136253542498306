import moment from "moment";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { DecodeToken } from "../../DecodeToken";
import { rechercherquantitecommandesparjourparrepas } from "../../services/commandes/CommandeRequest";
import { returnImageLink } from "../ReturnImageLink";

function CommandPlats(props) {
  const [loading, setloading] = useState(false);
  const [listeCommandes, setlisteCommandes] = useState([]);
  let [dataFiltre, setdataFiltre] = useState({
    employeId: "",
    entrepriseId: "",
    repasId: "",
    vendeuseId: "",
    modeRecuperation: "",
    restaurantParentId: ""
  });

  const afficherlisteRepasJour = (date, filtre) => {
    setloading(true);
    rechercherquantitecommandesparjourparrepas(
      moment(date).format("yyyy-MM-DD"),
      filtre
    )
      .then((res) => {
        setloading(false);
        setlisteCommandes(res.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const rowListeCommandes = listeCommandes.map((item, index) => (
    <tr key={index}>
      {DecodeToken().profil !== "ROLE_RESTAURATRICE" && (
        <td>
          {item.repas !== null && item.repas.vendeuse !== null
            ? item.repas.vendeuse.nom + " " + item.repas.vendeuse.prenoms
            : item.repas.restaurant !== null && item.repas.restaurant.nom}
        </td>
      )}
      <td>
        {item.repas.image ? (
          <img
            src={returnImageLink(item.repas.image ? item.repas.image.id : "")}
            width="50"
            className="rounded img-fluid"
            alt="Image plat"
            style={{ marginRight: "8px" }}
          />
        ) : null}
        {/* {item.repas !== null && item.repas.designation} */}
        {item.repas.designation}
      </td>
      <td>{item.nombreCommande}</td>
      <td>{item.montantcommande}F CFA</td>
    </tr>
  ));

  useEffect(() => {
    if (DecodeToken().profil !== "ROLE_RESTAURATRICE") {
      dataFiltre.vendeuseId = props.vendeuse !== null ? props.vendeuse.id : "";
      dataFiltre.restaurant =
        props.restaurant && props.restaurant.id ? props.restaurant.id : props.restaurantParent && props.restaurantParent.id ? props.restaurantParent.id : "";
      dataFiltre.statut = props.statut !== null ? props.statut.valeur : "";
      dataFiltre.restaurantParentId = props.restaurantParent && props.restaurantParent.id ? props.restaurantParent.id : "";
      dataFiltre.modeRecuperation =
        props.modeRecuperation !== null ? props.modeRecuperation.value : "";
      if (DecodeToken().profil === "ROLE_ADMIN") {
        dataFiltre.entrepriseId =
          props.entreprise !== null ? props.entreprise.id : "";
        dataFiltre.siteId =
          props.entreprise !== null && props.siteEntreprise !== null ? props.entreprise.id : "";
      }
      setdataFiltre(dataFiltre);
    } else {
      dataFiltre.entrepriseId =
        props.entreprise !== null ? props.entreprise.id : "";
      dataFiltre.siteId =
        props.entreprise !== null && props.siteEntreprise !== null ? props.siteEntreprise.id : "";
      dataFiltre.restaurant =
        props.restaurant !== null ? props.restaurant.id : props.restaurantParent && props.restaurantParent.id ? props.restaurantParent.id : "";
      dataFiltre.statut = props.statut !== null ? props.statut.valeur : "";
      dataFiltre.modeRecuperation =
        props.modeRecuperation !== null ? props.modeRecuperation.value : "";
      setdataFiltre(dataFiltre);
    }
    afficherlisteRepasJour(props.jourSelected, dataFiltre);
  }, [
    props.jourSelected,
    props.vendeuse,
    props.entreprise,
    props.siteEntreprise,
    props.modeRecuperation,
    props.restaurant,
    props.restaurantParent,
    props.statut,
  ]);

  return (
    <>
      <table className="table">
        <thead>
          <tr className="gray-table rounded">
            {DecodeToken().profil !== "ROLE_RESTAURATRICE" && (
              <th>
                Vendeuse
              </th>
            )}
            <th>
              Plat
            </th>
            <th>
              Nombre de commande
            </th>
            <th>
              Coût
            </th>
          </tr>
        </thead>
        <tbody>{!loading && rowListeCommandes}</tbody>
      </table>
      {
        <div className="d-flex justify-content-center">
          <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
        </div>
      }
    </>
  );
}

export default CommandPlats;
