import React, { useEffect, useState } from "react";
import SnackbarProvider from "react-simple-snackbar";
import { Navigate } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import { DecodeToken } from "../../DecodeToken";
import AddVendeur from "./AddVendeur";
import { Oval } from "react-loader-spinner";
import Layout from "../layouts/Layout";
import HeaderNomResto from "../HeaderNomResto";
import {
  autoriservendeuse,
  changerEtatVendeuse,
  recherchervendeuses,
  recherchervendeusesparparam,
  refreshVendeuse,
  retirervendeuse,
  supprimerVendeuse,
} from "../../services/vendeuses/VendeuseRequest";
import { fichier } from "../../services/restauratrice/RestauratriceRequest";
import Paginate from "../pagination";
import $ from "jquery";
import { updatePasswordUtilisateurs } from "../../services/utilisateurs/UtilisateurRequest";
import SnackbarComponent from "./Snackbar";


function Vendeur() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showSweetAlertRefresh, setShowSweetAlertRefresh] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [vendeuseIdToDelete, setVendeuseIdToDelete] = useState("");
  const [vendeuseIdToRefresh, setVendeuseIdToRefresh] = useState("");
  const [infovendeur, setinfovendeur] = useState({});
  const [loading, setloading] = useState(false);
  const [listeVendeur, setlisteVendeur] = useState({});
  const [pageActive, setPageActive] = useState(1);
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [filterParametres, setfilterParametres] = useState({ param: "" });
  const [usernameToUpdate, setUserNameToUpdate] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const afficherVendeur = (currentPage) => {
    setloading(true);
    recherchervendeuses(currentPage - 1)
      .then((response) => {
        setloading(false);
        setlisteVendeur(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch(() => {
        setloading(false);
      });
  };
  const lienImage = (id) => {
    if (id != null && id != undefined) {
      return fichier(id);
    } else {
      return "";
    }
  };
  const afficherVendeurFiltrer = (filtrePram, currentPage) => {
    setloading(true);
    recherchervendeusesparparam(currentPage - 1, filtrePram)
      .then((response) => {
        setloading(false);
        setlisteVendeur(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch(() => {
        setloading(false);
      });
  };
  const handleAutorized = (id) => {
    setloading(true);
    autoriservendeuse(id)
      .then(() => {
        setloading(false);
        afficherVendeur(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handleUnAutorized = (id) => {
    setloading(true);
    retirervendeuse(id)
      .then(() => {
        setloading(false);
        afficherVendeur(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    filterParametres.param === ""
      ? afficherVendeur(pageNumber)
      : afficherVendeurFiltrer(filterParametres.param, 1);
  };

  const handleSearchVendeuse = (parametre) => {
    setfilterParametres({ param: parametre });
    afficherVendeurFiltrer(parametre, 1);
  };

  const getVendeuseIdToDelete = (vendeuseId) => {
    setShowSweetAlert(true);
    setVendeuseIdToDelete(vendeuseId);
  };

  const getVendeuseIdToRefresh = (vendeuseId) => {
    setShowSweetAlertRefresh(true);
    setVendeuseIdToRefresh(vendeuseId);
  };

  const deleteVendeuse = (id) => {
    setloading(true);
    supprimerVendeuse(id)
      .then((res) => {
        setloading(false);
        afficherVendeur(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const changeEtatVendeuse = (vendeuseId) => {
    setloading(true);
    changerEtatVendeuse(vendeuseId)
      .then((res) => {
        setloading(false);
        afficherVendeur(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const updateVendeusePassword = () => {
    const dataToSend = {
      username: usernameToUpdate,
      newPassword: newPassword,
    };

    setloading(true);
    updatePasswordUtilisateurs(dataToSend)
      .then((response) => {
        setloading(false);
        afficherVendeur(pageActive);
        $("#update-password").trigger("click");
      })
      .catch((error) => {
        setloading(false);
        afficherVendeur(pageActive);
      });
  };

  const refreshAccountVendeuse = (vendeuseId) => {
    setloading(true);

    refreshVendeuse(vendeuseId)
      .then((res) => {
        setloading(false);
        setShowSnackbar(true)
        setSnackbarMessage('Compte utilisateur créé avec succès')
        afficherVendeur(pageActive);
      })
      .catch((error) => {
        setShowSnackbar(true)
        if (error.response.data) {
          setSnackbarMessage(error.response.data.donnee)
        } else {
          setSnackbarMessage('Une erreur s\'est updatePasswordUtilisateurs, rééssayer!')
        }
        setloading(false);
      });
  };

  const rowListeVendeur =
    listeVendeur.vendeuses !== undefined &&
    listeVendeur.vendeuses.map((item, index) => (
      <tr key={index}>
        <td>
          <img src={lienImage(item.visuelId)} alt="" className="img" />
        </td>
        <td>{item.nom + " " + item.prenoms}</td>
        <td>{item.numero}</td>
        <td>{item.adresse}</td>
        <td>
          {DecodeToken().profil === "ROLE_COMPTABLE" && !item.autorise && (
            <button
              onClick={() => handleAutorized(item.id)}
              className="btn btn-rose btn-sm mx-2 text-white"
            >
              Ajouter
            </button>
          )}
          {DecodeToken().profil === "ROLE_COMPTABLE" && item.autorise && (
            <button
              onClick={() => handleUnAutorized(item.id)}
              className="btn btn-danger btn-sm mx-2 text-white"
            >
              Retirer
            </button>
          )}
          {DecodeToken().profil === "ROLE_ADMIN" && (
            <>
              <button
                data-bs-target="#vendeur"
                data-bs-toggle="modal"
                onClick={() => setinfovendeur(item)}
                className="btn bg-primary btn-sm text-white"
              >
                Modifier
              </button>
              <button
                className="btn bg-success btn-sm text-white mx-1"
                onClick={() => getVendeuseIdToRefresh(item.id)}
              >
                <i className="fa fa-refresh white-table-bottom"></i>
              </button>
              {/* <button
                onClick={() => {
                  setUserNameToUpdate(item.numero);
                  setNewPassword("");
                }}
                className="btn bg-success btn-sm text-white mx-1"
                data-bs-toggle="modal"
                data-bs-target="#updatePassword"
              >
                <i className="fa fa-key white-table-bottom"></i>
              </button> */}
              <button
                onClick={() => changeEtatVendeuse(item.id)}
                className="btn bg-secondary btn-sm text-white mx-1"
              >
                {item.enabled ? "Désactiver" : "Activer"}
              </button>
              <button
                onClick={() => getVendeuseIdToDelete(item.id)}
                className="btn bg-danger btn-sm text-white"
              >
                <i className="fa fa-trash white-table-bottom"></i>
              </button>
            </>
          )}
        </td>
      </tr>
    ));

  useEffect(() => {
    afficherVendeur(pageActive);
  }, [pageActive]);

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_ADMIN" &&
        DecodeToken().profil !== "ROLE_COMPTABLE" && (
          <Navigate to="/login" replace />
        )}
      <HeaderNomResto />
      <p className="mb-3 mt-3 fw-bold">Vendeurs(ses) enregistrées</p>
      <div className="bg-white rounded p-2">
        <div className="d-flex justify-content-between">
          <div className="">
            <div className="input-group bg-gris rounded mt-1 mb-3">
              <div className="input-group-text">
                <i className="fa fa-search text-secondary"></i>
              </div>
              <input
                type="search"
                onChange={(e) => handleSearchVendeuse(e.target.value)}
                placeholder="Recherche..."
                name=""
                id=""
                className="form-control bg-gris form-control-sm loginborderleft"
                autoComplete="off"
              />
            </div>
          </div>
          {DecodeToken().profil === "ROLE_ADMIN" && (
            <div className="">
              <button
                onClick={() => setinfovendeur({})}
                className="btn btn-sm btn-rose text-white"
                data-bs-target="#vendeur"
                data-bs-toggle="modal"
              >
                AJOUTER
              </button>
            </div>
          )}
          <AddVendeur
            infovendeuse={infovendeur}
            actualisteListe={afficherVendeur}
            pageActive={pageActive}
          />
        </div>
        <table className="table">
          <thead>
            <tr className="gray-table rounded">
              <th width="8%">Image</th>
              <th width="20%">Nom & Prénoms</th>
              <th width="10%">Numéro</th>
              <th width="10%">Adresse</th>
              <th width="20%">
                <span className="mx-3">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>{!loading && rowListeVendeur}</tbody>
          <tfoot>
            <tr>
              <th colSpan="6">
                <div className="d-flex justify-content-center">
                  <Paginate
                    pageActive={pageActive}
                    handlePageChange={handlePageChange}
                    nbreElementParPage={totalElementByPage}
                    nbreTotalElmentPage={totalElement}
                  />
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
        {
          <div className="d-flex justify-content-center">
            <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
          </div>
        }
      </div>

      {/* MODAL UPDATE PASSWORD */}
      <div
        class="modal fade"
        id="updatePassword"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-rose">
            <div class="modal-header">
              <h5 class="modal-title fw-bold" id="exampleModalLabel">
                Modifier le mot de passse
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="recipient-name" class="col-form-label">
                  Nouveau mot de passe :
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between mx-2 my-3">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                id="update-password"
              >
                Annuler
              </button>
              <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
              {!loading && (
                <button
                  type="button"
                  class="btn text-white btn-rose"
                  onClick={updateVendeusePassword}
                >
                  <i className="bi bi-check-circle-fill"></i> Modifier
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* FIN MODAL UPDATE PASSWORD */}

      <SweetAlert
        show={showSweetAlert}
        title="Confirmer la suppression"
        text="Cette action est irréversible"
        showCancelButton
        onConfirm={() => {
          deleteVendeuse(vendeuseIdToDelete);
          setShowSweetAlert(false);
        }}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
        onEscapeKey={() => setShowSweetAlert(false)}
        onOutsideClick={() => setShowSweetAlert(false)}
      />

      <SweetAlert
        show={showSweetAlertRefresh}
        title="Confirmer"
        text="Créer un compte utilisateur pour cette vendeuse"
        showCancelButton
        onConfirm={() => {
          refreshAccountVendeuse(vendeuseIdToRefresh);
          setShowSweetAlertRefresh(false);
        }}
        onCancel={() => {
          setShowSweetAlertRefresh(false);
        }}
        onEscapeKey={() => setShowSweetAlertRefresh(false)}
        onOutsideClick={() => setShowSweetAlertRefresh(false)}
      />

      <SnackbarProvider>
        <SnackbarComponent message={snackbarMessage} openAlert={showSnackbar} />
      </SnackbarProvider>
    </Layout>
  );
}

export default Vendeur;
