import HeaderNomResto from "../HeaderNomResto";
import Layout from "../layouts/Layout";
import { Oval } from 'react-loader-spinner';
import AddLivreur from "./AddLivreur";
import {rechercherLivreurs,supprimercomptelivreur,changeretatcomptelivreur} from '../../services/livreurs/livreurRequest'
import { useState } from "react";
import { DecodeToken } from '../../DecodeToken';
import { Navigate } from 'react-router-dom';
import $ from "jquery"
import { useEffect } from "react";
const Livreure=()=>{
    let [listeLivreur, setListeLivreur]=useState([]);
    let [loading,setLoading]=useState(false);
    let [etat,setEtat]=useState("actif");
    let [livreurEtat,setLivreurEtat]=useState("")

  const  afficherListeLivreurs=()=>{
    rechercherLivreurs().then((res)=>{
        setListeLivreur(res.data.donnee);
    })
  }
  
  const suprimerlivreur=(id)=>{
    supprimercomptelivreur(id).then((res)=>{
        afficherListeLivreurs();
    }).catch((err)=>{
        console.log(err.message)
    })
  }

  const changeretatlivreurs=(id)=>{
    changeretatcomptelivreur(id).then((res)=>{
        afficherListeLivreurs();
    }).catch((err)=>{
        console.log(err);
    })
  }
    const handlFiltre=(e)=>{
        console.log(e.target.value);
    }
    const retournEtat=(etat)=>{
        if(etat==="Actif"){
            return <button type="button" className='btn btn-success' >{etat}</button>
        }else if(etat==="Inactif"){
            
            return <button type="button" className='btn btn-dark'>{etat}</button>
        }
        else{
            return <span className='btn btn-rose' >{etat}</span>
        }
    }
    
    let rowListeLivreurs=listeLivreur.map((livreur,index)=>(
        <tr key={index}>
            <td>{livreur.nom} </td>
            <td>{livreur.prenoms} </td>
            <td>{livreur.numero} </td>
            <td>{livreur.email} </td>
            <td>
               <button data-bs-target="#etat"  onClick={()=>changeretatlivreurs(livreur.id)}   className='btn btn-light  btn-sm text-white  '>{retournEtat(livreur.enabled)}</button>
            </td>
            <td>
                <button data-bs-target="#deleted"  onClick={()=>suprimerlivreur(livreur.id)}   className='btn bg-danger btn-sm text-white deleted '>Supprimer</button>
            </td>
        </tr>
    ))

    useEffect(()=>{
        afficherListeLivreurs();
    },[])
    return(
        <>
            <Layout>
            {DecodeToken().profil !== "ROLE_ADMIN" && <Navigate to="/login" replace />}
                <div >
                    <HeaderNomResto/>   
                    <p style={{fontWeight:"bold",fontSize:"1.1rem"}} >Livreurs</p>
                    <div className='bg-white rounded p-2' >
                    <div className='d-flex justify-content-between'>
                        <div className=''>
                            <div className='input-group bg-gris rounded mt-1 mb-3' hidden >
                                <div className="input-group-text">
                                    <i className='fa fa-search text-secondary'></i>
                                </div>
                                <input type="search" onChange={handlFiltre}  placeholder='Recherche...' name="" id="" className='form-control bg-gris form-control-sm loginborderleft' />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <button  className='btn btn-sm btn-rose text-white' data-bs-target="#entreprise" data-bs-toggle="modal">AJOUTER</button>
                        </div>
                        {<AddLivreur actualiserliste={afficherListeLivreurs} />}
                    </div>
                    <table className='table'>
                        <thead >
                            <tr className='gray-table rounded'>

                                <th >Nom<span className='mx-3'>
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z" fill="black" />
                                    </svg>
                                </span></th>
                                <th width="20%" >Prenom 
                                    <span className='mx-3'>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z" fill="black" />
                                        </svg>
                                    </span>
                                </th>
                                <th width="20%" >Numéro
                                    <span className='mx-3'>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z" fill="black" />
                                        </svg>
                                    </span>
                                </th>
                                <th width="15%" >Email
                                    <span className='mx-3'>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z" fill="black" />
                                        </svg>
                                    </span>
                                </th>
                                <th width="15%" >Etat
                                    <span className='mx-3'>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z" fill="black" />
                                        </svg>
                                    </span>
                                </th>
                                <th width="15%" >Actions
                                    <span className='mx-3'>
                                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z" fill="black" />
                                        </svg>
                                    </span>
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && rowListeLivreurs}
                        </tbody>
                        {/* <tfoot>
                            <tr>
                                <th colSpan="6">
                                    <div className='d-flex justify-content-center'>
                                        <Paginate pageActive={pageActive} handlePageChange={handlePageChange} nbreElementParPage={totalElementByPage} nbreTotalElmentPage={totalElement} />
                                    </div>
                                </th>
                            </tr></tfoot> */}
                    </table>
                    {<div className='d-flex justify-content-center'>
                        <Oval
                            width={40}
                            height={40}
                            color="#CE5D1D"
                            visible={loading}
                        />
                    </div>}</div>
                </div>
            </Layout>
        </>
    )
}
export default Livreure;