import React, { useState } from "react";
import {
    autoriserRestaurantPourEntreprise,
  recupereListeRestaurantAutorise,
  recupereListeRestaurantNotAutorise,
  retirerRestaurantAutorisePourEntreprise,
} from "../../services/entreprises/entrepriseRequest";
import { Oval } from "react-loader-spinner";
import { useEffect } from "react";

function RestaurantAutorise(props) {
  const [loading, setloading] = useState(false);
  const [ajoutLoading, setAjoutLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [restaurantSelected, setRestaurantSelected] = useState(null);
  const [listeRestaurantNonAutorise, setListeRestaurantNonAutorise] = useState([]);
  const [listeRestaurantAutorise, setListeRestaurantAutorise] = useState([]);

  const getListeRestaurantNotAutorise = (entrepriseId) => {
    recupereListeRestaurantNotAutorise(entrepriseId)
      .then((res) => {
        setListeRestaurantNonAutorise(res.data.donnee);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  const getListeRestaurantAutorise = (entrepriseId) => {
    recupereListeRestaurantAutorise(entrepriseId)
      .then((res) => {
        setListeRestaurantAutorise(res.data.donnee);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  const handleChoixRestaurant = (value) => {
    setRestaurantSelected(value.target.value);
  };

  const AutoriserRestaurant = () => {
    setAjoutLoading(true);
    if (restaurantSelected !== null) {
        autoriserRestaurantPourEntreprise(restaurantSelected, props.infoentreprise.id)
        .then((res) => {
          setAjoutLoading(false);
          getListeRestaurantAutorise(props.infoentreprise.id);
          getListeRestaurantNotAutorise(props.infoentreprise.id);
        })
        .catch((err) => {
          setAjoutLoading(false);
          console.log("api error", err);
        });
    }
  };

  const retirerRestaurantAutorise = (vendeuseId) => {
    retirerRestaurantAutorisePourEntreprise(vendeuseId, props.infoentreprise.id)
      .then((res) => {
        getListeRestaurantAutorise(props.infoentreprise.id);
        getListeRestaurantNotAutorise(props.infoentreprise.id);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    setloading(true);
    getListeRestaurantNotAutorise(props.infoentreprise.id);
    getListeRestaurantAutorise(props.infoentreprise.id);
  }, [props.infoentreprise]);

  return (
    <div
      className="modal fade"
      id="restaurant"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content ">
          <div className="d-flex modal-header justify-content-between p-2">
            <div></div>
            <div>
              <h6>Autoriser des restaurants</h6>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="CloseAddSubvention"
                onClick={() => setRestaurantSelected(null)}
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center w-100">
              <div className="w-50">
                <h6 className="text-center">
                  Liste des restaurants non autorisés
                </h6>
                {listeRestaurantNonAutorise.length !== 0 ? (
                  <>
                    <select
                      className="form-select mb-3 mt-4"
                      aria-label="select example"
                      onChange={handleChoixRestaurant}
                    >
                      <option selected value="">
                        (Sélectionner un restaurant){" "}
                      </option>
                      {listeRestaurantNonAutorise.map((vendeuse, index) => (
                        <option value={vendeuse.id} key={index}>
                          {vendeuse.nom} {vendeuse.prenoms}
                        </option>
                      ))}
                    </select>
                    {!ajoutLoading ? (
                      <div
                        style={{ cursor: "pointer" }}
                        className="px-3 py-2 d-block text-center bg-success text-white rounded"
                        onClick={AutoriserRestaurant}
                      >
                        Autoriser
                      </div>
                    ) : (
                      <Oval
                        width={40}
                        height={40}
                        color="#CE5D1D"
                        visible={ajoutLoading}
                      />
                    )}
                  </>
                ) : (
                  <p className="text-center mt-3">
                    Aucun restaurant à autoriser
                  </p>
                )}
              </div>
              <div className="w-50 px-3">
                <h6 className="text-center">Restaurants autorisés</h6>
                {listeRestaurantAutorise.length !== 0 ? (
                  listeRestaurantAutorise.map((item, index) => (
                    <div className="position-relative card bg-rose px-3 mt-4">
                      <div className="d-flex align-items-center gap-1">
                        <span>Nom & prénoms: </span>
                        <span>
                          {item.nom ? item.nom : "..."}{" "}
                          {item.prenoms ? item.prenoms : "..."}
                        </span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <span>Adresse: </span>
                        <span>{item.adresse ? item.adresse : "..."}</span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <span>Numéro: </span>
                        <span>{item.numero ? item.numero : "..."}</span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="position-absolute end-0 bottom-0 px-2 py-1 d-inline bg-danger text-white rounded"
                        onClick={() => retirerRestaurantAutorise(item.id)}
                      >
                        <i className="fa fa-trash white"></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center mt-3">Aucun restaurant autorisé</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantAutorise;
