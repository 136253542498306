import React from 'react'
import { useEffect } from 'react';
import { useSnackbar } from "react-simple-snackbar";

const SnackbarComponent = ({ openAlert, message }) => {
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const clickOpen = () => {
    document.getElementById('openTheSnack').click()
  }

  useEffect(() => {
    if (openAlert) {
      clickOpen()
    }
  }, [openAlert])
  

  return (
    <div>
      <button
        id='openTheSnack'
        onClick={() => openSnackbar(message)}
        style={{ visibility: 'hidden' }}
      >
      </button>
    </div>
  );
}

export default SnackbarComponent