import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { telechargerhistoriquepaiementparemployes } from "../../services/employes/EmployesRequest";
import { rechercherannees } from "../../services/statistiques/StatRequest";

function DetailsEmployes(props) {
  const [moisChoisi, setMoisChoisi] = useState(null);
  const [anneeChoisi, setAnneeChoisi] = useState(null);
  const [listeAnnee, setListeAnnee] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [messageRrror, setMessageError] = useState("");
  const listeMois = [
    { indiceMois: 1, libelle: "Janvier" },
    { indiceMois: 2, libelle: "Février" },
    { indiceMois: 3, libelle: "Mars" },
    { indiceMois: 4, libelle: "Avril" },
    { indiceMois: 5, libelle: "Mai" },
    { indiceMois: 6, libelle: "Juin" },
    { indiceMois: 7, libelle: "Juillet" },
    { indiceMois: 8, libelle: "Août" },
    { indiceMois: 9, libelle: "Septembre" },
    { indiceMois: 10, libelle: "Octobre" },
    { indiceMois: 11, libelle: "Novembre" },
    { indiceMois: 12, libelle: "Décembre" },
  ];

  const handleMois = (indicemois) => {
    setMoisChoisi(indicemois);
  };

  const handleAnnee = (indiceannee) => {
    setAnneeChoisi(indiceannee);
  };

  const exportDonneeEmploye = () => {
    setLoadingExport(true);
    setMessageError("")
    const currentDate = new Date()
    const data = {
        mois: moisChoisi && moisChoisi.indiceMois ? moisChoisi.indiceMois : currentDate.getMonth() + 1,
        annee: anneeChoisi ? anneeChoisi : currentDate.getFullYear(),
        employeId: props.infoEmployes.employeId
    }
    
    telechargerhistoriquepaiementparemployes(data)
      .then((res) => {
        setLoadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Detail_employe_${moisChoisi.libelle}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setLoadingExport(false);
        if (error && error.response) {
          setMessageError(error.response.data.donnee)
        } else {
          setMessageError("Une erreur s'est produite")
        }
      });
  };

  useEffect(() => {
    rechercherannees().then((res) => {
      setListeAnnee(res.data.donnee);
    });
  }, []);

  return (
    <div
      className="modal fade"
      id="detailemployes"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "40%" }}
      >
        <div className="modal-content ">
          <div className="d-flex modal-header justify-content-between p-2">
            <div></div>
            <div>
              <h6>Montant dû pour</h6>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body">
            {!props.loading ? (
              <div>
                <div className="row gx-1">
                  <div className="col-md-6 text-center">
                    <h6>{props.infoEmployes.nomPrenoms}</h6>
                    <p>{props.infoEmployes.fonction}</p>

                    <h6 className="mt-5">Subventionné</h6>
                    {props.infoEmployes.isSubventionne ? (
                      <p className="text-success">Oui</p>
                    ) : (
                      <p className="text-danger">Non</p>
                    )}

                    <h6 className="mt-3">Contact</h6>
                    <p>{props.infoEmployes.numero}</p>

                    <h6 className="mt-3">Matricule</h6>
                    <p>{props.infoEmployes.matricule}</p>
                  </div>
                  <div className="col-md-6">
                    <div className="mx-2 mt-3">
                      <div className="p-3 mb-3 bg-filtre rounded">
                        <p className="p-0 m-0">Montant dû aujourd'hui</p>
                        <span>
                          {props.infoEmployes.montantDuJour == null
                            ? "0"
                            : props.infoEmployes.montantDuJour}
                          F CFA
                        </span>
                      </div>

                      <div
                        className="p-3 mb-3 rounded"
                        style={{ backgroundColor: "#FE9C65" }}
                      >
                        <p className="p-0 m-0">Montant dû du mois</p>
                        <span>
                          {props.infoEmployes.montantDuMois == null
                            ? "0"
                            : props.infoEmployes.montantDuMois}
                          F CFA
                        </span>
                      </div>

                      <div
                        className="p-3 mb-3 rounded"
                        style={{ backgroundColor: "#AF9C65" }}
                      >
                        <p className="p-0 m-0">Forfait mensuel</p>
                        <span>
                          {props.infoEmployes.forfait == null
                            ? "0"
                            : props.infoEmployes.forfait}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  <div className="dropdown align-self-right w-50">
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {moisChoisi ? moisChoisi.libelle : "Sélectionner un mois"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeMois.map((item, index) => (
                        <li onClick={() => handleMois(item)} key={index}>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item.libelle}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="dropdown align-self-right w-50">
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {anneeChoisi
                        ? anneeChoisi
                        : "Sélectionner une année"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeAnnee.map((item, index) => (
                        <li onClick={() => handleAnnee(item)} key={index}>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <button
                  disabled={loadingExport}
                  onClick={() => exportDonneeEmploye()}
                  className="w-100 btn mt-2 text-white"
                  style={{
                    backgroundColor: "#CE5D1D",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!loadingExport ? (
                    "Exporter"
                  ) : (
                    <Oval
                      width={18}
                      height={18}
                      color="#fff"
                      visible={loadingExport}
                    />
                  )}
                </button>

                {messageRrror.length !== 0 ? <p className="mt-2">{messageRrror}</p> : null}
              </div>
            ) : (
              <Oval
                width={40}
                height={40}
                color="#CE5D1D"
                visible={props.loading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsEmployes;
