import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { rechercherRestaurantsEnfant } from "../../services/restaurants/RestaurantRequest";
import { DecodeToken } from "../../DecodeToken";
import { Oval } from "react-loader-spinner";
const DupliquerPlaning = (props) => {
  const [loading, setLoading] = useState(false);
  let [view, setView] = useState("MOIS");
  let [mensuel, setMensuel] = useState(true);
  let [periodique, setPeriodique] = useState(false);
  let [initDate, setInitDate] = useState(new Date());
  let [dateDebut, setDateDebut] = useState(new Date());
  let [dateFin, setDateFin] = useState(new Date());
  let [typePlat, setTypePlat] = useState("");
  const [listeRestaurantEnfant, setListeRestaurantEnfant] = useState([]);
  const [restaurantEnfantSelected, setRestaurantEnfantSelected] = useState("");
  const [listeMois, setlisteMois] = useState([
    { indiceMois: 0, libelle: "Janvier" },
    { indiceMois: 1, libelle: "Février" },
    { indiceMois: 2, libelle: "Mars" },
    { indiceMois: 3, libelle: "Avril" },
    { indiceMois: 4, libelle: "Mai" },
    { indiceMois: 5, libelle: "Juin" },
    { indiceMois: 6, libelle: "Juillet" },
    { indiceMois: 7, libelle: "Août" },
    { indiceMois: 8, libelle: "Septembre" },
    { indiceMois: 9, libelle: "Octobre" },
    { indiceMois: 10, libelle: "Novembre" },
    { indiceMois: 11, libelle: "Décembre" },
  ]);

  const listeTypeRepas = [
    {
      value: "PETIT_DEJEUNER",
      libelle: "Petit déjeuner",
    },
    {
      value: "DEJEUNER",
      libelle: "Déjeuner",
    },
    {
      value: "DINER",
      libelle: "Diner",
    },
  ];

  const initialValues = {
    mois: 1,
    instant: "",
  };
  const validationSchema = Yup.object().shape({
    mois: Yup.number().required("Veuillez selectionner un mois"),
  });

  const handleCheckboxMois = (e) => {
    if (e.target.checked) {
      setView("MOIS");
      setMensuel(true);
      setPeriodique(false);
    } else {
      setView("PERIODE");
      setMensuel(false);
      setPeriodique(true);
    }
  };

  const handleCheckboxPeriode = (e) => {
    if (e.target.checked) {
      setView("PERIODE");
      setMensuel(false);
      setPeriodique(true);
    } else {
      setView("MOIS");
      setMensuel(true);
      setPeriodique(false);
    }
  };

  const dupliquerParPeriode = () => {
    const values = {
      type: "PERIODE",
      dateDebut,
      dateFin,
      initDate,
      instant: typePlat,
      restaurantSelected: restaurantEnfantSelected
    }
    props.handleSubmit(values)
  }

  useEffect(() => {
    if (DecodeToken().profil === "ROLE_RESTAURATRICE") {
      rechercherRestaurantsEnfant().then((response) => {
        setListeRestaurantEnfant(response.data.donnee);
      });
    }
  }, [])


  return (
    <>
      <button
        type="button"
        className="btn btn-warning btn mt-1 mx-5 pb-1 btn-rose text-white"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Dupliquer x
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title " id="exampleModalLabel">
                Dupliquer le planning
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="d-flex align-items-center gap-2">
                <div
                  class="form-check w-100"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={mensuel}
                    id="flexCheckDefault"
                    onChange={handleCheckboxMois}
                    style={{ width: 5 }}
                  />
                  <label
                    class="form-check-label"
                    for="flexCheckDefault"
                    style={{ marginLeft: 6, marginTop: 1 }}
                  >
                    Par mois
                  </label>
                </div>
                <div
                  class="form-check w-100"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={periodique}
                    id="flexCheckDefault"
                    onChange={handleCheckboxPeriode}
                    style={{ width: 5 }}
                  />
                  <label
                    class="form-check-label"
                    for="flexCheckDefault"
                    style={{ marginLeft: 6, marginTop: 1 }}
                  >
                    Par période
                  </label>
                </div>
              </div>
              {view === "MOIS" ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    props.handleSubmit({ type: view, mois: values.mois, instant: values.instant, restaurantSelected: restaurantEnfantSelected })
                  }}
                >
                  {({ resetForm }) => (
                    <Form>
                      <span className="text-danger">{props.messageError}</span>
                      <div className="form-group mb-3">
                        <label htmlFor="mois">Mois</label>
                        <Field
                          as="select"
                          type="text"
                          id="mois"
                          name="mois"
                          className="form-control"
                        >
                          {listeMois.map((mois, index) => (
                            <option value={mois.indiceMois + 1} key={index}>
                              {mois.libelle}
                            </option>
                          ))}
                        </Field>
                        <Field
                          as="select"
                          type="text"
                          id="instant"
                          name="instant"
                          className="form-control mt-3"
                        >
                          <option value="">Tous</option>
                          {listeTypeRepas.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.libelle}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="mois"
                          component="small"
                          className="text-danger"
                        />
                      </div>
                      {DecodeToken().profil === "ROLE_RESTAURATRICE" ? <select
                        name="restaurant"
                        id="restaurant"
                        onChange={e => setRestaurantEnfantSelected(e.target.value)}
                        value={restaurantEnfantSelected}
                        className="form-control mt-2"
                      >
                        <option value="" disabled>
                          Choisir un restaurant enfant
                        </option>
                        <option value=""></option>
                        {listeRestaurantEnfant.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.nom}
                          </option>
                        ))}
                      </select> : null}

                      <div className="form-group modal-footer d-flex justify-content-between gap-3">
                        <button
                          type="button"
                          onClick={resetForm}
                          className="btn btn-dark annuler"
                          data-bs-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button
                          type="submit"
                          className="btn btn-warning btn mt-1 pb-1 btn-rose text-white bTenregister"
                          // onClick={dupliquerParPeriode}
                        >
                          {!props.dupliqueLoading ? 'Enregistrer' : <Oval
                            width={20}
                            height={20}
                            color="#fff"
                            visible={props.dupliqueLoading}
                          />}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : null}
              {view === "PERIODE" ? (
                <div className="mt-3">
                  <div className="w-50">
                    Jour à dupliquer
                    <DatePicker
                      selected={initDate}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        setInitDate(date)

                        const nextDate = new Date(date);
                        nextDate.setDate(nextDate.getDate() + 1);

                        setDateDebut(nextDate)
                        setDateFin(nextDate)
                      }}
                      className="form-control"
                    />
                  </div>
                  <div className="w-100 mt-3 d-flex align-items-center justify-content-center gap-2">
                    <div className="w-50">
                      Date début
                      <DatePicker
                        selected={dateDebut}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => {
                          setDateDebut(date)
                          setDateFin(date)
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="w-50">
                      Date fin
                      <DatePicker
                        selected={dateFin}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => setDateFin(date)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <select
                    value={typePlat}
                    onChange={(e) => setTypePlat(e.target.value)}
                    className="form-control mt-3"
                  >
                    <option value="">Tous</option>
                    {listeTypeRepas.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.libelle}
                      </option>
                    ))}
                  </select>
                  {DecodeToken().profil === "ROLE_RESTAURATRICE" ? <select
                    name="restaurant"
                    id="restaurant"
                    onChange={e => setRestaurantEnfantSelected(e.target.value)}
                    value={restaurantEnfantSelected}
                    className="form-control mt-2"
                  >
                    <option value="" disabled>
                      Choisir un restaurant enfant
                    </option>
                    <option value=""></option>
                    {listeRestaurantEnfant.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nom}
                      </option>
                    ))}
                  </select> : null}
                  <div className="form-group modal-footer d-flex justify-content-between gap-3 mt-4">
                    <button
                      type="button"
                      className="btn btn-dark annuler"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-warning btn mt-1 pb-1 btn-rose text-white bTenregister"
                      onClick={dupliquerParPeriode}
                    >
                      {!props.dupliqueLoading ? 'Enregistrer' : <Oval
                            width={20}
                            height={20}
                            color="#fff"
                            visible={props.dupliqueLoading}
                          />}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DupliquerPlaning;
