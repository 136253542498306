import React, { useState } from 'react'
import { envoyernotification } from '../../services/notifications/NotificationRequest';
import HeaderNomResto from '../HeaderNomResto'
import Layout from '../layouts/Layout'
import {Oval} from 'react-loader-spinner'
import { DecodeToken } from '../../DecodeToken';
import { Navigate } from 'react-router-dom';
function Message() {

    const [messagessended, setmessagessended] = useState("");
    const [loading, setloading] = useState(false);

    const handleSendMessage = () => {
        setloading(true);
        envoyernotification({ message: messagessended }).then(() => {
            setloading(false);
            setmessagessended("");
        }).catch(() => {
            setloading(false);
        })
    }

    return (
        <Layout>
            {DecodeToken().profil !== "ROLE_RESTAURATRICE" && <Navigate to="/login" replace />}
            <HeaderNomResto />
            <p className='mb-3 fw-bold'>Envoies des messages aux clients</p>

            <textarea value={messagessended} onChange={(e) => setmessagessended(e.target.value)} className='form-control p-2'></textarea>

            <div className='d-flex justify-content-between'>
                <button className='bg-dark text-white btn fw-bold mt-2' onClick={() => setmessagessended("")}>Annuler</button>
                <div>
                    <Oval
                        width={40}
                        height={40}
                        color="#CE5D1D"
                        visible={loading}
                    />
                    {!loading && <button className='btn-rose text-white btn fw-bold mt-2' onClick={() => handleSendMessage()}><i className="bi bi-send"></i> Envoyer</button>}
                </div>
            </div>
        </Layout>
    )
}

export default Message