import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import { DecodeToken } from "../../DecodeToken";
import Layout from "../layouts/Layout";
import AddRestaurant from "./AddRestaurant";
import { Oval } from "react-loader-spinner";
import HeaderNomResto from "../HeaderNomResto";
import {
  autoriserrestaurant,
  changerEtatRestaurant,
  rechercherRestaurants,
  rechercherRestaurantsparparam,
  retirerrestaurant,
  supprimerRestaurant,
} from "../../services/restaurants/RestaurantRequest";
import { fichier } from "../../services/restauratrice/RestauratriceRequest";
import Paginate from "../pagination";
function Restaurant() {
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [restaurantIdToDelete, setRestaurantIdToDelete] = useState("");
  const [inforestaurant, setinforestaurant] = useState({});
  const [loading, setloading] = useState(false);
  const [listeRestaurant, setlisteRestaurant] = useState({});
  const [pageActive, setPageActive] = useState(1);
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [filterParametres, setfilterParametres] = useState({ param: "" });

  const afficherRestaurant = (currentPage) => {
    setloading(true);
    rechercherRestaurants(currentPage - 1)
      .then((response) => {
        setloading(false);
        setlisteRestaurant(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const afficherRestaurantFilter = (values, currentPage) => {
    setloading(true);
    rechercherRestaurantsparparam(currentPage - 1, values)
      .then((response) => {
        setloading(false);
        setlisteRestaurant(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handleAutorized = (id) => {
    setloading(true);
    autoriserrestaurant(id)
      .then(() => {
        setloading(false);
        afficherRestaurant(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handleSearchRestaurant = (parametre) => {
    setfilterParametres({ param: parametre });
    afficherRestaurantFilter(parametre, 1);
  };

  const handleUnAutorized = (id) => {
    setloading(true);
    retirerrestaurant(id)
      .then(() => {
        setloading(false);
        afficherRestaurant(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  
  const lienImage = (id) => {
    if (id != null && id != undefined) {
      return fichier(id);
    } else {
      return "";
    }
  };
  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    filterParametres.param === ""
      ? afficherRestaurant(pageNumber)
      : afficherRestaurantFilter(filterParametres.param, 1);
  };

  useEffect(() => {
    afficherRestaurant(pageActive);
  }, [pageActive]);

  const getRestaurantIdToDelete = (restoId) => {
    setRestaurantIdToDelete(restoId);
    setShowSweetAlert(true);
  };

  const deleteRestauratrice = (restoId) => {
    setloading(true);
    supprimerRestaurant(restoId)
      .then((res) => {
        setloading(false);
        afficherRestaurant(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const changeEtatRestauratrice = (restoId) => {
    setloading(true);
    changerEtatRestaurant(restoId)
      .then((res) => {
        setloading(false);
        afficherRestaurant(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const rowListeRestaurant =
    listeRestaurant.restaurants !== undefined &&
    listeRestaurant.restaurants.map((item, index) => (
      <tr key={index}>
        <td>
          <img
            src={lienImage(item.visuelId)}
            alt={item.visuelId}
            className="img"
          />
        </td>
        <td>{item.restaurantParent ? item.restaurantParent.nom : '-'}</td>
        <td>{item.nom}</td>
        <td>{item.adresse}</td>
        <td>{item.email}</td>
        <td>{item.numero}</td>
        <td>
          {DecodeToken().profil === "ROLE_ADMIN" && (
            <>
              <button
                data-bs-target="#restaurant"
                data-bs-toggle="modal"
                onClick={() => setinforestaurant(item)}
                className="btn bg-secondary btn-sm text-white"
              >
                Modifier
              </button>
              <button
                onClick={() => changeEtatRestauratrice(item.id)}
                className="btn bg-secondary btn-sm text-white mx-1"
              >
                {item.enabled ? "Désactiver" : "Activer"}
              </button>
              <button
                onClick={() => getRestaurantIdToDelete(item.id)}
                className="btn bg-secondary btn-sm text-white"
              >
                <i className="fa fa-trash white-table-bottom"></i>
              </button>
            </>
          )}
          {DecodeToken().profil === "ROLE_COMPTABLE" && item.autorise && (
            <button
              onClick={() => handleUnAutorized(item.id)}
              className="btn btn-danger btn-sm text-white"
            >
              Retirer
            </button>
          )}
          {DecodeToken().profil === "ROLE_COMPTABLE" && !item.autorise && (
            <button
              onClick={() => handleAutorized(item.id)}
              className="btn btn-rose btn-sm text-white"
            >
              Ajouter
            </button>
          )}
        </td>
      </tr>
    ));

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_ADMIN" &&
        DecodeToken().profil !== "ROLE_COMPTABLE" && (
          <Navigate to="/login" replace />
        )}
      <HeaderNomResto />
      <p className="mb-3 mt-3 fw-bold">Restaurants enregistrés</p>
      <div className="bg-white rounded p-2">
        <div className="d-flex justify-content-between">
          <div className="">
            <div className="input-group bg-gris rounded mt-1 mb-3">
              <div className="input-group-text">
                <i className="fa fa-search text-secondary"></i>
              </div>
              <input
                type="search"
                onChange={(e) => handleSearchRestaurant(e.target.value)}
                placeholder="Recherche..."
                name=""
                id=""
                className="form-control bg-gris form-control-sm loginborderleft"
              />
            </div>
          </div>
          {DecodeToken().profil === "ROLE_ADMIN" && (
            <div className="">
              <button
                onClick={() => setinforestaurant({})}
                className="btn btn-sm btn-rose text-white"
                data-bs-target="#restaurant"
                data-bs-toggle="modal"
              >
                AJOUTER
              </button>
            </div>
          )}
          <AddRestaurant
            inforestaurant={inforestaurant}
            actualisteListe={afficherRestaurant}
            pageActive={pageActive}
          />
        </div>
        <table className="table">
          <thead>
            <tr className="gray-table rounded">
              <th width="7%">Image</th>
              <th width="15%">Restaurant parent</th>
              <th width="15%">Nom</th>
              <th width="15%">Adresse</th>
              <th width="15%">Adresse email</th>
              <th width="10%">Numéro</th>
              <th width="15%">
                <span className="mx-3">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>{!loading && rowListeRestaurant}</tbody>
          <tfoot>
            <tr>
              <th colSpan="7">
                <div className="d-flex justify-content-center">
                  <Paginate
                    pageActive={pageActive}
                    handlePageChange={handlePageChange}
                    nbreElementParPage={totalElementByPage}
                    nbreTotalElmentPage={totalElement}
                  />
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
        {
          <div className="d-flex justify-content-center">
            <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
          </div>
        }
      </div>
      <SweetAlert
        show={showSweetAlert}
        title="Confirmer la suppression"
        text="Cette action est irréversible"
        showCancelButton
        onConfirm={() => {
          deleteRestauratrice(restaurantIdToDelete);
          setShowSweetAlert(false);
        }}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
        onEscapeKey={() => setShowSweetAlert(false)}
        onOutsideClick={() => setShowSweetAlert(false)}
      />
    </Layout>
  );
}

export default Restaurant;
