import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

export const enregistrerLivreur= async(data)=>{
    return await axios.post(baseUrl(`admin/enregistrerLivreur`),data);
}
export const rechercherLivreurs=async()=>{
    return await axios.get(baseUrl(`admin/rechercherLivreurs`));
}
export const supprimercomptelivreur=async(livreurId)=>{
    return await axios.delete(baseUrl(`admin/supprimercomptelivreur/${livreurId}`));
}
export const changeretatcomptelivreur =async(id)=>{
    return await axios.post(baseUrl(`admin/changeretatcomptelivreur/${id}`),{});
}