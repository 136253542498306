import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Oval } from "react-loader-spinner";
import * as yup from "yup";
import { DecodeToken } from "../../DecodeToken";
import {
  enregistrerversement,
  rechercherentreprisespartenaires,
  rechercherresteaverser,
} from "../../services/versements/versementRequest";
import { Autocomplete, TextField } from "@mui/material";
import { rechercherRestaurants } from "../../services/restaurants/RestaurantRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";

function AddReversement(props) {
  const [loading, setloading] = useState(false);
  const [entrepriseSelected, setentrepriseSelected] = useState(null);
  const [restaurantSelected, setRestaurantSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState(null);
  const [restantVersement, setRestantVersement] = useState(null);
  const [listeEntreprises, setlisteEntreprises] = useState([]);
  const [listeRestaurants, setListeRestaurants] = useState([]);
  const [listeTypes, setListeTypes] = useState([
    {
      label: "Subvention",
      value: "SUBVENTION",
    },
    {
      label: "Compte resto",
      value: "COMPTE_RESTO",
    },
    {
      label: "Salaire",
      value: "SALAIRE",
    },
    {
      label: "Mobile Money",
      value: "MOBILE_MONEY",
    },
  ]);
  const [messageError, setmessageError] = useState("");

  const initialValues = {
    id: props.infoVersement.id !== undefined ? props.infoVersement.id : "",
    date:
      props.infoVersement.date !== undefined ? props.infoVersement.date : "",
    montant:
      props.infoVersement.montant !== undefined
        ? props.infoVersement.montant
        : "",
    entrepriseId: props.infoVersement.entreprise
      ? props.infoVersement.entreprise.id
      : "",
    restaurantId:
      props.infoVersement.restaurant !== undefined
        ? props.infoVersement.restaurant.id
        : "",
    type:
      props.infoVersement.type !== undefined ? props.infoVersement.type : "",
  };

  const validateSchemaValues = yup.object().shape({
    date: yup.date().required("Veuillez sélectionner une date"),
    montant: yup.number().required("Veuillez saisir un montant"),
  });

  const afficherentrepriseautoriser = () => {
    rechercherentreprisespartenaires().then((res) => {
      setlisteEntreprises(res.data.donnee);
    });
  };

  const getListeRestaurants = (currentPage) => {
    setloading(true);
    rechercherRestaurants(currentPage)
      .then((response) => {
        setloading(false);
        setListeRestaurants(response.data.donnee.restaurants);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const getListeEntreprises = () => {
    rechercherentreprises()
      .then((response) => {
        setloading(false);
        setlisteEntreprises(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const handleEntreprise = (values) => {
    setentrepriseSelected(values);
    getMontantVersementRestant(values, restaurantSelected, typeSelected, "")
  };

  const handleRestaurant = (values) => {
    setRestaurantSelected(values);
    getMontantVersementRestant(entrepriseSelected, values, typeSelected, "")
  };

  const handleTypeVersement = (values) => {
    setTypeSelected(values);
    getMontantVersementRestant(entrepriseSelected, restaurantSelected, values, "")
  };

  const handleOnSubmit = (values) => {
    setloading(true);
    const dataVersement = { ...values, type: typeSelected.value };
    if (props.infoVersement.id === undefined) {
      dataVersement.entrepriseId =
        entrepriseSelected !== null ? entrepriseSelected.id : "";
      dataVersement.restaurantId =
        restaurantSelected !== null ? restaurantSelected.id : "";
    }
    setmessageError("");
    const red = Object.assign(dataVersement, {
      date: dataVersement.date.split("-").reverse().join("-"),
      dateOrdre: dataVersement.dateOrdre.split("-").reverse().join("-"),
    });

    enregistrerversement(
      Object.assign(dataVersement, {
        date: dataVersement.date.split("-").reverse().join("-"),
        dateOrdre: dataVersement.dateOrdre.split("-").reverse().join("-"),
      })
    )
      .then((res) => {
        setloading(false);
        setentrepriseSelected(null);
        setRestaurantSelected(null);
        setTypeSelected(null);
        setRestantVersement(null)
        setmessageError("");
        props.actualisteListe(1);
        document.getElementById("closeAjoutVersement").click();
      })
      .catch((error) => {
        setloading(false);
        setmessageError(error.response.data.donnee);
      });
  };

  const getMontantVersementRestant = (entreprise, restaurant, type, vendeuse) => {
    const filtres = {
      entrepriseId: entreprise ? entreprise.id : 0,
      restaurantId: restaurant ? restaurant.id : 0,
      type: type ? type.value : "",
      vendeuseId: 0,
    };

    rechercherresteaverser(filtres)
      .then((response) => {
        setloading(false);
        setRestantVersement(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    setRestantVersement(null)
    afficherentrepriseautoriser();
    getListeEntreprises();
    getListeRestaurants(0);
    if (props.infoVersement.id !== undefined) {
      getMontantVersementRestant(props.infoVersement.entreprise, props.infoVersement.receveur, props.infoVersement.type, 0)
    } else {
      getMontantVersementRestant(null, null, null, 0)
    }
    
  }, []);

  return (
    <div
      className="modal fade"
      id="reversement"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "30%" }}
      >
        <div className="modal-content bg-rose">
          <div className="p-2 d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="fw-bold">Versement</h6>
              <h6>Reste à payer: <span className="fw-bold">{restantVersement ? restantVersement : '0'} fcfa</span></h6>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchemaValues}
              enableReinitialize
              onSubmit={handleOnSubmit}
            >
              {({ resetForm }) => (
                <Form>
                  <div className="mt-4">
                    <div className="mb-3 text-danger text-center">
                      {messageError !== "" && messageError}
                    </div>
                    {props.infoVersement.id === undefined && (
                      <div className="mb-3">
                        {DecodeToken().profil !== "ROLE_ADMIN" ? (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={listeEntreprises}
                            getOptionLabel={(option) =>
                              option.raisonSociale ?? option
                            }
                            value={entrepriseSelected}
                            onChange={(event, value) => {
                              handleEntreprise(value);
                            }}
                            sx={{ width: "100%" }}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="bg-white"
                                label="Entreprises"
                              />
                            )}
                          />
                        ) : (
                          <>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={listeEntreprises}
                              getOptionLabel={(option) =>
                                option.raisonSociale ?? option
                              }
                              value={entrepriseSelected}
                              onChange={(event, value) => {
                                handleEntreprise(value);
                              }}
                              sx={{ width: "100%" }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="bg-white"
                                  label="Entreprise"
                                />
                              )}
                            />
                            <div className="mt-3">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={listeRestaurants}
                                getOptionLabel={(option) =>
                                  option.nom ?? option
                                }
                                value={restaurantSelected}
                                onChange={(event, value) => {
                                  handleRestaurant(value);
                                }}
                                sx={{ width: "100%" }}
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="bg-white"
                                    label="Restaurant"
                                  />
                                )}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    <div className="mb-3">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo-2"
                        options={listeTypes}
                        getOptionLabel={(option) => option.label ?? option}
                        value={typeSelected}
                        onChange={(event, value) => {
                          handleTypeVersement(value);
                        }}
                        sx={{ width: "100%" }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="bg-white"
                            label="Types de versement"
                          />
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateOrdre">
                        Date de l'ordre
                      </label>
                      <Field
                        type="date"
                        name="dateOrdre"
                        id="dateOrdre"
                        className="form-control"
                      />
                      <span>
                        <ErrorMessage name="dateOrdre"></ErrorMessage>
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="label-control" htmlFor="date">
                        Date de valeur
                      </label>
                      <Field
                        type="date"
                        name="date"
                        id="date"
                        className="form-control"
                      />
                      <span>
                        <ErrorMessage name="date"></ErrorMessage>
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="label-control" htmlFor="montant">
                        Montant versé
                      </label>
                      <Field
                        type="number"
                        name="montant"
                        id="montant"
                        className="form-control"
                      />
                      <span>
                        <ErrorMessage name="montant"></ErrorMessage>
                      </span>
                    </div>

                    <div className="mt-3 d-flex justify-content-end">
                      <button
                        type="button"
                        id="closeAjoutVersement"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                        hidden
                      ></button>
                      <Oval
                        width={40}
                        height={40}
                        color="#CE5D1D"
                        visible={loading}
                      />
                      <button
                        disabled={loading}
                        style={{ marginLeft: "16px" }}
                        type="submit"
                        className="btn btn-rose text-white"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddReversement;
