import React from "react";
import { useState, useEffect } from "react";
import { DecodeToken } from "../../DecodeToken";
import {
  rechercherentreprises,
  rechercherentreprisespartenaires,
  recherchersiteparentreprise,
} from "../../services/entreprises/entrepriseRequest";
import {
  enregistrerrepasjournalier,
  rechercherlisteplats,
  rechercherlisteplatsentreprise,
  rechercherlisteplatsentreprisesite,
  rechercherrepaspardesignation,
} from "../../services/restauratrice/RestauratriceRequest";
import {
  enregistrerrepasjournalierparvendeuseetparrestaurant,
  rechercherlisteplatsparentrepriseForAdmin,
  rechercherlisteplatsparrestaurant,
  rechercherlisteplatsparvendeuse,
} from "../../services/vendeuses/VendeuseRequest";
import { returnImageLink } from "../ReturnImageLink";
import { rechercherRestaurantsEnfant } from "../../services/restaurants/RestaurantRequest";

function ChoosePlatModal(props) {
  let [loading, setloading] = useState(false);
  let [forEntreprise, setForEntreprise] = useState(false);
  let [instant, setInstant] = useState("PETIT_DEJEUNER");
  let [entreprise, setEntreprise] = useState(0);
  let [entrepriseSite, setEntrepriseSite] = useState(0);
  let [listeEntreprise, setListeEntreprise] = useState([]);
  let [listeSite, setListeSite] = useState([]);
  let [listePlats, setListePlats] = useState([]);
  let [selectedPlat, setSelectedPlat] = useState([]);
  const [listeRestaurantEnfant, setListeRestaurantEnfant] = useState([]);
  const [restaurantEnfantSelected, setRestaurantEnfantSelected] = useState("");

  const afficherEntreprise = () => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
      rechercherentreprises()
        .then((response) => {
          setListeEntreprise(response.data.donnee);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    } else {
      rechercherentreprisespartenaires().then((res) => {
        setListeEntreprise(res.data.donnee);
      });
    }
  };

  const getListeTousLesPlats = (entrepriseId) => {
    if (DecodeToken().profil !== "ROLE_ADMIN") {
      rechercherlisteplatsentreprise(entrepriseId).then((res) => {
        setListePlats(res.data.donnee);
      });
    } else {
      if (props.vendeuse !== null) {
        let vendeuseId = props.vendeuse === null ? 0 : props.vendeuse.id;
        rechercherlisteplatsparvendeuse(vendeuseId).then((res) => {
          setListePlats(res.data.donnee);
        });
      } else {
        let restaurantId = props.restaurant === null ? 0 : props.restaurant.id;
        rechercherlisteplatsparrestaurant(restaurantId).then((res) => {
          setListePlats(res.data.donnee);
        });
      }
    }
  };

  const getListePlatPourEntreprise = (entrepriseId) => {
    if (DecodeToken().profil !== "ROLE_ADMIN") {
      rechercherlisteplatsentreprise(entrepriseId).then((res) => {
        setListePlats(res.data.donnee);
      });
    } else {
      if (props.vendeuse !== null) {
        let vendeuseId = props.vendeuse === null ? 0 : props.vendeuse.id;
        rechercherlisteplatsparentrepriseForAdmin(
          entrepriseId,
          0,
          vendeuseId
        ).then((res) => {
          setListePlats(res.data.donnee);
        });
      } else {
        let restaurantId = props.restaurant === null ? 0 : props.restaurant.id;
        rechercherlisteplatsparentrepriseForAdmin(
          entrepriseId,
          restaurantId,
          0
        ).then((res) => {
          setListePlats(res.data.donnee);
        });
      }
    }
  };

  // const getListePlatPourEntrepriseParSite = (siteId) => {
  //   if (DecodeToken().profil !== "ROLE_ADMIN") {
  //     console.log("auvcgecaed", siteId);
  //     rechercherlisteplatsentreprisesite(siteId).then((res) => {
  //       setListePlats(res.data.donnee);
  //     });
  //   } else {
  //     let vendeuseId = props.vendeuse === null ? 0 : props.vendeuse.id;
  //     rechercherlisteplatsparvendeuse(vendeuseId).then((res) => {
  //       setListePlats(res.data.donnee);
  //     });
  //   }
  // };

  const addSelectedFood = (plat) => {
    let listePlat = [...selectedPlat];
    // Check if an element with the same id exists in selectedElements
    const existingElementIndex = listePlat.findIndex((el) => el.id === plat.id);

    if (existingElementIndex === -1) {
      // If the element is not in the array, add it
      listePlat.push({ ...plat, quantite: "" });
      setSelectedPlat(listePlat);
    } else {
      // If the element is in the array, remove it
      listePlat.splice(existingElementIndex, 1);
      setSelectedPlat(listePlat);
    }
  };

  const addQuantitePlat = (plat, quantite) => {
    let listePlat = [...selectedPlat];
    const platTotal = {
      ...plat,
      quantite,
    };

    const existingElementIndex = listePlat.findIndex((el) => el.id === plat.id);

    if (existingElementIndex !== -1) {
      // If the element is in the array, remove it
      listePlat.splice(existingElementIndex, 1);
      listePlat.push(platTotal);
      setSelectedPlat(listePlat);
    }
  };

  const getQuantitePlat = (plat) => {
    let listePlat = [...selectedPlat];
    const existingElementIndex = listePlat.findIndex((el) => el.id === plat.id);

    return selectedPlat[existingElementIndex].quantite;
  };

  const enregistrerPlanningMultiple = () => {
    const listeRepas = [];
    selectedPlat.forEach((element) => {
      listeRepas.push({
        repasId: element.id,
        quantite: parseInt(element.quantite),
      });
    });

    DecodeToken().profil !== "ROLE_ADMIN"
      ? enregistrerrepasjournalier({
        quantitesRepas: listeRepas,
        restaurantId: props.restaurant && props.restaurant.id ? props.restaurant.id : "",
        date: props.dateSelected,
        instant: instant,
        entrepriseId: entreprise,
        siteId: entrepriseSite,
      }).then((res) => {
        props.actualiserList(props.dateSelected);
        setForEntreprise(false);
        setEntreprise("");
        setEntrepriseSite("");
        getListeTousLesPlats(0);
        setSelectedPlat([]);
        document.getElementById("flexCheckDefault").checked = false;
        document.getElementById("closeSelectedPlat").click();
      })
      : enregistrerrepasjournalierparvendeuseetparrestaurant({
        quantitesRepas: listeRepas,
        restaurantId: props.restaurant && props.restaurant.id ? props.restaurant.id : "",
        date: props.dateSelected,
        instant: instant,
        entrepriseId: entreprise,
        siteId: entrepriseSite,
      }).then((res) => {
        setForEntreprise(false);
        setEntreprise("");
        setEntrepriseSite("");
        props.actualiserList(props.dateSelected);
        getListeTousLesPlats(0);
        setSelectedPlat([]);
        document.getElementById("flexCheckDefault").checked = false;
        document.getElementById("closeSelectedPlat").click();
      });
  };

  const handleInstant = (event) => {
    instant = event.target.value;
    setInstant(instant);
  };

  const handleChoixEntreprise = (e) => {
    const entrepriseId = e.target.value;
    getListePlatPourEntreprise(entrepriseId);
    getListeSiteEntreprise(entrepriseId);
    setEntreprise(entrepriseId);
  };

  const getListeSiteEntreprise = (entrepriseId) => {
    recherchersiteparentreprise(entrepriseId).then((res) => {
      setListeSite(res.data.donnee);
    });
  };

  const handleChoixEntrepriseSite = (e) => {
    const siteId = e.target.value;
    setEntrepriseSite(siteId);
  };

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setForEntreprise(true);
    } else {
      setEntreprise("");
      setEntrepriseSite("");
      setForEntreprise(false);
      getListeTousLesPlats(0);
    }
  };

  const handleSearchPlat = (designation) => {
    setloading(true);
    rechercherrepaspardesignation(designation)
      .then((res) => {
        setloading(false);
        setListePlats(res.data.donnee);
        props.setlistePlat(res.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    afficherEntreprise();
    getListeTousLesPlats(0);
  }, [props.vendeuse, props.restaurant]);

  return (
    <div
      className="modal fade"
      id="ajoutplat"
      tab-index={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ height: "500px" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="w-100 d-flex px-3 align-items-center justify-content-between mt-2">
            <h6 className="text-center pt-4" style={{ fontSize: 18, fontWeight: "bold", textTransform: "uppercase" }}>
              Planning du {props.dateSelected != null && props.dateSelected}
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="w-100 d-flex p-2 align-items-center justify-content-end">
            <div className="input-group bg-gris rounded search">
              <div className="input-group-text">
                <i className="fa fa-search text-secondary"></i>
              </div>
              <input
                type="search"
                placeholder="Recherche..."
                name=""
                id=""
                className="form-control bg-gris form-control-sm loginborderleft"
                onChange={(e) => handleSearchPlat(e.target.value)}
              />
            </div>

          </div>
          <div className="modal-body">
            {selectedPlat.length !== 0 ? <p>Plats sélectionnés</p> : null}
            {selectedPlat.length !== 0 ? <div className="row gx-1">
              {selectedPlat.map((item, index) =>
                item.image !== null ? (
                  <div className="col-md-3">
                    <div
                      className="text-start mb-3 cursor"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid yellow",
                      }}
                      onClick={() => addSelectedFood(item)}
                      key={index}
                    >
                      <img
                        src={returnImageLink(
                          item.image != null ? item.image.id : null
                        )}
                        width="60px"
                        height="60px"
                        className="rounded"
                        alt={item.designation}
                      />{" "}
                      <br />
                      <span
                        className="fw-bold"
                        style={{ fontSize: "11px", marginTop: "-15px" }}
                      >
                        {item.designation != null && item.designation} (
                        {item.prix} FCFA)
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2">
                    <div
                      onClick={() => addSelectedFood(item)}
                      style={{
                        boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                        width: "120px",
                        height: "60px",
                        borderRadius: "5px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: item.entreprise ? "#fff3cd" : "",
                        border: "1px solid yellow",
                      }}
                      key={index}
                      className="food-card text-start mb-3 cursor"
                    >
                      <span
                        className="fw-bold text-center"
                        style={{ fontSize: "11px" }}
                        key={index}
                      >
                        {item.designation != null && item.designation} (
                        {item.prix} FCFA)
                      </span>
                    </div>
                  </div>
                )
              )}
            </div> : null}
            <select
              name="instant"
              id="instant"
              onChange={handleInstant}
              value={instant}
              className="form-control  mb-2"
            >
              <option selected value="PETIT_DEJEUNER">
                Petit déjeuner
              </option>
              <option value="DEJEUNER">Déjeuner</option>
              <option value="DINER">Diner</option>
            </select>
            {/* {DecodeToken().profil === "ROLE_RESTAURATRICE" ? <select
              name="restaurant"
              id="restaurant"
              onChange={e => setRestaurantEnfantSelected(e.target.value)}
              value={restaurantEnfantSelected}
              className="form-control mt-2"
            >
              <option value="0" disabled>
                Choisir un restaurant enfant
              </option>
              <option value="0"></option>
              {listeRestaurantEnfant.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.nom}
                </option>
              ))}
            </select> : null} */}
            <div className="mb-4 py-2">
              <div
                class="form-check w-100"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="entreprise"
                  id="flexCheckDefault"
                  onChange={handleCheckbox}
                  style={{ width: 5 }}
                />
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style={{ marginLeft: 6, marginTop: 1 }}
                >
                  Pour entreprise
                </label>
              </div>
              {forEntreprise ? (
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <select
                    name="instant"
                    id="instant"
                    onChange={handleChoixEntreprise}
                    value={entreprise}
                    className="form-control mt-2"
                  >
                    <option value="0" disabled>
                      Choisir une entreprise
                    </option>
                    <option value="0"></option>
                    {listeEntreprise.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.raisonSociale}
                      </option>
                    ))}
                  </select>
                  <select
                    name="instant"
                    id="instant"
                    onChange={handleChoixEntrepriseSite}
                    value={entrepriseSite}
                    className="form-control mt-2"
                  >
                    <option value="0" disabled>
                      Choisir un site
                    </option>
                    <option value="0"></option>
                    {listeSite.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nom}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
            <div
              className="row gx-1"
              height="400px"
            // style={{ overflow: "scroll", overflowY: "hidden" }}
            >
              {listePlats.map((item, index) =>
                item.image !== null ? (
                  <div className="col-md-3">
                    <div
                      className="text-start mb-3 cursor"
                      style={
                        selectedPlat.findIndex(
                          (plat) => plat.id === item.id
                        ) !== -1
                          ? {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            border: "1px solid yellow",
                          }
                          : {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }
                      }
                      onClick={() => addSelectedFood(item)}
                      key={index}
                    >
                      <img
                        src={returnImageLink(
                          item.image != null ? item.image.id : null
                        )}
                        width="120px"
                        height="100px"
                        className="rounded"
                        alt={item.designation}
                      />{" "}
                      <br />
                      <span
                        className="fw-bold"
                        style={{ fontSize: "12px", marginTop: "-15px" }}
                      >
                        {item.designation != null && item.designation} (
                        {item.prix} FCFA)
                      </span>
                    </div>
                    {selectedPlat.findIndex((plat) => plat.id === item.id) !==
                      -1 ? (
                      <input
                        type="text"
                        placeholder="Quantité"
                        className="custom-form-control text-center"
                        value={getQuantitePlat(item)}
                        onChange={(e) => addQuantitePlat(item, e.target.value)}
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="col-md-2">
                    <div
                      onClick={() => addSelectedFood(item)}
                      style={
                        selectedPlat.findIndex(
                          (plat) => plat.id === item.id
                        ) !== -1
                          ? {
                            boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                            width: "120px",
                            height: "100px",
                            borderRadius: "5px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: item.entreprise ? "#fff3cd" : "",
                            border: "1px solid yellow",
                            marginBottom: -3,
                          }
                          : {
                            boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                            width: "120px",
                            height: "100px",
                            borderRadius: "5px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: item.entreprise ? "#fff3cd" : "",
                          }
                      }
                      key={index}
                      className="food-card text-start mb-3 cursor"
                    >
                      <span
                        className="fw-bold text-center"
                        style={{ fontSize: "12px" }}
                        key={index}
                      >
                        {item.designation != null && item.designation} (
                        {item.prix} FCFA)
                      </span>
                    </div>
                    {selectedPlat.findIndex((plat) => plat.id === item.id) !==
                      -1 ? (
                      <input
                        type="text"
                        placeholder="Quantité"
                        className="custom-form-control text-center"
                        value={getQuantitePlat(item)}
                        onChange={(e) => addQuantitePlat(item, e.target.value)}
                      />
                    ) : null}
                  </div>
                )
              )}
            </div>
            <div className="w-100 d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-warning btn mt-1 mx-5 pb-1 btn-rose text-white"
                onClick={enregistrerPlanningMultiple}
              >
                Enregistrer
              </button>
            </div>
            <button
              id="closeSelectedPlat"
              className="btn"
              data-bs-dismiss="modal"
              hidden
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoosePlatModal;
