import axios from "axios";
import { headers } from "../../headers";
import { baseUrl } from "../BaseUrl";
import moment from "moment";

const NB_ELEMENT = 5;
export const rechercherlistecommandes = async (page) => {
  return await axios.get(
    baseUrl(
      "alluser/rechercherlistecommandes?page=" + page + "&size=" + NB_ELEMENT
    )
  );
};

export const rechercherlistecommandesparjour = async (page, jour, data) => {
  return await axios.post(
    baseUrl(
      "alluser/rechercherlistecommandesparjour?page=" +
        page +
        "&size=" +
        NB_ELEMENT +
        "&date=" +
        jour
    ),
    data
  );
};

export const rechercherquantitecommandesparjourparrepas = async (
  param,
  data
) => {
  return await axios.post(
    baseUrl("alluser/rechercherquantitecommandesparjourparrepas?date=" + param),
    data
  );
};

export const enregistrerheurecommande = async (values) => {
  return await axios.post(
    baseUrl("restauratrice/enregistrerheurecommande"),
    values
  );
};

export const rechercherlisteheurecommandes = async () => {
  return await axios.get(
    baseUrl("restauratrice/rechercherlisteheurecommandes")
  );
};

export const filtrelisteheurecommandes = async (entrepriseId) => {
  return await axios.get(
    baseUrl(
      "restauratrice/rechercherlisteheurecommandesparparam/" + entrepriseId
    )
  );
};

export const supprimerheurecommandes = async (heureId) => {
  return await axios.delete(
    baseUrl("restauratrice/supprimerheurecommande/" + heureId)
  );
};

export const rechercherentrepriseayantcommander = async (param) => {
  return await axios.get(
    baseUrl(`restauratrice/rechercherentrepriseayantcommander?date=${param}`)
  );
};

export const rechercherQuantiteCommanderParJourParEntreprise = async (
  jour,
  data
) => {
  return await axios.post(
    baseUrl(
      `alluser/rechercherQuantiteCommanderParJourParEntreprise?date=${jour}`
    ),
    { data }
  );
};

export const exportDataCommandeFromApi = async (
  type = "PLAT",
  restaurant = 0,
  entreprise = 0,
  dateDebut,
  dateFin
) => {
  return await axios.get(
    baseUrl(
      `exportercommandes?type=${type}&restaurantId=${restaurant}&entrepriseId=${entreprise}&dateDebut=${dateDebut}&dateFin=${dateFin}`
    ),
    Object.assign(headers, { responseType: "blob" })
  );
};

export const exportDataProcessusCommandesFromApi = async (
  dateDebut,
  dateFin,
  type = "TOUS",
  data
) => {
  return await axios.post(
    baseUrl(
      `exporterprocesscommande?dateDebut=${dateDebut}&dateFin=${dateFin}&type=${type}`
    ),
    data,
    Object.assign(headers, { responseType: "blob" })
  );
};

// COMMANDES PROCESSUS
export const rechercherCommandeProcessus = async (page, date, filter) => {
  return await axios.post(
    baseUrl(
      `alluser/recherchecommandesparjourafficherparcommande?page=${page}&size=${7}&date=${date}`
    ),
    filter
  );
};

export const rechercherPlatParCommandeProcessus = async (commandeId) => {
  return await axios.get(
    baseUrl(`alluser/rechercherlistrepasparcommande/${commandeId}`)
  );
};

export const rechercherImageParCommandeProcessus = async (commandeId) => {
  return await axios.get(
    baseUrl(`alluser/rechercherlisteimagesparcommande/${commandeId}`)
  );
};
