import React, { useState } from 'react'
import moment from 'moment';

function FilterDayAndMonth(props) {

    const [listeMois, setlisteMois] = useState([
        { indiceMois: 0, libelle: "Janvier" },
        { indiceMois: 1, libelle: "Février" },
        { indiceMois: 2, libelle: "Mars" },
        { indiceMois: 3, libelle: "Avril" },
        { indiceMois: 4, libelle: "Mai" },
        { indiceMois: 5, libelle: "Juin" },
        { indiceMois: 6, libelle: "Juillet" },
        { indiceMois: 7, libelle: "Août" },
        { indiceMois: 8, libelle: "Septembre" },
        { indiceMois: 9, libelle: "Octobre" },
        { indiceMois: 10, libelle: "Novembre" },
        { indiceMois: 11, libelle: "Décembre" }
    ])

    const [moisSelected, setmoisSelected] = useState(new Date().getMonth())
    let [jourSelected, setjourSelected] = useState(new Date())
    let dateJour = new Date()


    const handlePrevMois = () => {
        if (moisSelected > 0) {
            jourSelected = new Date(dateJour.getFullYear(), moisSelected - 1, 1);
            setjourSelected(jourSelected);
            setmoisSelected(moisSelected - 1);

            props.setjourSelected(jourSelected);
            props.setmoisSelected(moisSelected - 1);
            props.rechercherentreprise(moment(jourSelected).format("yyyy-MM-DD"))
            // affichercommandeparjour(pageActive);
        }

    }
   

    const handleNextMois = () => {
        if (moisSelected < new Date().getMonth()) {
            jourSelected = new Date(dateJour.getFullYear(), moisSelected + 1, 1);
            setjourSelected(jourSelected);
            setmoisSelected(moisSelected + 1);

            props.setjourSelected(jourSelected);
            props.setmoisSelected(moisSelected + 1);
            props.rechercherentreprise(moment(jourSelected).format("yyyy-MM-DD"))
            // affichercommandeparjour(pageActive);
        }
    }

    const handlePrevJour = () => {
        const lastDay = new Date(dateJour.getFullYear(), moisSelected + 1, 0)

        if (moisSelected == jourSelected.getMonth()) {
            if ((jourSelected.getDate() < lastDay.getDate() + 1) && jourSelected.getDate() > 1) {
                jourSelected = new Date(dateJour.getFullYear(), moisSelected, (jourSelected.getDate() - 1))
                setjourSelected(jourSelected);

                props.setjourSelected(jourSelected);
                props.rechercherentreprise(moment(jourSelected).format("yyyy-MM-DD"))
                // affichercommandeparjour(pageActive);
            }
        }

    }

    const handleNextJour = () => {
        const lastDay = new Date(dateJour.getFullYear(), moisSelected + 1, 0)

        if (moisSelected == dateJour.getMonth()) { //On verifie si le mois sélectionné est celui du moi en cours

            if ((jourSelected.getDate() < lastDay.getDate()) && jourSelected.getDate() < dateJour.getDate()) {
                //Empêcher que l'utilisateur aille à un jour supérieur au jour actuelle
                jourSelected = new Date(dateJour.getFullYear(), moisSelected, (jourSelected.getDate() + 1))
                setjourSelected(jourSelected);

                props.setjourSelected(jourSelected);
                // affichercommandeparjour(pageActive);
                props.rechercherentreprise(moment(jourSelected).format("yyyy-MM-DD"))
            }
        } else {
            if ((moisSelected == jourSelected.getMonth())) {
                if ((jourSelected.getDate() < lastDay.getDate())) {
                    jourSelected = new Date(dateJour.getFullYear(), moisSelected, (jourSelected.getDate() + 1))
                    setjourSelected(jourSelected);

                    props.setjourSelected(jourSelected);
                    props.rechercherentreprise(moment(jourSelected).format("yyyy-MM-DD"))
                    // affichercommandeparjour(pageActive);
                }
            }
        }
    }

    return (
        <>
            {/* Filtre sur le jour */}
            <div className='mb-3 bg-rose p-2 rounded'>
                <span className='cursor mx-2' onClick={() => handlePrevJour()}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V2ZM0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L14 0C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2ZM11.5 7.5C11.6326 7.5 11.7598 7.55268 11.8536 7.64645C11.9473 7.74021 12 7.86739 12 8C12 8.13261 11.9473 8.25979 11.8536 8.35355C11.7598 8.44732 11.6326 8.5 11.5 8.5H5.707L7.854 10.646C7.90049 10.6925 7.93736 10.7477 7.96252 10.8084C7.98768 10.8692 8.00063 10.9343 8.00063 11C8.00063 11.0657 7.98768 11.1308 7.96252 11.1916C7.93736 11.2523 7.90049 11.3075 7.854 11.354C7.80751 11.4005 7.75232 11.4374 7.69158 11.4625C7.63084 11.4877 7.56574 11.5006 7.5 11.5006C7.43426 11.5006 7.36916 11.4877 7.30842 11.4625C7.24768 11.4374 7.19249 11.4005 7.146 11.354L4.146 8.354C4.09944 8.30755 4.06249 8.25238 4.03729 8.19163C4.01208 8.13089 3.99911 8.06577 3.99911 8C3.99911 7.93423 4.01208 7.86911 4.03729 7.80837C4.06249 7.74762 4.09944 7.69245 4.146 7.646L7.146 4.646C7.23989 4.55211 7.36722 4.49937 7.5 4.49937C7.63278 4.49937 7.76011 4.55211 7.854 4.646C7.94789 4.73989 8.00063 4.86722 8.00063 5C8.00063 5.13278 7.94789 5.26011 7.854 5.354L5.707 7.5H11.5Z" fill="#767676" />
                    </svg>
                </span>
                {
                    (new Date().getMonth()) <= moisSelected ?
                        (new Date().getDate()) == jourSelected.getDate() ?
                            "Ajourd'hui" : (new Date().getDate() - 1) == jourSelected.getDate() ?
                                "Hier" : moment(jourSelected).format("DD/MM/yyyy")
                        : moment(jourSelected).format("DD/MM/yyyy")
                
                }
                
                <span className='cursor mx-2' onClick={() => handleNextJour()}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1 14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15L14 15C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14L15 2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1L2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2L1 14ZM16 14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16L2 16C1.46957 16 0.960859 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14L0 2C0 1.46957 0.210714 0.960859 0.585787 0.585787C0.960859 0.210714 1.46957 0 2 0H14C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.960859 16 1.46957 16 2V14ZM4.5 8.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5L10.293 7.5L8.146 5.354C8.09951 5.30751 8.06264 5.25232 8.03748 5.19158C8.01232 5.13084 7.99937 5.06574 7.99937 5C7.99937 4.93426 8.01232 4.86916 8.03748 4.80842C8.06264 4.74768 8.09951 4.69249 8.146 4.646C8.19249 4.59951 8.24768 4.56264 8.30842 4.53748C8.36916 4.51232 8.43426 4.49937 8.5 4.49937C8.56574 4.49937 8.63084 4.51232 8.69158 4.53748C8.75232 4.56264 8.80751 4.59951 8.854 4.646L11.854 7.646C11.9006 7.69245 11.9375 7.74762 11.9627 7.80837C11.9879 7.86911 12.0009 7.93423 12.0009 8C12.0009 8.06577 11.9879 8.13089 11.9627 8.19163C11.9375 8.25238 11.9006 8.30755 11.854 8.354L8.854 11.354C8.76011 11.4479 8.63278 11.5006 8.5 11.5006C8.36722 11.5006 8.23989 11.4479 8.146 11.354C8.05211 11.2601 7.99937 11.1328 7.99937 11C7.99937 10.8672 8.05211 10.7399 8.146 10.646L10.293 8.5L4.5 8.5Z" fill="#BBBBBB" />
                    </svg>

                </span>
            </div>
            {/* Fin filtre sur le jour */}

            {/* Filtre sur le mois */}
            <div className='mb-3 p-2 bg-filtre rounded mx-3'>
                <span className='cursor mx-2' onClick={() => handlePrevMois()}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V2ZM0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L14 0C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2ZM11.5 7.5C11.6326 7.5 11.7598 7.55268 11.8536 7.64645C11.9473 7.74021 12 7.86739 12 8C12 8.13261 11.9473 8.25979 11.8536 8.35355C11.7598 8.44732 11.6326 8.5 11.5 8.5H5.707L7.854 10.646C7.90049 10.6925 7.93736 10.7477 7.96252 10.8084C7.98768 10.8692 8.00063 10.9343 8.00063 11C8.00063 11.0657 7.98768 11.1308 7.96252 11.1916C7.93736 11.2523 7.90049 11.3075 7.854 11.354C7.80751 11.4005 7.75232 11.4374 7.69158 11.4625C7.63084 11.4877 7.56574 11.5006 7.5 11.5006C7.43426 11.5006 7.36916 11.4877 7.30842 11.4625C7.24768 11.4374 7.19249 11.4005 7.146 11.354L4.146 8.354C4.09944 8.30755 4.06249 8.25238 4.03729 8.19163C4.01208 8.13089 3.99911 8.06577 3.99911 8C3.99911 7.93423 4.01208 7.86911 4.03729 7.80837C4.06249 7.74762 4.09944 7.69245 4.146 7.646L7.146 4.646C7.23989 4.55211 7.36722 4.49937 7.5 4.49937C7.63278 4.49937 7.76011 4.55211 7.854 4.646C7.94789 4.73989 8.00063 4.86722 8.00063 5C8.00063 5.13278 7.94789 5.26011 7.854 5.354L5.707 7.5H11.5Z" fill="#767676" />
                    </svg>
                </span>
                {listeMois.filter(item => item.indiceMois == moisSelected)[0].libelle}
                <span className='cursor mx-2' onClick={() => handleNextMois()}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1 14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15L14 15C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14L15 2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1L2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2L1 14ZM16 14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16L2 16C1.46957 16 0.960859 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14L0 2C0 1.46957 0.210714 0.960859 0.585787 0.585787C0.960859 0.210714 1.46957 0 2 0H14C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.960859 16 1.46957 16 2V14ZM4.5 8.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5L10.293 7.5L8.146 5.354C8.09951 5.30751 8.06264 5.25232 8.03748 5.19158C8.01232 5.13084 7.99937 5.06574 7.99937 5C7.99937 4.93426 8.01232 4.86916 8.03748 4.80842C8.06264 4.74768 8.09951 4.69249 8.146 4.646C8.19249 4.59951 8.24768 4.56264 8.30842 4.53748C8.36916 4.51232 8.43426 4.49937 8.5 4.49937C8.56574 4.49937 8.63084 4.51232 8.69158 4.53748C8.75232 4.56264 8.80751 4.59951 8.854 4.646L11.854 7.646C11.9006 7.69245 11.9375 7.74762 11.9627 7.80837C11.9879 7.86911 12.0009 7.93423 12.0009 8C12.0009 8.06577 11.9879 8.13089 11.9627 8.19163C11.9375 8.25238 11.9006 8.30755 11.854 8.354L8.854 11.354C8.76011 11.4479 8.63278 11.5006 8.5 11.5006C8.36722 11.5006 8.23989 11.4479 8.146 11.354C8.05211 11.2601 7.99937 11.1328 7.99937 11C7.99937 10.8672 8.05211 10.7399 8.146 10.646L10.293 8.5L4.5 8.5Z" fill="#BBBBBB" />
                    </svg>

                </span>
            </div>
            {/* Fin filtre sur le mois */}
        </>
    )
}

export default FilterDayAndMonth