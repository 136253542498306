import React, { useState, useEffect } from "react";
import Layout from "../layouts/Layout";
import HeaderNomResto from "../HeaderNomResto";
import { DecodeToken } from "../../DecodeToken";
import { Navigate } from "react-router-dom";
import Chart from "react-apexcharts";
import {
  rechercherRestaurantsAutorise,
  rechercherRestaurantsEnfant,
  recherchertouslesRestaurants,
} from "../../services/restaurants/RestaurantRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";
import {
  rechercherentreprisespartenaire,
  rechercherrestaurantsautorises,
} from "../../services/statistiques/StatRequest";
import {
  grapheChiffreAffaire,
  grapheComparatifChiffreAffaire,
  grapheComparatifNombrePlatVendu,
  grapheNombrePlatVendu,
} from "../../services/graphes/GrapheRequest";
import { Oval } from "react-loader-spinner";

function Graphes() {
  const [graphNombrePlat, setGraphNombrePlat] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "Nombre",
        data: [],
      },
    ],
  });
  const [graphChiffre, setGraphChiffre] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "Montant",
        data: [],
      },
    ],
  });
  const [grapheMultiNext, setGrapheMultiNext] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: [],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#dc2626", "#f59e0b", "#27272a", "#16a34a"],
    },
  });
  const [grapheMultiNext2, setGrapheMultiNext2] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: [],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#dc2626", "#f59e0b", "#27272a", "#16a34a"],
    },
  });
  const listeMois = [
    {
      libelle: "Janvier",
      value: 1,
    },
    {
      libelle: "Février",
      value: 2,
    },
    {
      libelle: "Mars",
      value: 3,
    },
    {
      libelle: "Avril",
      value: 4,
    },
    {
      libelle: "Mai",
      value: 5,
    },
    {
      libelle: "Juin",
      value: 6,
    },
    {
      libelle: "Juillet",
      value: 7,
    },
    {
      libelle: "Août",
      value: 8,
    },
    {
      libelle: "Septembre",
      value: 9,
    },
    {
      libelle: "Octobre",
      value: 10,
    },
    {
      libelle: "Novembre",
      value: 11,
    },
    {
      libelle: "Décembre",
      value: 12,
    },
  ];
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [listeEntreprise, setListeEntreprise] = useState([]);
  const [listeRestaurants, setListeRestaurants] = useState([]);
  const [periode, setPeriode] = useState("JOUR");
  const [mois, setMois] = useState("");
  const [annee, setAnnee] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [restaurant, setRestaurant] = useState("");

  const rechercherEntreprisePartenaire = () => {
    rechercherentreprisespartenaire().then((res) => {
      setListeEntreprise(res.data.donnee);
    });
  };

  const rechercherTouteLesEntreprises = () => {
    rechercherentreprises().then((response) => {
      setListeEntreprise(response.data.donnee);
    });
  };

  const fetchNombrePlatVendu = (param) => {
    grapheNombrePlatVendu(param)
      .then((res) => {
        setLoadingRequest(false);
        const graph = {
          options: {
            chart: {
              id: "basic-bar",
              toolbar: {
                show: true,
              },
            },
            dataLabels: { enabled: true },
            xaxis: {
              categories: res.data.donnee.categories,
            },
          },
          series: [
            {
              name: "Nombre",
              data: res.data.donnee.series,
            },
          ],
        };

        setGraphNombrePlat(graph);
      })
      .catch((err) => {
        setLoadingRequest(false);
        console.log("api error", err);
      });
  };

  const fetchChiffreAffaire = (param) => {
    grapheChiffreAffaire(param)
      .then((res) => {
        setLoadingRequest(false);
        const graph = {
          options: {
            chart: {
              id: "basic-bar",
              toolbar: {
                show: true,
              },
            },
            dataLabels: { enabled: true },
            xaxis: {
              categories: res.data.donnee.categories,
            },
          },
          series: [
            {
              name: "Montant",
              data: res.data.donnee.series,
            },
          ],
        };

        setGraphChiffre(graph);
      })
      .catch((err) => {
        setLoadingRequest(false);
        console.log("api error", err);
      });
  };

  const fetchComparatifChiffreAffaire = (param) => {
    grapheComparatifChiffreAffaire(param)
      .then((res) => {
        setLoadingRequest(false);
        const graph = {
          series: res.data.donnee.series,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              stackType: "100%",
              toolbar: {
                show: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            xaxis: {
              categories: res.data.donnee.categories,
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
            colors: ["#dc2626", "#f59e0b", "#27272a", "#16a34a"],
          },
        };

        setGrapheMultiNext(graph);
      })
      .catch((err) => {
        setLoadingRequest(false);
        console.log("api error", err);
      });
  };

  const fetchComparatifNombrePlatVendu = (param) => {
    grapheComparatifNombrePlatVendu(param)
      .then((res) => {
        setLoadingRequest(false);
        const graph = {
          series: res.data.donnee.series,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              stackType: "100%",
              toolbar: {
                show: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            xaxis: {
              categories: res.data.donnee.categories,
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
            colors: ["#dc2626", "#f59e0b", "#27272a", "#16a34a"],
          },
        };

        setGrapheMultiNext2(graph);
      })
      .catch((err) => {
        setLoadingRequest(false);
        console.log("api error", err);
      });
  };

  const updateGraphInfo = () => {
    setLoadingRequest(true);

    const parameter = {
      periode: periode,
      annee: annee,
      mois: mois,
      entrepriseId: entreprise,
      restaurantId: restaurant,
    };

    fetchNombrePlatVendu(parameter);
    fetchChiffreAffaire(parameter);
    if (DecodeToken().profil === "ROLE_ADMIN") {
      fetchComparatifChiffreAffaire(parameter);
      fetchComparatifNombrePlatVendu(parameter);
    }
  };

  useEffect(() => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
      recherchertouslesRestaurants().then((res) => {
        setListeRestaurants(res.data.donnee);
      });
      rechercherTouteLesEntreprises();
    }

    if (DecodeToken().profil === "ROLE_COMPTABLE") {
      rechercherrestaurantsautorises().then((res) => {
        setListeRestaurants(res.data.donnee);
      });
      rechercherEntreprisePartenaire();
    }

    if (DecodeToken().profil === "ROLE_RESTAURATRICE") {
      rechercherRestaurantsEnfant().then((res) => {
        setListeRestaurants(res.data.donnee);
      });
      rechercherEntreprisePartenaire();
    }

    const parameter = {
      periode: periode,
      annee: annee,
      mois: mois,
      entrepriseId: entreprise,
      restaurantId: restaurant,
    };

    fetchNombrePlatVendu(parameter);
    fetchChiffreAffaire(parameter);
    if (DecodeToken().profil === "ROLE_ADMIN") {
      fetchComparatifChiffreAffaire(parameter);
      fetchComparatifNombrePlatVendu(parameter);
    }
  }, []);

  return (
    <Layout>
      <HeaderNomResto />
      <p className="mb-3 mt-3 fw-bold">Graphes</p>
      <div style={{ marginTop: 50, marginBottom: 80 }}>
        <div
          className="mb-5 d-flex align-items-end justify-content-end gap-2"
          style={{ marginRight: 60 }}
        >
          <div>
            <span>Période</span>
            <select
              value={periode}
              onChange={(e) => setPeriode(e.target.value)}
              className="form-select w-full font-normal"
            >
              <option value="" disabled selected>
                Choisir une période
              </option>
              <option value="JOUR">Jour</option>
              <option value="MOIS">Mois</option>
            </select>
          </div>
          {periode === "JOUR" ? (
            <div>
              <span>Mois</span>
              <select
                value={mois}
                onChange={(e) => setMois(e.target.value)}
                className="form-select w-full font-normal"
              >
                <option value="" disabled selected>
                  Choisir un mois
                </option>
                {listeMois.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.libelle}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <div>
            <span>Année</span>
            <select
              value={annee}
              onChange={(e) => setAnnee(e.target.value)}
              className="form-select w-full font-normal"
            >
              <option value="" disabled selected>
                Choisir une année
              </option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
          {DecodeToken().profil === "ROLE_ADMIN" ||
            DecodeToken().profil === "ROLE_RESTAURATRICE" ? (
            <div>
              <span>Entreprise</span>
              <select
                value={entreprise}
                onChange={(e) => setEntreprise(e.target.value)}
                className="form-select w-full font-normal"
              >
                <option value="" disabled selected>
                  Choisir une entreprise
                </option>
                <option value=""></option>
                {listeEntreprise.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.raisonSociale}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <div>
            <span>Restaurant</span>
            <select
              value={restaurant}
              onChange={(e) => setRestaurant(e.target.value)}
              className="form-select w-full font-normal"
            >
              <option value="" disabled selected>
                Choisir un restaurant
              </option>
              <option value=""></option>
              {listeRestaurants.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.nom}
                </option>
              ))}
            </select>
          </div>
          <div>
            <button
              className="btn btn-rose text-white"
              onClick={updateGraphInfo}
            >
              {loadingRequest ? (
                <Oval
                  width={30}
                  height={30}
                  color="#fff"
                  visible={loadingRequest}
                />
              ) : (
                "Rechercher"
              )}
            </button>
          </div>
        </div>

        <div className="w-100" style={{ marginTop: 80 }}>
          <div className="w-100" style={{ position: "relative" }}>
            <p
              className="text-left text-rose fw-bold"
              style={{ position: "absolute", top: -15, left: -5 }}
            >
              Nombre
            </p>
            <Chart
              options={graphNombrePlat.options}
              series={graphNombrePlat.series}
              type="bar"
              width="90%"
              height={350}
            />
            <p className="text-center text-rose fw-bold">
              Nombre de plats vendu
            </p>
          </div>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#9ca3af",
              marginTop: 80,
              marginBottom: 80,
            }}
          ></div>
          <div className="w-100 mt-5" style={{ position: "relative" }}>
            <p
              className="text-left text-rose fw-bold"
              style={{ position: "absolute", top: -15, left: -10 }}
            >
              Montant (FCFA)
            </p>
            <Chart
              options={graphChiffre.options}
              series={graphChiffre.series}
              type="bar"
              width="90%"
              height={350}
            />
            <p className="text-center text-rose fw-bold">Chiffre d'affaire</p>
          </div>
        </div>

        <div
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "#9ca3af",
            marginTop: 80,
            marginBottom: 80,
          }}
        ></div>

        {DecodeToken().profil === "ROLE_ADMIN" ? (
          <div className="my-5" style={{ position: "relative" }}>
            <Chart
              options={grapheMultiNext.options}
              series={grapheMultiNext.series}
              type="bar"
              height={450}
            />
            <p className="text-center text-rose fw-bold">
              Comparaison des chiffres d'affaire
            </p>
          </div>
        ) : null}

        <div
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "#9ca3af",
            marginTop: 80,
            marginBottom: 80,
          }}
        ></div>

        {DecodeToken().profil === "ROLE_ADMIN" ? (
          <div className="my-5" style={{ position: "relative" }}>
            <Chart
              options={grapheMultiNext2.options}
              series={grapheMultiNext2.series}
              type="bar"
              height={450}
            />
            <p className="text-center text-rose fw-bold">
              Comparaison des nombres de plat vendu
            </p>
          </div>
        ) : null}
      </div>
    </Layout>
  );
}

export default Graphes;
