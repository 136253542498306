import { baseUrl } from "../BaseUrl"
import axios from "axios"
import { headers } from "../../headers"

export const signin = async (values) => {
    return await axios.post(baseUrl("signin"), values)
}

export const changermotdepasse = async (values) => {
    return await axios.post(baseUrl("changermotdepasse"), values)
}

export const reinitialiserpassword = async(values)=>{
    return await axios.post(baseUrl("reinitialiserpassword"), values)
}