import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

export const rechercherannees = async()=>{
    return await axios.get(baseUrl("rechercherannees"))
}

export const rechercherchiffreaffaire = async(params)=>{
    return await axios.post(baseUrl("rechercherchiffreaffaire"), params)
}

export const rechercherchiffreaffaireadmin = async(params)=>{
    return await axios.post(baseUrl('admin/rechercherchiffreaffaire'), params);
}

export const recherchermontantduparvendeuse = async(params)=>{
    return await axios.post(baseUrl('admin/recherchermontantduparvendeuse'),params);
}

export const exportdatastatistique = async(params)=>{
    return await axios.post(baseUrl('telechargerstatistiques'), params, Object.assign(headers, { responseType: 'blob'}));
}

export const rechercherentreprisespartenaire=async ()=>{
    return await axios.get(baseUrl("restauratrice/rechercherentreprisespartenaires"));
}
export const rechercherrestaurantsautorises=async()=>{
    return await axios.get(baseUrl("entreprise/rechercherrestaurantautorises"));
}