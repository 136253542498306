import { Autocomplete, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DecodeToken } from "../DecodeToken";
import { recherchertouslesRestaurants } from "../services/restaurants/RestaurantRequest";
import {
  recherchervendeuseautorises,
  recherchertouteslesvendeuses,
} from "../services/vendeuses/VendeuseRequest";

function ListeVendeuse(props) {
  let [vendeuseSelected, setvendeuseSelected] = useState(null);
  let [listeRestaurants, setListeRestaurants] = useState([]);

  const afficherVendeur = () => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
      recherchertouslesRestaurants().then((response) => {
        setListeRestaurants(response.data.donnee);
      });
    } else {
      recherchervendeuseautorises().then((response) => {
        setListeRestaurants(response.data.donnee);
      });
    }
  };

  const handlePlatday = (values) => {
    vendeuseSelected = values;
    setvendeuseSelected(vendeuseSelected);
    props.setdataSelected(vendeuseSelected);
  };

  useEffect(() => {
    afficherVendeur();
  }, []);

  return (
    <div className=" d-flex justify-content-end">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={listeRestaurants}
        getOptionLabel={(option) => option.nom ?? option}
        value={vendeuseSelected}
        onChange={(event, value) => handlePlatday(value)}
        sx={{ width: props.width !== undefined ? props.width : 180 }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} className="bg-white" label="Vendeuses" />
        )}
      />
    </div>
  );
}

export default ListeVendeuse;
