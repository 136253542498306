import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { DecodeToken } from '../../DecodeToken'
import logo from '../Images/SkanResto.png'

function Sidebar() {
    return (
        <div className="d-flex  flex-column flex-shrink-0 " style={{ height: "100%",  }}>
            <a href="#" className="d-block mt-2 text-decoration-none" title="Restaurant hôpital" data-bs-toggle="tooltip" data-bs-placement="right">
                <img src={logo} alt="Logo Restaurant" className='img-fluid' />
                {/* <h6>RestoSIB</h6> */}
            </a>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                {DecodeToken().profil == "ROLE_ADMIN" && <><div className='my-2'></div>
                    <NavLink to="/entreprises" title="Entreprise" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="bi fs-5 bi-building"></i>

                        </li>
                    </NavLink></>}
                    
                {(DecodeToken().profil === "ROLE_ADMIN" || DecodeToken().profil === "ROLE_COMPTABLE" ) && <><div className='my-2'></div>
                    <NavLink to="/vendeurs" title="Vendeurs(ses)" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fa-users"></i>
                        </li>
                    </NavLink></>}
                {(DecodeToken().profil === "ROLE_ADMIN" || DecodeToken().profil === "ROLE_COMPTABLE") && <><div className='my-2'></div>
                    <NavLink to="/restaurants" title="Restaurants" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="bi bi-shop"></i>
                        </li>
                    </NavLink></>}

                <div className='my-2'></div>

                {(DecodeToken().profil === "ROLE_ADMIN" || DecodeToken().profil === "ROLE_COMPTABLE" || DecodeToken().profil === "ROLE_RESTAURATRICE") && <NavLink to="/plat" activeclassname="nav-link py-3 border-bottom" title="Liste des plats" data-bs-toggle="tooltip" data-bs-placement="right" aria-current="page" >
                    <li className="nav-item">
                        <i className="fa-solid fs-5 fa-bowl-rice"></i>
                    </li>
                </NavLink>}

                {DecodeToken().profil == "ROLE_RESTAURATRICE" && <><div className='my-2'></div>
                    <NavLink to="/planninghourcommand" title="Heures de réception des commandes" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="bi bi-clock"></i>
                        </li>
                    </NavLink></>}

                {DecodeToken().profil == "ROLE_RESTAURATRICE" && <><div className='my-2'></div>
                    <NavLink to="/sendmessage" title="Envoie de messages" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fa-bell"></i>
                        </li>
                    </NavLink></>}


                {(DecodeToken().profil == "ROLE_RESTAURATRICE" || DecodeToken().profil == "ROLE_ADMIN") && <><div className='my-2'></div>
                    <NavLink to="/planning" title="Planning" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="bi fs-5 bi-calendar-week"></i>
                        </li>
                    </NavLink></>}

                {DecodeToken().profil === "ROLE_ADMIN" && <><div className='my-2'></div>
                    <NavLink to="/versements" title="Versements vendeuses" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="bi bi-cash-stack"></i>
                        </li>
                    </NavLink></>}



                {DecodeToken().profil === "ROLE_COMPTABLE" && <> <div className='my-2'></div>
                    <NavLink to="/employes" title="Employés" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fa-user-tie"></i>
                        </li>
                    </NavLink></>
                }
                {DecodeToken().profil === "ROLE_ADMIN" && <> <div className='my-2'></div>
                    <NavLink to="/employes" title="Employés" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fa-user-tie"></i>
                        </li>
                    </NavLink></>
                }
                <div className='my-2'></div>

                {DecodeToken().profil !== "ROLE_CAISSIERE_ENTREPRISE" && (<NavLink to="/commandes" title="Commandes" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                    <li className="nav-item">
                        <i className="fa-solid fs-5 fa-clipboard-list"></i>
                    </li>
                </NavLink>)}
                
                {/* {DecodeToken().profil === "ROLE_RESTAURATRICE" ? <><div className='my-2'></div>
                    <NavLink to="/versement" title="Versement" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fs-5 fa-hand-holding-dollar"></i>
                        </li>
                    </NavLink></> : DecodeToken().profil === "ROLE_ADMIN" ? <><div className='my-2'></div>
                    <NavLink to="/versement" title="Versement" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fs-5 fa-hand-holding-dollar"></i>
                        </li>
                    </NavLink></> : null} */}
                    <><div className='my-2'></div>
                    <NavLink to="/versement" title="Versement" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fs-5 fa-hand-holding-dollar"></i>
                        </li>
                    </NavLink></>

                    {DecodeToken().profil === "ROLE_ADMIN" && <> <div className='my-2'></div>
                    <NavLink to="/livreurs" title="Livreurs" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-car-front-fill" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189l.956-1.913A.5.5 0 0 1 4.309 3h7.382a.5.5 0 0 1 .447.276l.956 1.913a.51.51 0 0 1-.497.731c-.91-.073-3.35-.17-4.597-.17-1.247 0-3.688.097-4.597.17a.51.51 0 0 1-.497-.731Z"/>
                            </svg>
                        </li>
                    </NavLink></>
                }
                <div className='my-2'></div>
                
                {DecodeToken().profil !== "ROLE_CAISSIERE_ENTREPRISE" && (<NavLink to="/" title="Statistiques" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                    <li className="nav-item">

                        <i className="fa-solid fs-5 fa-chart-column"></i>
                    </li>
                </NavLink>)}

                <div className='my-2'></div>
                <NavLink to="/graphes" title="Graphes" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                    <li className="nav-item">
                        <i className="fa-solid fs-5 fa-pie-chart"></i>
                    </li>
                </NavLink>
                
                <div className='my-2'></div>
                <NavLink to="/parametres" title="Parametres" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                    <li className="nav-item">
                        <i className="fa-solid fs-5 fa-gear"></i>
                    </li>
                </NavLink>

                <div className='my-2'></div>

                {DecodeToken().profil !== "ROLE_RESTAURATRICE" && <NavLink to="/portefeuille" title="Portefeuille" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                    <li className="nav-item">
                        {/* <i className="fa-solid fs-5 fa-gear"></i> */}
                        <i className="bi bi-wallet2"></i>
                    </li>
                </NavLink>}
                

                {(DecodeToken().profil === "ROLE_ADMIN" ) && <><div className='my-2'></div>
                    <NavLink to="/utilisateurs" title="Utilisateurs" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fa-users"></i>
                        </li>
                    </NavLink></>}  
                {(DecodeToken().profil === "ROLE_RESTAURATRICE" ) && <><div className='my-2'></div>
                    <NavLink to="/utilisateurs" title="Utilisateurs" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fa-users"></i>
                        </li>
                    </NavLink></>}
                {(DecodeToken().profil === "ROLE_COMPTABLE" ) && <><div className='my-2'></div>
                    <NavLink to="/utilisateurs" title="Utilisateurs" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="fa-solid fa-users"></i>
                        </li>
                    </NavLink></>}
                
                {DecodeToken().profil === "ROLE_COMPTABLE" && <> <div className='my-2'></div>
                    <NavLink to="/subvention" title="Subventions" data-bs-toggle="tooltip" data-bs-placement="right" activeclassname="nav-link py-3 border-bottom" >
                        <li className="nav-item">
                            <i className="bi bi-journal-text "></i>
                        </li>
                    </NavLink></>
                }

            </ul>

        </div>
    )
}

export default Sidebar