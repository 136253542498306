import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

export const enregistrerdelaiderappel= async(param)=>{
    return await axios.post(baseUrl(`restauratrice/enregistrerdelaiderappel`),param)
}
export const rechercherdelaisrappel=async()=>{
    return await axios.get(baseUrl(`restauratrice/rechercherdelaisrappel`))
}
export const supprimerrappel=async(entrepriseId)=>{
    return await axios.delete(baseUrl(`restauratrice/supprimerrappel/${entrepriseId}`))
}