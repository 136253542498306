import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "./vues/login/Login";
import ListePlat from "./vues/restauratrice/ListePlat";
import PlanningMenu from "./vues/restauratrice/PlanningMenu";
import Statistiques from "./vues/statistiques/Statistiques";
import { isAuthenticated } from "./TokenValidation";
import Commandes from "./vues/commandes/Commandes";
import Employes from "./vues/employes/Employes";
import Versement from "./vues/reversement/Versement";
import Parametres from "./vues/parametres/Parametres";
import Entreprise from "./vues/Entreprise/Entreprise";
import Vendeur from "./vues/vendeurs/Vendeur";
import Restaurant from "./vues/restaurants/Restaurant";
import VersementVendeuse from "./vues/versement-vendeuse/VersementVendeuse";
import Message from "./vues/messages/Message";
import HeureCommandes from "./vues/commandes/HeureCommandes";
import Utilisateurs from "./vues/utilisateurs/Utilisateurs";
import ChargerListeSubventionParGrade from "./vues/Entreprise/ChargerListeSubventionParGrade";
import Livreure from "./vues/livreures/Livreure";
import Portefeuille from "./vues/portefeuille/Portefeuille";
import Graphes from "./vues/graphes/Graphes";
import StatutPaiement from "./vues/statut-paiement/StatutPaiement";

function RoutesApp() {
  const ProtectedRoute = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
  };

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route
        exact
        path="/statut-paiement/:transactionId"
        element={<StatutPaiement />}
      />

      <Route exact path="/plat" element={<ProtectedRoute />}>
        <Route exact path="/plat" element={<ListePlat />} />
      </Route>
      <Route exact path="/graphes" element={<ProtectedRoute />}>
        <Route exact path="/graphes" element={<Graphes />} />
      </Route>
      <Route exact path="/planning" element={<ProtectedRoute />}>
        <Route exact path="/planning" element={<PlanningMenu />} />
      </Route>
      <Route exact path="/" element={<ProtectedRoute />}>
        <Route exact path="/" element={<Statistiques />} />
      </Route>
      <Route exact path="/employes" element={<ProtectedRoute />}>
        <Route exact path="/employes" element={<Employes />} />
      </Route>
      <Route exact path="/commandes" element={<ProtectedRoute />}>
        <Route exact path="/commandes" element={<Commandes />} />
      </Route>
      <Route exact path="/utilisateurs" element={<ProtectedRoute />}>
        <Route exact path="/utilisateurs" element={<Utilisateurs />} />
      </Route>
      <Route exact path="/versement" element={<ProtectedRoute />}>
        <Route exact path="/versement" element={<Versement />} />
      </Route>
      <Route exact path="/parametres" element={<ProtectedRoute />}>
        <Route exact path="/parametres" element={<Parametres />} />
      </Route>
      <Route exact path="/portefeuille" element={<ProtectedRoute />}>
        <Route exact path="/portefeuille" element={<Portefeuille />} />
      </Route>

      <Route exact path="/entreprises" element={<ProtectedRoute />}>
        <Route exact path="/entreprises" element={<Entreprise />} />
      </Route>
      <Route exact path="/vendeurs" element={<ProtectedRoute />}>
        <Route exact path="/vendeurs" element={<Vendeur />} />
      </Route>

      <Route exact path="/restaurants" element={<ProtectedRoute />}>
        <Route exact path="/restaurants" element={<Restaurant />} />
      </Route>

      <Route exact path="/versements" element={<ProtectedRoute />}>
        <Route exact path="/versements" element={<VersementVendeuse />} />
      </Route>

      <Route exact path="/sendmessage" element={<ProtectedRoute />}>
        <Route exact path="/sendmessage" element={<Message />} />
      </Route>

      <Route exact path="/planninghourcommand" element={<ProtectedRoute />}>
        <Route exact path="/planninghourcommand" element={<HeureCommandes />} />
      </Route>
      <Route exact path="/subvention" element={<ProtectedRoute />}>
        <Route
          exact
          path="/subvention"
          element={<ChargerListeSubventionParGrade />}
        />
      </Route>
      <Route exact path="/livreurs" element={<ProtectedRoute />}>
        <Route exact path="/livreurs" element={<Livreure />} />
      </Route>

      {/*       
      <Route exact path='/' element={<ListePlat />} />
      <Route exact path='/planning' element={<PlanningMenu />} />
      <Route exact path='/stats' element={<Statistiques />} />
      <Route exact path='/employes' element={<Employes />} />
      <Route exact path='/commandes' element={<Commandes />} />
      <Route exact path='/versement' element={<Versement />} /> */}
    </Routes>
  );
}

export default RoutesApp;
