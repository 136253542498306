import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { changermotdepasse } from '../../services/login/LoginRequest'

function ChangePassword() {

    const [loading, setloading] = useState(false)
    const [messageError, setmessageError] = useState("")
    const initialValues = {
        password: "",
        newPassword: "",
        passwordConfirm: ""
    }

    const validationSchemaValues = yup.object().shape({
        password: yup.string().required("Veuillez saisir l'ancien mot de passe"),
        newPassword: yup.string().required("Veuillez saisir le nouveau mot de passe"),
        passwordConfirm: yup.string().required("Veuillez confirmer le nouveau mot de passe")
    })

    const onSubmitValuesForm = (values) => {
        setmessageError("")
        if (values.newPassword === values.passwordConfirm) {
            setloading(true)
            changermotdepasse(values).then(() => {
                setloading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Votre mot de passe a bien été modifié !',
                    showConfirmButton: false,
                    timer: 1500
                })
                setmessageError("")
            }).catch((error) => {
                setloading(false)
                setmessageError(error.response.data.donnee)
            })
        } else {
            setmessageError("Les deux mots de passe que vous avez saisi ne sont pas identique")
        }
    }

    return (
        <div style={{ width: "100%" }}>

            {messageError !== "" && <div className='text-center mb-3'>
                <span className='text-danger'> <i className="fas fa-exclamation-triangle"></i> {messageError}</span>
            </div>}

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchemaValues}
                onSubmit={onSubmitValuesForm}

            >
                <Form>

                    <div className='mb-3'>
                        <label htmlFor="oldPassword" className='label-control'>Ancien mot de passe</label>
                        <Field type="password" name='password' id='oldPassword' className='form-control' />
                        <span className='text-danger'><ErrorMessage name='password'></ErrorMessage></span>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="newPassword" className='label-control'>Nouveau mot de passe</label>
                        <Field type="password" name='newPassword' id='newPassword' className='form-control' />
                        <span className='text-danger'><ErrorMessage name='newPassword'></ErrorMessage></span>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="passwordConfirm" className='label-control'>Confirmer mot de passe</label>
                        <Field type="password" name='passwordConfirm' id='passwordConfirm' className='form-control' />
                        <span className='text-danger'><ErrorMessage name='passwordConfirm'></ErrorMessage></span>
                    </div>
                    <div className='mb-3 d-flex justify-content-end'>
                        {loading ? <Oval width={30} height={30} color="#CE5D1D" visible={loading} /> : <button type='submit' className='btn btn-rose text-white'>Enregistrer</button>}
                    </div>

                </Form>
            </Formik>
        </div>
    )
}

export default ChangePassword