import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { rechercherlistecommandesparjour } from "../../services/commandes/CommandeRequest";

import moment from "moment";
import Paginate from "../pagination";
import { returnImageLink } from "../ReturnImageLink";
import { DecodeToken } from "../../DecodeToken";

function CommandEmployes(props) {
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [pageActive, setPageActive] = useState(1);
  const [listeCommandes, setlisteCommandes] = useState([]);
  const [loading, setloading] = useState(false);
  let [dataFiltre, setdataFiltre] = useState({
    employeId: "",
    entrepriseId: "",
    repasId: "",
    vendeuseId: "",
    restaurantId: "",
    restaurantParentId: "",
    statut: "",
    modeRecuperation: "",
  });

  const affichercommandeparjour = (currentPage, filtre) => {
    setloading(true);
    rechercherlistecommandesparjour(
      currentPage - 1,
      moment(props.jourSelected)
        .format("DD-MM-yyyy")
        .split("-")
        .reverse()
        .join("-"),
      filtre
    )
      .then((res) => {
        setloading(false);
        setlisteCommandes(res.data.donnee.commandes);
        settotalElement(res.data.donnee.totalElements);
        setTotalElementByPage(res.data.donnee.numberOfElements);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    affichercommandeparjour(pageNumber, dataFiltre);
  };

  const retournerStatut = (statut, afficher) => {
    if (statut === "VALIDE") {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "3px",
            fontSize: "11px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {afficher}
        </span>
      );
    } else if (statut === "INVALIDE") {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "red",
            color: "white",
            borderRadius: "3px",
            fontSize: "11px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {afficher}
        </span>
      );
    } else {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "#ffcc00",
            color: "white",
            borderRadius: "3px",
            fontSize: "11px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {afficher}
        </span>
      );
    }
  };

  useEffect(() => {
    if (DecodeToken().profil !== "ROLE_RESTAURATRICE") {
      dataFiltre.restaurantId =
        props.restaurant && props.restaurant.id
          ? props.restaurant.id
          : props.vendeuse && props.vendeuse.id
            ? props.vendeuse.id
            : props.restaurantParent && props.restaurantParent.id ? props.restaurantParent.id : "";
      dataFiltre.statut = props.statut !== null ? props.statut.valeur : "";
      dataFiltre.restaurantParentId = props.restaurantParent && props.restaurantParent.id ? props.restaurantParent.id : "";
      // dataFiltre.vendeuseId = props.vendeuse !== null ? props.vendeuse.id : "";
      dataFiltre.modeRecuperation =
        props.modeRecuperation !== null ? props.modeRecuperation.value : "";
      if (DecodeToken().profil === "ROLE_ADMIN") {
        dataFiltre.entrepriseId =
          props.entreprise !== null ? props.entreprise.id : "";
        dataFiltre.siteId =
          props.entreprise !== null && props.siteEntreprise !== null ? props.siteEntreprise.id : "";
      }
      setdataFiltre(dataFiltre);
    } else {
      dataFiltre.restaurantId =
        props.restaurant !== null
          ? props.restaurant.id
          : props.vendeuse !== null
            ? props.vendeuse.id
            : props.restaurantParent && props.restaurantParent.id ? props.restaurantParent.id : "";
      dataFiltre.statut = props.statut !== null ? props.statut.valeur : "";
      dataFiltre.entrepriseId =
        props.entreprise !== null ? props.entreprise.id : "";
      dataFiltre.siteId =
        props.entreprise !== null && props.siteEntreprise !== null ? props.siteEntreprise.id : "";
      dataFiltre.modeRecuperation =
        props.modeRecuperation !== null ? props.modeRecuperation.value : "";
      setdataFiltre(dataFiltre);
    }
    affichercommandeparjour(pageActive, dataFiltre);
  }, [
    props.jourSelected,
    props.vendeuse,
    props.entreprise,
    props.siteEntreprise,
    props.modeRecuperation,
    props.restaurant,
    props.restaurantParent,
    props.statut,
  ]);

  const rowListeCommandes = listeCommandes.map((item, index) => (
    <tr key={index}>
      <td>
        {item.employe !== null && item.employe.nom + " " + item.employe.prenoms}
      </td>
      <td>
        {item.repas.image ? (
          <img
            src={returnImageLink(
              item.repas.image !== null && item.repas.image.id
            )}
            width="50"
            className="rounded img-fluid"
            alt="Image plat"
            style={{ marginRight: "8px" }}
          />
        ) : null}
        {/* {item.repas.image === null && item.repas.designation} */}
        {item.repas.designation}
      </td>
      {DecodeToken().profil !== "ROLE_RESTAURATRICE" && (
        <td>
          {item.repas !== null && item.repas.vendeuse !== null
            ? item.repas.vendeuse.nom + " " + item.repas.vendeuse.prenoms
            : item.repas.restaurant !== null && item.repas.restaurant.nom}
        </td>
      )}
      <td>{item.quantite != null && item.quantite} </td>
      {/* <td>{(item.montantsubvention !== null? item.montantsubvention:0 )} F CFA</td> */}
      <td>
        {item.prix !== null && item.prix * item.quantite}F CFA
      </td>
      <td>{item.dateCommande !== null && item.dateCommande}</td>
      <td>{item.datePrise !== null && item.datePrise}</td>
      <td>
        {item.employe.entreprise !== null
          ? item.employe.entreprise.raisonSociale
          : item.employe.numero}
      </td>
      <td>
        {item.employe.site !== null
          ? item.employe.site.nom
          : ""}
      </td>
      <td>{retournerStatut(item.statut, item.textStatut)}</td>
    </tr>
  ));

  return (
    <>
      <table className="table">
        <thead>
          <tr className="gray-table rounded">
            <th>
              Employé
            </th>
            <th>
              Plat
            </th>
            {DecodeToken().profil !== "ROLE_RESTAURATRICE" && (
              <th>
                Vendeuse
              </th>
            )}
            <th>
              Quantité
            </th>
            <th>
              Montant
            </th>

            <th>
              Date commande
            </th>
            <th>
              Date récupération
            </th>
            <th>
              Entreprise
            </th>
            <th>
              Site
            </th>
            <th>
              Statut
            </th>
          </tr>
        </thead>
        <tbody>{!loading && rowListeCommandes}</tbody>
        <tfoot>
          <tr>
            <th colSpan="6">
              <div className="d-flex justify-content-center">
                <Paginate
                  pageActive={pageActive}
                  handlePageChange={handlePageChange}
                  nbreElementParPage={totalElementByPage}
                  nbreTotalElmentPage={totalElement}
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
      {
        <div className="d-flex justify-content-center">
          <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
        </div>
      }
    </>
  );
}

export default CommandEmployes;
