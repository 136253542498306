import React, { useEffect, useState } from "react";
import SweetAlert from "sweetalert-react";
import Layout from "../layouts/Layout";
import { Oval } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import { DecodeToken } from "../../DecodeToken";
import HeaderNomResto from "../HeaderNomResto";
import {
  supprimercompte,
  rechercherUtilisateurs,
  enregistrerUtilisateur,
  enregistrerUtilisateurPourComptable,
  updatePasswordUtilisateurs,
} from "../../services/utilisateurs/UtilisateurRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";
import { rechercherRestaurantsEnfant, recherchertouslesRestaurants } from "../../services/restaurants/RestaurantRequest";
import { changerstatutcompte } from "../../services/utilisateurs/UtilisateurRequest";
import {
  rechercherUtilisateursparparam,
  enregistrerRestauratriceUtilisateurs,
} from "../../services/utilisateurs/UtilisateurRequest";
import $ from "jquery";
import Paginate from "../pagination";

function Utilisateurs() {
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [usernameToUpdate, setUserNameToUpdate] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [filterParametres, setfilterParametres] = useState({ param: "" });
  const [pageActive, setPageActive] = useState(1);

  const [infoentreprise, setinfoentreprise] = useState({});
  const [loading, setloading] = useState(false);
  const [utilisateurs, setUtilisateurs] = useState({});
  const [param, setparam] = useState("");
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [numero, setNumero] = useState("");
  const [entrepriseId, setEntrepriseId] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [profil, setProfil] = useState("ROLE_RESTAURATRICE");
  const [listeEntreprise, setlisteEntreprise] = useState([]);
  const [listeRestaurantEnfant, setListeRestaurantEnfant] = useState([]);
  let [Error, setError] = useState("");
  let [utilisateur, setUtilisateur] = useState({
    username: "",
    password: "",
    nom: "",
    prenoms: "",
    email: "",
    numero: "",
    entrepriseId: "",
    restaurantId: "",
  });
  const [listeRestaurant, setlisteRestaurant] = useState([]);

  const afficherRestaurant = () => {
    recherchertouslesRestaurants()
      .then((response) => {
        setlisteRestaurant(response.data.donnee);
      })
      .catch((error) => { });
  };

  const rechercherUsers = (currentPage) => {
    setloading(true);
    rechercherUtilisateurs(currentPage - 1)
      .then((response) => {
        setloading(false);
        setUtilisateurs(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const afficherUtilisateurFiltrer = (parametre, currentPage) => {
    setloading(true);
    rechercherUtilisateursparparam(currentPage - 1, parametre)
      .then((response) => {
        setloading(false);
        setUtilisateurs(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const handleChangeetat = (user) => {
    changerstatutcompte(user.id).then((res) => {
      rechercherUsers(pageActive);
    });
  };

  const statusUseur = (enabled) => {
    if (enabled == true) {
      return <button className="btn btn-rose btn-sm text-white">Acfif</button>;
    } else if (enabled == false) {
      return (
        <button className="btn btn-dark btn-sm text-white">Inactif</button>
      );
    } else {
      return <button className="btn btn-dark btn-sm text-white">statut</button>;
    }
  };

  const afficherEntreprise = () => {
    rechercherentreprises()
      .then((response) => {
        setlisteEntreprise(response.data.donnee);
      })
      .catch(() => { });
  };

  const suprimerCompteutilisateur = (user) => {
    setUserIdToDelete(user.id);
    setShowSweetAlert(true);
  };

  const handleSearchuser = (parametre) => {
    setfilterParametres({ param: parametre });
    afficherUtilisateurFiltrer(parametre, 1);
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    filterParametres.param === ""
      ? afficherEntreprise(pageNumber)
      : afficherUtilisateurFiltrer(filterParametres.param, 1);
  };

  const deleteUtilisateurs = (userId) => {
    supprimercompte(userId).then((res) => {
      setShowSweetAlert(false);
      rechercherUsers(pageActive);
    });
  };

  const updateUserPassword = () => {
    const dataToSend = {
      username: usernameToUpdate,
      newPassword: newPassword,
    };

    setloading(true);
    updatePasswordUtilisateurs(dataToSend)
      .then((response) => {
        setloading(false);
        rechercherUsers(pageActive);
        $("#update-password").trigger("click");
      })
      .catch((error) => {
        setloading(false);
        rechercherUsers(pageActive);
      });
  };

  const rowListeEntreprise =
    utilisateurs.utilisateurs !== undefined &&
    utilisateurs.utilisateurs.map((item, index) => (
      <tr key={index}>
        <td>{item.nom + " " + item.prenoms}</td>
        <td>{item.profil}</td>
        <td>{item.numero}</td>
        <td>{item.email}</td>
        {DecodeToken().profil === "ROLE_ADMIN" && (
          <>
            <td>
              {item.entreprise != null ? item.entreprise.raisonSociale : ""}
            </td>
            <td>{item.restaurant != null ? item.restaurant.nom : ""}</td>
          </>
        )}
        <td>
          {" "}
          <button onClick={() => handleChangeetat(item)} className="btn">
            {statusUseur(item.enabled)}
          </button>{" "}
        </td>
        {DecodeToken().profil === "ROLE_ADMIN" && (
          <td>
            <div className="d-flex">
              <button
                onClick={() => {
                  setUserNameToUpdate(item.username);
                  setNewPassword("");
                }}
                className="btn bg-success btn-sm text-white mx-1"
                data-bs-toggle="modal"
                data-bs-target="#updatePassword"
              >
                <i className="fa fa-key white-table-bottom"></i>
              </button>
              <button
                onClick={() => suprimerCompteutilisateur(item)}
                className="btn bg-danger btn-sm text-white"
              >
                Supprimer
              </button>
            </div>
          </td>
        )}
      </tr>
    ));

  const entrepriseHandleChange = (event) => {
    console.log(event.target.value);
    if (event.target.value.length != 0) {
      setRestaurantId("");
    }
    setEntrepriseId(event.target.value);
  };

  const reinitialiser = () => {
    setNumero("");
    setNom("");
    setPrenoms("");
    setEmail("");
    setUsername("");
    setEntrepriseId("");
    setRestaurantId("");
    setProfil("ROLE_RESTAURATRICE");
  };

  const handleSubmit = () => {
    let object = {
      username: email,
      password: password,
      nom: nom,
      prenoms: prenoms,
      email: email,
      numero: numero,
      entrepriseId: entrepriseId,
      restaurantId: restaurantId,
      profil: profil,
    };

    setloading(true);

    DecodeToken().profil === "ROLE_ADMIN" &&
      enregistrerUtilisateur(object)
        .then(() => {
          rechercherUsers(pageActive);
          setloading(false);
          reinitialiser();
          $("#reinitialiseFormEntreprise").trigger("click");
        })
        .catch((error) => {
          Error = error.response.data.donnee;
          setError(Error);
          setloading(false);
        });

    DecodeToken().profil === "ROLE_RESTAURATRICE" &&
      enregistrerRestauratriceUtilisateurs(object)
        .then(() => {
          rechercherUsers(pageActive);
          setloading(false);
          reinitialiser();
          $("#reinitialiseFormEntreprise").trigger("click");
        })
        .catch((error) => {
          Error = error.response.data.donnee;
          setError(Error);
          setloading(false);
        });

    DecodeToken().profil === "ROLE_COMPTABLE" &&
      enregistrerUtilisateurPourComptable(object)
        .then(() => {
          rechercherUsers(pageActive);
          setloading(false);
          reinitialiser();
          $("#reinitialiseFormEntreprise").trigger("click");
        })
        .catch((error) => {
          Error = error.response.data.donnee;
          setError(Error);
          setloading(false);
        });
  };

  const restaurantHandleChange = (event) => {
    if (event.target.value.length != 0) {
      setEntrepriseId("");
    }
    setRestaurantId(event.target.value);
  };

  useEffect(() => {
    afficherEntreprise();
    afficherRestaurant();
    if (DecodeToken().profil === "ROLE_RESTAURATRICE") {
      rechercherRestaurantsEnfant().then((response) => {
        setListeRestaurantEnfant(response.data.donnee);
      });
    }
  }, []);

  useEffect(() => {
    rechercherUsers(pageActive);
  }, [pageActive]);

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_ADMIN" &&
        DecodeToken().profil !== "ROLE_RESTAURATRICE" &&
        DecodeToken().profil !== "ROLE_COMPTABLE" && (
          <Navigate to="/login" replace />
        )}
      <HeaderNomResto />
      <p className="mb-3 mt-3 fw-bold">Utilisateurs</p>
      <div>
        <div className="bg-white rounded p-2">
          <div className="d-flex justify-content-between">
            <div className="">
              <div className="input-group d-flex bg-gris rounded mt-1 mb-3">
                <div className="input-group-text">
                  <i className="fa fa-search text-secondary"></i>
                </div>
                <input
                  type="search"
                  onChange={(e) => handleSearchuser(e.target.value)}
                  placeholder="Recherche..."
                  name=""
                  id=""
                  className="form-control bg-gris form-control-sm loginborderleft"
                />
              </div>
            </div>
            <div className="">
              <button
                onClick={reinitialiser}
                className="btn btn-sm btn-rose text-white"
                data-bs-target="#entreprise"
                data-bs-toggle="modal"
              >
                AJOUTER
              </button>
            </div>
            {/* <AddEntreprise infoentreprise={infoentreprise} actualisteListe={afficherEntreprise} /> */}
          </div>
          <table className="table">
            <thead>
              <tr className="gray-table rounded">
                <th width="20%">Nom prenoms</th>
                <th>Profil</th>
                <th width="15%">Téléphone</th>
                <th width="25%">Adresse email</th>
                {DecodeToken().profil === "ROLE_ADMIN" ? (
                  <>
                    <th width="15%">Entreprise</th>
                    <th width="18%">Restaurant</th>
                  </>
                ) : null}
                <th width="10%">Statut</th>
                {DecodeToken().profil === "ROLE_ADMIN" && (
                  <th width="10%">Actions</th>
                )}
              </tr>
            </thead>
            <tbody>{!loading && rowListeEntreprise}</tbody>
            <tfoot>
              <tr>
                <th colSpan="6">
                  <div className="d-flex justify-content-center">
                    <Paginate
                      pageActive={pageActive}
                      handlePageChange={handlePageChange}
                      nbreElementParPage={totalElementByPage}
                      nbreTotalElmentPage={totalElement}
                    />
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
          {
            <div className="d-flex justify-content-center">
              <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
            </div>
          }
        </div>

        {/* FORMULAIRE D'AJOUT D'UN NOUVEL UTILISATEUR */}
        <div
          className="modal fade"
          id="entreprise"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered ">
            <div className="modal-content bg-rose">
              <div className="modal-header">
                <h5 className="modal-title fw-bold">Ajouter utilisateur</h5>
              </div>
              <div className="modal-body">
                <div className="row gx-1">
                  <div className="mb-3 col-md-4">
                    <label htmlFor="nom" className="label-control">
                      Nom
                    </label>
                    <input
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                      type="text"
                      name="nom"
                      id="nom"
                      placeholder="Entrer le nom d'entreprise"
                      className="form-control"
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <label htmlFor="prenoms" className="label-control">
                      Prenoms
                    </label>
                    <input
                      value={prenoms}
                      onChange={(e) => setPrenoms(e.target.value)}
                      type="text"
                      name="prenoms"
                      id="prenoms"
                      placeholder="Entrer le nom d'entreprise"
                      className="form-control"
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <label htmlFor="adresse" className="label-control">
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      name="adresse"
                      id="adresse"
                      placeholder="Entrer l'adresse"
                      className="form-control"
                    />
                  </div>
                </div>
                {DecodeToken().profil !== "ROLE_RESTAURATRICE" &&
                  DecodeToken().profil !== "ROLE_COMPTABLE" && (
                    <div className="row gx-1">
                      <div className="mb-3 col-md-6">
                        <label htmlFor="email" className="label-control">
                          Entreprise
                        </label>
                        <select
                          value={entrepriseId}
                          onChange={entrepriseHandleChange}
                          className="form-select"
                        >
                          <option value=""></option>
                          {listeEntreprise.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.raisonSociale}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="mb-3 col-md-6">
                        <label htmlFor="numero" className="label-control">
                          Restaurant
                        </label>
                        <select
                          value={restaurantId}
                          onChange={restaurantHandleChange}
                          className="form-select"
                        >
                          <option value=""></option>
                          {listeRestaurant.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.nom}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}

                {restaurantId.length != 0 && (
                  <div className="row gx-1">
                    <div className="mb-3 col-md-6">
                      <label htmlFor="numero" className="label-control">
                        Profil
                      </label>

                      {DecodeToken().profil === "ROLE_RESTAURATRICE" ? (
                        <select
                          value={profil}
                          onChange={(e) => setProfil(e.target.value)}
                          className="form-select"
                        >
                          <option value="ROLE_RESTAURATRICE">
                            Administrateur
                          </option>
                          <option value="ROLE_SCANNER">Scanner</option>
                        </select>
                      ) : DecodeToken().profil === "ROLE_COMPTABLE" ? (
                        <select
                          value={profil}
                          onChange={(e) => setProfil(e.target.value)}
                          className="form-select"
                        >
                          <option value="ROLE_COMPTABLE">Administrateur</option>
                          <option value="ROLE_CAISSIERE_ENTREPRISE">
                            Caissière
                          </option>
                        </select>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className="row gx-1">
                  <div
                    className={
                      DecodeToken().profil === "ROLE_RESTAURATRICE" ||
                        DecodeToken().profil === "ROLE_COMPTABLE"
                        ? "mb-3 col-md-4"
                        : "mb-3 col-md-6"
                    }
                  >
                    <label htmlFor="numero" className="label-control">
                      Numéro
                    </label>
                    <input
                      type="text"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      name="numero"
                      id="numero"
                      placeholder="Entrer le numéro"
                      className="form-control"
                    />
                    <div style={{ color: "red" }}>{Error} </div>
                  </div>
                  <div
                    className={
                      DecodeToken().profil === "ROLE_RESTAURATRICE" ||
                        DecodeToken().profil === "ROLE_COMPTABLE"
                        ? "mb-3 col-md-4"
                        : "mb-3 col-md-6"
                    }
                  >
                    <label htmlFor="email" className="label-control">
                      Mot de passe
                    </label>
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      name="email"
                      id="email"
                      placeholder="Entrer mot de passe"
                      className="form-control"
                    />
                  </div>
                  {(DecodeToken().profil === "ROLE_RESTAURATRICE" ||
                    DecodeToken().profil === "ROLE_COMPTABLE") && (
                      <div className="mb-3 col-md-4">
                        <label htmlFor="numero" className="label-control">
                          Profil
                        </label>

                        {DecodeToken().profil === "ROLE_RESTAURATRICE" ? (
                          <select
                            value={profil}
                            onChange={(e) => setProfil(e.target.value)}
                            className="form-select"
                          >
                            <option value="ROLE_RESTAURATRICE">
                              Administrateur
                            </option>
                            <option value="ROLE_SCANNER">Scanner</option>
                          </select>
                        ) : DecodeToken().profil === "ROLE_COMPTABLE" ? (
                          <select
                            value={profil}
                            onChange={(e) => setProfil(e.target.value)}
                            className="form-select"
                          >
                            <option value="ROLE_COMPTABLE">Administrateur</option>
                            <option value="ROLE_CAISSIERE_ENTREPRISE">
                              Caissière
                            </option>
                          </select>
                        ) : null}
                      </div>
                    )}
                </div>
                <div className="row gx-1">
                  {DecodeToken().profil === "ROLE_RESTAURATRICE" && (
                    <div className="mb-3 col-md-4">
                      <label htmlFor="numero" className="label-control">
                        Restaurant enfant
                      </label>

                      <select
                        value={restaurantId}
                        onChange={(e) => setRestaurantId(e.target.value)}
                        className="form-select"
                      >
                        <option value="">Sélectionner un restaurant</option>
                        {listeRestaurantEnfant.map((item, index) => <option value={item.id} key={index}>{item.nom}</option>)}
                      </select>
                    </div>
                  )}
                </div>
                <div className="mb-3 d-flex justify-content-between mx-2 my-3  ">
                  <button
                    type="button"
                    className="btn btn-dark"
                    id="reinitialiseFormEntreprise"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <Oval
                    width={40}
                    height={40}
                    color="#CE5D1D"
                    visible={loading}
                  />
                  {!loading && (
                    <button
                      onClick={handleSubmit}
                      className="btn text-white btn-rose"
                    >
                      {" "}
                      <i className="bi bi-check-circle-fill"></i> Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FIN DU FORMULAIRE D'AJOUT D'UN NOUVEL UTILISATEUR */}

        {/* MODAL UPDATE PASSWORD */}
        <div
          class="modal fade"
          id="updatePassword"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content bg-rose">
              <div class="modal-header">
                <h5 class="modal-title fw-bold" id="exampleModalLabel">
                  Modifier le mot de passse
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label for="recipient-name" class="col-form-label">
                    Nouveau mot de passe :
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-between mx-2 my-3">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id="update-password"
                >
                  Annuler
                </button>
                <Oval
                  width={40}
                  height={40}
                  color="#CE5D1D"
                  visible={loading}
                />
                {!loading && (
                  <button
                    type="button"
                    class="btn text-white btn-rose"
                    onClick={updateUserPassword}
                  >
                    <i className="bi bi-check-circle-fill"></i> Modifier
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* FIN MODAL UPDATE PASSWORD */}
      </div>
      <SweetAlert
        show={showSweetAlert}
        title="Confirmer la suppression"
        text="Cette action est irréversible"
        showCancelButton
        onConfirm={() => deleteUtilisateurs(userIdToDelete)}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
        onEscapeKey={() => setShowSweetAlert(false)}
        onOutsideClick={() => setShowSweetAlert(false)}
      />
    </Layout>
  );
}

export default Utilisateurs;
