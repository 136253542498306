
import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../services/BaseUrl';
import { Oval } from 'react-loader-spinner';
import {rechercherSites,rechercherentreprises,recherchersiteparentreprise } from '../../services/entreprises/entrepriseRequest';
import { DecodeToken } from '../../DecodeToken';
function ChargerListeEmploye(props) {

    const [fileUploded, setfileUploded] = useState([]);
    const [loading, setloading] = useState(false);
    const [loadingregister, setloadingregister] = useState(false);
    const [showDetailsListEmploye, setshowDetailsListEmploye] = useState(false);
    const [retourServeurApresEnvoieFichier, setRetourServeurApresEnvoieFichier] = useState({
        nombreEmploye: "0",
        nombreEmployeInvalid: "0",
        nombreEmployeValid: "0"
    });
    let [listeSite,setListeSite]=useState([])
    let [site,setSite]=useState(null)
    let [siteparentreprise,setSiteparentreprise]=useState(null)
    let [listeEntreprise,setlisteEntreprise]=useState([])
    //console.log(listeSite);
    const [messageError, setmessageError] = useState("");
    let formatData = new FormData()
    
    const afficherLaListeSite=()=>{
        rechercherSites().then((response) => {
            setloading(false);
            listeSite = response.data.donnee;
            setListeSite(listeSite);
        }).catch(() => {
            setloading(false);
        })
        
    }

    const recherchersite=(id)=>{
        recherchersiteparentreprise(id).then((res)=>{
            listeSite = res.data.donnee;
            setListeSite(listeSite);
        })
    }

    //input
    const onFileChange = (event) => {
        setloading(true);
        setfileUploded(event.target.files[0]);
        formatData.append("fichierEmploye", event.target.files[0]);
        const response = axios.post(baseUrl("verifierfichier"), formatData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${localStorage.getItem("tokenRestaurant")}` } }).then((res) => {
            setRetourServeurApresEnvoieFichier(res.data.donnee);
            setloading(false);
            setshowDetailsListEmploye(true)
            setmessageError("")
        }).catch((err) => {
            setmessageError(err.response.data.donnee)
            setloading(false);
            setshowDetailsListEmploye(false)
        })
    }


    //Enregistrement des employes
    const SendDataFile = (params) => {
        setloadingregister(true)
        const loadingEmployeResponse = axios.post(baseUrl("chargerfichieremployes"), params, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${localStorage.getItem("tokenRestaurant")}` } }).then((responseReq) => {
            setshowDetailsListEmploye(false);
            setloadingregister(false)
            setloading(false);
            document.getElementById("fermerList").click()
            setmessageError("")
            props.actualiserList(1);
        }).catch((err) => {
            setloadingregister(false)
            setmessageError(err.response.data.donnee)
        })
    }

    //bouton upload
    const uploadFile = () => {
        let param = new FormData();

        param.append("fichierEmploye", fileUploded);
        param.append("siteId",site)
        SendDataFile(param)
    }

    //Bouton Fermer
    const handleClosing = () => {
        setloading(false);
        setmessageError("");
        setshowDetailsListEmploye(false)
        document.getElementById("champUpload").value = "";
    }

    const handleSite=(e)=>{
        site=e.target.value
        setSite(site)
    }

    const afficherEntreprise = () => {
        rechercherentreprises().then((response) => {
            setlisteEntreprise(response.data.donnee);
        }).catch((err) => {
            console.log(err);
        })
    }
    
    useEffect(()=>{
        afficherEntreprise()
      {DecodeToken().profil !=="ROLE_ADMIN" && afficherLaListeSite()}
    },[])

    
    return (
        <div className="modal fade" id="listeEmploye" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">

            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Charger la liste des employés</h5>
                        <button type="button" onClick={handleClosing} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {messageError !== "" &&
                            <div className="row">
                                <p className='text-danger'> <i className='fa fa-exclamation-triangle'></i> {messageError}</p>
                            </div>
                        }
                        {
                            DecodeToken().profil==="ROLE_ADMIN" && 
                                <div className='mb-3'>
                                    <select  name="entrepriseId" id="entrepriseId"  className='form-control' onChange={(e)=>recherchersite(e.target.value)}>
                                        <option selected disabled value="">Sélectionner une entreprise</option>
                                                    {
                                                        listeEntreprise.map((entreprise,index)=><option  value={entreprise.id} key={index} >{entreprise.raisonSociale} </option>)
                                                    }
                                        </select>
                                 <span className='text-danger'></span>
                                        </div>
                                        }
                        <div className="mb-3">
                            <select className="form-select mb-4" aria-label="Default select example" onChange={handleSite} required>
                                <option selected value="" disabled>Selectionner un site</option>
                                {listeSite.map((site,index)=>(<option value={site.id} key={index} >{site.nom} </option>))}
                                
                            </select>
                            {!showDetailsListEmploye && <input type="file" onChange={onFileChange} className="form-control d-flex" id="champUpload" />}

                            {loading && <div className=' mt-2 d-flex justify-content-center'><Oval visible={loading} color="#CE5D1D" height={40} width={40} /></div>}

                            {showDetailsListEmploye &&
                                <div className="d-flex text-center justify-content-between" >
                                    <span className='mt-3 mx-1'>Total employé : {retourServeurApresEnvoieFichier.nombreEmploye}</span><br />
                                    <span className='mt-3 mx-1 text-success' > Nombre valide : {retourServeurApresEnvoieFichier.nombreEmployeValid}</span><br />
                                    <span className='mt-3 mx-1 text-danger'> Nombre invalide : {retourServeurApresEnvoieFichier.nombreEmployeInvalid}</span>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" id="fermerList" onClick={handleClosing} className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                        {!loadingregister ? <button type="button" onClick={uploadFile} className="btn btn-rose text-white" disabled={loading}>Ajouter</button>
                            : <Oval visible={loadingregister} color="#CE5D1D" height={40} width={40} />
                        }</div>
                </div>
            </div>
        </div>)
}



export default ChargerListeEmploye;
