import React, { useEffect, useState } from "react";
import HeaderNomResto from "../HeaderNomResto";
import Layout from "../layouts/Layout";
import ChangePassword from "./ChangePassword";
import { DecodeToken } from "../../DecodeToken";
import DelaisRappelle from "./DelaisRappelle";
import {
  rechercherdelaisrappel,
  supprimerrappel,
} from "../../services/delais/DelaisRequest";
import { Navigate } from "react-router-dom";
import Version from "./Version";
import ModeSubvention from "./ModeSubvention";
import CaractereSubvention from "./CaractereSubvention";
import SuspensionSubvention from "./SuspensionSubvention";
import Questionnaire from "./Questionnaire";
import DelaiService from "./DelaiService";
function Parametres() {
  let [composant, setComposant] = useState("parametre");
  let [context, setContext] = useState("Parametres");
  let [listeRappel, setListeRappel] = useState([]);
  let [inforappel, setInforappel] = useState({});
  let [loading, setLoading] = useState(false);

  const rechercherdelaisrappelle = () => {
    rechercherdelaisrappel().then((res) => {
      setListeRappel(res.data.donnee);
    });
  };

  const supprimerrappelle = (id) => {
    listeRappel.map((list) => id == list.id && setInforappel(list));
    supprimerrappel(id)
      .then((res) => {
        rechercherdelaisrappelle();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const rowListeRappelle = listeRappel.map((item, index) => (
    <tr key={index}>
      <td>{item.entreprise.raisonSociale}</td>
      <td>{item.jour}</td>
      <td>
        <button
          data-bs-target="#rappel"
          data-bs-toggle="modal"
          onClick={() => supprimerrappelle(item.entreprise.id)}
          className="btn bg-danger btn-sm text-white"
        >
          Supprimer
        </button>
      </td>
    </tr>
  ));

  useEffect(() => {
    rechercherdelaisrappelle();
  }, []);

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_RESTAURATRICE" &&
        DecodeToken().profil !== "ROLE_ADMIN" &&
        DecodeToken().profil !== "ROLE_COMPTABLE" &&
        DecodeToken().profil !== "ROLE_CAISSIERE_ENTREPRISE" && (
          <Navigate to="/login" replace />
        )}
      <div>
        <HeaderNomResto />
        {DecodeToken().profil !== "ROLE_RESTAURATRICE" &&
        DecodeToken().profil !== "ROLE_ADMIN" ? (
          <div>
            <div>
              <nav className="nav">
                <div
                  className="nav-link el active"
                  aria-current="page"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setComposant("parametre");
                    setContext("Parametres");
                    document.querySelector(".el").classList.add("active");
                    document.querySelector(".s").classList.remove("active");
                  }}
                >
                  Parametres
                </div>
                <div
                  className="nav-link s"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setComposant("subvention");
                    setContext("Mode de subvention");
                    document.querySelector(".s").classList.add("active");
                    document.querySelector(".el").classList.remove("active");
                  }}
                >
                  Mode de subvention
                </div>
              </nav>
            </div>
            <p className="mb-3 mt-3 fw-bold">{context}</p>
            {composant === "parametre" && (
              <div className="bg-white rounded px-2 py-3">
                <div className="row my-3 align-items-start">
                  <div
                    className="nav col-3 flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div className="px-3">
                      <button
                        className="nav-link active"
                        id="password-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#password"
                        type="button"
                        role="tab"
                        aria-controls="password"
                        aria-selected="true"
                      >
                        Changer mot de passe
                      </button>
                    </div>
                    {/* <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button> */}
                  </div>
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="password"
                      role="tabpanel"
                      aria-labelledby="password-tab"
                    >
                      <ChangePassword />
                    </div>
                    {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...</div> */}
                  </div>
                </div>
              </div>
            )}
            {composant === "subvention" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="row my-3 align-items-start">
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <ModeSubvention />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : DecodeToken().profil === "ROLE_RESTAURATRICE" ? (
          <div>
            <nav className="nav">
              <div
                className="nav-link el active"
                aria-current="page"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("parametre");
                  setContext("Parametres");
                  document.querySelector(".el").classList.add("active");
                  document.querySelector(".f").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Parametres
              </div>
              <div
                className="nav-link dls"
                aria-current="page"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("delai_service");
                  setContext("Délai de service");
                  document.querySelector(".dls").classList.add("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".f").classList.remove("active");
                }}
              >
                Délai de service
              </div>
              <div
                className="nav-link f"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("delais");
                  setContext("Delais de rappel");
                  document.querySelector(".f").classList.add("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Delais de rappel
              </div>
            </nav>
            <p className="mb-3 mt-3 fw-bold">{context}</p>
            {composant === "parametre" && (
              <div className="bg-white rounded px-2 py-3">
                <div className="row my-3 align-items-start">
                  <div
                    className="nav col-3 flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div className="px-3">
                      <button
                        className="nav-link active"
                        id="password-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#password"
                        type="button"
                        role="tab"
                        aria-controls="password"
                        aria-selected="true"
                      >
                        Changer mot de passe
                      </button>
                    </div>
                    {/* <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button> */}
                  </div>
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="password"
                      role="tabpanel"
                      aria-labelledby="password-tab"
                    >
                      <ChangePassword />
                    </div>
                    {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...</div> */}
                  </div>
                </div>
              </div>
            )}
            {composant === "delai_service" ? (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="row my-3 align-items-start">
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <DelaiService />
                  </div>
                </div>
              </div>
            ) : null}
            {composant === "delais" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="row my-3 align-items-start">
                  <div
                    className="nav col-3  m-4 "
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <table className="table">
                      <thead>
                        <tr className="gray-table rounded">
                          <th width="20%">
                            Entreprises
                            <span className="mx-3">
                              <svg
                                width="12"
                                height="7"
                                viewBox="0 0 12 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </th>
                          <th width="20%">
                            Jours
                            <span className="mx-3">
                              <svg
                                width="12"
                                height="7"
                                viewBox="0 0 12 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </th>
                          <th width="15%">
                            Actions
                            <span className="mx-3">
                              <svg
                                width="12"
                                height="7"
                                viewBox="0 0 12 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{!loading && rowListeRappelle}</tbody>
                    </table>
                  </div>
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="password"
                      role="tabpanel"
                      aria-labelledby="password-tab"
                    >
                      <DelaisRappelle
                        actualisation={rechercherdelaisrappelle}
                        inforappel={inforappel}
                        setInforappel={setInforappel}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : DecodeToken().profil === "ROLE_ADMIN" ? (
          <div>
            <nav className="nav">
              <div
                className="nav-link el active"
                aria-current="page"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("parametre");
                  setContext("Parametres");
                  document.querySelector(".el").classList.add("active");
                  document.querySelector(".sub").classList.remove("active");
                  document.querySelector(".carac").classList.remove("active");
                  document.querySelector(".f").classList.remove("active");
                  document.querySelector(".s").classList.remove("active");
                  document.querySelector(".faq").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Parametres
              </div>
              <div
                className="nav-link dls"
                aria-current="page"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("delai_service");
                  setContext("Délai de service");
                  document.querySelector(".dls").classList.add("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".sub").classList.remove("active");
                  document.querySelector(".carac").classList.remove("active");
                  document.querySelector(".f").classList.remove("active");
                  document.querySelector(".s").classList.remove("active");
                  document.querySelector(".faq").classList.remove("active");
                }}
              >
                Délai de service
              </div>
              <div
                className="nav-link f"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("version");
                  setContext("Version de déploiement");
                  document.querySelector(".f").classList.add("active");
                  document.querySelector(".sub").classList.remove("active");
                  document.querySelector(".carac").classList.remove("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".s").classList.remove("active");
                  document.querySelector(".faq").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Version de déploiement
              </div>
              <div
                className="nav-link s"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("subvention");
                  setContext("Mode de subvention");
                  document.querySelector(".s").classList.add("active");
                  document.querySelector(".sub").classList.remove("active");
                  document.querySelector(".carac").classList.remove("active");
                  document.querySelector(".f").classList.remove("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".faq").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Mode de subvention
              </div>
              <div
                className="nav-link carac"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("caractere_subvention");
                  setContext("Caractère subvention");
                  document.querySelector(".carac").classList.add("active");
                  document.querySelector(".sub").classList.remove("active");
                  document.querySelector(".s").classList.remove("active");
                  document.querySelector(".f").classList.remove("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".faq").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Caractère subvention
              </div>
              <div
                className="nav-link sub"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("suspension_subvention");
                  setContext("Suspension subvention");
                  document.querySelector(".sub").classList.add("active");
                  document.querySelector(".carac").classList.remove("active");
                  document.querySelector(".s").classList.remove("active");
                  document.querySelector(".f").classList.remove("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".faq").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Suspension subvention
              </div>
              <div
                className="nav-link faq"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("questionnaire");
                  setContext("Questionnaire");
                  document.querySelector(".faq").classList.add("active");
                  document.querySelector(".sub").classList.remove("active");
                  document.querySelector(".carac").classList.remove("active");
                  document.querySelector(".s").classList.remove("active");
                  document.querySelector(".f").classList.remove("active");
                  document.querySelector(".el").classList.remove("active");
                  document.querySelector(".dls").classList.remove("active");
                }}
              >
                Questionnaires
              </div>
            </nav>

            <p className="mb-3 mt-3 fw-bold">{context}</p>
            {composant === "parametre" && (
              <div className="bg-white rounded px-2 py-3">
                <div className="row my-3 align-items-start">
                  <div
                    className="nav col-3 flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div className="px-3">
                      <button
                        className="nav-link active"
                        id="password-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#password"
                        type="button"
                        role="tab"
                        aria-controls="password"
                        aria-selected="true"
                      >
                        Changer mot de passe
                      </button>
                    </div>
                    {/* <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button> */}
                  </div>
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="password"
                      role="tabpanel"
                      aria-labelledby="password-tab"
                    >
                      <ChangePassword />
                    </div>
                    {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...</div> */}
                  </div>
                </div>
              </div>
            )}
            {composant === "delai_service" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="row my-3 align-items-start">
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <DelaiService />
                  </div>
                </div>
              </div>
            )}
            {composant === "version" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="row my-3 align-items-start">
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <Version />
                  </div>
                </div>
              </div>
            )}
            {composant === "version" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="row my-3 align-items-start">
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <Version />
                  </div>
                </div>
              </div>
            )}
            {composant === "subvention" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="row my-3 align-items-start">
                  <div className="tab-content col-7" id="v-pills-tabContent">
                    <ModeSubvention />
                  </div>
                </div>
              </div>
            )}
            {composant === "caractere_subvention" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="my-3">
                  <div className="tab-content" id="v-pills-tabContent">
                    <CaractereSubvention />
                  </div>
                </div>
              </div>
            )}
            {composant === "suspension_subvention" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="my-3">
                  <div className="tab-content" id="v-pills-tabContent">
                    <SuspensionSubvention />
                  </div>
                </div>
              </div>
            )}
            {composant === "questionnaire" && (
              <div className="bg-white rounded px-2 py-3 position-relative">
                <div className="my-3">
                  <div className="tab-content" id="v-pills-tabContent">
                    <Questionnaire />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>Le profil n'a pas accès à ce paramètre</>
        )}
      </div>
    </Layout>
  );
}

export default Parametres;
