import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

const NBRE_ELEMENT=7
export const recherchervendeuses = async(page) =>{
    return await axios.get(baseUrl(`recherchervendeuses?page=${page}&size=${NBRE_ELEMENT}`));
}
export const recherchertouteslesvendeuses = async() =>{
    return await axios.get(baseUrl(`recherchertouteslesvendeuses`));
}

export const enregistrervendeuse = async(values) =>{
    return await axios.post(baseUrl('admin/enregistrervendeuse'), values);
}

export const enregistrerrepasvendeuse = async(values)=>{
    return await axios.post(baseUrl('admin/enregistrerrepasvendeuseetrestaurant'), values);
}

export const adminsupprimerrepas =async(id)=>{
    return await axios.delete(baseUrl(`admin/supprimerrepas/${id}`));
}

export const recherchermenuparmoisparvendeuse = async(id, date)=>{
    return await axios.get(baseUrl(`admin/recherchermenuparmoisparvendeuse/${id}?date=${date}`));
}

export const recherchermenuparmoisparrestaurant = async(id, date)=>{
    return await axios.get(baseUrl(`admin/recherchermenuparmoisparrestaurant/${id}?date=${date}`));
}

export const recherchermenuparmoisparentreprise = async(entrepriseId = 0, vendeuseId = 0, restaurantId = 0, siteId = 0, date)=>{
    return await axios.get(baseUrl(`recherchermenuparmois/${entrepriseId}/${restaurantId}/${vendeuseId}/${siteId}?date=${date}`));
}

export const rechercherlisteplatsparvendeuse = async(id)=>{
    return await axios.get(baseUrl(`admin/rechercherlisteplatsparvendeuse/${id}`));
}

export const rechercherlisteplatsparentrepriseForAdmin = async(entrepriseId = 0, restaurantId = 0, vendeuseId = 0)=>{
    return await axios.get(baseUrl(`rechercherlisteplats/${entrepriseId}/${restaurantId}/${vendeuseId}`));
}

export const rechercherlisteplatsparentreprise = async(id)=>{
    return await axios.get(baseUrl(`rechercherlisteplatsparentreprise/${id}`));
}

export const rechercherlisteplatsparrestaurant = async(id)=>{
    return await axios.get(baseUrl(`admin/rechercherlisteplatsparrestaurant/${id}`));
}

export const enregistrerrepasjournalierparvendeuse = async(values)=>{
    return await axios.post(baseUrl("admin/enregistrerrepasjournalierparvendeuse"), values)
}

export const enregistrerrepasjournalierparvendeuseetparrestaurant = async(values)=>{
    return await axios.post(baseUrl("admin/enregistrerrepasjournalierparvendeuseparrestaurant"), values)
}

export const recherchervendeusesparparam = async(page,param) =>{
    return await axios.post(baseUrl(`recherchervendeusesparparam?page=${page}&param=${param}&size=${NBRE_ELEMENT}`),{});
}

export const autoriservendeuse = async(id)=>{
    return await axios.post(baseUrl(`entreprise/autoriservendeuse/${id}`),{});
}

export const changerEtatVendeuse = async(id)=>{
    return await axios.post(baseUrl(`admin/changeretatvendeuse/${id}`),{});
}

export const refreshVendeuse = async(id)=>{
    return await axios.post(baseUrl(`admin/rajoutervendeuseauutilisateur/${id}`),{});
}

export const supprimerVendeuse = async(id)=>{
    return await axios.delete(baseUrl(`admin/supprimerVendeuse/${id}`));
}

export const retirervendeuse = async(id)=>{
    return await axios.get(baseUrl(`entreprise/retirervendeuse/${id}`));
}

export const recherchervendeuseautorises = async()=>{
    return await axios.get(baseUrl('entreprise/recherchervendeuseautorises'));
}


