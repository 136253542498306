import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { Autocomplete, TextField } from "@mui/material";
import { enregistrerCaratereSubvention } from "../../services/parametre/ParametreRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";

function AddCaractereSubvention(props) {
  const [loading, setloading] = useState(false);
  const [caractereId, setCaractereId] = useState(null);
  const [entrepriseSelected, setentrepriseSelected] = useState(null);
  const [caractereSelected, setCaractereSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState(null);
  const [listeEntreprises, setlisteEntreprises] = useState([]);
  const [listeCaractereSubvention, setListeCaractereSubvention] = useState([
    {
      label: "Cumulé",
      value: "CUMULE",
    },
    {
      label: "Non cumulé",
      value: "NON_CUMULE",
    },
  ]);
  const [listeTypesSubvention, setListeTypesSubvention] = useState([
    {
      label: "Journalier",
      value: "JOUR",
    },
    {
      label: "Mensuelle",
      value: "MOIS",
    },
  ]);
  const [messageError, setmessageError] = useState("");

  const initialValues = {
    dateDebut:
      props.infoCaractere.dateDebut !== undefined
        ? props.infoCaractere.dateDebut
        : "",
  };

  function transformDate(date) {
    const dateArray = date.split("-");
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  }

  const handleOnSubmit = (values) => {
    setloading(true);
    setmessageError("");

    const dataToSubmit = {
      id: caractereId ? caractereId : "",
      entrepriseId: entrepriseSelected ? entrepriseSelected.id : "",
      caractere: caractereSelected.value,
      periode: typeSelected.value,
      dateDebut: transformDate(values.dateDebut),
    };

    enregistrerCaratereSubvention(dataToSubmit)
      .then((res) => {
        setloading(false);
        setentrepriseSelected(null);
        setCaractereSelected(null);
        setTypeSelected(null);
        setmessageError("");
        props.actualiserliste(1);
        document.getElementById("close-btn").click();
      })
      .catch((error) => {
        setloading(false);
        setmessageError(
          error && error.response
            ? error.response.data.donnee
            : "Une erreur s'est produite"
        );
        console.log("error", error);
      });
  };

  const getListeEntreprises = () => {
    rechercherentreprises()
      .then((response) => {
        setloading(false);
        setlisteEntreprises(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    getListeEntreprises();
  }, []);

  useEffect(() => {
    setCaractereId(
      props.infoCaractere && props.infoCaractere.id !== undefined
        ? props.infoCaractere.id
        : ""
    );
    setentrepriseSelected(
      props.infoCaractere && props.infoCaractere.entreprise !== undefined
        ? props.infoCaractere.entreprise
        : null
    );
    setCaractereSelected(
      props.infoCaractere &&
        props.infoCaractere.caractere !== undefined &&
        props.infoCaractere.caractere === "NON_CUMULE"
        ? {
            label: "Non cumulé",
            value: "NON_CUMULE",
          }
        : props.infoCaractere &&
          props.infoCaractere.caractere !== undefined &&
          props.infoCaractere.caractere === "CUMULE"
        ? {
            label: "Cumulé",
            value: "CUMULE",
          }
        : null
    );
    setTypeSelected(
      props.infoCaractere &&
        props.infoCaractere.periode !== undefined &&
        props.infoCaractere.periode === "JOUR"
        ? {
            label: "Journalier",
            value: "JOUR",
          }
        : props.infoCaractere &&
          props.infoCaractere.periode !== undefined &&
          props.infoCaractere.periode === "MOIS"
        ? {
            label: "Mensuelle",
            value: "MOIS",
          }
        : null
    );
  }, [props.infoCaractere]);

  return (
    <div
      className="modal fade"
      id="caratereForm"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "30%" }}
      >
        <div className="modal-content bg-rose">
          <div className="p-2 d-flex justify-content-end">
            <button
              id="close-btn"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="fw-bold">Caractère subvention</h6>
            </div>
            <Formik
              initialValues={initialValues}
              // validationSchema={validateSchemaValues}
              enableReinitialize
              onSubmit={handleOnSubmit}
            >
              {({ resetForm }) => (
                <Form>
                  <div className="">
                    <div className="mb-3 text-danger text-center">
                      {messageError !== "" && messageError}
                    </div>

                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateOrdre">
                        Entreprise
                      </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={listeEntreprises}
                        getOptionLabel={(option) =>
                          option.raisonSociale ?? option
                        }
                        value={entrepriseSelected}
                        onChange={(event, value) => {
                          setentrepriseSelected(value);
                        }}
                        sx={{ width: "100%" }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} className="bg-white" />
                        )}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateOrdre">
                        Caractère subvention
                      </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo-2"
                        options={listeCaractereSubvention}
                        getOptionLabel={(option) => option.label ?? option}
                        value={caractereSelected}
                        onChange={(event, value) => {
                          setCaractereSelected(value);
                        }}
                        sx={{ width: "100%" }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} className="bg-white" />
                        )}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateOrdre">
                        Type subvention
                      </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo-2"
                        options={listeTypesSubvention}
                        getOptionLabel={(option) => option.label ?? option}
                        value={typeSelected}
                        onChange={(event, value) => {
                          setTypeSelected(value);
                        }}
                        sx={{ width: "100%" }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} className="bg-white" />
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateDebut">
                        Date
                      </label>
                      <Field
                        type="date"
                        name="dateDebut"
                        id="dateDebut"
                        className="form-control"
                      />
                      <span>
                        <ErrorMessage name="dateDebut"></ErrorMessage>
                      </span>
                    </div>

                    <div className="mt-3 d-flex justify-content-end">
                      <button
                        type="button"
                        id="closeAjoutVersement"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                        hidden
                      ></button>
                      <Oval
                        width={30}
                        height={30}
                        color="#CE5D1D"
                        visible={loading}
                      />
                      <button
                        disabled={loading}
                        style={{ marginLeft: "16px" }}
                        type="submit"
                        className="btn btn-rose text-white"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCaractereSubvention;
