import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { enregistrerrestaurant, recherchertouslesRestaurants } from '../../services/restaurants/RestaurantRequest';

import { Oval } from 'react-loader-spinner'

function AddRestaurant(props) {

    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");
    const [listeRestaurantParent, setListeRestaurantParent] = useState([]);
    let [file, setFile] = useState([]);


    const initialValues = {
        id: props.inforestaurant.id !== undefined ? props.inforestaurant.id : "",
        nom: props.inforestaurant.nom !== undefined ? props.inforestaurant.nom : "",
        adresse: props.inforestaurant.adresse !== undefined ? props.inforestaurant.adresse : "",
        email: props.inforestaurant.email !== undefined ? props.inforestaurant.email : "",
        numero: props.inforestaurant.numero !== undefined ? props.inforestaurant.numero : "",
        restaurantParent: props.inforestaurant.restaurantParent && props.inforestaurant.restaurantParent.id ? props.inforestaurant.restaurantParent.id : "",
        openToPublic: props.inforestaurant.isOpenToPublic !== undefined ? props.inforestaurant.isOpenToPublic : false,
        modifierRepas: props.inforestaurant.modifierRepas !== undefined ? props.inforestaurant.modifierRepas : false,
        processCommande: props.inforestaurant.enabledProcessCommandeServi !== undefined ? props.inforestaurant.enabledProcessCommandeServi : false,
    }

    const validationSchemaValues = yup.object().shape({
        nom: yup.string().required("Veuillez saisir le nom du restaurant"),
        adresse: yup.string().required("Veuillez saisir l'adresse du restaurant"),
        email: yup.string().required("Veuillez saisir l'adresse email du restaurant").email("Veuillez saisir une adresse email valide"),
        numero: yup.number().required("Veuillez saisir le numéro du restaurant"),
    });

    const onSubmitForm = (values) => {
        setloading(true);
        let data = new FormData();
        data.append("id", values.id)
        data.append("nom", values.nom)
        data.append("email", values.email)
        data.append("adresse", values.adresse)
        data.append("numero", values.numero)
        data.append("isOpenToPublic", values.openToPublic)
        data.append("modifierRepas", values.modifierRepas)
        data.append("enabledProcessCommandeServi", values.processCommande)
        data.append("restaurantParentId", values.restaurantParent)
        data.append("visuel", file)
        data.append("visuelId", "")
        setmessageError('')
        enregistrerrestaurant(data).then(() => {
            setloading(false);
            props.actualisteListe(props.pageActive);
            document.getElementById('reinitialiseFormRestaurant').click();
            setmessageError("")
        }).catch((error) => {
            setloading(false);
            console.log(error.response.data);
            messageError = error.response.data.donnee
            setmessageError(messageError)
        })
    }
    const handleImage = (event) => {
        file = event.target.files[0]
        setFile(file)
        console.log(file);
    }

    useEffect(() => {
        recherchertouslesRestaurants().then(res => {
            setListeRestaurantParent(res.data.donnee)
        }).catch(err => {
            console.log('catch', err)
        })
    }, [])


    return (
        <div className="modal fade" id="restaurant" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered ">
                <div className="modal-content bg-rose">

                    <div className="modal-header">
                        <h5 className="modal-title fw-bold">Ajouter un restaurant</h5>
                    </div>
                    <div className="modal-body">

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchemaValues}
                            onSubmit={onSubmitForm}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <span className="text-danger">{messageError}</span>
                                    <div className='row gx-1'>
                                        <div className="mb-3">
                                            <label htmlFor="restaurantParent" className='label-control'>Restaurant parent</label>
                                            <Field
                                                as="select"
                                                name="restaurantParent"
                                                id="restaurantParent"
                                                className="form-control loginborderright cursor"
                                                aria-label="Text input with checkbox"
                                            >
                                                <option disabled selected value="">
                                                    Restaurant parent
                                                </option>
                                                <option disabled value=""></option>
                                                {listeRestaurantParent.map((item, index) => (
                                                    <option value={item.id} key={index}>
                                                        {item.nom}
                                                    </option>
                                                ))}
                                            </Field>
                                            <div className="text-danger">
                                                <ErrorMessage name="restaurantParent"></ErrorMessage>{" "}
                                            </div>
                                        </div>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="nom" className='label-control'>Nom</label>
                                            <Field type="text" name="nom" id="nom" placeholder="Entrer le nom du restaurant" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='nom'></ErrorMessage></span>
                                        </div>

                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="adresse" className='label-control'>Adresse</label>
                                            <Field type="text" name="adresse" id="adresse" placeholder="Entrer l'adresse" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='adresse'></ErrorMessage></span>
                                        </div>

                                    </div>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="email" className='label-control'>Email</label>
                                            <Field type="text" name="email" id="email" placeholder="Entrer l'email du restaurant" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='email'></ErrorMessage></span>
                                        </div>

                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="numero" className='label-control'>Contact</label>
                                            <Field type="text" name="numero" id="numero" placeholder="Entrer le numéro" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='numero'></ErrorMessage></span>
                                        </div>
                                    </div>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="visuelId" className='label-control '>
                                                Image
                                                <Field type="file" name="visuelId" id="visuelId" accept="image/*" className='form-control ' onChange={handleImage} />
                                            </label>
                                            <span className='text-danger'><ErrorMessage name='visuelId'></ErrorMessage></span>
                                        </div>
                                        <div className='mt-4 col-md-3'>
                                            <label className='label-contro'>
                                                <Field type="checkbox" name="openToPublic" id="openToPublic" />
                                                <span className='mt-2'>Ouvert au public</span>
                                            </label>
                                            <span className='text-danger'><ErrorMessage name='openToPublic'></ErrorMessage></span>

                                            <label className='label-contro mt-2'>
                                                <Field type="checkbox" name="modifierRepas" id="openToPublic" />
                                                <span className='mt-2'>Modifier repas</span>
                                            </label>
                                            <span className='text-danger'><ErrorMessage name='modifierRepas'></ErrorMessage></span>
                                        </div>
                                        <div className='mt-4 col-md-3'>
                                            <label className='label-contro'>
                                                <Field type="checkbox" name="processCommande" id="processCommande" />
                                                Processus commande
                                            </label>
                                            <span className='text-danger'><ErrorMessage name='processCommande'></ErrorMessage></span>
                                        </div>
                                    </div>
                                    <div className='mb-3 d-flex justify-content-between mx-2 my-3  '>
                                        <button type='button' className='btn btn-dark' id='reinitialiseFormRestaurant' data-bs-dismiss="modal" onClick={resetForm} >Annuler</button>
                                        <Oval
                                            width={40}
                                            height={40}
                                            color="#CE5D1D"
                                            visible={loading}
                                        />
                                        {!loading && <button type='submit' className='btn text-white btn-rose'> <i className="bi bi-check-circle-fill"></i>  Enregistrer</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddRestaurant