import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from 'yup';
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import {enregistrersubventionpargrade} from "../../services/entreprises/entrepriseRequest"
const AddSubvention = (props) => {
  const [loading, setloading] = useState(false);
  const [isBuventionne, setisBuventionne] = useState(true);
  let [typesuvention, setTypesurvention]=useState([
    {typeId:1,variable:"Valeur fixe",option:"FIXE"},
    {typeId:2,variable:" Pourcentage",option:"POURCENTAGE"}
  ])
  let [Err,setErr]=useState('');

  const onSubmitForm = (values) => {
    console.log(values);
    setloading(true)
    setErr('')
    enregistrersubventionpargrade(Object.assign(values)).then((res) => {
        setloading(false)
        props.actualiserList()
        setErr('')
        document.getElementById("reinitialiseFormSubvention").click();
    }).catch((error) => {
        setloading(false)
        Err=error.response.data.donnee
        setErr(Err);
    })
  };

  const validationSchema = yup.object().shape({
    grade: yup.string().required("Veuillez saisir la grade"),
    subvention: isBuventionne ? yup.number("Veuillez saisir des chiffres").required("Veuillez saisir la subvention") : yup.number("Veuillez saisir des chiffres"),
    typeSubvention:yup.string().required("Veuillez selectionner un type de subvention")
})

const initialValues = {
    subvention:props.modifiersubvention.subvention !==undefined? props.modifiersubvention.subvention:"",
    grade: props.modifiersubvention.grade !==undefined? props.modifiersubvention.grade:"",
    id:props.modifiersubvention.id!==undefined? props.modifiersubvention.id:"",
    typeSubvention:props.modifiersubvention.typeSubvention !==undefined?props.modifiersubvention.typeSubvention:""
}


  return (
    <>
      <button
        className="btn btn-sm"
        data-bs-target="#employes"
        data-bs-toggle="modal"
        
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.5 1.75C24.9641 1.75 25.4092 1.93437 25.7374 2.26256C26.0656 2.59075 26.25 3.03587 26.25 3.5V24.5C26.25 24.9641 26.0656 25.4092 25.7374 25.7374C25.4092 26.0656 24.9641 26.25 24.5 26.25H3.5C3.03587 26.25 2.59075 26.0656 2.26256 25.7374C1.93437 25.4092 1.75 24.9641 1.75 24.5V3.5C1.75 3.03587 1.93437 2.59075 2.26256 2.26256C2.59075 1.93437 3.03587 1.75 3.5 1.75H24.5ZM3.5 0C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5L0 24.5C0 25.4283 0.368749 26.3185 1.02513 26.9749C1.6815 27.6313 2.57174 28 3.5 28H24.5C25.4283 28 26.3185 27.6313 26.9749 26.9749C27.6313 26.3185 28 25.4283 28 24.5V3.5C28 2.57174 27.6313 1.6815 26.9749 1.02513C26.3185 0.368749 25.4283 0 24.5 0L3.5 0Z"
            fill="#767676"
          />
          <path
            d="M14 7C14.2321 7 14.4546 7.09219 14.6187 7.25628C14.7828 7.42038 14.875 7.64294 14.875 7.875V13.125H20.125C20.3571 13.125 20.5796 13.2172 20.7437 13.3813C20.9078 13.5454 21 13.7679 21 14C21 14.2321 20.9078 14.4546 20.7437 14.6187C20.5796 14.7828 20.3571 14.875 20.125 14.875H14.875V20.125C14.875 20.3571 14.7828 20.5796 14.6187 20.7437C14.4546 20.9078 14.2321 21 14 21C13.7679 21 13.5454 20.9078 13.3813 20.7437C13.2172 20.5796 13.125 20.3571 13.125 20.125V14.875H7.875C7.64294 14.875 7.42038 14.7828 7.25628 14.6187C7.09219 14.4546 7 14.2321 7 14C7 13.7679 7.09219 13.5454 7.25628 13.3813C7.42038 13.2172 7.64294 13.125 7.875 13.125H13.125V7.875C13.125 7.64294 13.2172 7.42038 13.3813 7.25628C13.5454 7.09219 13.7679 7 14 7Z"
            fill="#767676"
          />
        </svg>
      </button>

      <div className="modal fade" id="employes" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" style={{ width: "30%" }}>
                <div className="modal-content bg-rose">
                    <div className="p-2 d-flex justify-content-end">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='text-center '>
                            <i className="fa-solid fs-1 fa-users"></i>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={(values)=>onSubmitForm(values)}
                        >
                            {({ resetForm }) => (
                                <Form>
                                  <div style={{color:'red', marginTop:"1rem"}}>{Err} </div>

                                    <div className='my-4'>
                                        <h6 className='text-center'>Ajouter une Subvention par grade</h6>
                                    </div>

                                    <div className='mb-3'>
                                      <label htmlFor="grade">Entrez une grade</label>
                                        <Field type="text" name="grade" id="grade"  placeholder='Grade' className='form-control' autoComplete="off"/>
                                        <span className='text-danger'><ErrorMessage name='grade' component="small"></ErrorMessage></span>
                                    </div>
                                    <div className='mb-3'>
                                      <label htmlFor="subvention">Entrez une subvention</label>
                                        <Field type="number" name="subvention" id="subvention"  placeholder='Subvention' className='form-control' autoComplete="off"/>
                                        <span className='text-danger'><ErrorMessage name='subvention' component="small"></ErrorMessage></span>
                                    </div>
                                    <div className='mb-3'>
                                      <label htmlFor="typeSubvention">Selectionnez le type de subvention </label>
                                        <Field as="select" name="typeSubvention" id="typeSubvention"  placeholder='typeSubvention' className='form-control' autoComplete="off">
                                          {/* <option defaultValue="FIXE"></option> */}
                                          {
                                            typesuvention.map((type,index)=><option value={type.option} key={index} >{type.variable}</option>)
                                          }

                                        </Field>
                                        <span className='text-danger'><ErrorMessage name='typeSubvention' component="small"></ErrorMessage></span>
                                    </div>
                                    
                                    <div className='mb-3 row mx-2 my-3  '>
                                        <button type='button' className='btn' id='reinitialiseFormSubvention' data-bs-dismiss="modal" onClick={resetForm} hidden></button>
                                        <Oval
                                            width={40}
                                            height={40}
                                            color="#CE5D1D"
                                            visible={loading}
                                        />
                                        {!loading && <button  type='submit' className='btn text-white btn-rose'>Enregistrer</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
    </>
  );
};
export default AddSubvention;
