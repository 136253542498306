import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import {
  enregistrerrepasparfichier,
  exportdatalisterepas,
  fichier,
  rechercherlisteplats,
  rechercherrepaspardesignation,
  supprimerrepas,
} from "../../services/restauratrice/RestauratriceRequest";
import PlatAdd from "./PlatAdd";
import { returnImageLink } from "../ReturnImageLink";
import { DecodeToken } from "../../DecodeToken";
import { Oval } from "react-loader-spinner";
import HeaderNomResto from "../HeaderNomResto";
import {
  adminsupprimerrepas,
  rechercherlisteplatsparentreprise,
  rechercherlisteplatsparvendeuse,
} from "../../services/vendeuses/VendeuseRequest";
import ListeVendeuse from "../ListeVendeuse";

import { Navigate } from "react-router-dom";
import PlatAddDupliquer from "./PlatAddDupliquer";
import {
  rechercherentreprises,
  rechercherentreprisespartenaires,
} from "../../services/entreprises/entrepriseRequest";
import { Autocomplete, TextField } from "@mui/material";

function ListePlat() {
  const [loadingExport, setloadingExport] = useState(false);
  const [listePlats, setlistePlats] = useState([]);
  const [listeEntreprise, setListeEntreprise] = useState([]);
  const [infoPlat, setinfoPlat] = useState({});
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [infoDupliquerPlat, setInfoDupliquerPlat] = useState({});
  const [lienImage, setlienImage] = useState("");
  const [loading, setloading] = useState(false);
  const [vendeuseSelected, setvendeuseSelected] = useState(null);
  const [entrepriseSelected, setEntrepriseSelected] = useState(null);
  const [fichierRepas, setFichierRepas] = useState(null);
  const [errorMessage, setErrorMessage] = useState("Une erreur present");

  const afficherPlat = () => {
    setloading(true);
    rechercherlisteplats()
      .then((res) => {
        setloading(false);
        setlistePlats(res.data.donnee);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  // Récupère la liste des entreprises
  const afficherEntreprise = () => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
      rechercherentreprises()
        .then((response) => {
          setListeEntreprise(response.data.donnee);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    } else {
      rechercherentreprisespartenaires().then((res) => {
        setListeEntreprise(res.data.donnee);
      });
    }
  };

  const modifierPlat = (values) => {
    setinfoPlat(values);
    setIsDuplicate(false);
    if (values.image) {
      setlienImage(returnImageLink(values.image.id));
    }
  };

  const dupliquerPlat = (values) => {
    setInfoDupliquerPlat(values);
    setIsDuplicate(true);
    if (values.image) {
      setlienImage(returnImageLink(values.image.id));
    }
  };

  const hideDuplicate = (val) => {
    setIsDuplicate(val);
  };

  const handleDeletePlat = (id) => {
    setloading(true);
    DecodeToken().profil !== "ROLE_ADMIN"
      ? supprimerrepas(id)
          .then((res) => {
            setloading(false);
            afficherPlat();
          })
          .catch(() => {
            setloading(false);
          })
      : adminsupprimerrepas(id)
          .then(() => {
            setloading(false);
            afficherPlat();
          })
          .catch(() => {
            setloading(false);
          });
  };

  const handleSearchPlat = (designation) => {
    setloading(true);
    rechercherrepaspardesignation(designation)
      .then((res) => {
        setloading(false);
        setlistePlats(res.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const handlePlatday = (values) => {
    setvendeuseSelected(values);
    let vendeuseId = values !== null ? values.id : 0;
    afficherlisteplatvendeuse(vendeuseId);
  };

  const handleChoixEntreprise = (value) => {
    setEntrepriseSelected(value);
    let entrepriseId = value !== null ? value.id : 0;
    afficherlisteplatentreprise(entrepriseId);
  };

  const afficherlisteplatvendeuse = (vendeuseId) => {
    rechercherlisteplatsparvendeuse(vendeuseId).then((res) => {
      setlistePlats(res.data.donnee);
    });
  };

  const afficherlisteplatentreprise = (entrepriseId) => {
    rechercherlisteplatsparentreprise(entrepriseId).then((res) => {
      setlistePlats(res.data.donnee);
    });
  };

  const exportListeRepas = () => {
    setloadingExport(true);

    exportdatalisterepas()
      .then((res) => {
        setloadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Liste_repas.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setloadingExport(false);
      });
  };

  const fileInputHandler = () => {
    document.getElementById("file-selector-2").click();
  };

  const handleFileChangeMobile = (e) => {
    const fileUploaded = e.target.files[0];

    if (fileUploaded || fileUploaded !== undefined) {
      setFichierRepas(fileUploaded);
      setErrorMessage("");
    }
  };

  const chargerFichierRepas = () => {
    setloading(true);
    setErrorMessage("");
    const fichier = new FormData();

    if (!fichierRepas) {
      setloading(false);
      setErrorMessage("Veillez choisir un fichier");
    } else {
      fichier.append("liste", fichierRepas);

      enregistrerrepasparfichier(fichier)
        .then((res) => {
          setErrorMessage("");
          afficherPlat();
          afficherEntreprise();
          document.getElementById("closeAjoutFichier").click();
        })
        .catch((err) => {
          if (err && err.response) {
            setErrorMessage(err.response.data.donnee);
          } else {
            setErrorMessage("Une erreur s'est produite durant le chargement");
          }

          setloading(false);
        });
    }
  };

  useEffect(() => {
    afficherPlat();
    afficherEntreprise();
  }, []);

  DecodeToken().profil !== "ROLE_RESTAURATRICE" &&
    DecodeToken().profil !== "ROLE_COMPTABLE" &&
    DecodeToken().profil !== "ROLE_ADMIN" && <Navigate to="/login" replace />;

  const rowlistePlat = listePlats.map((item, index) => (
    <div
      className={
        DecodeToken().profil == "ROLE_RESTAURATRICE"
          ? "col-md-4 mb-3"
          : "col-md-3 mb-3"
      }
      key={index}
    >
      <div
        className="card position-relative"
        style={item.entreprise ? { backgroundColor: "#fff3cd" } : {}}
      >
        <div
          style={{
            height: "200px",
            objectFit: "cover",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage:
              "url('" +
              returnImageLink(item.image != null ? item.image.id : null) +
              "')",
          }}
        >
          {(DecodeToken().profil == "ROLE_RESTAURATRICE" ||
            DecodeToken().profil == "ROLE_ADMIN") && (
            <div className="">
              <p className="text-end px-2 py-1">
                <i
                  onClick={() => dupliquerPlat(item)}
                  className="fa-regular fa-copy bg-warning text-white p-2 rounded-circle fw-bold cursor"
                ></i>
                <i
                  onClick={() => modifierPlat(item)}
                  className="fa fa-pencil text-danger mx-2 p-2 rounded-circle fw-bold edit cursor"
                ></i>
                <i
                  onClick={() => handleDeletePlat(item.id)}
                  className="fa fa-trash-alt text-danger p-2 rounded-circle fw-bold delete cursor"
                ></i>
              </p>
            </div>
          )}
        </div>
        <div className="card-body">
          <h6 className="card-title">
            {item.designation != null &&
              item.designation != undefined &&
              item.designation}
          </h6>
          <p className="card-text mt-3">
            {item.prix != null && item.prix != undefined && item.prix}F CFA
          </p>

          {item.entreprise ? (
            <div
              className="card position-absolute text-wrap bottom-0 end-0 d-flex align-items-center p-2"
              style={{ backgroundColor: "#f9f1ed" }}
            >
              <p
                className="card-text text-wrap fw-bold"
                style={{ fontSize: 14 }}
              >
                {item.entreprise != null &&
                  item.entreprise != undefined &&
                  item.entreprise.raisonSociale}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ));

  return (
    <Layout>
      <div className="row justify-content-between">
        <div
          className={
            DecodeToken().profil !== "ROLE_COMPTABLE" ? "col-9" : "col-12"
          }
          style={{ height: "135vh", overflowY: "hidden", overflow: "scroll" }}
        >
          <HeaderNomResto />
          <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
            <p className="fw-bold">Liste des plats disponibles</p>
            {DecodeToken().profil === "ROLE_RESTAURATRICE" ? (
              <div className="d-flex gap-2">
                <button
                  className="btn text-white"
                  style={{ backgroundColor: "#CE5D1D" }}
                  disabled={loadingExport}
                  onClick={exportListeRepas}
                >
                  Exporter
                </button>
                <button
                  className="btn text-white"
                  style={{ backgroundColor: "#CE5D1D" }}
                  data-bs-target="#fichier"
                  data-bs-toggle="modal"
                >
                  Charger liste de plat
                </button>
              </div>
            ) : null}
          </div>

          <div className="d-flex justify-content-between">
            <div className="input-group mb-3" style={{ width: "30%" }}>
              <div className="input-group-text bg-white">
                <i className="fa fa-search text-secondary"></i>
              </div>
              <input
                type="search"
                placeholder="Recherche..."
                name=""
                id=""
                onChange={(e) => handleSearchPlat(e.target.value)}
                className="form-control loginborderleft"
              />
            </div>
            {DecodeToken().profil === "ROLE_ADMIN" ? (
              <div className="d-flex align-items-center gap-2">
                <div>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listeEntreprise}
                    getOptionLabel={(option) => option.raisonSociale ?? option}
                    value={entrepriseSelected}
                    onChange={(event, value) => handleChoixEntreprise(value)}
                    sx={{ width: 250 }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-white"
                        label="Entreprise"
                      />
                    )}
                  />
                </div>
                <ListeVendeuse setdataSelected={handlePlatday} />
              </div>
            ) : (
              <div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listeEntreprise}
                  getOptionLabel={(option) => option.raisonSociale ?? option}
                  value={entrepriseSelected}
                  onChange={(event, value) => handleChoixEntreprise(value)}
                  sx={{ width: 250 }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="bg-white"
                      label="Entreprise"
                    />
                  )}
                />
              </div>
            )}
          </div>

          <div className="row gx-2">
            {loading ? (
              <div className="d-flex mt-5 justify-content-center">
                <Oval
                  width={50}
                  height={50}
                  color="#CE5D1D"
                  visible={loading}
                />
              </div>
            ) : (
              rowlistePlat
            )}
          </div>
        </div>
        {DecodeToken().profil !== "ROLE_COMPTABLE" && (
          <div className="col-3 bg-rose px-5">
            {!isDuplicate ? (
              <PlatAdd
                lienImage={lienImage}
                vendeuseSelected={vendeuseSelected}
                actualiserlisteparvendeuse={afficherlisteplatvendeuse}
                funLienImage={setlienImage}
                setinfoPlat={setinfoPlat}
                infoPlat={infoPlat}
                actualiserliste={afficherPlat}
              />
            ) : (
              <PlatAddDupliquer
                lienImage={lienImage}
                vendeuseSelected={vendeuseSelected}
                actualiserlisteparvendeuse={afficherlisteplatvendeuse}
                funLienImage={setlienImage}
                infoDupliquerPlat={infoDupliquerPlat}
                hideDuplicate={hideDuplicate}
                actualiserliste={afficherPlat}
              />
            )}
          </div>
        )}
      </div>

      <div
        className="modal fade"
        id="fichier"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-rose">
            <div className="p-2 d-flex justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setFichierRepas(null);
                  setErrorMessage("");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p>Fichier de repas</p>
              <div
                style={{
                  width: "100%",
                  height: 40,
                  borderRadius: 5,
                  backgroundColor: "whitesmoke",
                  border: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={fileInputHandler}
              >
                {!fichierRepas ? (
                  <span>Choisir un fichier</span>
                ) : (
                  <span>{fichierRepas.name}</span>
                )}
              </div>
              <input
                id="file-selector-2"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileChangeMobile}
                style={{ display: "none" }}
              />

              <button
                className="btn text-white mt-3 w-100"
                style={{ backgroundColor: "#CE5D1D" }}
                disabled={loading}
                onClick={chargerFichierRepas}
              >
                Enregistrer
              </button>
              {errorMessage !== "" ? (
                <p className="text-danger text-center mt-3">{errorMessage}</p>
              ) : null}
              <button
                type="button"
                id="closeAjoutFichier"
                data-bs-dismiss="modal"
                hidden
              ></button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ListePlat;
