import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import { convertNumber } from "../../convertToMoneyFormat";
import { DecodeToken } from "../../DecodeToken";
import $ from "jquery";
import generator from "generate-password-browser";
import {
  changeEmployePassword,
  changeStatutEmploye,
  deleteEmploye,
  rechercherdetailemploye,
  rechercherEmployes,
  rechercheremployesparparam,
  recherchermontantglobaldumois,
  refreshPassword,
  refreshPasswordEmploye,
  telechargermontantparemployes,
} from "../../services/employes/EmployesRequest";
import { rechercherannees } from "../../services/statistiques/StatRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";
import HeaderNomResto from "../HeaderNomResto";
import Layout from "../layouts/Layout";
import Paginate from "../pagination";
import AddEmployes from "./AddEmployes";
import ChargerListeEmploye from "./ChargerListeEmploye";
import DetailsEmployes from "./DetailsEmployes";
import AddSubvention from "./AddSubvention";
import ExportModal from "./ExportModal";

function Employes() {
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showSweetAlertPassword, setShowSweetAlertPassword] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [userIdToRefreshPassword, setUserIdToRefreshPassword] = useState("");
  const [newPasswordReset, setNewPasswordReset] = useState(null);
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [filterParametres, setfilterParametres] = useState({ param: "" });
  const [pageActive, setPageActive] = useState(1);
  const [listeEmployes, setlisteEmployes] = useState([]);
  const [infoEmployes, setinfoEmployes] = useState({});
  const [employeDetail, setEmployeDetail] = useState({});
  const [loading, setloading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDetailsEmploye, setloadingDetailsEmploye] = useState(false);
  const [loadingExport, setloadingExport] = useState(false);
  const [generateEnable, setGenerateEnable] = useState(true);
  const [montantGlobalDuMois, setMontantGlobalDuMois] = useState(0);
  const [portefeuilleGlobalDuMois, setPortefeuilleGlobalDuMois] = useState(0);
  const [listeMois, setlisteMois] = useState([
    { indiceMois: 1, libelle: "Janvier" },
    { indiceMois: 2, libelle: "Février" },
    { indiceMois: 3, libelle: "Mars" },
    { indiceMois: 4, libelle: "Avril" },
    { indiceMois: 5, libelle: "Mai" },
    { indiceMois: 6, libelle: "Juin" },
    { indiceMois: 7, libelle: "Juillet" },
    { indiceMois: 8, libelle: "Août" },
    { indiceMois: 9, libelle: "Septembre" },
    { indiceMois: 10, libelle: "Octobre" },
    { indiceMois: 11, libelle: "Novembre" },
    { indiceMois: 12, libelle: "Décembre" },
  ]);

  const [listeannee, setlisteannee] = useState([]);
  let [entrepriseSelected, setEntrepriseSelected] = useState(0);
  let [moisSelected, setmoisSelected] = useState(new Date().getMonth() + 1);
  let [anneeSelected, setanneeSelected] = useState(new Date().getFullYear());
  let [paramsFilter, setparamsFilter] = useState({
    annee: new Date().getFullYear(),
    mois: new Date().getMonth() + 1,
    entrepriseId: entrepriseSelected,
  });

  let [listeentreprise, setListeentreprise] = useState([]);
  let [userIdToUpdate, setUserIdToUpdate] = useState("");
  let [newPassword, setNewPassword] = useState("");

  const afficherEntreprise = () => {
    rechercherentreprises().then((res) => {
      setListeentreprise(res.data.donnee);
    });
  };

  const afficherEmployes = (currentPage) => {
    setloading(false);
    rechercherEmployes(currentPage - 1, entrepriseSelected)
      .then((res) => {
        setloading(false);
        // console.log('AAAAAAAAAAAAAAAAAA', res.data.donnee.employes)
        setlisteEmployes(res.data.donnee.employes);
        settotalElement(res.data.donnee.totalElements);
        setTotalElementByPage(res.data.donnee.numberOfElements);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const afficherannee = () => {
    rechercherannees().then((res) => {
      setlisteannee(res.data.donnee);
    });
  };

  useEffect(() => {
    afficherannee();
    afficherEntreprise();
  }, []);

  const AfficherEmployesFilter = (filterParam, currentPage) => {
    setloading(true);
    rechercheremployesparparam(currentPage - 1, filterParam)
      .then((res) => {
        setloading(false);
        setlisteEmployes(res.data.donnee.employes);
        settotalElement(res.data.donnee.totalElements);
        setTotalElementByPage(res.data.donnee.numberOfElements);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    filterParametres.param === ""
      ? afficherEmployes(pageNumber)
      : AfficherEmployesFilter(filterParametres.param, 1);
  };

  const handleSearchEmployes = () => {
    AfficherEmployesFilter(filterParametres.param, 1);
  };

  const detailemploye = (id) => {
    setloadingDetailsEmploye(true);
    rechercherdetailemploye(id, paramsFilter.mois, paramsFilter.annee)
      .then((res) => {
        setloadingDetailsEmploye(false);
        setinfoEmployes({ ...res.data.donnee, employeId: id });
      })
      .catch((error) => {
        setloadingDetailsEmploye(false);
      });
  };

  const downloadDoc = () => {
    setloadingExport(true);
    paramsFilter.entrepriseId = entrepriseSelected;
    telechargermontantparemployes(paramsFilter)
      .then((res) => {
        setloadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Employes_${
            listeMois.filter((item) => item.indiceMois == paramsFilter.mois)[0]
              .libelle
          }_${paramsFilter.annee}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setloadingExport(false);
      });
  };

  const affichermontantglobaldumois = () => {
    recherchermontantglobaldumois(
      paramsFilter.mois,
      paramsFilter.annee,
      entrepriseSelected
    ).then((res) => {
      setPortefeuilleGlobalDuMois(
        res.data.donnee.montantportefeuille
          ? res.data.donnee.montantportefeuille
          : 0
      );
      setMontantGlobalDuMois(
        res.data.donnee.montantdu ? res.data.donnee.montantdu : 0
      );
    });
  };

  const handleMois = (indice) => {
    moisSelected = indice;
    setmoisSelected(moisSelected);
    paramsFilter.annee = anneeSelected;
    paramsFilter.mois = moisSelected;
    setparamsFilter(paramsFilter);
    affichermontantglobaldumois();
  };

  const handleAnnee = (indiceannee) => {
    anneeSelected = indiceannee;
    setanneeSelected(anneeSelected);
    paramsFilter.annee = anneeSelected;
    paramsFilter.mois = moisSelected;
    setparamsFilter(paramsFilter);
  };

  useEffect(() => {
    afficherEmployes(pageActive);
  }, [pageActive]);

  const handleChargeEntrepriseId = (event) => {
    event.preventDefault();
    let parametre = event.target.value;
    entrepriseSelected = parametre;
    setEntrepriseSelected(entrepriseSelected);
    affichermontantglobaldumois();
    afficherEmployes(pageActive);
  };

  useEffect(() => {
    affichermontantglobaldumois();
  }, []);

  const getUserIdToDelete = (userId) => {
    setShowSweetAlert(true);
    setUserIdToDelete(userId);
  };

  const getUserIdToRefreshPassword = (userId) => {
    setShowSweetAlertPassword(true);
    setUserIdToRefreshPassword(userId);
  };

  const deleteEmployes = (employeId) => {
    setloading(true);
    deleteEmploye(employeId)
      .then((res) => {
        setloading(false);
        setShowSweetAlert(false);
        afficherEmployes(pageActive);
      })
      .catch((error) => {
        setloading(false);
        setShowSweetAlert(false);
      });
  };

  const refreshPasswordEmployes = (employeId) => {
    setloading(true);
    setLoadingRefresh(true);
    refreshPasswordEmploye(employeId)
      .then((res) => {
        setloading(false);
        setLoadingRefresh(false);
        setShowSweetAlertPassword(false);
        setNewPasswordReset(res.data.donnee);
        afficherEmployes(pageActive);
        console.log("RESPONSE", res.data.donnee);
        document.getElementById("showResetPassword").click();
      })
      .catch((error) => {
        setloading(false);
        setLoadingRefresh(false);
        setShowSweetAlertPassword(false);
      });
  };

  const passEmployeInfo = (employe) => {
    setinfoEmployes(employe);
  };

  const genNewPassword = (e) => {
    const genPassword = generator.generate({
      length: 6,
      numbers: true,
    });

    if (e.target.checked === true) {
      setNewPassword(genPassword);
      setGenerateEnable(true);
    } else {
      setNewPassword("");
      setGenerateEnable(false);
    }
  };

  const updateUserPassword = () => {
    const dataToSend = {
      id: userIdToUpdate,
      newPassword: newPassword,
    };

    if (newPassword !== "") {
      setLoadingUpdate(true);
      changeEmployePassword(dataToSend)
        .then((response) => {
          setLoadingUpdate(false);
          afficherEmployes(pageActive);
          $("#update-password").trigger("click");
          $("#gen-password").trigger("click");
          // setGenerateEnable(false)
          // document.getElementById("gen-password").click()
          // document.getElementById("gen-password").addAttribute("disabled")
          // document.getElementById("gen-password").removeAttribute("disabled")
        })
        .catch((error) => {
          setLoadingUpdate(false);
          setGenerateEnable(false);
          afficherEmployes(pageActive);
        });
    }
  };

  const handleEmployeStatut = (employeId) => {
    setloading(true);
    changeStatutEmploye(employeId)
      .then((res) => {
        setloading(false);
        afficherEmployes(pageActive);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const rowListeEmployes = listeEmployes.map((item, index) => (
    <tr key={index}>
      <td>{`${item.nom} ${item.prenoms}`}</td>
      <td>{item.fonction}</td>
      <td>{item.numero}</td>
      <td>
        {item.isSubventionne ? (
          <span className="text-success">OUI</span>
        ) : (
          <span className="text-danger">NON</span>
        )}
      </td>
      <td>
        <button className="btn" onClick={() => handleEmployeStatut(item.id)}>
          {item.enabled ? (
            <span className="text-danger">Désactiver</span>
          ) : (
            <span className="text-success">Activer</span>
          )}
        </button>
        <button
          data-bs-target="#detailemployes"
          data-bs-toggle="modal"
          onClick={() => detailemploye(item.id)}
          className="btn"
        >
          <i className="fa fa-eye gray-table-bottom"></i>
        </button>
        <button
          onClick={() => passEmployeInfo(item)}
          data-bs-target="#employes"
          data-bs-toggle="modal"
          className="btn"
        >
          <i className="fa fa-pencil gray-table-bottom"></i>
        </button>
        {DecodeToken().profil === "ROLE_ADMIN" ? (
          <button
            onClick={() => {
              setUserIdToUpdate(item.id);
              setNewPassword("");
            }}
            className="btn bg-success btn-sm text-white mx-1"
            data-bs-toggle="modal"
            data-bs-target="#updatePassword"
          >
            <i className="fa fa-key white-table-bottom"></i>
          </button>
        ) : null}
        <button
          data-bs-target="#addsubvention"
          data-bs-toggle="modal"
          onClick={() => setEmployeDetail(item)}
          className="btn"
        >
          <i className="fa fa-plus gray-table-bottom"></i>
        </button>
        {DecodeToken().profil === "ROLE_ADMIN" ||
        DecodeToken().profil === "ROLE_COMPTABLE" ? (
          <button
            onClick={() => refreshPasswordEmployes(item.id)}
            className="btn bg-success btn-sm text-white mx-1"
          >
            <i className="fa fa-refresh white-table-bottom"></i>
          </button>
        ) : null}
        {DecodeToken().profil === "ROLE_ADMIN" ? (
          <button onClick={() => getUserIdToDelete(item.id)} className="btn">
            <i className="fa fa-trash gray-table-bottom"></i>
          </button>
        ) : null}
      </td>
    </tr>
  ));

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_COMPTABLE" &&
        DecodeToken().profil !== "ROLE_ADMIN" && (
          <Navigate to="/login" replace />
        )}
      <HeaderNomResto />
      <div className="d-flex justify-content-between">
        <p className="mb-3 mt-3 fw-bold">Employés</p>
        <h6 className="mt-3">
          Montant global portefeuille :{" "}
          <span className="fw-bold">
            {portefeuilleGlobalDuMois !== null ||
            portefeuilleGlobalDuMois !== undefined
              ? convertNumber(portefeuilleGlobalDuMois)
              : "0"}
            F CFA
          </span>
        </h6>
        <h6 className="mt-3">
          Montant global dû des employés :{" "}
          <span className="fw-bold">
            {montantGlobalDuMois !== null || montantGlobalDuMois !== undefined
              ? convertNumber(montantGlobalDuMois)
              : "0"}
            F CFA
          </span>
        </h6>
      </div>
      <div className="bg-white rounded p-2">
        <div className="d-flex justify-content-between">
          <div className="">
            <div className="input-group bg-gris rounded mt-1 mb-3">
              <div className="input-group-text">
                <i className="fa fa-search text-secondary"></i>
              </div>
              <input
                type="search"
                placeholder="Recherche..."
                onChange={(e) => setfilterParametres({ param: e.target.value })}
                value={filterParametres.param}
                name=""
                id=""
                className="form-control bg-gris form-control-sm loginborderleft"
              />
              <button
                className="btn btn-rose text-white"
                onClick={handleSearchEmployes}
              >
                Rechercher
              </button>
            </div>
          </div>
          <div className="d-flex">
            <div className="mx-5 d-flex" style={{ marginLeft: "50px" }}>
              {DecodeToken().profil === "ROLE_ADMIN" && (
                <div className="dropdown align-self-center mx-2">
                  <select
                    name="entrepriseId"
                    id="entrepriseId"
                    className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-gris"
                    onClick={(e) => handleChargeEntrepriseId(e)}
                  >
                    <option selected disabled value="">
                      Sélectionner une entreprise
                    </option>
                    <option value="0"></option>
                    {listeentreprise.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.raisonSociale}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="dropdown align-self-center mx-2">
                <button
                  type="button"
                  id="mois"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-gris"
                >
                  {
                    listeMois.filter(
                      (item) => item.indiceMois == moisSelected
                    )[0].libelle
                  }{" "}
                </button>
                <ul className="dropdown-menu" aria-labelledby="mois">
                  {listeMois.map((item, index) => (
                    <li onClick={() => handleMois(item.indiceMois)} key={index}>
                      <div
                        className="dropdown-item p-1"
                        style={{ cursor: "pointer" }}
                      >
                        {item.libelle}{" "}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="dropdown align-self-center">
                <button
                  type="button"
                  id="annee"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="btn btn-sm px-4 rounded statsFiltre dropdown-toggle bg-gris"
                >
                  {anneeSelected}
                </button>
                <ul className="dropdown-menu" aria-labelledby="annee">
                  {listeannee.map((item, index) => (
                    <li onClick={() => handleAnnee(item)} key={index}>
                      <div
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        {item}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <button
                data-bs-target="#exportmodal"
                data-bs-toggle="modal"
                // disabled={loadingExport}
                // onClick={downloadDoc}
                className="btn mx-2 text-white"
                style={{ backgroundColor: "#CE5D1D" }}
              >
                Exporter liste employés <i className="fa fa-file-excel"></i>
              </button>
              <button
                className="btn btn-sm"
                data-bs-target="#listeEmploye"
                data-bs-toggle="modal"
              >
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.625 12.4101H18.5117V25.625C18.5117 25.7969 18.6523 25.9375 18.8242 25.9375H21.168C21.3398 25.9375 21.4805 25.7969 21.4805 25.625V12.4101H24.375C24.6367 12.4101 24.7812 12.1094 24.6211 11.9062L20.2461 6.36719C20.2169 6.32984 20.1795 6.29963 20.1369 6.27885C20.0942 6.25808 20.0474 6.24728 20 6.24728C19.9526 6.24728 19.9058 6.25808 19.8631 6.27885C19.8205 6.29963 19.7831 6.32984 19.7539 6.36719L15.3789 11.9023C15.2188 12.1094 15.3633 12.4101 15.625 12.4101ZM34.2969 24.4531H31.9531C31.7812 24.4531 31.6406 24.5937 31.6406 24.7656V30.7812H8.35938V24.7656C8.35938 24.5937 8.21875 24.4531 8.04688 24.4531H5.70312C5.53125 24.4531 5.39062 24.5937 5.39062 24.7656V32.5C5.39062 33.1914 5.94922 33.75 6.64062 33.75H33.3594C34.0508 33.75 34.6094 33.1914 34.6094 32.5V24.7656C34.6094 24.5937 34.4688 24.4531 34.2969 24.4531Z"
                    fill="#767676"
                  />
                </svg>
              </button>

              <button
                className="btn btn-sm"
                data-bs-target="#employes"
                data-bs-toggle="modal"
                onClick={() => setinfoEmployes({})}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.5 1.75C24.9641 1.75 25.4092 1.93437 25.7374 2.26256C26.0656 2.59075 26.25 3.03587 26.25 3.5V24.5C26.25 24.9641 26.0656 25.4092 25.7374 25.7374C25.4092 26.0656 24.9641 26.25 24.5 26.25H3.5C3.03587 26.25 2.59075 26.0656 2.26256 25.7374C1.93437 25.4092 1.75 24.9641 1.75 24.5V3.5C1.75 3.03587 1.93437 2.59075 2.26256 2.26256C2.59075 1.93437 3.03587 1.75 3.5 1.75H24.5ZM3.5 0C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5L0 24.5C0 25.4283 0.368749 26.3185 1.02513 26.9749C1.6815 27.6313 2.57174 28 3.5 28H24.5C25.4283 28 26.3185 27.6313 26.9749 26.9749C27.6313 26.3185 28 25.4283 28 24.5V3.5C28 2.57174 27.6313 1.6815 26.9749 1.02513C26.3185 0.368749 25.4283 0 24.5 0L3.5 0Z"
                    fill="#767676"
                  />
                  <path
                    d="M14 7C14.2321 7 14.4546 7.09219 14.6187 7.25628C14.7828 7.42038 14.875 7.64294 14.875 7.875V13.125H20.125C20.3571 13.125 20.5796 13.2172 20.7437 13.3813C20.9078 13.5454 21 13.7679 21 14C21 14.2321 20.9078 14.4546 20.7437 14.6187C20.5796 14.7828 20.3571 14.875 20.125 14.875H14.875V20.125C14.875 20.3571 14.7828 20.5796 14.6187 20.7437C14.4546 20.9078 14.2321 21 14 21C13.7679 21 13.5454 20.9078 13.3813 20.7437C13.2172 20.5796 13.125 20.3571 13.125 20.125V14.875H7.875C7.64294 14.875 7.42038 14.7828 7.25628 14.6187C7.09219 14.4546 7 14.2321 7 14C7 13.7679 7.09219 13.5454 7.25628 13.3813C7.42038 13.2172 7.64294 13.125 7.875 13.125H13.125V7.875C13.125 7.64294 13.2172 7.42038 13.3813 7.25628C13.5454 7.09219 13.7679 7 14 7Z"
                    fill="#767676"
                  />
                </svg>
              </button>
            </div>
          </div>
          <ChargerListeEmploye actualiserList={afficherEmployes} />
          <AddEmployes
            infoEmployes={infoEmployes}
            actualiserList={afficherEmployes}
            pageActive={pageActive}
          />
        </div>

        <table className="table">
          <thead>
            <tr className="gray-table rounded">
              <th>Nom et Prénoms</th>
              <th>Fonction</th>
              <th>Contact</th>
              <th>Subventionné</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>{!loading && rowListeEmployes}</tbody>
          <tfoot>
            <tr>
              <th colSpan="6">
                <div className="d-flex justify-content-center">
                  <Paginate
                    pageActive={pageActive}
                    handlePageChange={handlePageChange}
                    nbreElementParPage={totalElementByPage}
                    nbreTotalElmentPage={totalElement}
                  />
                </div>
              </th>
            </tr>
          </tfoot>
        </table>

        <div className="d-flex justify-content-center">
          <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
        </div>
        <DetailsEmployes
          loading={loadingDetailsEmploye}
          infoEmployes={infoEmployes}
        />
        <AddSubvention
          listeSub="Welcome Home 2ZE"
          detailEmploye={employeDetail}
        />
        <button
          id="showResetPassword"
          data-bs-target="#newResetPassword"
          data-bs-toggle="modal"
          className="btn"
          style={{ visibility: "hidden" }}
        ></button>
      </div>


      {/* MODAL EXPORT DE DONNEE */}
      <ExportModal />

      {/* MODAL UPDATE PASSWORD */}
      <div
        class="modal fade"
        id="updatePassword"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-rose">
            <div class="modal-header">
              <h5 class="modal-title fw-bold" id="exampleModalLabel">
                Modifier le mot de passse
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="recipient-name" class="col-form-label">
                  Nouveau mot de passe :
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-check form-switch">
                <input
                  onChange={(e) => genNewPassword(e)}
                  className="form-check-input"
                  type="checkbox"
                  id="gen-password"
                  value={generateEnable}
                />
                <label htmlFor="gen-password">Générer le mot de passe</label>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between mx-2 my-3">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                id="update-password"
              >
                Annuler
              </button>
              <Oval
                width={40}
                height={40}
                color="#CE5D1D"
                visible={loadingUpdate}
              />
              {!loadingUpdate && (
                <button
                  type="button"
                  class="btn text-white btn-rose"
                  onClick={updateUserPassword}
                >
                  <i className="bi bi-check-circle-fill"></i> Modifier
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MODAL NOUVEAU PASSWORD */}
      <div
        class="modal fade"
        id="newResetPassword"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-rose">
            <div class="modal-header">
              <h5 class="modal-title fw-bold" id="exampleModalLabel">
                Nouveau mot de passse
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <p>
                  Nom d'utilisateur :{" "}
                  <strong>
                    {newPasswordReset ? newPasswordReset.email : ""}
                  </strong>
                </p>
              </div>
              <div class="mb-3">
                <p>
                  Mot de passe :{" "}
                  <strong>
                    {newPasswordReset ? newPasswordReset.password : ""}
                  </strong>
                </p>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-end mx-2 mb-3">
              <button
                type="button"
                class="btn text-white btn-rose"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL DELETE EMPLOYE */}
      <SweetAlert
        show={showSweetAlert}
        title="Confirmer la suppression"
        text="Cette action est irréversible"
        showCancelButton
        onConfirm={() => deleteEmployes(userIdToDelete)}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
        onEscapeKey={() => setShowSweetAlert(false)}
        onOutsideClick={() => setShowSweetAlert(false)}
      />

      {/* MODAL UPDATE PASSWORD */}
      <SweetAlert
        show={showSweetAlertPassword}
        title="Réinitialiser le mot de passe de cet employé"
        text="Un nouveau mot de passe lui sera envoyé par email"
        showCancelButton
        onConfirm={() => refreshPasswordEmployes(userIdToRefreshPassword)}
        onCancel={() => {
          setShowSweetAlertPassword(false);
        }}
        onEscapeKey={() => setShowSweetAlertPassword(false)}
        onOutsideClick={() => setShowSweetAlertPassword(false)}
      />
    </Layout>
  );
}

export default Employes;
