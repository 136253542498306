import React, { useState } from 'react'

function Heure(props) {

    const [heureselected, setheureselected] = useState("");

    let listeHeure = [];
    for (let index = 0; index <= 23; index++) {
        listeHeure[index] = index < 10 ? "0" + index : index
    }

    const rowOptionHeure = listeHeure.map((item, index) => (
        <option value={item} key={index}>{item}</option>
    ))

    const handleHeureSelected = (value) =>{
        setheureselected(value);
        if (props.type === "DEBUT") {
            props.setheureDebut(value);
        }else{
            props.setheureFin(value);
        }
    }

    return (
        <select name="" id="" className='form-control form-select p-2' value={heureselected} onChange={(e)=>handleHeureSelected(e.target.value)}>
            {rowOptionHeure}
        </select>
    )
}

export default Heure