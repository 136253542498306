import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import * as yup from "yup";
import { Autocomplete, TextField } from "@mui/material";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";

function AddSuspensionSubvention(props) {
  const [loading, setloading] = useState(false);
  const [entrepriseSelected, setentrepriseSelected] = useState(null);
  const [moisSelected, setMoisSelected] = useState(null);
  const [jourSelected, setJourSelected] = useState(null);
  const [listeEntreprises, setlisteEntreprises] = useState([]);
  const [listeMois, setListeMois] = useState([
    {
      label: "Janvier",
      value: 1,
    },
    {
      label: "Février",
      value: 2,
    },
    {
      label: "Mars",
      value: 3,
    },
    {
      label: "Avril",
      value: 4,
    },
    {
      label: "Mai",
      value: 5,
    },
    {
      label: "Juin",
      value: 6,
    },
    {
      label: "Juillet",
      value: 7,
    },
    {
      label: "Août",
      value: 8,
    },
    {
      label: "Septembre",
      value: 9,
    },
    {
      label: "Octobre",
      value: 10,
    },
    {
      label: "Novembre",
      value: 11,
    },
    {
      label: "Décembre",
      value: 12,
    },
  ]);
  const [listeJourSemaine, setListeJourSemaine] = useState([
    {
      label: "Lundi",
      value: 1,
    },
    {
      label: "Mardi",
      value: 2,
    },
    {
      label: "Mercredi",
      value: 3,
    },
    {
      label: "Jeudi",
      value: 4,
    },
    {
      label: "Vendredi",
      value: 5,
    },
    {
      label: "Samedi",
      value: 6,
    },
    {
      label: "Dimanche",
      value: 7,
    },
  ]);
  const [messageError, setmessageError] = useState("");

  const initialValues = {
    dateExclusion:
      props.infoSuspension.dateExclusion !== undefined ? props.infoSuspension.dateExclusion : "",
  };

  const getListeEntreprises = () => {
    rechercherentreprises()
      .then((response) => {
        setloading(false);
        setlisteEntreprises(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const handleOnSubmit = (values) => {
    setloading(true);
    setmessageError("");
    
    const dataToSubmit = {
      entreprise: entrepriseSelected,
      caractere: moisSelected.value,
      type: jourSelected.value,
      dateExclusion: values.dateExclusion
    }

    // enregistrerversement(
    //   Object.assign(dataVersement, {
    //     date: dataVersement.date.split("-").reverse().join("-"),
    //     dateOrdre: dataVersement.dateOrdre.split("-").reverse().join("-"),
    //   })
    // )
    //   .then((res) => {
    //     setloading(false);
    //     setentrepriseSelected(null);
    //     setMoisSelected(null);
    //     setJourSelected(null);
    //     setmessageError("");
        // props.actualisteListe(1);
    //     document.getElementById("closeAjoutVersement").click();
    //   })
    //   .catch((error) => {
    //     setloading(false);
    //     setmessageError(error.response.data.donnee);
    //   });
  };

  useEffect(() => {
    getListeEntreprises()
  }, []);

  return (
    <div
      className="modal fade"
      id="suspensionForm"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "30%" }}
      >
        <div className="modal-content bg-rose">
          <div className="p-2 d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="fw-bold">Suspension subvention</h6>
            </div>
            <Formik
              initialValues={initialValues}
              // validationSchema={validateSchemaValues}
              enableReinitialize
              onSubmit={handleOnSubmit}
            >
              {({ resetForm }) => (
                <Form>
                  <div className="">
                    <div className="mb-3 text-danger text-center">
                      {messageError !== "" && messageError}
                    </div>

                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateOrdre">
                        Entreprise
                      </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={listeEntreprises}
                        getOptionLabel={(option) =>
                          option.raisonSociale ?? option
                        }
                        value={entrepriseSelected}
                        onChange={(event, value) => {
                          setentrepriseSelected(value);
                        }}
                        sx={{ width: "100%" }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="bg-white"
                          />
                        )}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateOrdre">
                        Mois
                      </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo-2"
                        options={listeMois}
                        getOptionLabel={(option) => option.label ?? option}
                        value={moisSelected}
                        onChange={(event, value) => {
                          setMoisSelected(value);
                        }}
                        sx={{ width: "100%" }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="bg-white"
                          />
                        )}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateOrdre">
                        Jour
                      </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo-2"
                        options={listeJourSemaine}
                        getOptionLabel={(option) => option.label ?? option}
                        value={jourSelected}
                        onChange={(event, value) => {
                          setJourSelected(value);
                        }}
                        sx={{ width: "100%" }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="bg-white"
                          />
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="label-control" htmlFor="dateExclusion">
                        Date exclusion
                      </label>
                      <Field
                        type="date"
                        name="dateExclusion"
                        id="dateExclusion"
                        className="form-control"
                      />
                      <span>
                        <ErrorMessage name="dateExclusion"></ErrorMessage>
                      </span>
                    </div>

                    <div className="mt-3 d-flex justify-content-end">
                      <button
                        type="button"
                        id="closeAjoutVersement"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                        hidden
                      ></button>
                      <Oval
                        width={30}
                        height={30}
                        color="#CE5D1D"
                        visible={loading}
                      />
                      <button
                        disabled={loading}
                        style={{ marginLeft: "16px" }}
                        type="submit"
                        className="btn btn-rose text-white"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSuspensionSubvention;
