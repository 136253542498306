import React, { useEffect, useState } from "react";
import {
  exportdatastatistique,
  rechercherchiffreaffaireadmin,
  recherchermontantduparvendeuse,
} from "../../services/statistiques/StatRequest";
import { recherchertouteslesvendeuses } from "../../services/vendeuses/VendeuseRequest";
import Zonefilter from "./Zonefilter";

function StatsAdmin() {
  let [paramsFilter, setparamsFilter] = useState({
    annee: new Date().getFullYear(),
    mois: new Date().getMonth() + 1,
    entrepriseId: "",
    restaurantId: "",
  });
  const [listeMois, setlisteMois] = useState([
    { indiceMois: "", libelle: "" },
    { indiceMois: 1, libelle: "Janvier" },
    { indiceMois: 2, libelle: "Février" },
    { indiceMois: 3, libelle: "Mars" },
    { indiceMois: 4, libelle: "Avril" },
    { indiceMois: 5, libelle: "Mai" },
    { indiceMois: 6, libelle: "Juin" },
    { indiceMois: 7, libelle: "Juillet" },
    { indiceMois: 8, libelle: "Août" },
    { indiceMois: 9, libelle: "Septembre" },
    { indiceMois: 10, libelle: "Octobre" },
    { indiceMois: 11, libelle: "Novembre" },
    { indiceMois: 12, libelle: "Décembre" },
  ]);
  const [loadingExport, setloadingExport] = useState(false);
  const [infoStatsAdminCommande, setinfoStatsAdminCommande] = useState({});
  const [statMontantdu, setstatMontantdu] = useState("");
  const [vendeuseSelected, setvendeuseSelected] = useState({});
  const [listeVendeuses, setlisteVendeuses] = useState([]);
  let [mobileMensuel, setMobileMensuel] = useState(null);
  let [mobileGlobal, setMobileGlobal] = useState(null);
  const [portefeuilleMensuel, setPortefeuilleMensuel] = useState(null);
  const [portefeuilleGlobal, setPortefeuilleGlobal] = useState(null);
  const [montantGlobalCommande, setMontantGlobalCommande] = useState(null);
  const [montantGlobalSubvention, setMontantGlobalSubvention] = useState(null);
  const [montantSubventionMois, setMontantSubventionMois] = useState(null);
  const [soldeCompteResto, setSoldeCompteResto] = useState(null);
  const [versementStat, setVersementStat] = useState({
    montantGlobal: "",
    montantCompteResto: "",
    montantSubvention: "",
    montantSkan: "",
    montantAVerser: "",
    montantSalaire: "",
  });
  const [commandeAnnulee, setCommandeAnnulee] = useState({
    montantTotal: "",
    montantMensuel: "",
  });

  const affichervendeuses = () => {
    recherchertouteslesvendeuses().then((res) => {
      setlisteVendeuses(res.data.donnee);
    });
  };

  const afficherchiffreaffaire = () => {
    rechercherchiffreaffaireadmin(paramsFilter).then((res) => {
      setCommandeAnnulee({
        montantTotal: res.data.donnee.montantTotalCommandeAnnulee,
        montantMensuel: res.data.donnee.montantCommandeAnnuleeParMois,
      });

      mobileMensuel =
        res.data.donnee.montantMobileMoneyDuMois !== undefined
          ? res.data.donnee.montantMobileMoneyDuMois
          : 0;
      mobileGlobal =
        res.data.donnee.montantMobileMoneyGobal !== undefined
          ? res.data.donnee.montantMobileMoneyGobal
          : 0;
      setMobileMensuel(mobileMensuel);
      setMobileGlobal(mobileGlobal);
      setinfoStatsAdminCommande(res.data.donnee);

      setSoldeCompteResto(
        res.data.donnee.soldePortefeuille !== undefined
          ? res.data.donnee.soldePortefeuille
          : 0
      );

      setMontantGlobalCommande(
        res.data.donnee.montantglobalcommande !== undefined
          ? res.data.donnee.montantglobalcommande
          : 0
      );

      setVersementStat({
        montantGlobal:
          res.data.donnee.montantTotalVersement !== undefined
            ? res.data.donnee.montantTotalVersement
            : 0,
        montantCompteResto:
          res.data.donnee.montantTotalVersementCompteResto !== undefined
            ? res.data.donnee.montantTotalVersementCompteResto
            : 0,
        montantSubvention:
          res.data.donnee.montantTotalVersementSubvention !== undefined
            ? res.data.donnee.montantTotalVersementSubvention
            : 0,
        montantSkan:
          res.data.donnee.montantTotalSkanTech !== undefined
            ? res.data.donnee.montantTotalSkanTech
            : 0,
        montantAVerser:
          res.data.donnee.resteAVerserResto !== undefined
            ? res.data.donnee.resteAVerserResto
            : 0,
        montantSalaire:
          res.data.donnee.montantTotalVersementSalaire !== undefined
            ? res.data.donnee.montantTotalVersementSalaire
            : 0,
      });

      setMontantGlobalSubvention(
        res.data.donnee.montantGlobalSubventionne !== undefined
          ? res.data.donnee.montantGlobalSubventionne
          : 0
      );

      setMontantSubventionMois(
        res.data.donnee.montantSubventionneDuMois !== undefined
          ? res.data.donnee.montantSubventionneDuMois
          : 0
      );

      setPortefeuilleMensuel(
        res.data.donnee.montantPortefeuilleDuMois !== undefined
          ? res.data.donnee.montantPortefeuilleDuMois
          : 0
      );

      setPortefeuilleGlobal(
        res.data.donnee.montantPortefeuilleGlobal !== undefined
          ? res.data.donnee.montantPortefeuilleGlobal
          : 0
      );
    });
  };

  const recupermontantDu = (vendeuse) => {
    setvendeuseSelected(vendeuse);
    recherchermontantduparvendeuse({ vendeuseId: vendeuse.id }).then((res) => {
      setstatMontantdu(res.data.donnee);
    });
  };

  const dropdownItemServeuse = listeVendeuses.map((item, index) => (
    <li key={index} onClick={() => recupermontantDu(item)}>
      <div className="dropdown-item" style={{ cursor: "pointer" }}>
        {item.nomPrenoms}
      </div>
    </li>
  ));

  const exportData = () => {
    setloadingExport(true);

    exportdatastatistique(paramsFilter)
      .then((res) => {
        setloadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Statistique_${
            listeMois.filter((item) => item.indiceMois == paramsFilter.mois)[0]
              .libelle
          }_${paramsFilter.annee}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setloadingExport(false);
      });
  };

  useEffect(() => {
    afficherchiffreaffaire();
    affichervendeuses();
  }, []);

  return (
    <div className="pb-5">
      <div className="d-flex align-items-center">
        <Zonefilter
          paramsFilter={paramsFilter}
          setparamsFilter={setparamsFilter}
          actualiseStat={afficherchiffreaffaire}
        />
        <div style={{ marginLeft: 10, marginTop: 16 }}>
          <button
            className="btn text-white"
            style={{ backgroundColor: "#CE5D1D" }}
            disabled={loadingExport}
            onClick={exportData}
          >
            Exporter
          </button>
        </div>
      </div>

      {/* ======= COMMANDES ======= */}
      <div
        className="row p-4 bg-white rounded"
        style={{ marginRight: "30px", marginTop: "20px" }}
      >
        <div className="col-2">
          <span>Commandes du jour</span>
          <h6>
            {infoStatsAdminCommande.montantdujour
              ? infoStatsAdminCommande.montantdujour
              : "0"}{" "}
            F CFA /{" "}
            <span className="text-danger">
              {infoStatsAdminCommande.nombreCommandeDuJour
                ? infoStatsAdminCommande.nombreCommandeDuJour
                : "0"}
            </span>
          </h6>
        </div>
        <div
          className="col-2"
          style={{
            borderRight: " 2px solid #e5e5e5",
            borderLeft: " 2px solid #e5e5e5",
          }}
        >
          <span>Commandes du mois</span>
          <h6>
            {infoStatsAdminCommande.montantmois
              ? infoStatsAdminCommande.montantmois
              : "0"}{" "}
            F CFA /{" "}
            <span className="text-danger">
              {infoStatsAdminCommande.nombreCommandeDuMois
                ? infoStatsAdminCommande.nombreCommandeDuMois
                : "0"}
            </span>
          </h6>
        </div>
        <div
          className="col-3"
          style={{
            borderRight: " 2px solid #e5e5e5",
          }}
        >
          <span>Montant global commandes</span>
          <h6>
            {montantGlobalCommande ? montantGlobalCommande : "0"} F CFA /{" "}
            <span className="text-danger">
              {infoStatsAdminCommande.nombreCommande
                ? infoStatsAdminCommande.nombreCommande
                : "0"}
            </span>
          </h6>
        </div>
        <div
          className="col-2"
          style={{
            borderRight: " 2px solid #e5e5e5",
          }}
        >
          <span>Solde compte resto</span>
          <h6>{soldeCompteResto ? soldeCompteResto : "0"} F CFA</h6>
        </div>
        <div className="col-3">
          <span>Total à verser aux vendeuses</span>
          <h6>
            {infoStatsAdminCommande.resteAVerser
              ? infoStatsAdminCommande.resteAVerser
              : "0"}
            F CFA
          </h6>
        </div>
      </div>

      <div
        className="row p-4 bg-white rounded"
        style={{ marginRight: "30px", marginTop: "20px" }}
      >
        <div className="col-3">
          <span>Nombre de plats vendu jour</span>
          <h5 className="text-danger">
            {infoStatsAdminCommande.nombrePlatVenduDuJour
              ? infoStatsAdminCommande.nombrePlatVenduDuJour
              : "0"}
          </h5>
        </div>
        <div
          className="col-3"
          style={{
            borderRight: " 2px solid #e5e5e5",
            borderLeft: " 2px solid #e5e5e5",
          }}
        >
          <span>Nombre de plats vendu mois</span>
          <h5 className="text-danger">
            {infoStatsAdminCommande.nombrePlatVenduDuMois
              ? infoStatsAdminCommande.nombrePlatVenduDuMois
              : "0"}
          </h5>
        </div>
        <div className="col-3">
          <span>Nombre de plats vendu total</span>
          <h5 className="text-danger">
            {infoStatsAdminCommande.nombrePlatVendu
              ? infoStatsAdminCommande.nombrePlatVendu
              : "0"}
          </h5>
        </div>
        <div className="col-3">
          <span>Temps moyen de traitement</span>
          <h5 className="text-danger">
            {infoStatsAdminCommande.tempsmoyentraitement
              ? infoStatsAdminCommande.tempsmoyentraitement
              : "Indéfini"}
          </h5>
        </div>
      </div>

      <div className="row mt-5 col-6">
        <span className="fw-bold text-danger">Commandes annulées</span>
        <div className="row mt-1">
          <div className="bg-white py-3 px-4 rounded col-5">
            <div className="dropdown">
              <span style={{ marginRight: "10px" }}>
                Montant global commandes annulées
              </span>
            </div>
            <h5 className="mt-1">
              {commandeAnnulee.montantTotal
                ? commandeAnnulee.montantTotal
                : "0"}{" "}
              F CFA
            </h5>
          </div>

          <div className="rounded mx-2 bg-white px-4 py-3 col-5">
            <span>
              <span style={{ marginRight: "10px" }}>
                Montant mensuel commandes annulées{" "}
              </span>
            </span>
            <h5 className="mt-1">
              {commandeAnnulee.montantMensuel
                ? commandeAnnulee.montantMensuel
                : "0"}
            </h5>
          </div>
        </div>
      </div>

      {/* ======== VERSEMENT ====== */}
      <div class="mt-5">
        <span className="fw-bold">Versement</span>
        <div class="row gx-1 mt-1" style={{ marginLeft: "-20px" }}>
          <div class="col-lg-3 col-md-6">
            <div className="rounded mx-2 bg-white px-4 py-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant versement global
                </span>
              </span>
              <h6>
                {versementStat.montantGlobal
                  ? versementStat.montantGlobal
                  : "0"}{" "}
                F CFA
              </h6>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div className="rounded mx-2 bg-white px-4 py-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant versement compte-resto
                </span>
              </span>
              <h6>
                {versementStat.montantCompteResto
                  ? versementStat.montantCompteResto
                  : "0"}{" "}
                F CFA
              </h6>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-md-2 mt-lg-0">
            <div className="rounded mx-2 bg-white px-4 py-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant versement subvention
                </span>
              </span>
              <h6>
                {versementStat.montantSubvention
                  ? versementStat.montantSubvention
                  : "0"}{" "}
                F CFA
              </h6>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-md-2 mt-lg-0">
            <div className="rounded mx-2 bg-white px-4 py-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant versement Skanpay
                </span>
              </span>
              <h6>
                {versementStat.montantSkan ? versementStat.montantSkan : "0"} F
                CFA
              </h6>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-2">
            <div className="rounded mx-2 bg-white px-4 py-3">
              <span>
                <span style={{ marginRight: "10px" }}>Reste à verser</span>
              </span>
              <h6>
                {versementStat.montantAVerser
                  ? versementStat.montantAVerser
                  : "0"}{" "}
                F CFA
              </h6>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-2">
            <div className="rounded mx-2 bg-white px-4 py-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant versement salaire
                </span>
              </span>
              <h6>
                {versementStat.montantSalaire
                  ? versementStat.montantSalaire
                  : "0"}{" "}
                F CFA
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* ======== MONTANT DÜ AU VENDEUR ====== */}
      <div className="mt-5">
        <span className="fw-bold">Montant dû aux vendeuses</span>
        <div className="row mt-1">
          <div className="bg-white d-flex align-items-center justify-content-center py-3 px-4 rounded col-2">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span style={{ marginRight: "10px" }}>Vendeuses </span>
              </button>
              <ul className="dropdown-menu">
                <li onClick={() => setvendeuseSelected({})}>
                  <div className="dropdown-item"></div>
                </li>
                {dropdownItemServeuse}
              </ul>
            </div>

            <h6 className="px-3">
              {vendeuseSelected.nomPrenoms !== undefined
                ? vendeuseSelected.nomPrenoms
                : ""}
            </h6>
          </div>

          <div className="rounded mx-2 bg-white px-4 py-3 col-3">
            <span>
              <span style={{ marginRight: "10px" }}>Montant dû </span>
            </span>
            <h6>{statMontantdu ? statMontantdu : "0"} F CFA</h6>
          </div>
          <div className="rounded bg-white px-4 py-3 col-3">
            <span>
              <span style={{ marginRight: "10px" }}>Versement </span>
            </span>
            <h6>{statMontantdu ? statMontantdu : "0"} F CFA</h6>
          </div>
          <div className="rounded mx-2 bg-white px-4 py-3 col-3">
            <span>
              <span style={{ marginRight: "10px" }}>Reste à verser </span>
            </span>
            <h6>{statMontantdu ? statMontantdu : "0"} F CFA</h6>
          </div>
        </div>
      </div>

      {/* ======== PAIEMENT MOBILE MONEY ====== */}
      <div className="mt-5">
        <span className="fw-bold">Paiement par Mobile Money</span>
        <div className="row mt-1">
          <div className="bg-white py-3 px-4 rounded col-2">
            <div>
              <span>Montant global Mobile Money </span>
            </div>
            <h6>{mobileGlobal ? mobileGlobal : "0"} F CFA</h6>
          </div>

          <div className="rounded mx-2 bg-white px-4 py-3 col-3">
            <span>
              <span>Montant Mobile Money du mois </span>
            </span>
            <h6>{mobileMensuel ? mobileMensuel : "0"} F CFA</h6>
          </div>
          <div className="rounded bg-white px-4 py-3 col-3">
            <span>
              <span>Rechargement compte resto (mois)</span>
            </span>
            <h6>
              {infoStatsAdminCommande.montantRechargementCompteRestoParMobileMoneyParMois
                ? infoStatsAdminCommande.montantRechargementCompteRestoParMobileMoneyParMois
                : "0"}{" "}
              F CFA
            </h6>
          </div>
          <div className="rounded mx-2 bg-white px-4 py-3 col-3">
            <span>
              <span>Rechargement compte resto (Total)</span>
            </span>
            <h6>
              {infoStatsAdminCommande.montantRechargementCompteRestoParMobileMoneyTotal
                ? infoStatsAdminCommande.montantRechargementCompteRestoParMobileMoneyTotal
                : "0"}{" "}
              F CFA
            </h6>
          </div>
        </div>
      </div>

      {/* ======== PAIEMENT COMPTE RESTO ====== */}
      <div className="mt-5 row">
        <div className="col-6">
          <span className="fw-bold">Paiement par compte resto</span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-5">
              <div className="dropdown">
                <span style={{ marginRight: "10px" }}>
                  Montant global compte resto{" "}
                </span>
              </div>
              <h6>{portefeuilleGlobal ? portefeuilleGlobal : "0"} F CFA</h6>
            </div>

            <div className="rounded mx-2 bg-white px-4 py-3 col-5">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant compte resto du mois{" "}
                </span>
              </span>
              <h6>{portefeuilleMensuel ? portefeuilleMensuel : "0"} F CFA</h6>
            </div>
          </div>
        </div>
        <div className="col-6">
          <span className="fw-bold">Subvention</span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-5">
              <div className="dropdown">
                <span style={{ marginRight: "10px" }}>Subvention du mois </span>
              </div>
              <h6>
                {montantSubventionMois ? montantSubventionMois : "0"} F CFA
              </h6>
            </div>

            <div className="rounded mx-2 bg-white px-4 py-3 col-5">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant global subventions{" "}
                </span>
              </span>
              <h6>
                {montantGlobalSubvention ? montantGlobalSubvention : "0"} F CFA
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsAdmin;
