import FullCalendar from "@fullcalendar/react";
import SweetAlert from "sweetalert-react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { fr } from "date-fns/locale";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../layouts/Layout";
import ChoosePlatModal from "./ChoosePlatModal";
import {
  rechercherlisteplats,
  recherchermenuparmois,
  retirerrepasdumenujournalier,
} from "../../services/restauratrice/RestauratriceRequest";
import moment from "moment";
import { returnImageLink } from "../ReturnImageLink";
import { Navigate } from "react-router-dom";
import { DecodeToken } from "../../DecodeToken";
import HeaderNomResto from "../HeaderNomResto";
import { Autocomplete, TextField } from "@mui/material";
import ListeVendeuse from "../ListeVendeuse";
import {
  rechercherlisteplatsparrestaurant,
  rechercherlisteplatsparvendeuse,
  recherchermenuparmoisparentreprise,
  recherchermenuparmoisparrestaurant,
  recherchermenuparmoisparvendeuse,
} from "../../services/vendeuses/VendeuseRequest";
import DupliquerPlaning from "./DupliquerPlaning";
import { dupliquermenu } from "../../services/restauratrice/RestauratriceRequest";
import ListeRestaurant from "../ListeRestaurant";
import ListeDesEntreprise from "../ListeDesEntreprise";
import {
  rechercherentreprises,
  rechercherentreprisespartenaires,
  recherchersiteparentreprise,
} from "../../services/entreprises/entrepriseRequest";
import { rechercherRestaurantsEnfant } from "../../services/restaurants/RestaurantRequest";

const convertDate = (dateToConvert) => {
  console.log("date", dateToConvert);
  let day =
    dateToConvert.getDate() > 9
      ? parseInt(dateToConvert.getDate())
      : "0" + parseInt(dateToConvert.getDate());
  let month =
    dateToConvert.getMonth() >= 9
      ? parseInt(dateToConvert.getMonth()) + 1
      : "0" + (parseInt(dateToConvert.getMonth()) + 1);
  return day + "-" + month + "-" + dateToConvert.getFullYear();
};

function PlanningMenu() {
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [repasId, setRepasId] = useState("");
  const [repasStart, setRepasStart] = useState("");
  const [dataSelected, setdataSelected] = useState(null);
  const [dateMois, setdateMois] = useState(null);
  const calendarApiRefresh = useRef();
  const [listeMenu, setlisteMenu] = useState([]);
  const [listePlats, setlistePlats] = useState([]);
  const [listeEntreprise, setListeEntreprise] = useState([]);
  const [listeSiteEntreprise, setListeSiteEntreprise] = useState([]);
  const [listeRestaurantEnfant, setListeRestaurantEnfant] = useState([]);
  const [dupliquer, setDupliquer] = useState("");
  const [dupliqueLoader, setDupliqueLoader] = useState(false);
  let [messageError, setMessageError] = useState("");
  let [datededuplication, setDatededuplication] = useState({});
  let [mois, setMois] = useState("");
  let [annee, setAnnee] = useState("");
  let [vendeuseSelected, setvendeuseSelected] = useState(null);
  let [restauSelected, setRestauSelected] = useState(null);
  let [entrepriseSelected, setEntrepriseSelected] = useState(null);
  let [entrepriseSiteSelected, setEntrepriseSiteSelected] = useState(null);
  let [restaurantEnfantSelected, setRestaurantEnfantSelected] = useState(null);

  const afficherEntreprise = () => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
      rechercherentreprises()
        .then((response) => {
          setListeEntreprise(response.data.donnee);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    } else {
      rechercherentreprisespartenaires().then((res) => {
        setListeEntreprise(res.data.donnee);
      });
    }
  };

  const getListeSiteEntreprise = (entrepriseId) => {
    recherchersiteparentreprise(entrepriseId).then((res) => {
      setListeSiteEntreprise(res.data.donnee);
    });
  };

  const afficherListPlatMois = (datecurrent) => {
    let platSet = [];

    if (DecodeToken().profil !== "ROLE_ADMIN") {
      recherchermenuparmois(datecurrent).then((res) => {
        res.data.donnee.forEach((element) => {
          platSet = [
            ...platSet,
            {
              id: element.id,
              title: element.designation,
              start: element.date,
              end: element.date,
              instant: element.instant,
              quantite: element.quantite,
              extendedProps: {
                platId: element.id,
                urlImage:
                  element.visuelId !== null && element.visuelId.length !== 0
                    ? returnImageLink(element.visuelId)
                    : null,
              },
            },
          ];
        });

        setlisteMenu(platSet);
        setdateMois(datecurrent);
      });
    } else {
      let vendeuseId = vendeuseSelected === null ? 0 : vendeuseSelected.id;

      recherchermenuparmoisparvendeuse(vendeuseId, datecurrent).then((res) => {
        res.data.donnee.forEach((element) => {
          platSet = [
            ...platSet,
            {
              id: element.id,
              title: element.designation,
              start: element.date,
              end: element.date,
              instant: element.instant,
              quantite: element.quantite,
              extendedProps: {
                platId: element.id,
                urlImage:
                  element.visuelId !== null && element.visuelId.length !== 0
                    ? returnImageLink(element.visuelId)
                    : null,
              },
            },
          ];
        });

        setlisteMenu(platSet);
        setdateMois(datecurrent);
      });
    }
  };

  const actualisePlanningApresProgrammation = (datecurrent) => {
    if (entrepriseSiteSelected !== null && entrepriseSelected !== null) {
      let platSet = [];
      let restaurantId =
        restaurantEnfantSelected && restaurantEnfantSelected.id
          ? restaurantEnfantSelected.id
          : 0;

      if (DecodeToken().profil !== "ROLE_ADMIN") {
        recherchermenuparmoisparentreprise(
          entrepriseSelected.id,
          0,
          restaurantId,
          entrepriseSiteSelected.id,
          datecurrent
        ).then((res) => {
          res.data.donnee.forEach((element) => {
            platSet = [
              ...platSet,
              {
                id: element.id,
                title: element.designation,
                start: element.date,
                end: element.date,
                instant: element.instant,
                quantite: element.quantite,
                extendedProps: {
                  platId: element.id,
                  urlImage:
                    element.visuelId !== null && element.visuelId.length !== 0
                      ? returnImageLink(element.visuelId)
                      : null,
                },
              },
            ];
          });

          setlisteMenu(platSet);
        });
      } else {
        let vendeuseId = vendeuseSelected === null ? 0 : vendeuseSelected.id;
        let restauId = restauSelected === null ? 0 : restauSelected.id;

        recherchermenuparmoisparentreprise(
          entrepriseSelected.id,
          vendeuseId,
          restauId,
          entrepriseSiteSelected.id,
          datecurrent
        ).then((res) => {
          res.data.donnee.forEach((element) => {
            platSet = [
              ...platSet,
              {
                id: element.id,
                title: element.designation,
                start: element.date,
                end: element.date,
                instant: element.instant,
                quantite: element.quantite,
                extendedProps: {
                  platId: element.id,
                  urlImage:
                    element.visuelId !== null && element.visuelId.length !== 0
                      ? returnImageLink(element.visuelId)
                      : null,
                },
              },
            ];
          });

          setlisteMenu(platSet);
        });
      }
    } else if (entrepriseSiteSelected === null && entrepriseSelected !== null) {
      let platSet = [];
      let restaurantId =
        restaurantEnfantSelected && restaurantEnfantSelected.id
          ? restaurantEnfantSelected.id
          : 0;

      if (DecodeToken().profil !== "ROLE_ADMIN") {
        recherchermenuparmoisparentreprise(
          entrepriseSelected.id,
          0,
          restaurantId,
          0,
          datecurrent
        ).then((res) => {
          res.data.donnee.forEach((element) => {
            platSet = [
              ...platSet,
              {
                id: element.id,
                title: element.designation,
                start: element.date,
                end: element.date,
                instant: element.instant,
                quantite: element.quantite,
                extendedProps: {
                  platId: element.id,
                  urlImage:
                    element.visuelId !== null && element.visuelId.length !== 0
                      ? returnImageLink(element.visuelId)
                      : null,
                },
              },
            ];
          });

          setlisteMenu(platSet);
        });
      } else {
        let vendeuseId = vendeuseSelected === null ? 0 : vendeuseSelected.id;

        recherchermenuparmoisparentreprise(
          entrepriseSelected.id,
          vendeuseId,
          0,
          0,
          datecurrent
        ).then((res) => {
          res.data.donnee.forEach((element) => {
            platSet = [
              ...platSet,
              {
                id: element.id,
                title: element.designation,
                start: element.date,
                end: element.date,
                instant: element.instant,
                quantite: element.quantite,
                extendedProps: {
                  platId: element.id,
                  urlImage:
                    element.visuelId !== null && element.visuelId.length !== 0
                      ? returnImageLink(element.visuelId)
                      : null,
                },
              },
            ];
          });

          setlisteMenu(platSet);
        });
      }
    } else if (entrepriseSiteSelected === null && entrepriseSelected === null) {
      let platSet = [];

      if (DecodeToken().profil !== "ROLE_ADMIN") {
        if (!restaurantEnfantSelected) {
          afficherListPlatMois(datecurrent);
        }

        let restaurantId =
          restaurantEnfantSelected && restaurantEnfantSelected.id
            ? restaurantEnfantSelected.id
            : 0;
        recherchermenuparmoisparentreprise(
          0,
          0,
          restaurantId,
          0,
          datecurrent
        ).then((res) => {
          res.data.donnee.forEach((element) => {
            platSet = [
              ...platSet,
              {
                id: element.id,
                title: element.designation,
                start: element.date,
                end: element.date,
                instant: element.instant,
                quantite: element.quantite,
                extendedProps: {
                  platId: element.id,
                  urlImage:
                    element.visuelId !== null && element.visuelId.length !== 0
                      ? returnImageLink(element.visuelId)
                      : null,
                },
              },
            ];
          });

          setlisteMenu(platSet);
        });
      } else {
        if (vendeuseSelected === null && restauSelected !== null) {
          afficherListPlatMoisRestau(datecurrent);
        } else if (vendeuseSelected !== null && restauSelected === null) {
          afficherListPlatMois(datecurrent);
        }
      }
    }
  };

  const afficherListPlatMoisRestau = (datecurrent) => {
    let platSet = [];
    let restaurantId = restauSelected === null ? 0 : restauSelected.id;
    setdateMois(datecurrent);

    recherchermenuparmoisparrestaurant(restaurantId, datecurrent).then(
      (res) => {
        res.data.donnee.forEach((element) => {
          platSet = [
            ...platSet,
            {
              id: element.id,
              title: element.designation,
              start: element.date,
              end: element.date,
              instant: element.instant,
              quantite: element.quantite,
              extendedProps: {
                platId: element.id,
                urlImage:
                  element.visuelId !== null && element.visuelId.length !== 0
                    ? returnImageLink(element.visuelId)
                    : null,
              },
            },
          ];
        });

        setlisteMenu(platSet);
        setdateMois(datecurrent);
      }
    );
  };

  const afficherListPlatMoisEntrepriseRestauratrice = (datecurrent) => {
    let platSet = [];
    let entrepriseId = entrepriseSelected === null ? 0 : entrepriseSelected.id;
    let restaurantId =
      restaurantEnfantSelected === null ? 0 : restaurantEnfantSelected.id;

    recherchermenuparmoisparentreprise(
      entrepriseId,
      0,
      restaurantId,
      0,
      datecurrent
    ).then((res) => {
      res.data.donnee.forEach((element) => {
        platSet = [
          ...platSet,
          {
            id: element.id,
            title: element.designation,
            start: element.date,
            end: element.date,
            instant: element.instant,
            quantite: element.quantite,
            extendedProps: {
              platId: element.id,
              urlImage:
                element.visuelId !== null && element.visuelId.length !== 0
                  ? returnImageLink(element.visuelId)
                  : null,
            },
          },
        ];
      });

      setlisteMenu(platSet);
    });
  };

  const afficherListPlatMoisSiteEntrepriseRestauratrice = (datecurrent) => {
    let platSet = [];
    let siteEntrepriseId =
      entrepriseSiteSelected && entrepriseSiteSelected.id
        ? entrepriseSiteSelected.id
        : 0;
    let restaurantId =
      restaurantEnfantSelected && restaurantEnfantSelected.id
        ? restaurantEnfantSelected.id
        : 0;

    recherchermenuparmoisparentreprise(
      entrepriseSiteSelected && entrepriseSiteSelected.id
        ? entrepriseSelected.id
        : 0,
      0,
      restaurantId,
      siteEntrepriseId,
      datecurrent
    ).then((res) => {
      res.data.donnee.forEach((element) => {
        platSet = [
          ...platSet,
          {
            id: element.id,
            title: element.designation,
            start: element.date,
            end: element.date,
            instant: element.instant,
            quantite: element.quantite,
            extendedProps: {
              platId: element.id,
              urlImage:
                element.visuelId !== null && element.visuelId.length !== 0
                  ? returnImageLink(element.visuelId)
                  : null,
            },
          },
        ];
      });

      setlisteMenu(platSet);
    });
  };

  const afficherListPlatMoisEntrepriseAdmin = (datecurrent) => {
    let platSet = [];
    let entrepriseId = entrepriseSelected === null ? 0 : entrepriseSelected.id;
    let vendeuseId = vendeuseSelected === null ? 0 : vendeuseSelected.id;
    let restaurantId = restauSelected === null ? 0 : restauSelected.id;

    if (entrepriseSelected === null && vendeuseSelected !== null) {
      console.log("vendeuse");
      afficherListPlatMois(datecurrent);
    } else if (entrepriseSelected === null && restauSelected !== null) {
      console.log("restaurant");
      afficherListPlatMoisRestau(datecurrent);
    } else {
      console.log("entreprise");
      recherchermenuparmoisparentreprise(
        entrepriseId,
        vendeuseId,
        restaurantId,
        0,
        datecurrent
      )
        .then((res) => {
          res.data.donnee.forEach((element) => {
            platSet = [
              ...platSet,
              {
                id: element.id,
                title: element.designation,
                start: element.date,
                end: element.date,
                instant: element.instant,
                quantite: element.quantite,
                extendedProps: {
                  platId: element.id,
                  urlImage:
                    element.visuelId !== null && element.visuelId.length !== 0
                      ? returnImageLink(element.visuelId)
                      : null,
                },
              },
            ];
          });

          setlisteMenu(platSet);
        })
        .catch((error) => {
          console.log("THE ERROR", error);
        });
    }
  };

  const afficherListPlatMoisSiteEntrepriseAdmin = (datecurrent) => {
    let platSet = [];
    let entrepriseId = entrepriseSelected === null ? 0 : entrepriseSelected.id;
    let vendeuseId = vendeuseSelected === null ? 0 : vendeuseSelected.id;
    let restaurantId = restauSelected === null ? 0 : restauSelected.id;
    let siteId =
      entrepriseSiteSelected === null ? 0 : entrepriseSiteSelected.id;

    if (siteId === null) {
      if (entrepriseSelected === null && vendeuseSelected !== null) {
        console.log("vendeuse");
        afficherListPlatMois(datecurrent);
      } else if (entrepriseSelected === null && restauSelected !== null) {
        console.log("restaurant");
        afficherListPlatMoisRestau(datecurrent);
      } else {
        console.log("entreprise");
        recherchermenuparmoisparentreprise(
          entrepriseId,
          vendeuseId,
          restaurantId,
          0,
          datecurrent
        )
          .then((res) => {
            res.data.donnee.forEach((element) => {
              platSet = [
                ...platSet,
                {
                  id: element.id,
                  title: element.designation,
                  start: element.date,
                  end: element.date,
                  instant: element.instant,
                  quantite: element.quantite,
                  extendedProps: {
                    platId: element.id,
                    urlImage:
                      element.visuelId !== null && element.visuelId.length !== 0
                        ? returnImageLink(element.visuelId)
                        : null,
                  },
                },
              ];
            });

            setlisteMenu(platSet);
          })
          .catch((error) => {
            console.log("THE ERROR", error);
          });
      }
    } else {
      recherchermenuparmoisparentreprise(
        entrepriseId,
        vendeuseId,
        restaurantId,
        siteId,
        datecurrent
      )
        .then((res) => {
          res.data.donnee.forEach((element) => {
            platSet = [
              ...platSet,
              {
                id: element.id,
                title: element.designation,
                start: element.date,
                end: element.date,
                instant: element.instant,
                quantite: element.quantite,
                extendedProps: {
                  platId: element.id,
                  urlImage:
                    element.visuelId !== null && element.visuelId.length !== 0
                      ? returnImageLink(element.visuelId)
                      : null,
                },
              },
            ];
          });

          setlisteMenu(platSet);
        })
        .catch((error) => {
          console.log("THE ERROR", error);
        });
    }
  };

  const afficherListePlat = () => {
    if (DecodeToken().profil !== "ROLE_ADMIN") {
      rechercherlisteplats().then((res) => {
        setlistePlats(res.data.donnee);
      });
    } else {
      let vendeuseId = vendeuseSelected === null ? 0 : vendeuseSelected.id;
      rechercherlisteplatsparvendeuse(vendeuseId).then((res) => {
        setlistePlats(res.data.donnee);
      });
    }
  };

  const afficherListePlatRestaurant = () => {
    if (DecodeToken().profil !== "ROLE_ADMIN") {
      rechercherlisteplats().then((res) => {
        setlistePlats(res.data.donnee);
      });
    } else {
      let restauId = restauSelected === null ? 0 : restauSelected.id;
      rechercherlisteplatsparrestaurant(restauId).then((res) => {
        setlistePlats(res.data.donnee);
      });
    }
  };

  const handleDateClick = (arg) => {
    document.getElementById("addplat").click();
    setdataSelected(convertDate(arg.date));
  };

  const handlePlatday = (values) => {
    let dateDuJour = new Date();
    let dateMonth =
      dateDuJour.getMonth() < 9
        ? `0${dateDuJour.getMonth() + 1}`
        : dateDuJour.getMonth() + 1;
    let dayDate =
      dateDuJour.getDate() <= 9
        ? `0${dateDuJour.getDate()}`
        : dateDuJour.getDate();
    let dateCurrent =
      dayDate + "-" + dateMonth + "-" + dateDuJour.getFullYear();
    vendeuseSelected = values;
    setRestauSelected(null);
    setvendeuseSelected(vendeuseSelected);
    dateMois !== null
      ? afficherListPlatMois(dateMois)
      : afficherListPlatMois(dateCurrent);
    afficherListePlat();
  };

  const handlePlatdayRestau = (values) => {
    let dateDuJour = new Date();
    let dateMonth =
      dateDuJour.getMonth() < 9
        ? `0${dateDuJour.getMonth() + 1}`
        : dateDuJour.getMonth() + 1;
    let dayDate =
      dateDuJour.getDate() <= 9
        ? `0${dateDuJour.getDate()}`
        : dateDuJour.getDate();
    let dateCurrent =
      dayDate + "-" + dateMonth + "-" + dateDuJour.getFullYear();
    restauSelected = values;
    setvendeuseSelected(null);
    setRestauSelected(restauSelected);
    dateMois !== null
      ? afficherListPlatMoisRestau(dateMois)
      : afficherListPlatMoisRestau(dateCurrent);
    afficherListePlatRestaurant();
  };

  const handlePlatdayEntrepriseRestauratrice = (values) => {
    let dateDuJour = new Date();
    let dateMonth =
      dateDuJour.getMonth() < 9
        ? `0${dateDuJour.getMonth() + 1}`
        : dateDuJour.getMonth() + 1;
    let dayDate =
      dateDuJour.getDate() < 9
        ? `0${dateDuJour.getDate()}`
        : dateDuJour.getDate();

    let dateCurrent =
      dayDate + "-" + dateMonth + "-" + dateDuJour.getFullYear();
    entrepriseSelected = values;
    setEntrepriseSelected(entrepriseSelected);
    dateMois !== null
      ? afficherListPlatMoisEntrepriseRestauratrice(dateMois)
      : afficherListPlatMoisEntrepriseRestauratrice(dateCurrent);
    afficherListePlatRestaurant();
  };

  const handlePlatdaySiteEntrepriseRestauratrice = (values) => {
    let dateDuJour = new Date();
    let dateMonth =
      dateDuJour.getMonth() < 9
        ? `0${dateDuJour.getMonth() + 1}`
        : dateDuJour.getMonth() + 1;
    let dayDate =
      dateDuJour.getDate() <= 9
        ? `0${dateDuJour.getDate()}`
        : dateDuJour.getDate();
    let dateCurrent =
      dayDate + "-" + dateMonth + "-" + dateDuJour.getFullYear();
    entrepriseSiteSelected = values;
    setEntrepriseSiteSelected(entrepriseSiteSelected);
    dateMois !== null
      ? afficherListPlatMoisSiteEntrepriseRestauratrice(dateMois)
      : afficherListPlatMoisSiteEntrepriseRestauratrice(dateCurrent);
    afficherListePlatRestaurant();
  };

  const handlePlatdayRestaurantEnfantRestauratrice = (values) => {
    let dateDuJour = new Date();
    let dateMonth =
      dateDuJour.getMonth() < 9
        ? `0${dateDuJour.getMonth() + 1}`
        : dateDuJour.getMonth() + 1;
    let dayDate =
      dateDuJour.getDate() <= 9
        ? `0${dateDuJour.getDate()}`
        : dateDuJour.getDate();
    let dateCurrent =
      dayDate + "-" + dateMonth + "-" + dateDuJour.getFullYear();
    restaurantEnfantSelected = values;
    setRestaurantEnfantSelected(restaurantEnfantSelected);
    dateMois !== null
      ? afficherListPlatMoisSiteEntrepriseRestauratrice(dateMois)
      : afficherListPlatMoisSiteEntrepriseRestauratrice(dateCurrent);
    afficherListePlatRestaurant();
  };

  const handlePlatdaySiteEntrepriseForAdmin = (values) => {
    let dateDuJour = new Date();
    let dateMonth =
      dateDuJour.getMonth() < 9
        ? `0${dateDuJour.getMonth() + 1}`
        : dateDuJour.getMonth() + 1;
    let dayDate =
      dateDuJour.getDate() <= 9
        ? `0${dateDuJour.getDate()}`
        : dateDuJour.getDate();
    let dateCurrent =
      dayDate + "-" + dateMonth + "-" + dateDuJour.getFullYear();
    entrepriseSiteSelected = values;
    setEntrepriseSiteSelected(entrepriseSiteSelected);
    dateMois !== null
      ? afficherListPlatMoisSiteEntrepriseAdmin(dateMois)
      : afficherListPlatMoisSiteEntrepriseAdmin(dateCurrent);
    // afficherListePlatRestaurant();
  };

  const handlePlatdayEntrepriseAdmin = (values) => {
    let dateDuJour = new Date();
    let dateMonth =
      dateDuJour.getMonth() < 9
        ? `0${dateDuJour.getMonth() + 1}`
        : dateDuJour.getMonth() + 1;
    let dayDate =
      dateDuJour.getDate() <= 9
        ? `0${dateDuJour.getDate()}`
        : dateDuJour.getDate();
    let dateCurrent =
      dayDate + "-" + dateMonth + "-" + dateDuJour.getFullYear();
    entrepriseSelected = values;
    setEntrepriseSelected(entrepriseSelected);
    dateMois !== null
      ? afficherListPlatMoisEntrepriseAdmin(dateMois)
      : afficherListPlatMoisEntrepriseAdmin(dateCurrent);
  };

  const handlePlatdayEntreprise = (values) => {
    entrepriseSelected = values;
    if (values !== null) {
      setEntrepriseSelected(entrepriseSelected);
      setEntrepriseSiteSelected(null);
      getListeSiteEntreprise(entrepriseSelected.id);
      handlePlatdayEntrepriseRestauratrice(entrepriseSelected);
    } else {
      setEntrepriseSelected(entrepriseSelected);
      setEntrepriseSiteSelected(null);
      handlePlatdayEntrepriseRestauratrice(entrepriseSelected);
    }
  };

  const handlePlatdayEntrepriseForAdmin = (values) => {
    entrepriseSelected = values;
    if (values !== null) {
      setEntrepriseSelected(entrepriseSelected);
      setEntrepriseSiteSelected(null);
      getListeSiteEntreprise(entrepriseSelected.id);
      handlePlatdayEntrepriseAdmin(entrepriseSelected);
    } else {
      setEntrepriseSelected(entrepriseSelected);
      setEntrepriseSiteSelected(null);
      handlePlatdayEntrepriseAdmin(entrepriseSelected);
    }
  };

  const handlePlatdayEntrepriseSite = (values) => {
    entrepriseSiteSelected = values;
    setEntrepriseSiteSelected(entrepriseSiteSelected);
    handlePlatdaySiteEntrepriseRestauratrice(entrepriseSiteSelected);
  };

  const handlePlatRestaurantEnfant = (values) => {
    restaurantEnfantSelected = values;
    setRestaurantEnfantSelected(restaurantEnfantSelected);
    handlePlatdayRestaurantEnfantRestauratrice(restaurantEnfantSelected);
  };

  const handlePlatdayEntrepriseSiteForAdmin = (values) => {
    entrepriseSiteSelected = values;
    setEntrepriseSiteSelected(entrepriseSiteSelected);
    handlePlatdaySiteEntrepriseForAdmin(entrepriseSiteSelected);
  };

  // ici
  const handleSubmit = (value) => {
    const values = {
      mois: parseInt(value.mois),
      instant: value.instant,
    };
    // setDupliqueLoader(true)
    let dateCurrent = "01-" + mois + "-" + annee;
    setMessageError("");
    dupliquermenu({
      type: value.type,
      date: value.type == "MOIS" ? dateMois : convertDate(value.initDate),
      mois: parseInt(value.mois),
      dateDebut: value.dateDebut ? convertDate(value.dateDebut) : "",
      dateFin: value.dateFin ? convertDate(value.dateFin) : "",
      instant: value.instant,
      entreprise: entrepriseSelected ? entrepriseSelected.id : "",
      restaurantId:
        restaurantEnfantSelected && restaurantEnfantSelected.id
          ? restaurantEnfantSelected.id
          : "",
      restaurantFinalId:
        value.restaurantSelected !== "" ? value.restaurantSelected : "",
    })
      .then((res) => {
        const message = `Le planning a bien été dupliqué avec success`;
        setDupliquer(message);
        document.querySelector(`.annuler`).click();
        document.querySelector(`.alert`).classList.add("voir");
        setMessageError("");
        // afficherListPlatMois(dateMois);
        actualisePlanningApresProgrammation(dateMois);
        setDupliqueLoader(false);
      })
      .catch((err) => {
        messageError = err.response.data.donnee;
        setMessageError(messageError);
        setDupliqueLoader(false);
      });
  };
  // fin

  const updateListeDesPlatModal = (values) => {
    setlistePlats(values);
  };

  const deletePlatDuPlaning = () => {
    retirerrepasdumenujournalier(repasId).then((res) => {
      actualisePlanningApresProgrammation(convertDate(repasStart));
    });
  };

  useEffect(() => {
    annee = moment(
      calendarApiRefresh.current.getApi().currentDataManager.data.currentDate
    ).format("YYYY");
    mois = moment(
      calendarApiRefresh.current.getApi().currentDataManager.data.currentDate
    ).format("MM");
    setMois(mois);
    setAnnee(annee);
    afficherListePlat();
    afficherEntreprise();
    if (DecodeToken().profil === "ROLE_RESTAURATRICE") {
      rechercherRestaurantsEnfant().then((response) => {
        setListeRestaurantEnfant(response.data.donnee);
      });
    }

    dateMois != null && afficherListPlatMois(dateMois);
  }, [dateMois]);

  const renderEvent = (eventInfo) => {
    return (
      <>
        {eventInfo.event.extendedProps.instant === "PETIT_DEJEUNER" && (
          <div className="p-2 ">
            <div className="d-flex">
              {eventInfo.event.extendedProps.urlImage ? (
                <img
                  src={eventInfo.event.extendedProps.urlImage}
                  width="30%"
                  className="img-fluid"
                  alt="Image plat"
                />
              ) : null}
              <div className="align-self-center mx-2">
                <span className="mx-1">
                  {eventInfo.event.title} (
                  {`${eventInfo.event.extendedProps.quantite}`})
                </span>
              </div>
              <div
                className="px-2"
                onClick={() => {
                  setRepasId(eventInfo.event.id);
                  setRepasStart(eventInfo.event.start);
                  setShowSweetAlert(true);
                }}
              >
                <i className="fa cursor fa-trash-alt text-danger"></i>
              </div>
            </div>
          </div>
        )}
        {eventInfo.event.extendedProps.instant === "DEJEUNER" && (
          <div className="p-2 " style={{ backgroundColor: "#B9FFF8" }}>
            <div className="d-flex">
              {eventInfo.event.extendedProps.urlImage ? (
                <img
                  src={eventInfo.event.extendedProps.urlImage}
                  width="30%"
                  className="img-fluid"
                  alt="Image plat"
                />
              ) : null}
              <div className="align-self-center mx-2">
                <span className="mx-1">
                  {eventInfo.event.title} (
                  {`${eventInfo.event.extendedProps.quantite}`})
                </span>
              </div>
              <div
                className="px-2"
                onClick={() => {
                  setRepasId(eventInfo.event.id);
                  setRepasStart(eventInfo.event.start);
                  setShowSweetAlert(true);
                }}
              >
                <i className="fa cursor fa-trash-alt text-danger"></i>
              </div>
            </div>
          </div>
        )}
        {eventInfo.event.extendedProps.instant === "DINER" && (
          <div
            className="p-2 "
            style={{ backgroundColor: "#D2D79F", borderRadius: "5px" }}
          >
            <div className="d-flex">
              {eventInfo.event.extendedProps.urlImage ? (
                <img
                  src={eventInfo.event.extendedProps.urlImage}
                  width="30%"
                  className="img-fluid"
                  alt="Image plat"
                />
              ) : null}
              <div className="align-self-center mx-2">
                <span className="mx-1">
                  {eventInfo.event.title} (
                  {`${eventInfo.event.extendedProps.quantite}`})
                </span>
              </div>
              <div
                className="px-2"
                onClick={() => {
                  setRepasId(eventInfo.event.id);
                  setRepasStart(eventInfo.event.start);
                  setShowSweetAlert(true);
                }}
              >
                <i className="fa cursor fa-trash-alt text-danger"></i>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_RESTAURATRICE" &&
        DecodeToken().profil !== "ROLE_ADMIN" && <Navigate to="/" replace />}
      <HeaderNomResto />
      <div className="w-100 d-flex justify-content-between mb-3">
        <p className="mb-3 fw-bold">Planifier vos menus</p>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className=""
            style={{
              marginRight: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "20px",
                width: "20px",
                backgroundColor: "rgb(246, 246, 246)",
                borderRadius: "50%",
                marginRight: "0.5rem",
              }}
            ></div>
            <span>Petit déjeuner</span>
          </div>
          <div
            className=""
            style={{
              marginRight: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <div
              style={{
                height: "20px",
                width: "20px",
                backgroundColor: "#B9FFF8",
                borderRadius: "50%",
                marginRight: "0.5rem",
              }}
            ></div>{" "}
            Déjeuner
          </div>
          <div
            className=""
            style={{
              marginRight: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <div
              style={{
                height: "20px",
                width: "20px",
                backgroundColor: "#D2D79F",
                borderRadius: "50%",
                marginRight: "0.5rem",
              }}
            ></div>{" "}
            Diner
          </div>
        </div>
      </div>

      <div
        className="container bg-white p-3 rounded"
        style={{ height: "120vh", overflowY: "hidden", overflow: "scroll" }}
      >
        <div className="alert alert-success" role="alert">
          {dupliquer}
        </div>
        <div className="d-flex align-items-center">
          {DecodeToken().profil === "ROLE_ADMIN" ? (
            <div
              style={{ position: "relative", top: "35px", left: "250px" }}
              className="d-flex"
            >
              <div>
                <ListeVendeuse setdataSelected={handlePlatday} />
              </div>
              <div className="mx-3">
                <ListeRestaurant setdataSelected={handlePlatdayRestau} />
              </div>
              <div className="me-3">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listeEntreprise}
                  getOptionLabel={(option) => option.raisonSociale ?? option}
                  value={entrepriseSelected}
                  onChange={(event, value) =>
                    handlePlatdayEntrepriseForAdmin(value)
                  }
                  sx={{
                    width: 180,
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="bg-white"
                      label="Entreprise"
                    />
                  )}
                />
              </div>
              <div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listeSiteEntreprise}
                  getOptionLabel={(option) => option.nom ?? option}
                  value={entrepriseSiteSelected}
                  onChange={(event, value) =>
                    handlePlatdayEntrepriseSiteForAdmin(value)
                  }
                  sx={{
                    width: 180,
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="bg-white"
                      label="Site entreprise"
                    />
                  )}
                />
              </div>
            </div>
          ) : null}
          {DecodeToken().profil === "ROLE_RESTAURATRICE" && (
            <div
              style={{ position: "relative", top: "35px", left: "250px" }}
              className="w-75 d-flex justify-content-between"
            >
              <div className="d-flex align-items-center gap-2">
                <div className=" d-flex justify-content-end">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listeEntreprise}
                    getOptionLabel={(option) => option.raisonSociale ?? option}
                    value={entrepriseSelected}
                    onChange={(event, value) => handlePlatdayEntreprise(value)}
                    sx={{
                      width: 250,
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-white"
                        label="Entreprise"
                      />
                    )}
                  />
                </div>
                <div className=" d-flex justify-content-end">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listeSiteEntreprise}
                    getOptionLabel={(option) => option.nom ?? option}
                    value={entrepriseSiteSelected}
                    onChange={(event, value) =>
                      handlePlatdayEntrepriseSite(value)
                    }
                    sx={{
                      width: 250,
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-white"
                        label="Site entreprise"
                      />
                    )}
                  />
                </div>
                <div className=" d-flex justify-content-end">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listeRestaurantEnfant}
                    getOptionLabel={(option) => option.nom ?? option}
                    value={restaurantEnfantSelected}
                    onChange={(event, value) =>
                      handlePlatRestaurantEnfant(value)
                    }
                    sx={{
                      width: 250,
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-white"
                        label="Restaurant enfant"
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ position: "relative", left: "100px" }}>
                <DupliquerPlaning
                  handleSubmit={handleSubmit}
                  dupliquer={dupliquer}
                  alert={alert}
                  messageError={messageError}
                  dupliqueLoading={dupliqueLoader}
                />
              </div>
            </div>
          )}
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={fr}
          editable={false}
          ref={calendarApiRefresh}
          events={listeMenu}
          eventClick={(info) => {}}
          eventContent={renderEvent}
          dateClick={handleDateClick}
          datesSet={(datesInfos) => {
            if (datesInfos.end.getMonth() != 0) {
              if (datesInfos.end.getMonth() <= 9) {
                let dateMonth = "0" + datesInfos.end.getMonth();
                let dateCurrent =
                  "01-" + dateMonth + "-" + datesInfos.end.getFullYear();
                if (restauSelected !== null) {
                  afficherListPlatMoisRestau(dateCurrent);
                } else {
                  afficherListPlatMois(dateCurrent);
                }
              } else if (datesInfos.end.getMonth() == 10) {
                let dateMonth = datesInfos.end.getMonth();
                let dateCurrent =
                  "01-" + dateMonth + "-" + datesInfos.end.getFullYear();
                if (restauSelected !== null) {
                  afficherListPlatMoisRestau(dateCurrent);
                } else {
                  afficherListPlatMois(dateCurrent);
                }
              } else {
                let dateMonth = datesInfos.end.getMonth();
                let dateCurrent =
                  "01-" + dateMonth + "-" + datesInfos.end.getFullYear();
                if (restauSelected !== null) {
                  afficherListPlatMoisRestau(dateCurrent);
                } else {
                  afficherListPlatMois(dateCurrent);
                }
              }

              //console.log("date",dateMonth);
              document.querySelector(`.alert`).classList.remove("voir");
            } else {
              let dateCurrent =
                "01-" + 12 + "-" + (datesInfos.end.getFullYear() - 1);
              afficherListPlatMois(dateCurrent);
            }
          }}
          eventTextColor="#000"
          eventColor="#F6F6F6"
          headerToolbar={{
            start: "prev title next",
            center: "",
            end: "",
          }}
        />
      </div>

      <button
        id="addplat"
        data-bs-target="#ajoutplat"
        data-bs-toggle="modal"
        className="btn"
        hidden
      ></button>
      <ChoosePlatModal
        calendarApiRefresh={calendarApiRefresh}
        dateMois={dateMois}
        actualiserList={actualisePlanningApresProgrammation}
        listePlat={listePlats}
        vendeuse={vendeuseSelected}
        restaurant={
          DecodeToken().profil === "ROLE_ADMIN"
            ? restauSelected
            : DecodeToken().profil === "ROLE_RESTAURATRICE"
            ? restaurantEnfantSelected
            : null
        }
        setlistePlat={updateListeDesPlatModal}
        dateSelected={dataSelected}
      />
      <SweetAlert
        show={showSweetAlert}
        title="Confirmer la suppression"
        text="Cette action est irréversible"
        showCancelButton
        onConfirm={() => {
          setShowSweetAlert(false);
          deletePlatDuPlaning();
        }}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
        onEscapeKey={() => setShowSweetAlert(false)}
        onOutsideClick={() => setShowSweetAlert(false)}
      />
    </Layout>
  );
}

export default PlanningMenu;
