import React from 'react'
import { Link } from 'react-router-dom'

function HeaderNomResto() {
    return (
        <div className='d-flex justify-content-between '>
            {/* <h6 className='mt-2 '>RestoSIB</h6> */}
            <h6 className='mt-2 '>SkanResto</h6>
            {/* <h6 className='mt-2 '>ProcreaResto</h6> */}
            <div className='my-2 text-center' title="Déconnexion" data-bs-toggle="tooltip" data-bs-placement="top">
                <Link to="/login" className='text-danger ' style={{ textDecoration: "none" }} replace id='deconnect' onClick={() => {
                    localStorage.removeItem('tokenRestaurant')
                    localStorage.removeItem('process')
                }}>
                    <i className='fa fa-sign-out '></i> Déconnexion
                </Link>
            </div>
        </div>
    )
}

export default HeaderNomResto