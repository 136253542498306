import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import {
  enregistrerheurecommande,
  filtrelisteheurecommandes,
  rechercherlisteheurecommandes,
  supprimerheurecommandes,
} from "../../services/commandes/CommandeRequest";
import HeaderNomResto from "../HeaderNomResto";
import Layout from "../layouts/Layout";
import Heure from "./Heure";
import MinuteSeconde from "./MinuteSeconde";
import { DecodeToken } from "../../DecodeToken";
import { Navigate } from "react-router-dom";
import { rechercherentreprisespartenaire } from "../../services/statistiques/StatRequest";

function HeureCommandes() {
  const [heureDebut, setheureDebut] = useState("00");
  const [heureFin, setheureFin] = useState("00");
  const [minuteDebut, setminuteDebut] = useState("00");
  const [minuteFin, setminuteFin] = useState("00");
  const [typeJour, settypeJour] = useState("");
  const [entreprise, setentreprise] = useState("");
  const [listeHeureCommande, setlisteHeureCommande] = useState([]);
  const [listeEntreprise, setlisteEntreprise] = useState([]);
  const [loading, setloading] = useState(false);
  let [instant, setInstant] = useState("PETIT_DEJEUNER");

  const handleheureCommande = () => {
    setloading(true);
    enregistrerheurecommande({
      heureDebut: heureDebut + ":" + minuteDebut,
      heureFin: heureFin + ":" + minuteFin,
      typeJour: typeJour,
      instant: instant,
      entrepriseId: entreprise,
    })
      .then(() => {
        setloading(false);
        afficherheurecommandes();
      })
      .catch(() => {
        setloading(false);
      });
  };

  const afficherheurecommandes = () => {
    setloading(true);
    rechercherlisteheurecommandes()
      .then((res) => {
        setloading(false);
        setlisteHeureCommande(res.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const handleInstant = (event) => {
    instant = event.target.value;
    setInstant(instant);
  };

  const afficherEntreprise = () => {
    rechercherentreprisespartenaire()
      .then((response) => {
        setlisteEntreprise(response.data.donnee);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filtreEntreprise = (entrepriseId) => {
    setentreprise(entrepriseId);
    setloading(true);

    filtrelisteheurecommandes(entrepriseId)
      .then((res) => {
        setloading(false);
        setlisteHeureCommande(res.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const deleteHoraire = (id) => {
    setloading(true)
    supprimerheurecommandes(id)
      .then((res) => {
        setloading(false);
        afficherheurecommandes();
      })
      .catch(() => {
        setloading(false);
      });
  }

  useEffect(() => {
    afficherheurecommandes();
    afficherEntreprise();
  }, []);

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_RESTAURATRICE" && (
        <Navigate to="/login" replace />
      )}
      <HeaderNomResto />
      <p className="mb-3 fw-bold">Heures de réceptions des commandes</p>

      <div className="mb-5 p-2">
        <div className="mb-3">
          <div className="row">
            <div className="col-md-2 ">
              <select
                name=""
                id=""
                value={typeJour}
                onChange={(e) => settypeJour(e.target.value)}
                className="form-control form-select "
              >
                <option value=""></option>
                <option value="OUVRABLE">Jour ouvrable</option>
                <option value="NONOUVRABLE">Jour non ouvrable</option>
              </select>
            </div>
            <div className="col-md-2 ">
              <select
                name="instant"
                id="instant"
                value={instant}
                onChange={handleInstant}
                className="form-control form-select "
              >
                <option value=""></option>
                <option selected value="PETIT_DEJEUNER">
                  Petit déjeuner
                </option>
                <option value="DEJEUNER">Déjeuner</option>
                <option value="DINER">Dîner</option>
              </select>
            </div>
            <div className="col-md-2 ">
              <select
                name="entreprise"
                id="entreprise"
                value={entreprise}
                onChange={(e) => filtreEntreprise(e.target.value)}
                className="form-control form-select "
              >
                <option selected value="0"></option>
                {listeEntreprise.map((item) => (
                  <option key={item.id} selected value={item.id}>
                    {item.raisonSociale}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3 d-flex">
              <Heure type="DEBUT" setheureDebut={setheureDebut} />{" "}
              <MinuteSeconde type="DEBUT" setminuteDebut={setminuteDebut} />{" "}
              <span className="fs-3 mx-2">/</span>{" "}
              <Heure type="FIN" setheureFin={setheureFin} />{" "}
              <MinuteSeconde type="FIN" setminuteFin={setminuteFin} />
            </div>
            <div className="col-md-1">
              <button
                onClick={() => handleheureCommande()}
                className="btn mt-1 mx-5 pb-1 btn-rose text-white"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>

      {listeHeureCommande.map((item, index) => (
        <div className="d-flex mb-3" key={index}>
          <button className="btn bg-danger mx-2" onClick={() => deleteHoraire(item.id)}>
            <i className="fa fa-trash text-white"></i>
          </button>
          <div
            className="col-md-2 py-1 px-2 d-flex align-items-center bg-white rounded rounded fw-bold"
            style={{ marginRight: "1rem" }}
          >
            <span>
              {item.typeJour === "NONOUVRABLE"
                ? "Jour non ouvrable"
                : "Jour ouvrable"}
            </span>
          </div>
          <div className="col-md-2 py-1 px-2 d-flex align-items-center bg-white rounded rounded fw-bold">
            <span>
              {(item.instantNourriture === "DEJEUNER" && "Déjeuner") ||
                (item.instantNourriture === "PETIT_DEJEUNER" &&
                  " Petit déjeuner") ||
                (item.instantNourriture === "DINER" && "Diner")}
            </span>
          </div>
          <div className="col-md- mx-2 d-flex align-items-center">
            <span className="text-success bg-white fw-bold p-1 px-2 border border-dark">
              {item.heureDebut.split(":")[0]}
            </span>
            <span className="fs-5 mx-1">:</span>
            <span className="text-success bg-white fw-bold p-1 px-2 border border-dark">
              {item.heureDebut.split(":")[1]}
            </span>
            <span className="fs-5 mx-2">/</span>
            <span className="text-danger bg-white fw-bold p-1 px-2 border border-dark">
              {item.heureFin.split(":")[0]}
            </span>
            <span className="fs-5 mx-1">:</span>
            <span className="text-danger bg-white fw-bold p-1 px-2 border border-dark">
              {item.heureFin.split(":")[1]}
            </span>
          </div>
          {item.entreprise ? (
            <div className="col-md-2 py-1 mx-2 px-2 d-flex align-items-center bg-rose rounded rounded fw-bold">
              <span style={{ fontSize: 14 }}>
                {item.entreprise ? item.entreprise.raisonSociale : null}
              </span>
            </div>
          ) : null}
        </div>
      ))}
      <div className="d-flex justify-content-center">
        <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
      </div>
    </Layout>
  );
}

export default HeureCommandes;
