import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { reinitialiserpassword } from '../../services/login/LoginRequest'

function ResetPassword() {

    const [loading, setloading] = useState(false)
    const [messageError, setmessageError] = useState("")

    const initialvalues = {
        email: ""
    }

    const validationschemavalues = yup.object().shape({
        email: yup.string().email("Veuillez saisir un email valide").required("veuillez saisir un email")
    })

    const onsubmitvaluesresetpassword = (values) => {
        setloading(true)
        reinitialiserpassword(values).then(() => {
            setloading(false)
            setmessageError("")
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Votre mot de passe a bien été réinitialisé, vérifiez votre boîte email !',
                showConfirmButton: false,
                timer: 1500
            })
            document.getElementById("closeModal").click()
        }).catch((error) => {
            setloading(false)
            setmessageError(error.response.data.donnee)
        })
    }

    return (

        <div className="modal fade" id="resetpassword" tabIndex={-1} role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">

                    <Formik
                        initialValues={initialvalues}
                        enableReinitialize
                        validationSchema={validationschemavalues}
                        onSubmit={onsubmitvaluesresetpassword}
                    >
                        {({ resetForm }) => (
                            <Form>

                                <div className="modal-header">
                                    <h5 className="modal-title">Réinitialiser mot de passe</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                {messageError !== "" && <div className='text-center my-3'>
                                    <span className='text-danger'> <i className="fas fa-exclamation-triangle"></i> {messageError}</span>
                                </div>}
                                <div className="modal-body">
                                    <div className='mb-3 container'>
                                        <label htmlFor="email" className='label-control'>E-mail</label>
                                        <Field type="text" className="form-control" name="email" id="email" />
                                        <span className='text-danger'><ErrorMessage name='email'></ErrorMessage></span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={()=>{resetForm(); setmessageError("")}} id='closeModal' className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    {loading ? <Oval width={30} height={30} color="#CE5D1D" visible={loading} /> : <button type="submit" className="btn btn-rose text-white">Enregistrer</button>}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>

    )
}

export default ResetPassword