import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

const NBRE_ELEMENT=7
export const rechercherRestaurants = async(page) =>{
    return await axios.get(baseUrl(`rechercherRestaurants?page=${page}&size=${NBRE_ELEMENT}`));
}
export const rechercherRestaurantsAutorise = async(page, nbrElement) =>{
    return await axios.get(baseUrl(`rechercherRestaurants?page=${page}&size=${nbrElement}`));
}
export const rechercherRestaurantsEnfant = async() =>{
    return await axios.get(baseUrl(`restauratrice/rechercherrestaurantsenfants`));
}
export const recherchertouslesRestaurants = async() =>{
    return await axios.get(baseUrl(`recherchertouslesRestaurants`));
}

export const enregistrerrestaurant = async(values) =>{
    return await axios.post(baseUrl('admin/enregistrerrestaurant'), values);
}

export const rechercherRestaurantsparparam = async(page,param) =>{
    return await axios.post(baseUrl(`rechercherRestaurantsparparam?page=${page}&param=${param}&size=${NBRE_ELEMENT}`),{});
}

export const autoriserrestaurant = async(id)=>{
    return await axios.post(baseUrl(`entreprise/autoriserrestaurant/${id}`), {});
}

export const changerEtatRestaurant = async(id)=>{
    return await axios.post(baseUrl(`admin/changeretatrestaurant/${id}`), {});
}

export const supprimerRestaurant = async(id)=>{
    return await axios.delete(baseUrl(`admin/supprimerrestaurant/${id}`));
}

export const retirerrestaurant = async(id)=>{
    return await axios.get(baseUrl(`entreprise/retirerrestaurant/${id}`));
}

