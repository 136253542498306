import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { useParams } from "react-router-dom";

const StatutPaiement = () => {
  let param = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [statut, setStatut] = useState("");
  const [data, setData] = useState("");

  const getStatutPaiement = () => {
    axios
      .get(baseUrl(`wave/verifierstatutpaiement/${param.transactionId}`))
      .then((response) => {
        setStatut(response.data.donnee.titre);
        setData(response.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log(
          "Une erreur à survenue lors de la recuperation des infos....."
        );
        console.log(error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    setisLoading(true);
    getStatutPaiement();
  }, []);

  return (
    <div>
      <div
        className="bg-white pt-3 container"
        style={{ position: "relative", marginTop: 100 }}
      >
        <h1 className="text-center fw-bold">Statut paiement</h1>

        <div
          style={{
            width: "100px",
            margin: "auto",
            marginTop: 80,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Bars
            color="#E5E5E5"
            height={50}
            width={50}
            visible={isLoading}
          />
        </div>

        {data.statut === 0 && statut === "CONFIRMATION" && !isLoading ? (
          <div>
            <div style={{ width: "100px", margin: "auto", marginTop: 80 }}>
              <svg
                width="100px"
                height="100px"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM20.9502 14.2929C21.3407 13.9024 21.3407 13.2692 20.9502 12.8787C20.5597 12.4882 19.9265 12.4882 19.536 12.8787L14.5862 17.8285L12.4649 15.7071C12.0744 15.3166 11.4412 15.3166 11.0507 15.7071C10.6602 16.0977 10.6602 16.7308 11.0507 17.1213L13.8791 19.9498C14.2697 20.3403 14.9028 20.3403 15.2933 19.9498L20.9502 14.2929Z"
                  fill="#4BB543"
                />
              </svg>
            </div>{" "}
            <p className="text-center" style={{ fontSize: 24, marginTop: 20 }}>
              Paiement effectuée avec succès
            </p>
          </div>
        ) : null}

        {data.statut === 0 && statut !== "CONFIRMATION" && !isLoading ? (
          <div>
            <div style={{ width: "85px", margin: "auto", marginTop: 80 }}>
              <svg
                fill="#db0f13"
                height="85px"
                width="85px"
                version="1.1"
                viewBox="0 0 51.976 51.976"
              >
                <g>
                  <path d="M44.373,7.603c-10.137-10.137-26.632-10.138-36.77,0c-10.138,10.138-10.137,26.632,0,36.77s26.632,10.138,36.77,0 C54.51,34.235,54.51,17.74,44.373,7.603z M36.241,36.241c-0.781,0.781-2.047,0.781-2.828,0l-7.425-7.425l-7.778,7.778 c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828l7.778-7.778l-7.425-7.425c-0.781-0.781-0.781-2.048,0-2.828 c0.781-0.781,2.047-0.781,2.828,0l7.425,7.425l7.071-7.071c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828 l-7.071,7.071l7.425,7.425C37.022,34.194,37.022,35.46,36.241,36.241z" />
                </g>
              </svg>
            </div>
            <p className="text-center" style={{ fontSize: 24, marginTop: 20 }}>
              Echec de paiement
            </p>
          </div>
        ) : null}

        {data.statut !== 0 && !isLoading ? (
          <div>
            <div style={{ width: "85px", margin: "auto", marginTop: 80 }}>
              <svg
                fill="#db0f13"
                height="85px"
                width="85px"
                version="1.1"
                viewBox="0 0 51.976 51.976"
              >
                <g>
                  <path d="M44.373,7.603c-10.137-10.137-26.632-10.138-36.77,0c-10.138,10.138-10.137,26.632,0,36.77s26.632,10.138,36.77,0 C54.51,34.235,54.51,17.74,44.373,7.603z M36.241,36.241c-0.781,0.781-2.047,0.781-2.828,0l-7.425-7.425l-7.778,7.778 c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828l7.778-7.778l-7.425-7.425c-0.781-0.781-0.781-2.048,0-2.828 c0.781-0.781,2.047-0.781,2.828,0l7.425,7.425l7.071-7.071c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828 l-7.071,7.071l7.425,7.425C37.022,34.194,37.022,35.46,36.241,36.241z" />
                </g>
              </svg>
            </div>
            <p className="text-center" style={{ fontSize: 24, marginTop: 20 }}>
              Echec de paiement
            </p>
          </div>
        ) : null}

        <div></div>
      </div>
    </div>
  );
};

export default StatutPaiement;
