import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import { Oval } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import { DecodeToken } from "../../DecodeToken";
import HeaderNomResto from "../HeaderNomResto";
import $ from "jquery";
import { rechercherUtilisateursparparam } from "../../services/utilisateurs/UtilisateurRequest";
import Paginate from "../pagination";
import {
  ajoutDecaissement,
  enregistrerPortefeuilleCredit,
  enregistrerPortefeuilleDebit,
  listeSoldeDecaissement,
  rechercherPortefeuille,
  rechercherPortefeuilleByEntreprise,
  rechercherPortefeuilleByParam,
  soldePortefeuille,
  updatePortefeuilleCredit,
} from "../../services/portefeuille/portefeuilleRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";

function Portefeuille() {
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [totalElementDecaissement, settotalElementDecaissement] = useState(0);
  const [totalElementDecaissementByPage, setTotalElementDecaissementByPage] =
    useState(0);
  const [filterParametres, setfilterParametres] = useState({ param: "" });
  const [pageActive, setPageActive] = useState(0);
  const [pageActiveDecaissement, setPageActiveDecaissement] = useState(0);

  const [loading, setloading] = useState(false);
  const [montant, setMontant] = useState("");
  const [updateMontant, setUpdateMontant] = useState("");
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState("");

  const [composant, setComposant] = useState("portefeuille");
  const [context, setContext] = useState("Portefeuille");
  const [soldeTotal, setSoldeTotal] = useState(null);
  const [montantDecaissement, setMontantDecaissement] = useState(0);

  const [listePortefeuille, setListePortefeuille] = useState([]);
  const [listeDecaissement, setListeDecaissement] = useState([]);
  const [listeEntreprise, setListeEntreprise] = useState([]);

  const afficherEntreprise = () => {
    setloading(true);
    rechercherentreprises()
      .then((response) => {
        setloading(false);
        setListeEntreprise(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const ListeSoldePortefeuille = (pageSkip) => {
    rechercherPortefeuille(pageSkip)
      .then((response) => {
        setListePortefeuille(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch((error) => {});
  };

  const ListeSoldeDecaissement = (pageSkip) => {
    listeSoldeDecaissement(pageSkip)
      .then((response) => {
        setListeDecaissement(response.data.donnee);
        settotalElementDecaissement(response.data.donnee.totalElements);
        setTotalElementDecaissementByPage(
          response.data.donnee.numberOfElements
        );
      })
      .catch((error) => {});
  };

  const soldeTotalPortefeuille = () => {
    soldePortefeuille()
      .then((response) => {
        setSoldeTotal(response.data.donnee);
      })
      .catch((error) => {});
  };

  const afficherUtilisateurFiltrer = (parametre, currentPage) => {
    setloading(true);
    rechercherUtilisateursparparam(currentPage - 1, parametre)
      .then((response) => {
        setloading(false);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const handleSearchPortefeuille = (param) => {
    setTimeout(() => {
      const searchData = {
        entrepriseId: 0,
        param: param
      }
      setloading(true);
      rechercherPortefeuilleByParam(pageActive, searchData)
        .then((response) => {
          setloading(false);
          setListePortefeuille(response.data.donnee);
          settotalElement(response.data.donnee.totalElements);
          setTotalElementByPage(response.data.donnee.numberOfElements);
        })
        .catch((error) => {
          setloading(false);
        });
    }, 1500);
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    filterParametres.param === ""
      ? ListeSoldePortefeuille(pageNumber)
      : afficherUtilisateurFiltrer(filterParametres.param, 1);
  };

  const handlePageChangeDecaissement = (pageNumber) => {
    setPageActiveDecaissement(pageNumber);
    ListeSoldeDecaissement(pageNumber);
  };

  const addCredit = () => {
    setloading(true);
    const credit = {
      employeId: userId,
      montant: montant !== "" ? parseInt(montant) : 0,
    };
    enregistrerPortefeuilleCredit(credit)
      .then(() => {
        setloading(false);
        setMontant("");
        $("#closeForm").trigger("click");
        ListeSoldePortefeuille(0);
      })
      .catch((err) => {
        setloading(false);
        $("#closeForm").trigger("click");
        console.log("API DATA ERROR", err.response);
      });
  };

  const debiterCredit = () => {
    setloading(true);
    const credit = {
      employeId: userId,
      montant: montant !== "" ? parseInt(montant) : 0,
    };
    enregistrerPortefeuilleDebit(credit)
      .then(() => {
        setloading(false);
        setMontant("");
        $("#closeFormDebit").trigger("click");
        ListeSoldePortefeuille(0);
      })
      .catch((err) => {
        setloading(false);
        $("#closeFormDebit").trigger("click");
        console.log("API DATA ERROR", err.response);
      });
  };

  const updateCredit = () => {
    setloading(true);
    const credit = {
      employeId: userId,
      montant: updateMontant !== "" ? parseInt(updateMontant) : 0,
    };
    updatePortefeuilleCredit(credit)
      .then(() => {
        setloading(false);
        setUpdateMontant("");
        $("#closeUpdateCreditForm").trigger("click");
        ListeSoldePortefeuille(0);
      })
      .catch((err) => {
        setloading(false);
        $("#closeUpdateCreditForm").trigger("click");
        console.log("API DATA ERROR", err.response);
      });
  };

  const getUserId = (id) => {
    setUserId(id);
  };

  const getUserIdForUpdate = (item) => {
    setUserId(item.id);
    setUpdateMontant(item.solde);
  };

  const handleSubmit = () => {
    let object = {
      montant: montantDecaissement ? montantDecaissement : 0,
    };

    setloading(true);
    ajoutDecaissement(object)
      .then(() => {
        setloading(false);
        setMontantDecaissement("");
        ListeSoldeDecaissement(pageActiveDecaissement);
        $("#reinitialiseFormEntreprise").trigger("click");
      })
      .catch((error) => {
        Error = error.response.data.donnee;
        setError(Error);
        setloading(false);
      });
  };

  const filtrePortefeuilleParEntreprise = (e) => {
    const entrepriseId = e.target.value;
    setloading(true);
    const searchData = {
      entrepriseId: entrepriseId,
      param: ""
    }
    setloading(true);
    rechercherPortefeuilleByParam(pageActive, searchData)
      .then((response) => {
        setloading(false);
        setListePortefeuille(response.data.donnee);
        settotalElement(response.data.donnee.totalElements);
        setTotalElementByPage(response.data.donnee.numberOfElements);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const rowListeEntreprise =
    listePortefeuille.employes !== undefined &&
    listePortefeuille.employes.map((item, index) => (
      <tr key={index}>
        <td>{item.nom + " " + item.prenoms}</td>
        <td>
          {item.numero !== null || item.numero !== undefined
            ? item.numero
            : "..."}
        </td>
        <td>
          {item.entreprise != null || item.entreprise != undefined
            ? item.entreprise.raisonSociale
            : "..."}
        </td>
        <td>
          {item.solde !== null || item.solde !== undefined
            ? `${item.solde}F CFA`
            : "..."}
        </td>

        {(DecodeToken().profil === "ROLE_ADMIN" ||
          DecodeToken().profil === "ROLE_COMPTABLE") && (
          <td>
            <div className="">
              <button
                onClick={() => getUserId(item.id)}
                className="btn bg-danger btn-sm text-white"
                data-bs-target="#crediter"
                data-bs-toggle="modal"
              >
                Créditer
              </button>
              {DecodeToken().profil === "ROLE_ADMIN" ? <button
                onClick={() => getUserId(item.id)}
                className="btn bg-primary btn-sm text-white mx-3"
                data-bs-target="#debiter"
                data-bs-toggle="modal"
              >
                Débiter
              </button> : null}
              {DecodeToken().profil === "ROLE_ADMIN" ? (
                <button
                  onClick={() => getUserIdForUpdate(item)}
                  className="btn bg-success btn-sm text-white"
                  data-bs-target="#update-credit"
                  data-bs-toggle="modal"
                >
                  <i className="fa fa-pen text-white"></i>
                </button>
              ) : null}
              {/* <button
                onClick={() => getUserId(item.id)}
                className="btn bg-danger btn-sm text-white"
                data-bs-target="#crediter"
                data-bs-toggle="modal"
              >
                <i className="fa fa-trash text-white"></i>
              </button> */}
            </div>
          </td>
        )}
      </tr>
    ));

  const rowListeDecaissement =
    listeDecaissement.decaissements !== undefined &&
    listeDecaissement.decaissements.map((item, index) => (
      <tr key={index}>
        <td>{item.Montant}F CFA</td>
        <td>{item.date}</td>
      </tr>
    ));

  useEffect(() => {
    ListeSoldePortefeuille(pageActive);
    ListeSoldeDecaissement(pageActiveDecaissement);
    soldeTotalPortefeuille();
    afficherEntreprise();
  }, []);

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_ADMIN" &&
        DecodeToken().profil !== "ROLE_COMPTABLE" &&
        DecodeToken().profil !== "ROLE_CAISSIERE_ENTREPRISE" && (
          <Navigate to="/login" replace />
        )}
      <HeaderNomResto />
      {DecodeToken().profil !== "ROLE_COMPTABLE" && (
        <>
          <p className="mb-3 mt-3 fw-bold">Portefeuille</p>
          <div>
            <div className="bg-white rounded p-2">
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <div className="input-group bg-gris rounded mt-1 mb-3">
                    <div className="input-group-text">
                      <i className="fa fa-search text-secondary"></i>
                    </div>
                    <input
                      type="search"
                      onChange={(e) => handleSearchPortefeuille(e.target.value)}
                      placeholder="Recherche..."
                      name=""
                      id=""
                      className="form-control bg-gris form-control-sm loginborderleft"
                    />
                  </div>
                  <div className="input-group bg-gris rounded mt-1 mb-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={filtrePortefeuilleParEntreprise}
                    >
                      <option value="" selected>
                        Entreprise
                      </option>
                      {listeEntreprise.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.raisonSociale}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr className="gray-table rounded">
                    <th width="15%">Nom prenoms </th>
                    <th width="15%">Téléphone</th>
                    <th width="15%">Entreprise</th>
                    <th width="7%">Solde</th>
                    {(DecodeToken().profil === "ROLE_ADMIN" ||
                      DecodeToken().profil === "ROLE_COMPTABLE") && (
                      <th width="14%">Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>{!loading && rowListeEntreprise}</tbody>
                <tfoot>
                  <tr>
                    <th colSpan="6">
                      <div className="d-flex justify-content-center">
                        <Paginate
                          pageActive={pageActive}
                          handlePageChange={handlePageChange}
                          nbreElementParPage={totalElementByPage}
                          nbreTotalElmentPage={totalElement}
                        />
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
              {
                <div className="d-flex justify-content-center">
                  <Oval
                    width={40}
                    height={40}
                    color="#CE5D1D"
                    visible={loading}
                  />
                </div>
              }
            </div>
            {/* Début modal créditer */}
            <div
              className="modal fade"
              id="crediter"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md modal-dialog-centered ">
                <div className="modal-content bg-rose">
                  <div className="modal-header">
                    <h5 className="modal-title fw-bold">
                      Créditer cet utilisateur
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="row gx-1">
                      <div className="mb-3">
                        <label htmlFor="montant" className="label-control">
                          Montant
                        </label>
                        <input
                          value={montant}
                          onChange={(e) => setMontant(e.target.value)}
                          type="number"
                          name="montant"
                          id="montant"
                          placeholder="Entrer le montant"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="mb-3 d-flex justify-content-between mx-2 my-3  ">
                      <button
                        type="button"
                        className="btn btn-dark"
                        id="closeForm"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <Oval
                        width={40}
                        height={40}
                        color="#CE5D1D"
                        visible={loading}
                      />
                      {!loading && (
                        <button
                          className="btn text-white btn-rose"
                          onClick={addCredit}
                        >
                          <i className="bi bi-check-circle-fill"></i>{" "}
                          Enregistrer
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Fin modal créditer */}

            {/* Début modal débiter */}
            <div
              className="modal fade"
              id="debiter"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md modal-dialog-centered ">
                <div className="modal-content bg-rose">
                  <div className="modal-header">
                    <h5 className="modal-title fw-bold">
                      Débiter cet utilisateur
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="row gx-1">
                      <div className="mb-3">
                        <label htmlFor="montant" className="label-control">
                          Montant
                        </label>
                        <input
                          value={montant}
                          onChange={(e) => setMontant(e.target.value)}
                          type="number"
                          name="montant"
                          id="montant"
                          placeholder="Entrer le montant"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="mb-3 d-flex justify-content-between mx-2 my-3  ">
                      <button
                        type="button"
                        className="btn btn-dark"
                        id="closeFormDebit"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <Oval
                        width={40}
                        height={40}
                        color="#CE5D1D"
                        visible={loading}
                      />
                      {!loading && (
                        <button
                          className="btn text-white btn-rose"
                          onClick={debiterCredit}
                        >
                          <i className="bi bi-check-circle-fill"></i>{" "}
                          Enregistrer
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Fin modal débiter */}

            {/* Début modal update crédit */}
            <div
              className="modal fade"
              id="update-credit"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md modal-dialog-centered ">
                <div className="modal-content bg-rose">
                  <div className="modal-header">
                    <h5 className="modal-title fw-bold">Modifier le crédit</h5>
                  </div>
                  <div className="modal-body">
                    <div className="row gx-1">
                      <div className="mb-3">
                        <label htmlFor="montant" className="label-control">
                          Montant
                        </label>
                        <input
                          value={updateMontant}
                          onChange={(e) => setUpdateMontant(e.target.value)}
                          type="number"
                          name="montant"
                          id="montant"
                          placeholder="Entrer le montant"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="mb-3 d-flex justify-content-between mx-2 my-3  ">
                      <button
                        type="button"
                        className="btn btn-dark"
                        id="closeUpdateCreditForm"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <Oval
                        width={40}
                        height={40}
                        color="#CE5D1D"
                        visible={loading}
                      />
                      {!loading && (
                        <button
                          className="btn text-white btn-rose"
                          onClick={updateCredit}
                        >
                          <i className="bi bi-check-circle-fill"></i> Modifier
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Fin modal update crédit */}
          </div>
        </>
      )}
      {DecodeToken().profil === "ROLE_COMPTABLE" && (
        <>
          <div>
            {/* <nav className="nav">
              <div
                className="nav-link el active"
                aria-current="page"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("portefeuille");
                  setContext("Portefeuille");
                  document.querySelector(".el").classList.add("active");
                  document.querySelector(".f").classList.remove("active");
                }}
              >
                Portefeuille
              </div>
              <div
                className="nav-link f"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComposant("decaissement");
                  setContext("Décaissement");
                  document.querySelector(".f").classList.add("active");
                  document.querySelector(".el").classList.remove("active");
                }}
              >
                Décaissement
              </div>
            </nav> */}
            <div className="d-flex justify-content-between">
              <p className="mb-3 mt-3 fw-bold">{context}</p>
              {composant === "portefeuille" && (
                <p className="mb-3 mt-3 fw-bold">
                  Montant total portefeuille : {soldeTotal}F CFA
                </p>
              )}
            </div>
            {composant === "portefeuille" && (
              <div>
                <div className="bg-white rounded p-2">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <div className="input-group bg-gris rounded mt-1 mb-3">
                        <div className="input-group-text">
                          <i className="fa fa-search text-secondary"></i>
                        </div>
                        <input
                          type="search"
                          onChange={(e) =>
                            handleSearchPortefeuille(e.target.value)
                          }
                          placeholder="Recherche..."
                          name=""
                          id=""
                          className="form-control bg-gris form-control-sm loginborderleft"
                        />
                      </div>
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr className="gray-table rounded">
                        <th width="20%">Nom prenoms </th>
                        <th width="15%">Téléphone</th>
                        <th width="15%">Entreprise</th>
                        <th width="10%">Solde</th>
                        {(DecodeToken().profil === "ROLE_ADMIN" ||
                          DecodeToken().profil === "ROLE_COMPTABLE") && (
                          <th width="10%">Actions</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>{!loading && rowListeEntreprise}</tbody>
                    <tfoot>
                      <tr>
                        <th colSpan="6">
                          <div className="d-flex justify-content-center">
                            <Paginate
                              pageActive={pageActive}
                              handlePageChange={handlePageChange}
                              nbreElementParPage={totalElementByPage}
                              nbreTotalElmentPage={totalElement}
                            />
                          </div>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="d-flex justify-content-center">
                    <Oval
                      width={40}
                      height={40}
                      color="#CE5D1D"
                      visible={loading}
                    />
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="crediter"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-md modal-dialog-centered ">
                    <div className="modal-content bg-rose">
                      <div className="modal-header">
                        <h5 className="modal-title fw-bold">
                          Créditer cet utilisateur
                        </h5>
                      </div>
                      <div className="modal-body">
                        <div className="row gx-1">
                          <div className="mb-3">
                            <label htmlFor="montant" className="label-control">
                              Montant
                            </label>
                            <input
                              value={montant}
                              onChange={(e) => setMontant(e.target.value)}
                              type="number"
                              name="montant"
                              id="montant"
                              placeholder="Entrer le montant"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-between mx-2 my-3  ">
                          <button
                            type="button"
                            className="btn btn-dark"
                            id="closeForm"
                            data-bs-dismiss="modal"
                          >
                            Annuler
                          </button>
                          <Oval
                            width={40}
                            height={40}
                            color="#CE5D1D"
                            visible={loading}
                          />
                          {!loading && (
                            <button
                              className="btn text-white btn-rose"
                              onClick={addCredit}
                            >
                              <i className="bi bi-check-circle-fill"></i>{" "}
                              Enregistrer
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {composant === "decaissement" && (
              <>
                <div className="bg-white rounded px-2 py-3 position-relative">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <div className="input-group bg-gris rounded mt-1 mb-3">
                        <div className="input-group-text">
                          <i className="fa fa-search text-secondary"></i>
                        </div>
                        <input
                          type="search"
                          onChange={(e) =>
                            handleSearchPortefeuille(e.target.value)
                          }
                          placeholder="Recherche..."
                          name=""
                          id=""
                          className="form-control bg-gris form-control-sm loginborderleft"
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-sm btn-rose text-white"
                      data-bs-target="#decaissement"
                      data-bs-toggle="modal"
                    >
                      AJOUTER
                    </button>
                  </div>
                  <table className="table">
                    <thead>
                      <tr className="gray-table rounded">
                        <th width="20%">Montant</th>
                        <th width="15%">Date d'ajout</th>
                      </tr>
                    </thead>
                    <tbody>{!loading && rowListeDecaissement}</tbody>
                    <tfoot>
                      <tr>
                        <th colSpan="6">
                          <div className="d-flex justify-content-center">
                            <Paginate
                              pageActive={pageActive}
                              handlePageChange={handlePageChangeDecaissement}
                              nbreElementParPage={
                                totalElementDecaissementByPage
                              }
                              nbreTotalElmentPage={totalElementDecaissement}
                            />
                          </div>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                {/* FORMULAIRE D'AJOUT D'UN DECAISSEMENT */}
                <div
                  className="modal fade"
                  id="decaissement"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-md modal-dialog-centered ">
                    <div className="modal-content bg-rose">
                      <div className="modal-header">
                        <h5 className="modal-title fw-bold">
                          Ajouter un décaissement
                        </h5>
                      </div>
                      <div className="modal-body">
                        <div className="row gx-1">
                          <div className="mb-3">
                            <label
                              htmlFor="montantDecaissement"
                              className="label-control"
                            >
                              Montant
                            </label>
                            <input
                              value={montantDecaissement}
                              onChange={(e) =>
                                setMontantDecaissement(e.target.value)
                              }
                              type="number"
                              name="montantDecaissement"
                              id="montantDecaissement"
                              placeholder="Entrer le montant"
                              className="form-control"
                            />
                            {error && <span>{error}</span>}
                          </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-between my-3  ">
                          <button
                            type="button"
                            className="btn btn-dark"
                            id="reinitialiseFormEntreprise"
                            data-bs-dismiss="modal"
                          >
                            Annuler
                          </button>
                          <Oval
                            width={40}
                            height={40}
                            color="#CE5D1D"
                            visible={loading}
                          />
                          {!loading && (
                            <button
                              onClick={handleSubmit}
                              className="btn text-white btn-rose"
                            >
                              {" "}
                              <i className="bi bi-check-circle-fill"></i>{" "}
                              Enregistrer
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Layout>
  );
}

export default Portefeuille;
