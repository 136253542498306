import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

const NB_ELEMENT = 10;
export const rechercherversements = async(page)=>{
    return await axios.get(baseUrl("rechercherversements?page="+page+"&size="+NB_ELEMENT))
}

export const rechercherversementsadmin = async(page)=>{
    return await axios.get(baseUrl("admin/rechercherversements?page="+page+"&size="+NB_ELEMENT))
}

export const enregistrerversement = async(values)=>{
    return await axios.post(baseUrl("enregistrerversement"), values)
}

export const rechercherresteaverser = async(values)=>{
    return await axios.post(baseUrl("rechercherresteaverser"), values)
}

export const enregistrerversementvendeuse = async(values)=>{
    return await axios.post(baseUrl('admin/enregistrerversementvendeuse'), values);
}

export const rechercherentreprisespartenaires = async()=>{
    return await axios.get(baseUrl('restauratrice/rechercherentreprisespartenaires'));
}

