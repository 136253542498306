import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import {
  rechercherCommandeProcessus,
  rechercherImageParCommandeProcessus,
  rechercherPlatParCommandeProcessus,
} from "../../services/commandes/CommandeRequest";

import moment from "moment";
import Paginate from "../pagination";
import { returnImageLink } from "../ReturnImageLink";
import { DecodeToken } from "../../DecodeToken";

function CommandProccess(props) {
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [pageActive, setPageActive] = useState(1);
  const [listeCommandes, setlisteCommandes] = useState([]);
  const [commandeDetail, setCommandeDetail] = useState([]);
  const [imageCommande, setImageCommande] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadingDetail, setloadingDetail] = useState(false);
  let [dataFiltre, setdataFiltre] = useState({
    employeId: "",
    entrepriseId: "",
    repasId: "",
    vendeuseId: "",
    restaurantId: "",
    statut: "",
    modeRecuperation: "",
  });

  // RECUPERE LA LISTE DES COMMANDES
  const getListeDesCommandesProcessus = (page, filter) => {
    setloading(true);

    const periode = moment(props.jourSelected)
      .format("DD-MM-yyyy")
      .split("-")
      .reverse()
      .join("-");

    rechercherCommandeProcessus(page - 1, periode, filter)
      .then((res) => {
        setloading(false);
        setlisteCommandes(res.data.donnee.commandes);
        settotalElement(res.data.donnee.totalElements);
        setTotalElementByPage(res.data.donnee.numberOfElements);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  // RECUPERE LES DETAIL DE COMMANDE
  const getDetailDeCommande = (commandeId) => {
    setloadingDetail(false);
    rechercherPlatParCommandeProcessus(commandeId)
      .then((res) => {
        setloadingDetail(false);
        setCommandeDetail(res.data.donnee);
      })
      .catch((error) => {
        setloadingDetail(false);
      });
  };

  // RECUPERE PHOTO DE PLAT COMMANDE
  const getImagesDeCommande = (commandeId) => {
    rechercherImageParCommandeProcessus(commandeId)
      .then((res) => {
        setImageCommande(res.data.donnee);
      })
      .catch((error) => {
        console.log("++", error);
      });
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    const filter = {
      etat: props.statut,
      modeRecuperation: props.modeRecuperation,
    }
    getListeDesCommandesProcessus(pageNumber, filter);
  };

  const retournerStatut = (statut) => {
    if (statut === "VALIDE") {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "#2691a7",
            color: "white",
            borderRadius: "3px",
            fontSize: "12px",
          }}
        >
          Validé
        </span>
      );
    } else if (statut === "INCOMPLET") {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "#ffcc00",
            color: "white",
            borderRadius: "3px",
            fontSize: "12px",
          }}
        >
          Incomplet
        </span>
      );
    } else if (statut === "ATTENTE") {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "gray",
            color: "white",
            borderRadius: "3px",
            fontSize: "12px",
          }}
        >
          En attente
        </span>
      );
    } else if (statut === "SERVIT") {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "3px",
            fontSize: "12px",
          }}
        >
          Servit
        </span>
      );
    } else {
      return (
        <span
          className="p-1"
          style={{
            backgroundColor: "#ffcc00",
            color: "white",
            borderRadius: "3px",
            fontSize: "12px",
          }}
        >
          Invalide
        </span>
      );
    }
  };

  useEffect(() => {
    const filter = {
      etat: props.statut !== null ? props.statut.valeur : "",
      modeRecuperation: props.modeRecuperation !== null ? props.modeRecuperation.value : "",
      restaurantId: props.restaurantParent ? props.restaurantParent.id : "",
    }
    getListeDesCommandesProcessus(pageActive, filter);
  }, [
    props.jourSelected,
    props.modeRecuperation,
    props.restaurantParent,
    props.statut,
  ]);

  const rowListeCommandes = listeCommandes.map((item, index) => (
    <tr
      key={index}
      style={item.isHorDelai ? { backgroundColor: "#ffc4c4" } : {}}
    >
      <td>{item.identifiant ? item.identifiant : "-"}</td>
      <td>{item.employe ? item.employe : "-"}</td>
      <td>{item.numeroDestinataire ? item.numeroDestinataire : "-"}</td>
      <td>{item.addresseLivraison ? item.addresseLivraison : "-"}</td>
      <td>{item.prix ? item.prix : "0"}F CFA</td>
      <td>{item.heureCommande ? item.heureCommande : "-"}</td>
      <td>{item.heureRepasServi ? item.heureRepasServi : "-"}</td>
      <td>{item.heureValidation ? item.heureValidation : "-"}</td>
      <td style={!item.commandeTraiteDansLeTemps ? { color: "red" } : {}}>
        {item.tempsTraitement ? item.tempsTraitement : "-"}
      </td>
      <td>{item.delaiPrevu ? item.delaiPrevu : "-"}</td>
      <td>{retournerStatut(item.etat)}</td>
      <td>
        <div
          className="p-1 cursor btn-success text-white rounded text-center"
          data-bs-target="#detailCommande"
          data-bs-toggle="modal"
          onClick={() => {
            getDetailDeCommande(item.identifiant);
            getImagesDeCommande(item.identifiant);
          }}
        >
          Détail
        </div>
      </td>
    </tr>
  ));

  const rowListePlats = commandeDetail.map((item, index) => (
    <tr key={index}>
      <td>{item.designationRepas}</td>
      <td>{item.quantite}</td>
      <td>{item.prix * item.quantite}F CFA</td>
    </tr>
  ));

  return (
    <>
      <table className="table x-scroll">
        <thead>
          <tr className="gray-table rounded">
            <th style={{ fontSize: 14 }}>N° commande</th>
            <th style={{ fontSize: 14 }}>Employé</th>
            <th style={{ fontSize: 14 }}>Numéro destinataire</th>
            <th style={{ fontSize: 14 }}>Adresse de livraison</th>
            <th style={{ fontSize: 14 }}>Montant</th>
            <th style={{ fontSize: 14 }}>Date commande</th>
            <th style={{ fontSize: 14 }}>Repas servi à</th>
            <th style={{ fontSize: 14 }}>Heure de validation</th>
            <th style={{ fontSize: 14 }}>temps de traitement</th>
            <th style={{ fontSize: 14 }}>délai prévu</th>
            <th style={{ fontSize: 14 }}>Statut</th>
            <th style={{ fontSize: 14 }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!loading && listeCommandes.length !== 0 ? rowListeCommandes : null}
        </tbody>
        <tbody>
          {!loading && listeCommandes.length === 0 ? (
            <tr colspan="12">
              <td colspan="12">
                <p className="text-center text-danger mt-3">
                  Aucun élément trouvé
                </p>
              </td>
            </tr>
          ) : null}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan="12">
              <div className="d-flex justify-content-center">
                <Paginate
                  pageActive={pageActive}
                  handlePageChange={handlePageChange}
                  nbreElementParPage={totalElementByPage}
                  nbreTotalElmentPage={totalElement}
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
      <div className="d-flex justify-content-center">
        <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
      </div>

      <div
        className="modal fade"
        id="detailCommande"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content bg-rose">
            <div className="p-2 d-flex justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeBtn"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <h6
                  className="text-left"
                  style={{ fontSize: 21, marginTop: "-30px" }}
                >
                  Plats commandées
                </h6>
              </div>

              <div className="bg-white p-2" style={{ borderRadius: 8 }}>
                <p>Image(s) des plats</p>
                {imageCommande.length === 0 ? (
                  <p className="text-center text-danger mt-2">
                    Aucune image trouvée
                  </p>
                ) : null}
                {imageCommande.length !== 0
                  ? imageCommande.map((item, index) => (
                      <img
                        key={index}
                        src={returnImageLink(
                          item.visuelId ? item.visuelId : ""
                        )}
                        width="110"
                        height="110"
                        className="rounded"
                        alt="Image plat"
                        style={{ marginRight: "8px", objectFit: "cover" }}
                      />
                    ))
                  : null}
              </div>
              <div className="bg-white p-2 mt-2" style={{ borderRadius: 8 }}>
                <p>Liste des plats</p>
                <table className="table border">
                  <thead>
                    <tr className="gray-table rounded">
                      <th>Plat</th>
                      <th>Quantité</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>{!loading && rowListePlats}</tbody>
                </table>
                <div className="d-flex justify-content-center">
                  <Oval
                    width={40}
                    height={40}
                    color="#CE5D1D"
                    visible={loadingDetail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommandProccess;
