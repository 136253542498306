import React, { useState } from 'react'
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as yup from 'yup';
import { enregistrerentreprise } from '../../services/entreprises/entrepriseRequest';
import { Oval } from 'react-loader-spinner'


function AddEntreprise(props) {
    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");

    const initialValues = {
        id: props.infoentreprise.id !== undefined ? props.infoentreprise.id : "",
        raisonSociale: props.infoentreprise.raisonSociale !== undefined ? props.infoentreprise.raisonSociale : "",
        adresse: props.infoentreprise.adresse !== undefined ? props.infoentreprise.adresse : "",
        email: props.infoentreprise.email !== undefined ? props.infoentreprise.email : "",
        numero: props.infoentreprise.numero !== undefined ? props.infoentreprise.numero : "",
        enabledProcessCommandeServi: props.infoentreprise.enabledProcessCommandeServi !== undefined ? props.infoentreprise.enabledProcessCommandeServi : "",
    }

    const validationSchemaValues = yup.object().shape({
        raisonSociale: yup.string().required("Veuillez saisir la raison sociale"),
        adresse: yup.string().required("Veuillez saisir l'adresse de l'entreprise"),
        email: yup.string().required("Veuillez saisir l'adresse email de l'entreprise").email("Veuillez saisir une adresse email valide"),
        numero: yup.number().required("Veuillez saisir le numéro de l'entreprise")
    });

    const onSubmitForm = (values) => {
        setloading(true);
        // console.log('values',values)
        setmessageError("")
        enregistrerentreprise(values).then(() => {
            setloading(false);
            setmessageError("")
            props.actualisteListe();
            document.getElementById('reinitialiseFormEntreprise').click();
        }).catch((error) => {
            setloading(false);
            messageError = error.response.data.donnee
            setmessageError(error.response.data.donnee)
        })
    }

    return (
        <div className="modal fade" id="entreprise" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered ">
                <div className="modal-content bg-rose">

                    <div className="modal-header">
                        <h5 className="modal-title fw-bold">Ajouter une entreprise</h5>
                    </div>
                    <div className="modal-body">

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchemaValues}
                            onSubmit={onSubmitForm}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <span className='text-danger'>{messageError}</span>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="raisonSociale" className='label-control'>Raison sociale</label>
                                            <Field type="text" name="raisonSociale" id="raisonSociale" placeholder="Entrer le nom d'entreprise" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='raisonSociale'></ErrorMessage></span>
                                        </div>

                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="adresse" className='label-control'>Adresse</label>
                                            <Field type="text" name="adresse" id="adresse" placeholder="Entrer l'adresse" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='adresse'></ErrorMessage></span>
                                        </div>

                                    </div>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="email" className='label-control'>Email</label>
                                            <Field type="email" name="email" id="email" placeholder="Entrer l'email entreprise" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='email'></ErrorMessage></span>
                                        </div>

                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="numero" className='label-control'>Numéro</label>
                                            <Field type="text" name="numero" id="numero" placeholder="Entrer le numéro" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='numero'></ErrorMessage></span>
                                        </div>

                                    </div>
                                    <div className=''>
                                        <label className='label-contro'>
                                            <Field type="checkbox" name="enabledProcessCommandeServi" id="processCommande" />
                                            <span className='fw-normal' style={{ fontSize: "18px" }}>Processus commande</span>
                                        </label>
                                        <span className='text-danger'><ErrorMessage name='enabledProcessCommandeServi'></ErrorMessage></span>
                                    </div>
                                    <div className='mb-3 d-flex justify-content-between mx-2 my-3 mt-4'>
                                        <button type='button' className='btn btn-dark' id='reinitialiseFormEntreprise' data-bs-dismiss="modal" onClick={resetForm} >Annuler</button>
                                        <Oval
                                            width={40}
                                            height={40}
                                            color="#CE5D1D"
                                            visible={loading}
                                        />
                                        {!loading && <button type='submit' className='btn text-white btn-rose'> <i className="bi bi-check-circle-fill"></i>  Enregistrer</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddEntreprise