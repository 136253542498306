import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { DecodeToken } from "../../DecodeToken";
import { Navigate } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import AddCaractereSubvention from "./AddCaractereSubvention";
import Paginate from "../pagination";
import {
  rechercherCaractereSubvention,
  supprimerCaractereSubvention,
} from "../../services/parametre/ParametreRequest";
import AddQuestion from "./AddQuestion";
import AddReponse from "./AddReponse";
import {
  deleteQuestionFromList,
  getListeQuestionnaire,
} from "../../services/questionnaire/QuestionnaireRequest";

function Questionnaire() {
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [pageActive, setPageActive] = useState(1);
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [infoQuestion, setInfoQuestion] = useState("");
  const [questionIdToDelete, setQuestionIdToDelete] = useState("");
  const [listeQuestion, setListeQuestion] = useState([]);

  let rowListeQuestion = listeQuestion.map((question, index) => (
    <tr key={index}>
      <td>{question.designation ? question.designation : ""} </td>
      <td>{question.type == 0 ? "Select" : "Champ de saisi"}</td>
      {/* <td>
        {question.choix && question.choix.length !== 0
          ? question.choix.length
          : "0"}
      </td> */}
      <td>
        <button
          data-bs-target="#caratereForm"
          data-bs-toggle="modal"
          onClick={() => setInfoQuestion(question)}
          className="btn btn-sm bg-gris mx-1"
        >
          <i className="fa fa-pencil"></i> Modifier
        </button>
        {question.type == 0 ? (
          <button
            data-bs-target="#addreponse"
            data-bs-toggle="modal"
            onClick={() => setInfoQuestion(question)}
            className="btn btn-sm bg-gris mx-1"
          >
            <i className="fa fa-pencil"></i> Réponses
          </button>
        ) : null}
        <button
          onClick={() => supprimerQuestionFromListe(question)}
          className="btn bg-danger btn-sm text-white mx-1"
        >
          Supprimer
        </button>
      </td>
    </tr>
  ));

  const supprimerQuestionFromListe = (question) => {
    setQuestionIdToDelete(question.id);
    setShowSweetAlert(true);
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
  };

  const afficherListeQuestion = (currentPage) => {
    setLoading(true);
    getListeQuestionnaire(currentPage - 1)
      .then((res) => {
        setLoading(false);
        setListeQuestion(res.data.donnee.questionnaires);
        setTotalElementByPage(res.data.donnee.numberOfElements);
        settotalElement(res.data.donnee.totalElements);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteQuestion = (questionId) => {
    deleteQuestionFromList(questionId).then((res) => {
      setShowSweetAlert(false);
      afficherListeQuestion(pageActive);
    });
  };

  useEffect(() => {
    afficherListeQuestion(pageActive);
  }, [pageActive]);

  useEffect(() => {
    afficherListeQuestion(pageActive);
  }, []);

  return (
    <div className="w-100">
      {DecodeToken().profil !== "ROLE_ADMIN" && (
        <Navigate to="/login" replace />
      )}
      <div className="d-flex justify-content-between">
        <div className="">
          <div className="input-group bg-gris rounded mt-1 mb-3" hidden>
            <div className="input-group-text">
              <i className="fa fa-search text-secondary"></i>
            </div>
            <input
              type="search"
              // onChange={handlFiltre}
              placeholder="Recherche..."
              name=""
              id=""
              className="form-control bg-gris form-control-sm loginborderleft"
            />
          </div>
        </div>
        <div className="mb-2">
          <button
            className="btn btn-sm btn-rose text-white"
            data-bs-target="#caratereForm"
            data-bs-toggle="modal"
            onClick={() => setInfoQuestion("")}
          >
            AJOUTER
          </button>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr className="gray-table rounded">
            <th>Question</th>
            <th width="20%">Type</th>
            {/* <th width="20%">Nombre réponse</th> */}
            <th width="35%">Actions</th>
          </tr>
        </thead>
        <tbody>{!loading && rowListeQuestion}</tbody>
        <tfoot>
          <tr>
            <th colSpan="3">
              <div className="d-flex justify-content-center">
                <Paginate
                  pageActive={pageActive}
                  handlePageChange={handlePageChange}
                  nbreElementParPage={totalElementByPage}
                  nbreTotalElmentPage={totalElement}
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
      <div className="d-flex justify-content-center">
        <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
      </div>

      {/* Ajout question */}
      <AddQuestion
        infoQuestion={infoQuestion}
        actualiserliste={afficherListeQuestion}
      />

      {/* Ajout reponses */}
      <AddReponse
        infoQuestion={infoQuestion}
        actualiserliste={afficherListeQuestion}
      />

      <SweetAlert
        show={showSweetAlert}
        title="Confirmer la suppression"
        text="Cette action est irréversible"
        showCancelButton
        onConfirm={() => deleteQuestion(questionIdToDelete)}
        onCancel={() => {
          setShowSweetAlert(false);
        }}
        onEscapeKey={() => setShowSweetAlert(false)}
        onOutsideClick={() => setShowSweetAlert(false)}
      />
    </div>
  );
}

export default Questionnaire;
