import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import {
  addSubventionEmployes,
  deleteSubventionEmployes,
  getSubventionEmployes,
} from "../../services/employes/EmployesRequest";

function AddSubvention(props) {
  const [loading, setLoading] = useState(false);
  const [typeDeRepas, setTypeDeRepas] = useState([
    { idrepas: 1, titre: "Petit-déjeuner", value: "PETIT_DEJEUNER" },
    { idrepas: 2, titre: "Déjeuner", value: "DEJEUNER" },
    { idrepas: 3, titre: "Diner", value: "DINER" },
  ]);
  const [typesubvention, setTypesubvention] = useState([
    { typeid: 1, option: "FIXE", titre: "Valeur fixe" },
    { typeid: 2, option: "POURCENTAGE", titre: "Pourcentage" },
  ]);
  const [listeSubvention, setListeSubvention] = useState([]);

  const initialValues = {
    employeId: props.detailEmploye.id,
    id: "0",
    subvention: "",
    typeSubvention: "POURCENTAGE",
    instant: "",
    nombreutilisation: "",
  };

  const validationSchemaValues = yup.object().shape({
    subvention: yup
      .number("Veuillez saisir des chiffres")
      .required("Veuillez saisir la subvention"),
    instant: yup.string().required("Veillez choisir le repas"),
    typeSubvention: yup
      .string()
      .required("Veillez choisir le type de subvention"),
    nombreutilisation: yup
      .number("Veuillez saisir des chiffres")
      .required("Veuillez saisir le nombre d'utilisation"),
  });

  const getListeSubventionEmploye = (employeId) => {
    getSubventionEmployes(employeId)
      .then((res) => {
        setListeSubvention(res.data.donnee);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteSubvention = (idSub) => {
    deleteSubventionEmployes(idSub)
      .then((res) => {
        getListeSubventionEmploye(props.detailEmploye.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitForm = (values) => {
    setLoading(true);
    console.log(values);
    addSubventionEmployes(values)
      .then((res) => {
        setLoading(false);
        getListeSubventionEmploye(props.detailEmploye.id);
        document.getElementById("reinitialiseAddSubvention").click();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const clearForm = () => {
    document.getElementById("reinitialiseAddSubvention").click();
    setLoading(false);
  };

  useEffect(() => {
    getListeSubventionEmploye(props.detailEmploye.id);
  }, [props.detailEmploye.id]);

  return (
    <div
      className="modal fade"
      id="addsubvention"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content ">
          <div className="d-flex modal-header justify-content-between p-2">
            <div></div>
            <div>
              <h6>Ajouter des subvention</h6>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="CloseAddSubvention"
                onClick={clearForm}
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center w-100">
              <div className="w-50">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchemaValues}
                  onSubmit={onSubmitForm}
                >
                  {({ resetForm, values }) => (
                    <Form>
                      <div className="mb-3">
                        <Field
                          type="text"
                          name="subvention"
                          id="subvention"
                          placeholder="Valeur subvention"
                          className="form-control"
                        />
                        <span className="text-danger">
                          <ErrorMessage name="subvention"></ErrorMessage>
                        </span>
                      </div>
                      <div className="mb-3">
                        <Field
                          as="select"
                          name="typeSubvention"
                          id="typeSubvention"
                          className="form-control"
                        >
                          <option selected disabled value="">
                            Type de subvention
                          </option>
                          {typesubvention.map((item, index) => (
                            <option value={item.option} key={index}>
                              {item.titre}
                            </option>
                          ))}
                        </Field>
                        <span className="text-danger">
                          <ErrorMessage name="typeSubvention"></ErrorMessage>
                        </span>
                      </div>
                      <div className="mb-3">
                        <Field
                          type="number"
                          name="nombreutilisation"
                          id="nombreutilisation"
                          placeholder="Nombre d'utilisation de la subvention"
                          className="form-control"
                        />
                        <span className="text-danger">
                          <ErrorMessage name="nombreutilisation"></ErrorMessage>
                        </span>
                      </div>
                      <div className="mb-3">
                        <Field
                          as="select"
                          name="instant"
                          id="instant"
                          className="form-control"
                        >
                          <option selected disabled value="">
                            Repas subventionné
                          </option>
                          <option value=""></option>
                          {typeDeRepas.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.titre}
                            </option>
                          ))}
                        </Field>
                        <span className="text-danger">
                          <ErrorMessage name="instant"></ErrorMessage>
                        </span>
                      </div>
                      <div className="mb-3 row mx-1 my-3  ">
                        <button
                          type="button"
                          id="reinitialiseAddSubvention"
                          onClick={resetForm}
                          hidden
                        ></button>
                        <Oval
                          width={40}
                          height={40}
                          color="#CE5D1D"
                          visible={loading}
                        />
                        {!loading && (
                          <button
                            type="submit"
                            className="btn text-white btn-rose"
                          >
                            Enregistrer
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="w-50 px-3">
                <h6 className="text-center">Subventions enregistées</h6>
                {listeSubvention.length !== 0 ? (
                  listeSubvention.map((item, index) => (
                    <div className="position-relative card bg-rose px-3 mt-2">
                      <div className="d-flex align-items-center">
                        <span>Repas subventionné :</span>
                        <span>{item.instant ? item.instant : "..."}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <span>Type de subvention :</span>
                        <span>
                          {item.typeSubvention ? item.typeSubvention : "..."}
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <span>Valeur subvention :</span>
                        <span>{item.subvention ? item.subvention : "..."}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <span>Nombre d'utilisation :</span>
                        <span>
                          {item.nombreutilisation
                            ? item.nombreutilisation
                            : "..."}
                        </span>
                      </div>
                      <div
                        className="position-absolute end-0 bottom-0 px-2 py-1 d-inline bg-danger text-white rounded"
                        onClick={() => deleteSubvention(item.id)}
                      >
                        <i className="fa fa-trash white"></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center mt-3">Aucun subvention ajouté</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubvention;
