import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import {
  addSubventionEmployes,
  deleteSubventionEmployes,
  getSubventionEmployes,
} from "../../services/employes/EmployesRequest";
import {
  deleteReponseFromList,
  enregistrerNewReponse,
  getListeReponse,
} from "../../services/questionnaire/QuestionnaireRequest";

function AddReponse(props) {
  const [loading, setLoading] = useState(false);
  const [listeReponse, setListeReponse] = useState([]);

  const initialValues = {
    questionId: props.infoQuestion.id,
    id: "0",
    reponse: "",
  };

  const validationSchemaValues = yup.object().shape({
    reponse: yup.string().required("Veuillez saisir une réponse"),
  });

  const getListeReponseQuestion = (questionId) => {
    getListeReponse(questionId)
      .then((res) => {
        setListeReponse(res.data.donnee);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteReponse = (idSub) => {
    deleteReponseFromList(idSub)
      .then((res) => {
        getListeReponseQuestion(props.infoQuestion.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitForm = (values) => {
    setLoading(true);
    const valuesToSubmit = {
      designation: values.reponse,
      questionId: props.infoQuestion.id,
    };

    enregistrerNewReponse(valuesToSubmit)
      .then((res) => {
        setLoading(false);
        getListeReponseQuestion(props.infoQuestion.id);
        document.getElementById("reinitialiseAddReponse").click();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const clearForm = () => {
    document.getElementById("reinitialiseAddReponse").click();
    setLoading(false);
  };

  useEffect(() => {
    getListeReponseQuestion(props.infoQuestion.id);
  }, [props.infoQuestion.id]);

  return (
    <div
      className="modal fade"
      id="addreponse"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content ">
          <div className="d-flex modal-header justify-content-between p-2">
            <div></div>
            <div>
              <h6>Ajouter des réponses</h6>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="CloseAddReponse"
                onClick={clearForm}
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center w-100">
              <div className="w-50">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchemaValues}
                  onSubmit={onSubmitForm}
                >
                  {({ resetForm, values }) => (
                    <Form>
                      <div className="mb-3">
                        <Field
                          type="text"
                          name="reponse"
                          id="reponse"
                          placeholder="Entrer une réponse"
                          className="form-control"
                        />
                        <span className="text-danger">
                          <ErrorMessage name="reponse"></ErrorMessage>
                        </span>
                      </div>
                      <div className="mb-3 row mx-1 my-3  ">
                        <button
                          type="button"
                          id="reinitialiseAddReponse"
                          onClick={resetForm}
                          hidden
                        ></button>
                        <Oval
                          width={40}
                          height={40}
                          color="#CE5D1D"
                          visible={loading}
                        />
                        {!loading && (
                          <button
                            type="submit"
                            className="btn text-white btn-rose"
                          >
                            Ajouter
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="w-50 px-3">
                <h6 className="text-center">Réponses ajoutées</h6>
                {listeReponse.length !== 0 ? (
                  <div style={{ overflowY: "scroll", height: 200 }}>
                    {listeReponse.map((item, index) => (
                      <div
                        className="position-relative card bg-rose px-3 mt-2"
                        style={{ height: 35 }}
                      >
                        <div className="d-flex align-items-center mt-1">
                          <span>
                            {item.designation ? item.designation : "..."}
                          </span>
                        </div>
                        <div
                          className="position-absolute end-0 bottom-0 d-inline bg-danger text-white rounded px-2 py-1"
                          style={{ fontSize: 14 }}
                          onClick={() => deleteReponse(item.id)}
                        >
                          <i className="fa fa-trash white"></i>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-center mt-3">Aucun réponse ajoutée</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddReponse;
