import { Autocomplete, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DecodeToken } from "../DecodeToken";
import { rechercherentreprises, rechercherentreprisespartenaires } from "../services/entreprises/entrepriseRequest";

function ListeDesEntreprise(props) {
  let [entrepriseSelected, setEntrepriseSelected] = useState(null);
  let [listeEntreprise, setListeEntreprise] = useState([]);

  const afficherEntreprise = () => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
      rechercherentreprises()
        .then((response) => {
          setListeEntreprise(response.data.donnee);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    } else {
      rechercherentreprisespartenaires().then((res) => {
        setListeEntreprise(res.data.donnee);
      });
    }
  };

  const handlePlatdayEntreprise = (values) => {
    entrepriseSelected = values;
    setEntrepriseSelected(entrepriseSelected);
    props.setDataEntrepriseSelected(entrepriseSelected);
  };

  useEffect(() => {
    afficherEntreprise();
  }, []);

  return (
    <div className=" d-flex justify-content-end">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={listeEntreprise}
        getOptionLabel={(option) => option.raisonSociale ?? option}
        value={entrepriseSelected}
        onChange={(event, value) => handlePlatdayEntreprise(value)}
        sx={{ width: props.width !== undefined ? props.width : 250 }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} className="bg-white" label="Entreprise" />
        )}
      />
    </div>
  );
}

export default ListeDesEntreprise;
