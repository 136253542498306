import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import Swal from "sweetalert2";
import { DecodeToken } from "../../DecodeToken";
import { enregistreModeSubvention, rechercherentreprises, rechercherModeSubvention } from "../../services/entreprises/entrepriseRequest";

function ModeSubvention() {
  const [loading, setloading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [listeEntreprise, setListeEntreprise] = useState([]);
  const [modeUtilisation, setModeUtilisation] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [messageError, setmessageError] = useState("");

  const listeModeUtilisation = [
    {
      id: 1,
      libelle: "Utilisation multiple",
      value: "MULTIPLE",
    },
    {
      id: 2,
      libelle: "Utilisation unique (une subvention par jour)",
      value: "UNE_PARJOUR",
    },
  ];

  const afficherEntreprise = () => {
    setloading(true);
    rechercherentreprises()
      .then((response) => {
        setloading(false);
        setListeEntreprise(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const rechercherModeSubventionParEntreprise = (entrepriseId) => {
    setloading(true);
    setModeUtilisation("")
    rechercherModeSubvention(entrepriseId)
      .then((response) => {
        setloading(false);
        if (response.data.donnee.mode) {
            setModeUtilisation(response.data.donnee.mode)
        } else {
            setModeUtilisation("")
        }
      })
      .catch(() => {
        setModeUtilisation("")
        setloading(false);
      });
  };

  const selectEntreprise = (value) => {
    setEntreprise(value)
    rechercherModeSubventionParEntreprise(value)
  }

  const saveModeSubvention = () => {
    setloading(true);
    setSubmitLoading(true)
    setmessageError("");

    if (DecodeToken().profil === "ROLE_ADMIN") {
      const modeSub = {
        entrepriseId: entreprise,
        mode: modeUtilisation,
      };

      enregistreModeSubvention(modeSub)
        .then(() => {
          setloading(false);
          setSubmitLoading(false)
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Mode de subvention enregistré avec succès !",
            showConfirmButton: false,
            timer: 1500,
          });
          setEntreprise("")
          setModeUtilisation("")
          setmessageError("");
        })
        .catch((error) => {
          setloading(false);
          setSubmitLoading(false)
          console.log(error);
          setmessageError(error.response.data.donnee);
        });
    } else {
      const modeSub = {
        entrepriseId: 0,
        mode: modeUtilisation,
      };

      enregistreModeSubvention(modeSub)
        .then(() => {
          setloading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Mode de subvention enregistré avec succès !",
            showConfirmButton: false,
            timer: 1500,
          });
          setmessageError("");
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
          setmessageError(error.response.data.donnee);
        });
    }
  };

  useEffect(() => {
    if (DecodeToken().profil === "ROLE_ADMIN") {
        afficherEntreprise();
    } else {
        rechercherModeSubventionParEntreprise(0)
    }
  }, []);

  return (
    <div className="px-5">
      <div>
        {DecodeToken().profil === "ROLE_ADMIN" ? (
          <select
            name="mode"
            id="mode"
            className="form-control"
            value={entreprise}
            onChange={(e) => selectEntreprise(e.target.value)}
          >
            <option defaultValue={null} disabled value="">
              Sélectionner une entreprise
            </option>
            <option value=""></option>
            {listeEntreprise.map((entreprise, index) => (
              <option value={entreprise.id} key={index}>
                {entreprise.raisonSociale}{" "}
              </option>
            ))}
          </select>
        ) : null}
        <select
          name="mode"
          id="mode"
          className="form-control mt-4"
          value={modeUtilisation}
          onChange={(e) => setModeUtilisation(e.target.value)}
        >
          <option defaultValue={null} disabled value="">
            Sélectionner un mode d'utilisation
          </option>
          <option value=""></option>
          {listeModeUtilisation.map((mode, index) => (
            <option value={mode.value} key={index}>
              {mode.libelle}{" "}
            </option>
          ))}
        </select>
        <p className="text-danger mt-2">{messageError}</p>
        <div className="mb-3 d-flex justify-content-end">
          {submitLoading ? (
            <Oval width={30} height={30} color="#CE5D1D" visible={submitLoading} />
          ) : (
            <button
              className="btn btn-rose text-white"
              onClick={saveModeSubvention}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModeSubvention;
