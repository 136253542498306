import React, { useState } from 'react'

function MinuteSeconde(props) {

    const [minuteselected, setminuteselected] = useState("");

    let listeMinute = [];
    for (let index = 0; index < 60; index++) {
        listeMinute[index] = index < 10 ? "0" + index : index
    }

    const rowOptionMinute = listeMinute.map((item, index) => (
        <option value={item} key={index}>{item}</option>
    ))

    const handleMinuteSelected = (value) =>{
        setminuteselected(value);
        if (props.type === "DEBUT") {
            props.setminuteDebut(value);
        }else{
            props.setminuteFin(value);
        }
    }

    return (
        <select name="" id="" className='form-control form-select p-2' value={minuteselected} onChange={(e)=>handleMinuteSelected(e.target.value)}>
            {rowOptionMinute}
        </select>
    )
}

export default MinuteSeconde