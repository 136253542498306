import { Formik, Field, ErrorMessage, Form } from 'formik';
import { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup';
import {enregistrerSite} from '../../services/entreprises/entrepriseRequest'
const AddSite=(props)=>{
    const [loading, setloading] = useState(false);
    let [err,setErr]=useState('')
    const initialValues = {
        localisation:props.infoSite.localisation !==undefined? props.infoSite.localisation: "",
        longitude:props.infoSite.longitude !==undefined? props.infoSite.longitude: "",
        latitude:props.infoSite.latitude !==undefined? props.infoSite.latitude: "",
        code:props.infoSite.code !==undefined? props.infoSite.code:"",
        nom:props.infoSite.nom !==undefined? props.infoSite.nom:"",
        entrepriseId: props.infoentreprise.id !==undefined ? props.infoentreprise.id:"",
    }

    const validationSchemaValues = yup.object().shape({
        localisation: yup.string().required("Veuillez saisir la localisation du site"),
        longitude: yup.number("Veuillez saisir un chiffe").required("Veuillez saisir la longitude du site"),
        latitude: yup.number("Veuillez saisir un chiffe").required("Veuillez saisir la latitude du site"),
        code: yup.string().required("Veuillez saisir le code du site"),
        entrepriseId: yup.string().required('Veuillez sélectionner une entreprise'),
        nom:yup.string().required("Veuillez entrer un le nom du site")
    });

    const onSubmitForm =(values)=>{
        //console.log(values);
        setErr('')
        enregistrerSite(Object.assign(values)).then(() => {
            setloading(false);
            setErr('')
            props.actualisteListe();
            document.getElementById('reinitialiseFormSite').click();
            document.getElementById('reinitialiseFormSites').click();
        }).catch((error) => {
            setloading(false);
            const message="Ce code est déjà utilisé !"
            setErr(message)
            //console.log(error.response);
        })
    }
    return(
        <>
             <div className="modal fade" id="site" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered ">
                <div className="modal-content bg-rose">

                    <div className="modal-header">
                        <h5 className="modal-title fw-bold">Ajouter un site</h5>
                    </div>
                    <div className="modal-body">
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchemaValues}
                            onSubmit={(values)=>onSubmitForm(values)}
                        >
                            {({ resetForm }) => (
                                <Form>

                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="nom" className='label-control'>Nom</label>
                                            <Field type="text" name="nom" id="nom" placeholder="Entrer le Nom du site" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='nom'></ErrorMessage></span>
                                        </div>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="localisation" className='label-control'>Localisation</label>
                                            <Field type="text" name="localisation" id="localisation" placeholder="Entrer la localisation du site" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='localisation'></ErrorMessage></span>
                                        </div>

                                        
                                    </div>
                                    <div className='row gx-1'>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="longitude" className='label-control'>Longitude</label>
                                            <Field type="number" name="longitude" id="longitude" placeholder="Entrer la longitude du site" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='longitude'></ErrorMessage></span>
                                        </div>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="latitude" className='label-control'>Latitude</label>
                                            <Field type="number" name="latitude" id="latitude" placeholder="Entrer la latitude du site" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='latitude'></ErrorMessage></span>
                                        </div>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="latitude" className='label-control'>Code</label>
                                            <Field type="text" name="code" id="code" placeholder="Entrer le code du site" className='form-control' />
                                            <span className='text-danger'><ErrorMessage name='code'></ErrorMessage></span>
                                            <div className="err" style={{color:'red'}} >{err}</div>
                                        </div>
                                        <div className='mb-3 col-md-6'>
                                            <label htmlFor="entrepriseId" className='label-control'>Entreprise</label>
                                            <Field as="select" name="entrepriseId" id="entrepriseId" className='form-control'>
                                                <option value=""></option>
                                                {
                                                    props.listeEntreprise.map((entreprise,index)=><option value={entreprise.id} key={index} >{entreprise.raisonSociale} </option>)
                                                }
                                                

                                            </Field>
                                            <span className='text-danger'><ErrorMessage name='entrepriseId'></ErrorMessage></span>
                                        </div>

                                    </div>
                                    <div className='mb-3 d-flex justify-content-between mx-2 my-3  '>
                                        <button type='button' className='btn btn-dark' id='reinitialiseFormSite' data-bs-dismiss="modal" onClick={resetForm} >Annuler</button>
                                        <Oval
                                            width={40}
                                            height={40}
                                            color="#CE5D1D"
                                            visible={loading}
                                        />
                                        {!loading && <button type='submit' className='btn text-white btn-rose' id='reinitialiseFormSites' > <i className="bi bi-check-circle-fill" ></i>  Enregistrer</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}
export default AddSite;