import React, { useEffect, useState } from "react";
import {
  exportdatastatistique,
  rechercherchiffreaffaire,
} from "../../services/statistiques/StatRequest";
import Zonefilter from "./Zonefilter";

function StatsComptable() {
  let [paramsFilter, setparamsFilter] = useState({
    annee: new Date().getFullYear(),
    mois: new Date().getMonth() + 1,
    restaurantId: "",
  });
  const [listeMois, setlisteMois] = useState([
    { indiceMois: "", libelle: "" },
    { indiceMois: 1, libelle: "Janvier" },
    { indiceMois: 2, libelle: "Février" },
    { indiceMois: 3, libelle: "Mars" },
    { indiceMois: 4, libelle: "Avril" },
    { indiceMois: 5, libelle: "Mai" },
    { indiceMois: 6, libelle: "Juin" },
    { indiceMois: 7, libelle: "Juillet" },
    { indiceMois: 8, libelle: "Août" },
    { indiceMois: 9, libelle: "Septembre" },
    { indiceMois: 10, libelle: "Octobre" },
    { indiceMois: 11, libelle: "Novembre" },
    { indiceMois: 12, libelle: "Décembre" },
  ]);
  const [loadingExport, setloadingExport] = useState(false);
  const [infoStatsComptable, setinfoStatsComptable] = useState({});
  let [mobileMois, setMobileMois] = useState(null);
  let [mobileGlobale, setMobileGlobale] = useState(null);
  const [portefeuilleMensuel, setPortefeuilleMensuel] = useState(null);
  const [portefeuilleGlobal, setPortefeuilleGlobal] = useState(null);
  const [resteAVerserCompteRestau, setResteAVerserCompteRestau] =
    useState(null);
  const [versementTotalCompteRestau, setversementTotalCompteRestau] =
    useState(null);

  const afficherchiffreaffaire = () => {
    rechercherchiffreaffaire(paramsFilter).then((res) => {
      mobileMois = res.data.donnee.montantMobileMoneyDuMois;
      mobileGlobale = res.data.donnee.montantMobileMoneyGobal;
      setResteAVerserCompteRestau(res.data.donnee.resteAverserCompteResto);
      setversementTotalCompteRestau(
        res.data.donnee.montantTotalVersementCompteResto
      );
      setMobileMois(mobileMois);
      setMobileGlobale(mobileGlobale);
      setinfoStatsComptable(res.data.donnee);
      setPortefeuilleMensuel(
        res.data.donnee.montantPortefeuilleDuMois !== undefined
          ? res.data.donnee.montantPortefeuilleDuMois
          : 0
      );
      setPortefeuilleGlobal(
        res.data.donnee.montantPortefeuilleGlobal !== undefined
          ? res.data.donnee.montantPortefeuilleGlobal
          : 0
      );
    });
  };

  const exportData = () => {
    setloadingExport(true);
    exportdatastatistique(paramsFilter)
      .then((res) => {
        setloadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Statistique_${
            listeMois.filter((item) => item.indiceMois == paramsFilter.mois)[0]
              .libelle
          }_${paramsFilter.annee}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setloadingExport(false);
      });
  };

  useEffect(() => {
    afficherchiffreaffaire();
  }, []);

  return (
    <>
      <div style={{ height: "128vh", overflowY: "hidden", overflow: "scroll" }}>
        <div className="d-flex align-items-center">
          <Zonefilter
            paramsFilter={paramsFilter}
            setparamsFilter={setparamsFilter}
            actualiseStat={afficherchiffreaffaire}
          />
          <div style={{ marginLeft: 10, marginTop: 16 }}>
            <button
              className="btn text-white"
              style={{ backgroundColor: "#CE5D1D" }}
              disabled={loadingExport}
              onClick={exportData}
            >
              Exporter
            </button>
          </div>
        </div>

        {/* ======= COMMANDES ======= */}
        <div
          className="row p-4 bg-white rounded"
          style={{ marginRight: "50px" }}
        >
          <div className="col-4">
            <span>Commandes subventionnés</span>
            <h6>{infoStatsComptable.nombreSubventionne}</h6>
          </div>
          <div
            className="col-4"
            style={{
              borderRight: " 2px solid #e5e5e5",
              borderLeft: " 2px solid #e5e5e5",
            }}
          >
            <span>Nombre de plats achetés du mois</span>
            <h6>{infoStatsComptable.nombre}</h6>
          </div>
          <div className="col-4">
            <span>Total à verser</span>
            <h6>{infoStatsComptable.montant}F CFA</h6>
          </div>
        </div>

        <div
          className="row p-4 bg-white rounded"
          style={{ marginRight: "30px", marginTop: "20px" }}
        >
          <div className="col-4">
            <span>
              Nombre de plats achetés jour :{" "}
              <span
                className="text-danger"
                style={{ fontWeight: 700 }}
              >
                {infoStatsComptable.nombrePlatVenduDuJour
                  ? infoStatsComptable.nombrePlatVenduDuJour
                  : "0"}
              </span>{" "}
            </span>
            <div className="py-1"></div>
            <span>
              Nombre de commandes jour :{" "}
              <span
                className="text-danger"
                style={{ fontWeight: 700 }}
              >
                {infoStatsComptable.nombreCommandeDuJour
                  ? infoStatsComptable.nombreCommandeDuJour
                  : "0"}
              </span>{" "}
            </span>
          </div>
          <div
            className="col-4"
            style={{
              borderRight: " 2px solid #e5e5e5",
              borderLeft: " 2px solid #e5e5e5",
            }}
          >
            <span>
              Nombre de commandes mois :{" "}
              <span
                className="text-danger"
                style={{ fontWeight: 500 }}
              >
                {infoStatsComptable.nombreCommandeDuMois
                  ? infoStatsComptable.nombreCommandeDuMois
                  : "0"}
              </span>
            </span>
          </div>
          <div className="col-4">
            <span>
              Nombre de plats achetés total :{" "}
              <span
                className="text-danger"
                style={{ fontWeight: 500 }}
              >
                {infoStatsComptable.nombrePlatVendu
                  ? infoStatsComptable.nombrePlatVendu
                  : "0"}
              </span>
            </span>
            <div className="py-1"></div>
            <span>
              Nombre de commandes total :{" "}
              <span
                className="text-danger"
                style={{ fontWeight: 700 }}
              >
                {infoStatsComptable.nombreCommande
                  ? infoStatsComptable.nombreCommande
                  : "0"}
              </span>{" "}
            </span>
          </div>
        </div>

        <div className="mt-5">
          <span className="fw-bold">Etats des subventions</span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Nombre d'employé subventionné{" "}
                </span>
                <svg
                  width="8"
                  height="10"
                  viewBox="0 0 8 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.99998 9.5C4.14916 9.5 4.29224 9.44074 4.39773 9.33525C4.50322 9.22976 4.56248 9.08668 4.56248 8.9375V2.42037L6.97673 4.83575C7.02903 4.88805 7.09111 4.92953 7.15945 4.95784C7.22778 4.98614 7.30102 5.00071 7.37498 5.00071C7.44894 5.00071 7.52218 4.98614 7.59051 4.95784C7.65884 4.92953 7.72093 4.88805 7.77323 4.83575C7.82553 4.78345 7.86701 4.72136 7.89532 4.65303C7.92362 4.5847 7.93819 4.51146 7.93819 4.4375C7.93819 4.36354 7.92362 4.2903 7.89532 4.22197C7.86701 4.15364 7.82553 4.09155 7.77323 4.03925L4.39823 0.664248C4.34598 0.611865 4.2839 0.570304 4.21557 0.541947C4.14723 0.513589 4.07397 0.498993 3.99998 0.498993C3.92599 0.498993 3.85273 0.513589 3.78439 0.541947C3.71605 0.570304 3.65398 0.611865 3.60173 0.664248L0.226728 4.03925C0.121106 4.14487 0.0617676 4.28813 0.0617676 4.4375C0.0617676 4.58687 0.121106 4.73013 0.226728 4.83575C0.332351 4.94137 0.475606 5.00071 0.624978 5.00071C0.774351 5.00071 0.917606 4.94137 1.02323 4.83575L3.43748 2.42037V8.9375C3.43748 9.08668 3.49674 9.22976 3.60223 9.33525C3.70772 9.44074 3.85079 9.5 3.99998 9.5Z"
                    fill="#72C465"
                  />
                </svg>
              </span>
              <h6>{infoStatsComptable.nombreEmployeSubv}</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Nombre d'employé non subventionné{" "}
                </span>
                <svg
                  width="8"
                  height="10"
                  viewBox="0 0 8 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.99978 0.500002C3.85059 0.500002 3.70752 0.559264 3.60203 0.664754C3.49654 0.770243 3.43728 0.913318 3.43728 1.0625L3.43728 7.57963L1.02303 5.16425C0.970729 5.11195 0.908642 5.07047 0.84031 5.04216C0.771978 5.01386 0.69874 4.99929 0.624778 4.99929C0.550817 4.99929 0.477579 5.01386 0.409247 5.04216C0.340915 5.07047 0.278827 5.11195 0.226528 5.16425C0.174229 5.21655 0.132743 5.27864 0.104439 5.34697C0.0761356 5.4153 0.0615683 5.48854 0.0615683 5.5625C0.0615683 5.63646 0.0761356 5.7097 0.104439 5.77803C0.132743 5.84636 0.174229 5.90845 0.226528 5.96075L3.60153 9.33575C3.65378 9.38814 3.71585 9.4297 3.78419 9.45805C3.85253 9.48641 3.92579 9.50101 3.99978 9.50101C4.07377 9.50101 4.14703 9.48641 4.21537 9.45805C4.2837 9.4297 4.34578 9.38814 4.39803 9.33575L7.77303 5.96075C7.87865 5.85513 7.93799 5.71187 7.93799 5.5625C7.93799 5.41313 7.87865 5.26987 7.77303 5.16425C7.66741 5.05863 7.52415 4.99929 7.37478 4.99929C7.2254 4.99929 7.08215 5.05863 6.97653 5.16425L4.56228 7.57963L4.56228 1.0625C4.56228 0.913318 4.50301 0.770243 4.39753 0.664754C4.29204 0.559264 4.14896 0.500002 3.99978 0.500002Z"
                    fill="#F04949"
                  />
                </svg>
              </span>
              <h6>{infoStatsComptable.nombreEmployeNonSubv}</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant subvention du mois{" "}
                </span>
                {/* <svg
                  width="8"
                  height="10"
                  viewBox="0 0 8 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.99978 0.500002C3.85059 0.500002 3.70752 0.559264 3.60203 0.664754C3.49654 0.770243 3.43728 0.913318 3.43728 1.0625L3.43728 7.57963L1.02303 5.16425C0.970729 5.11195 0.908642 5.07047 0.84031 5.04216C0.771978 5.01386 0.69874 4.99929 0.624778 4.99929C0.550817 4.99929 0.477579 5.01386 0.409247 5.04216C0.340915 5.07047 0.278827 5.11195 0.226528 5.16425C0.174229 5.21655 0.132743 5.27864 0.104439 5.34697C0.0761356 5.4153 0.0615683 5.48854 0.0615683 5.5625C0.0615683 5.63646 0.0761356 5.7097 0.104439 5.77803C0.132743 5.84636 0.174229 5.90845 0.226528 5.96075L3.60153 9.33575C3.65378 9.38814 3.71585 9.4297 3.78419 9.45805C3.85253 9.48641 3.92579 9.50101 3.99978 9.50101C4.07377 9.50101 4.14703 9.48641 4.21537 9.45805C4.2837 9.4297 4.34578 9.38814 4.39803 9.33575L7.77303 5.96075C7.87865 5.85513 7.93799 5.71187 7.93799 5.5625C7.93799 5.41313 7.87865 5.26987 7.77303 5.16425C7.66741 5.05863 7.52415 4.99929 7.37478 4.99929C7.2254 4.99929 7.08215 5.05863 6.97653 5.16425L4.56228 7.57963L4.56228 1.0625C4.56228 0.913318 4.50301 0.770243 4.39753 0.664754C4.29204 0.559264 4.14896 0.500002 3.99978 0.500002Z"
                    fill="#F04949"
                  />
                </svg> */}
              </span>
              <h6>{infoStatsComptable.montantsubventiondumois}F CFA</h6>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <span className="fw-bold">Statistiques vendeuses</span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-4">
              <span>
                <span style={{ marginRight: "10px" }}>Montant à payer</span>
                <svg
                  width="8"
                  height="10"
                  viewBox="0 0 8 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.99998 9.5C4.14916 9.5 4.29224 9.44074 4.39773 9.33525C4.50322 9.22976 4.56248 9.08668 4.56248 8.9375V2.42037L6.97673 4.83575C7.02903 4.88805 7.09111 4.92953 7.15945 4.95784C7.22778 4.98614 7.30102 5.00071 7.37498 5.00071C7.44894 5.00071 7.52218 4.98614 7.59051 4.95784C7.65884 4.92953 7.72093 4.88805 7.77323 4.83575C7.82553 4.78345 7.86701 4.72136 7.89532 4.65303C7.92362 4.5847 7.93819 4.51146 7.93819 4.4375C7.93819 4.36354 7.92362 4.2903 7.89532 4.22197C7.86701 4.15364 7.82553 4.09155 7.77323 4.03925L4.39823 0.664248C4.34598 0.611865 4.2839 0.570304 4.21557 0.541947C4.14723 0.513589 4.07397 0.498993 3.99998 0.498993C3.92599 0.498993 3.85273 0.513589 3.78439 0.541947C3.71605 0.570304 3.65398 0.611865 3.60173 0.664248L0.226728 4.03925C0.121106 4.14487 0.0617676 4.28813 0.0617676 4.4375C0.0617676 4.58687 0.121106 4.73013 0.226728 4.83575C0.332351 4.94137 0.475606 5.00071 0.624978 5.00071C0.774351 5.00071 0.917606 4.94137 1.02323 4.83575L3.43748 2.42037V8.9375C3.43748 9.08668 3.49674 9.22976 3.60223 9.33525C3.70772 9.44074 3.85079 9.5 3.99998 9.5Z"
                    fill="#72C465"
                  />
                </svg>
              </span>
              <h6>{infoStatsComptable.montantGlobalVendeuses}</h6>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <span className="fw-bold">Etats financier</span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant global des commandes{" "}
                </span>
              </span>
              <h6>{infoStatsComptable.montantGlobalDesCommandes}F CFA</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant global des versements{" "}
                </span>
              </span>
              <h6>{infoStatsComptable.montantGlobalDesVersements}F CFA</h6>
            </div>

            <div className="rounded mx-2 bg-white px-4 py-3 col-2">
              <span>
                <span style={{ marginRight: "10px" }}>Reste à verser </span>
              </span>
              <h6>{infoStatsComptable.resteAVerser}F CFA</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Solde portefeuille compte restaurant{" "}
                </span>
              </span>
              <h6>{infoStatsComptable.soldePortefeuille}F CFA</h6>
            </div>
          </div>
        </div>

        <div className="mt-5" style={{ marginBottom: "1rem" }}>
          <span className="fw-bold">Paiement par Mobile Money</span>
          <div className="row mt-1">
            <div className="bg-white py-3 px-4 rounded col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant total Mobile Money
                </span>
              </span>
              <h6>{mobileGlobale}F CFA</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Montant Mobile Money du mois{" "}
                </span>
              </span>
              <h6>{mobileMois}F CFA</h6>
            </div>
            <div className="rounded mx-2 bg-white px-4 py-3 col-3">
              <span>
                <span style={{ marginRight: "10px" }}>
                  Rechargement compte restaurant
                </span>
              </span>
              <h6>
                {
                  infoStatsComptable.montantRechargementCompteRestoParMobileMoneyParMois
                }
                F CFA
              </h6>
            </div>
          </div>
        </div>

        <div className="lastest row" style={{ marginLeft: "-35px" }}>
          <div className="mt-5 col-6">
            <span className="fw-bold mx-4">Paiement par portefeuille</span>
            <div className="container row mt-1">
              <div className="card-stat bg-white mx-2 py-3 rounded col-5">
                <div className="dropdown">
                  <span style={{ marginRight: "10px" }}>Montant total </span>
                </div>
                <h6>{portefeuilleGlobal} F CFA</h6>
              </div>

              <div className="card-stat rounded mx-2 bg-white py-3 col-6">
                <span>
                  <span style={{ marginRight: "10px" }}>Montant du mois </span>
                </span>
                <h6>{portefeuilleMensuel} F CFA</h6>
              </div>
            </div>
          </div>
          <div className="mt-5 col-6">
            <span className="fw-bold mx-4">Versement compte restau</span>
            <div className="container row mt-1">
              <div className="card-stat bg-white mx-2 py-3 rounded col-7">
                <div className="dropdown">
                  <span style={{ marginRight: "10px" }}>
                    Versement total compte restau{" "}
                  </span>
                </div>
                <h6>{versementTotalCompteRestau} F CFA</h6>
              </div>

              <div className="card-stat rounded mx-2 bg-white py-3 col-4">
                <span>
                  <span style={{ marginRight: "10px" }}>Reste à payer </span>
                </span>
                <h6>{resteAVerserCompteRestau} F CFA</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatsComptable;
