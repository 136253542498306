import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { DecodeToken } from "../../DecodeToken";
import AddSuspensionSubvention from "./AddSuspensionSubvention";
import Paginate from "../pagination";

function SuspensionSubvention() {
  const [pageActive, setPageActive] = useState(1);
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [infoSuspension, setInfoSuspension] = useState({});
  const [listeSuspensionSubvention, setListeSuspensionSubvention] = useState(
    []
  );

  let rowListeSuspension = listeSuspensionSubvention.map(
    (suspension, index) => (
      <tr key={index}>
        <td>{suspension.nom}</td>
        <td>{suspension.prenoms}</td>
        <td>{suspension.numero}</td>
        <td>{suspension.email}</td>
        <td>
          <button
            data-bs-target="#suspensionForm"
            data-bs-toggle="modal"
            onClick={() => setInfoSuspension(suspension)}
            className="btn btn-sm"
          >
            <i className="fa fa-pencil"></i>
          </button>
        </td>
      </tr>
    )
  );

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    // filterParametres.param === "" && afficherListeCatactere(pageNumber);
  };

  const afficherListeSuspension = (currentPage) => {
    setLoading(false);
    // rechercherversements(currentPage - 1)
    //   .then((res) => {
    //     setLoading(false);
    //     setListeSuspensionSubvention(res.data.donnee.versements);
    //     settotalElement(res.data.donnee.totalElements);
    //     setTotalElementByPage(res.data.donnee.numberOfElements);
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    afficherListeSuspension();
  }, []);

  return (
    <div className="w-100">
      {DecodeToken().profil !== "ROLE_ADMIN" && (
        <Navigate to="/login" replace />
      )}
      <div className="d-flex justify-content-between">
        <div className="">
          <div className="input-group bg-gris rounded mt-1 mb-3" hidden>
            <div className="input-group-text">
              <i className="fa fa-search text-secondary"></i>
            </div>
            <input
              type="search"
              // onChange={handlFiltre}
              placeholder="Recherche..."
              name=""
              id=""
              className="form-control bg-gris form-control-sm loginborderleft"
            />
          </div>
        </div>
        <div className="mb-2">
          <button
            className="btn btn-sm btn-rose text-white"
            data-bs-target="#suspensionForm"
            data-bs-toggle="modal"
          >
            AJOUTER
          </button>
        </div>
        <AddSuspensionSubvention
          infoSuspension={infoSuspension}
          actualiserliste={afficherListeSuspension}
        />
      </div>
      <table className="table">
        <thead>
          <tr className="gray-table rounded">
            <th>Entreprise</th>
            <th width="20%">Jour</th>
            <th width="20%">Mois</th>
            <th width="15%">Date exclusion</th>
            <th width="15%">Actions</th>
          </tr>
        </thead>
        <tbody>{!loading && rowListeSuspension}</tbody>
        <tfoot>
          <tr>
            <th colSpan="5">
              <div className="d-flex justify-content-center">
                <Paginate
                  pageActive={pageActive}
                  handlePageChange={handlePageChange}
                  nbreElementParPage={totalElementByPage}
                  nbreTotalElmentPage={totalElement}
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
      <div className="d-flex justify-content-center">
        <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
      </div>
    </div>
  );
}

export default SuspensionSubvention;
