import axios from "axios"
import { baseUrl } from "../BaseUrl"
import { headers } from "../../headers"

const NBRE_ELEMENT = 7
export const rechercherEmployes = async(page,id)=>{
    return await axios.get(baseUrl(`rechercheremployes/${id}?page=${page}&size=${NBRE_ELEMENT}`))
}

export const addSubventionEmployes = async(values)=>{
    return await axios.post(baseUrl(`enregistrerSubvention`), values)
}

export const getSubventionEmployes = async(idemploye)=>{
    return await axios.get(baseUrl(`recherchersubventionsparemploye/${idemploye}`))
}

export const deleteSubventionEmployes = async(idSub)=>{
    return await axios.delete(baseUrl(`supprimerSubvention/${idSub}`))
}

export const rechercheremployesparparam = async(page, param)=>{
    return await axios.get(baseUrl("rechercheremployesparparam?page="+page+"&param="+param+"&size="+NBRE_ELEMENT))
}
export const enregistreremploye = async(values)=>{
    return await axios.post(baseUrl("enregistreremploye"), values);
}

export const deleteEmploye = async(id)=>{
    return await axios.delete(baseUrl(`admin/supprimeremploye/${id}`));
}

export const refreshPassword = async(id)=>{
    return await axios.post(baseUrl(`reinitialiserpassworduser/${id}`));
}

export const refreshPasswordEmploye = async(id)=>{
    return await axios.post(baseUrl(`emp/reinitialiserpassword/${id}`));
}

export const changeStatutEmploye = async(id)=>{
    return await axios.post(baseUrl(`changeretatcompteemploye/${id}`));
}

export const changeEmployePassword = async(values)=>{
    return await axios.post(baseUrl(`admin/modifiermotdepasseemploye`), values);
}

export const rechercherdetailemploye = async(id, mois, annee)=>{
    return await axios.get(baseUrl(`rechercherdetailemploye/${id}/${mois}/${annee}`))
}

export const telechargermontantparemployes = async(params)=>{
    return await axios.post(baseUrl("telechargermontantparemployes"), params ,Object.assign(headers, { responseType: 'arraybuffer'}))
}

export const telechargerhistoriquepaiementparemployes = async(params)=>{
    return await axios.post(baseUrl("telechargerhistoriquecommandeparemploye"), params ,Object.assign(headers, { responseType: 'arraybuffer'}))
}

export const telechargerhistoriquepaiementparperiode = async(params)=>{
    return await axios.post(baseUrl("exporterdonnees"), params ,Object.assign(headers, { responseType: 'arraybuffer'}))
}

export const recherchermontantglobaldumois = async(mois, annee, id = 0)=>{
    return await axios.get(baseUrl(`recherchermontantglobaldumois/${mois}/${annee}/${id}`))
}

