import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import {
  telechargerhistoriquepaiementparperiode,
  telechargermontantparemployes,
} from "../../services/employes/EmployesRequest";
import { rechercherannees } from "../../services/statistiques/StatRequest";
import { rechercherentreprises } from "../../services/entreprises/entrepriseRequest";
import { DecodeToken } from "../../DecodeToken";

function ExportModal(props) {
  const [currentView, setCurrentView] = useState("MOIS");
  const [moisInput, setMoisInput] = useState(true);
  const [periodeInput, setPeriodeInput] = useState(false);
  const [moisChoisi, setMoisChoisi] = useState(null);
  const [anneeChoisi, setAnneeChoisi] = useState(null);
  const [moisChoisiDebut, setMoisChoisiDebut] = useState(null);
  const [anneeChoisiDebut, setAnneeChoisiDebut] = useState(null);
  const [moisChoisiFin, setMoisChoisiFin] = useState(null);
  const [anneeChoisiFin, setAnneeChoisiFin] = useState(null);
  const [entreprise, setEntreprise] = useState(null);
  const [typeExport, setTypeExport] = useState({
    value: "SUBVENTION_CREDITE",
    libelle: "Subvention créditée",
  });
  const [listeAnnee, setListeAnnee] = useState([]);
  const [listeEntreprise, setListeEntreprise] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [messageRrror, setMessageError] = useState("");
  const listeTypeExport = [
    { value: "RESTE", libelle: "Suvention restante" },
    { value: "SUBVENTION_CREDITE", libelle: "Subvention créditée" },
    { value: "SUBVENTION_UTILISE", libelle: "Subvention utilisée" },
    { value: "CONSOMMATION", libelle: "Consommation" },
  ];
  const listeMois = [
    { indiceMois: 1, libelle: "Janvier" },
    { indiceMois: 2, libelle: "Février" },
    { indiceMois: 3, libelle: "Mars" },
    { indiceMois: 4, libelle: "Avril" },
    { indiceMois: 5, libelle: "Mai" },
    { indiceMois: 6, libelle: "Juin" },
    { indiceMois: 7, libelle: "Juillet" },
    { indiceMois: 8, libelle: "Août" },
    { indiceMois: 9, libelle: "Septembre" },
    { indiceMois: 10, libelle: "Octobre" },
    { indiceMois: 11, libelle: "Novembre" },
    { indiceMois: 12, libelle: "Décembre" },
  ];

  const afficherEntreprise = () => {
    rechercherentreprises().then((res) => {
      setListeEntreprise(res.data.donnee);
    });
  };

  const handleCheckboxMois2 = () => {
    setEntreprise(null);
    setMessageError("");
    if (currentView === "PERIODE") {
      setCurrentView("MOIS");
      setPeriodeInput(false);
      setMoisInput(true);
    } else {
      setCurrentView("PERIODE");
      setPeriodeInput(true);
      setMoisInput(false);
    }
  };

  const handleCheckboxMois = (e) => {
    setEntreprise(null);
    setMessageError("");
    if (e.target.checked) {
      setCurrentView("MOIS");
      setPeriodeInput(false);
      setMoisInput(true);
    } else {
      setCurrentView("PERIODE");
      setPeriodeInput(true);
      setMoisInput(false);
    }
  };

  const handleCheckboxPeriode2 = () => {
    setEntreprise(null);
    setMessageError("");
    if (currentView === "MOIS") {
      setCurrentView("PERIODE");
      setPeriodeInput(true);
      setMoisInput(false);
    } else {
      setCurrentView("MOIS");
      setPeriodeInput(false);
      setMoisInput(true);
    }
  };
  
  const handleCheckboxPeriode = (e) => {
    setEntreprise(null);
    setMessageError("");
    if (e.target.checked) {
      setCurrentView("PERIODE");
      setPeriodeInput(true);
      setMoisInput(false);
    } else {
      setCurrentView("MOIS");
      setPeriodeInput(false);
      setMoisInput(true);
    }
  };

  const handleMois = (indicemois) => {
    setMoisChoisi(indicemois);
  };

  const handleAnnee = (indiceannee) => {
    setAnneeChoisi(indiceannee);
  };

  const handleEntreprise = (entreprise) => {
    setEntreprise(entreprise);
  };

  const handleMoisPeriode = (mois, periode) => {
    if (periode === "debut") {
      setMoisChoisiDebut(mois);
    } else if (periode === "fin") {
      setMoisChoisiFin(mois);
    }
  };

  const handleAnneePeriode = (annee, periode) => {
    if (periode === "debut") {
      setAnneeChoisiDebut(annee);
    } else if (periode === "fin") {
      setAnneeChoisiFin(annee);
    }
  };

  const handleTypeExport = (type) => {
    setTypeExport(type);
  };

  const exportDonneMois = () => {
    setLoadingExport(true);
    setMessageError("");
    const currentDate = new Date();
    const data = {
      mois:
        moisChoisi && moisChoisi.indiceMois
          ? moisChoisi.indiceMois
          : currentDate.getMonth() + 1,
      annee: anneeChoisi ? anneeChoisi : currentDate.getFullYear(),
      entrepriseId: entreprise && entreprise.raisonSociale ? entreprise.id : "",
    };

    telechargermontantparemployes(data)
      .then((res) => {
        setLoadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Employes_${
            listeMois.filter((item) => item.indiceMois == data.mois)[0].libelle
          }_${data.annee}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setLoadingExport(false);
        if (error && error.response) {
          setMessageError(error.response.data.donnee);
        } else {
          setMessageError("Une erreur s'est produite");
        }
      });
  };

  const exportDonneeEmploye = () => {
    setLoadingExport(true);
    setMessageError("");
    const currentDate = new Date();
    const data = {
      moisDebut:
        moisChoisiDebut && moisChoisiDebut.indiceMois
          ? moisChoisiDebut.indiceMois
          : currentDate.getMonth() + 1,
      anneeDebut: anneeChoisiDebut
        ? anneeChoisiDebut
        : currentDate.getFullYear(),
      moisFin:
        moisChoisiFin && moisChoisiFin.indiceMois
          ? moisChoisiFin.indiceMois
          : currentDate.getMonth() + 1,
      anneeFin: anneeChoisiFin ? anneeChoisiFin : currentDate.getFullYear(),
      typeExport: typeExport && typeExport.libelle ? typeExport.value : "",
      entrepriseId: entreprise && entreprise.raisonSociale ? entreprise.id : "",
    };

    telechargerhistoriquepaiementparperiode(data)
      .then((res) => {
        setLoadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export_donnee_${moisChoisiDebut.libelle}_${anneeChoisiDebut}_${moisChoisiFin.libelle}_${anneeChoisiFin}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setLoadingExport(false);
        if (error && error.response) {
          setMessageError(error.response.data.donnee);
        } else {
          setMessageError("Une erreur s'est produite");
        }
      });
  };

  useEffect(() => {
    afficherEntreprise();
    rechercherannees().then((res) => {
      setListeAnnee(res.data.donnee);
    });
  }, []);

  return (
    <div
      className="modal fade"
      id="exportmodal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "40%" }}
      >
        <div className="modal-content ">
          <div className="d-flex modal-header justify-content-between p-2">
            <div></div>
            <div>
              <h6 className="mt-1">Export de données</h6>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center gap-4 mb-4">
              <div className="d-flex align-items-center gap-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={moisInput}
                  id="flexCheckDefault"
                  onChange={handleCheckboxMois}
                  style={{ width: 5 }}
                />
                <span onClick={handleCheckboxMois2}>Export par mois</span>
              </div>
              <div className="d-flex align-items-center gap-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={periodeInput}
                  id="flexCheckDefault"
                  onChange={handleCheckboxPeriode}
                  style={{ width: 5 }}
                />
                <span onClick={handleCheckboxPeriode2}>Export par période</span>
              </div>
            </div>
            {currentView === "MOIS" ? (
              <div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  <div className="dropdown align-self-right w-50">
                    Mois
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {moisChoisi ? moisChoisi.libelle : "Sélectionner un mois"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeMois.map((item, index) => (
                        <li onClick={() => handleMois(item)} key={index}>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item.libelle}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="dropdown align-self-right w-50">
                    Année
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {anneeChoisi ? anneeChoisi : "Sélectionner une année"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeAnnee.map((item, index) => (
                        <li onClick={() => handleAnnee(item)} key={index}>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {DecodeToken().profil === "ROLE_ADMIN" && (
                  <div className="dropdown align-self-right w-100 mt-2">
                    Entreprise
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {entreprise
                        ? entreprise.raisonSociale
                        : "Sélectionner une entreprise"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeEntreprise.map((item, index) => (
                        <li onClick={() => handleEntreprise(item)} key={index}>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item.raisonSociale}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <button
                  disabled={loadingExport}
                  onClick={() => exportDonneMois()}
                  className="w-100 btn mt-3 text-white"
                  style={{
                    backgroundColor: "#CE5D1D",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!loadingExport ? (
                    "Exporter"
                  ) : (
                    <Oval
                      width={18}
                      height={18}
                      color="#fff"
                      visible={loadingExport}
                    />
                  )}
                </button>

                {messageRrror.length !== 0 ? (
                  <p className="mt-2 text-center text-danger">{messageRrror}</p>
                ) : null}
              </div>
            ) : null}
            {currentView === "PERIODE" ? (
              <div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  <div className="dropdown align-self-right w-50">
                    Mois début
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {moisChoisiDebut
                        ? moisChoisiDebut.libelle
                        : "Sélectionner un mois"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeMois.map((item, index) => (
                        <li
                          onClick={() => handleMoisPeriode(item, "debut")}
                          key={index}
                        >
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item.libelle}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="dropdown align-self-right w-50">
                    Année début
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {anneeChoisiDebut
                        ? anneeChoisiDebut
                        : "Sélectionner une année"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeAnnee.map((item, index) => (
                        <li
                          onClick={() => handleAnneePeriode(item, "debut")}
                          key={index}
                        >
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  <div className="dropdown align-self-right w-50">
                    Mois fin
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {moisChoisiFin
                        ? moisChoisiFin.libelle
                        : "Sélectionner un mois"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeMois.map((item, index) => (
                        <li
                          onClick={() => handleMoisPeriode(item, "fin")}
                          key={index}
                        >
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item.libelle}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="dropdown align-self-right w-50">
                    Année fin
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {anneeChoisiFin
                        ? anneeChoisiFin
                        : "Sélectionner une année"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeAnnee.map((item, index) => (
                        <li
                          onClick={() => handleAnneePeriode(item, "fin")}
                          key={index}
                        >
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  <div className="dropdown align-self-right w-50">
                    Type d'export
                    <div
                      id="annee"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm w-100 dropdown-toggle bg-gris"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {typeExport ? typeExport.libelle : "Sélectionner un mois"}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="annee">
                      {listeTypeExport.map((item, index) => (
                        <li onClick={() => handleTypeExport(item)} key={index}>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                          >
                            {item.libelle}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {DecodeToken().profil === "ROLE_ADMIN" && (
                    <div className="dropdown align-self-right w-50">
                      Entreprise
                      <div
                        id="annee"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="btn btn-sm w-100 dropdown-toggle bg-gris"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {entreprise
                          ? entreprise.raisonSociale
                          : "Sélectionner une entreprise"}
                      </div>
                      <ul className="dropdown-menu" aria-labelledby="annee">
                        {listeEntreprise.map((item, index) => (
                          <li
                            onClick={() => handleEntreprise(item)}
                            key={index}
                          >
                            <div
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                            >
                              {item.raisonSociale}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <button
                  disabled={loadingExport}
                  onClick={() => exportDonneeEmploye()}
                  className="w-100 btn mt-3 text-white"
                  style={{
                    backgroundColor: "#CE5D1D",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!loadingExport ? (
                    "Exporter"
                  ) : (
                    <Oval
                      width={18}
                      height={18}
                      color="#fff"
                      visible={loadingExport}
                    />
                  )}
                </button>

                {messageRrror.length !== 0 ? (
                  <p className="mt-2 text-center text-danger">{messageRrror}</p>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportModal;
