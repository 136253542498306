import React from 'react'
import Sidebar from './Sidebar'

function Layout(props) {
    return (
        <div style={{ height: "100vh" }}>
            <div className='row bg-gris p-0 m-0' style={{ height: "100vh", width: "100%" }}>
                <div className='col-1' style={{ height: "100vh" }}>
                    <div className='row bg-rose' style={{ width: "95%", height: "100vh"}}>
                        <Sidebar />
                    </div>
                </div>
                <div className='col-11' style={{ height: '100%', overflowY: "scroll" }}>
                    {props.children}
                </div>
            </div>

        </div>

    )
}

export default Layout