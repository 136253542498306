import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import { DecodeToken } from "../../DecodeToken";
import { rechercherversements } from "../../services/versements/versementRequest";
import HeaderNomResto from "../HeaderNomResto";
import Layout from "../layouts/Layout";
import Paginate from "../pagination";
import AddReversement from "./AddReversement";
function Versement() {
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [filterParametres, setfilterParametres] = useState({ param: "" });
  const [pageActive, setPageActive] = useState(1);
  const [infoVersement, setinfoVersement] = useState({});
  const [listeVersements, setlisteVersements] = useState([]);
  const [loading, setloading] = useState(false);

  const afficherVersement = (currentPage) => {
    setloading(true);
    rechercherversements(currentPage - 1)
      .then((res) => {
        setloading(false);
        setlisteVersements(res.data.donnee.versements);
        settotalElement(res.data.donnee.totalElements);
        setTotalElementByPage(res.data.donnee.numberOfElements);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    filterParametres.param === "" && afficherVersement(pageNumber);
  };

  const rowListeVersements = listeVersements.map((item, index) => (
    <tr key={index}>
      {DecodeToken().profil === "ROLE_COMPTABLE" ? (
        <td>{item.receveur.nom}</td>
      ) : (
        <td>{item.entreprise ? item.entreprise.raisonSociale : 'Skanpay'}</td>
      )}
      {DecodeToken().profil === "ROLE_ADMIN" ? (
        <td>{item.receveur.nom}</td>
      ) : null}
      <td>{item.montant}F CFA</td>
      <td>{item.dateOrdre.split("-").reverse().join("-")}</td>
      <td>{item.date.split("-").reverse().join("-")}</td>
      <td>{item.type === "COMPTE_RESTO" ? 'Compte Resto' : item.type === "SUBVENTION" ? 'Subvention' : item.type === "SALAIRE" ? 'Salaire' : 'Mobile Money'}</td>
      {DecodeToken().profil === "ROLE_COMPTABLE" ? null : (
        <td>
          <button
            data-bs-target="#reversement"
            data-bs-toggle="modal"
            onClick={() => setinfoVersement(item)}
            className="btn btn-sm"
          >
            <i className="fa fa-pencil"></i>
          </button>
        </td>
      )}
    </tr>
  ));

  useEffect(() => {
    afficherVersement(pageActive);
  }, []);

  return (
    <Layout>
      {/* {DecodeToken().profil === "ROLE_COMPTABLE" ? <Navigate to="/login" replace /> : null} */}
      <HeaderNomResto />
      <p className="mb-3 mt-3 fw-bold">Versement</p>
      <div className="bg-white rounded p-2">
        <div className="d-flex justify-content-between">
          <div className="">
            {/* <div className='input-group bg-gris rounded mt-1 mb-3' >
                            <div className="input-group-text">
                                <i className='fa fa-search text-secondary'></i>
                            </div>
                            <input type="search" placeholder='Recherche...' name="" id="" className='form-control bg-gris form-control-sm loginborderleft' />
                        </div> */}
          </div>
          {DecodeToken().profil === "ROLE_COMPTABLE" ? null : (
            <div className="">
              <button
                onClick={() => setinfoVersement({})}
                className="btn btn-sm"
                data-bs-target="#reversement"
                data-bs-toggle="modal"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.5 1.75C24.9641 1.75 25.4092 1.93437 25.7374 2.26256C26.0656 2.59075 26.25 3.03587 26.25 3.5V24.5C26.25 24.9641 26.0656 25.4092 25.7374 25.7374C25.4092 26.0656 24.9641 26.25 24.5 26.25H3.5C3.03587 26.25 2.59075 26.0656 2.26256 25.7374C1.93437 25.4092 1.75 24.9641 1.75 24.5V3.5C1.75 3.03587 1.93437 2.59075 2.26256 2.26256C2.59075 1.93437 3.03587 1.75 3.5 1.75H24.5ZM3.5 0C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5L0 24.5C0 25.4283 0.368749 26.3185 1.02513 26.9749C1.6815 27.6313 2.57174 28 3.5 28H24.5C25.4283 28 26.3185 27.6313 26.9749 26.9749C27.6313 26.3185 28 25.4283 28 24.5V3.5C28 2.57174 27.6313 1.6815 26.9749 1.02513C26.3185 0.368749 25.4283 0 24.5 0L3.5 0Z"
                    fill="#767676"
                  />
                  <path
                    d="M14 7C14.2321 7 14.4546 7.09219 14.6187 7.25628C14.7828 7.42038 14.875 7.64294 14.875 7.875V13.125H20.125C20.3571 13.125 20.5796 13.2172 20.7437 13.3813C20.9078 13.5454 21 13.7679 21 14C21 14.2321 20.9078 14.4546 20.7437 14.6187C20.5796 14.7828 20.3571 14.875 20.125 14.875H14.875V20.125C14.875 20.3571 14.7828 20.5796 14.6187 20.7437C14.4546 20.9078 14.2321 21 14 21C13.7679 21 13.5454 20.9078 13.3813 20.7437C13.2172 20.5796 13.125 20.3571 13.125 20.125V14.875H7.875C7.64294 14.875 7.42038 14.7828 7.25628 14.6187C7.09219 14.4546 7 14.2321 7 14C7 13.7679 7.09219 13.5454 7.25628 13.3813C7.42038 13.2172 7.64294 13.125 7.875 13.125H13.125V7.875C13.125 7.64294 13.2172 7.42038 13.3813 7.25628C13.5454 7.09219 13.7679 7 14 7Z"
                    fill="#767676"
                  />
                </svg>
              </button>
            </div>
          )}
          <AddReversement
            infoVersement={infoVersement}
            actualisteListe={afficherVersement}
          />
        </div>
        <table className="table">
          <thead>
            <tr className="gray-table rounded">
              {DecodeToken().profil === "ROLE_ADMIN" ? <th>Entreprise</th> : DecodeToken().profil === "ROLE_RESTAURATRICE" ? <th>Entreprise</th> : null}
              {DecodeToken().profil === "ROLE_ADMIN" ? <th>Restaurant</th> : DecodeToken().profil === "ROLE_COMPTABLE" ? <th>Restaurant</th> : null}
              <th>Montant reversé</th>
              <th>Date de l'ordre</th>
              <th width="15%">Date de versement</th>
              <th >Type de versement</th>
              {DecodeToken().profil === "ROLE_COMPTABLE" ? null : (
                <th width="10%">Action</th>
              )}
            </tr>
          </thead>
          <tbody>{!loading && rowListeVersements}</tbody>
          <tfoot>
            <tr>
              <th colSpan="6">
                <div className="d-flex justify-content-center">
                  <Paginate
                    pageActive={pageActive}
                    handlePageChange={handlePageChange}
                    nbreElementParPage={totalElementByPage}
                    nbreTotalElmentPage={totalElement}
                  />
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
        {
          <div className="d-flex justify-content-center">
            <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
          </div>
        }
      </div>
    </Layout>
  );
}

export default Versement;
