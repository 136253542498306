import axios from "axios";
import { headers } from "../../headers";
import { baseUrl } from "../BaseUrl";

export const grapheNombrePlatVendu = async (params) => {
  return await axios.post(baseUrl("recherchergraphenombreplatvendu"), params);
};

export const grapheChiffreAffaire = async (params) => {
  return await axios.post(baseUrl("recherchergraphechiffreaffaire"), params);
};

export const grapheComparatifNombrePlatVendu = async (params) => {
  return await axios.post(
    baseUrl("admin/recherchergraphecomparatifnombreplats"),
    params
  );
};

export const grapheComparatifChiffreAffaire = async (params) => {
  return await axios.post(
    baseUrl("admin/recherchergraphecomparatifchiffreaffaire"),
    params
  );
};


