import React, { useState } from "react";
import {
  autoriserVendeusePourEntreprise,
  recupereListeVendeuseAutorise,
  recupereListeVendeuseNotAutorise,
  retirerVendeuseAutorisePourEntreprise,
} from "../../services/entreprises/entrepriseRequest";
import { Oval } from "react-loader-spinner";
import { useEffect } from "react";

function VendeuseAutorise(props) {
  const [loading, setloading] = useState(false);
  const [ajoutLoading, setAjoutLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [vendeuseSelected, setVendeuseSelected] = useState(null);
  const [listeVendeuseNonAutorise, setListeVendeuseNonAutorise] = useState([]);
  const [listeVendeuseAutorise, setListeVendeuseAutorise] = useState([]);

  const getListeVendeuseNotAutorise = (entrepriseId) => {
    recupereListeVendeuseNotAutorise(entrepriseId)
      .then((res) => {
        setListeVendeuseNonAutorise(res.data.donnee);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  const getListeVendeuseAutorise = (entrepriseId) => {
    recupereListeVendeuseAutorise(entrepriseId)
      .then((res) => {
        setListeVendeuseAutorise(res.data.donnee);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  const handleChoixVendeuse = (value) => {
    setVendeuseSelected(value.target.value);
  };

  const AutoriserVendeuse = () => {
    setAjoutLoading(true);
    if (vendeuseSelected !== null) {
      autoriserVendeusePourEntreprise(vendeuseSelected, props.infoentreprise.id)
        .then((res) => {
          setAjoutLoading(false);
          getListeVendeuseAutorise(props.infoentreprise.id);
          getListeVendeuseNotAutorise(props.infoentreprise.id);
        })
        .catch((err) => {
          setAjoutLoading(false);
          console.log("api error", err);
        });
    }
  };

  const retirerVendeuseAutorise = (vendeuseId) => {
    retirerVendeuseAutorisePourEntreprise(vendeuseId, props.infoentreprise.id)
      .then((res) => {
        getListeVendeuseAutorise(props.infoentreprise.id);
        getListeVendeuseNotAutorise(props.infoentreprise.id);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    setloading(true);
    getListeVendeuseNotAutorise(props.infoentreprise.id);
    getListeVendeuseAutorise(props.infoentreprise.id);
  }, [props.infoentreprise]);

  return (
    <div
      className="modal fade"
      id="vendeuse"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content ">
          <div className="d-flex modal-header justify-content-between p-2">
            <div></div>
            <div>
              <h6>Autoriser des vendeuses</h6>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="CloseAddSubvention"
                onClick={() => setVendeuseSelected(null)}
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center w-100">
              <div className="w-50">
                <h6 className="text-center">
                  Liste des vendeuses non autorisées
                </h6>
                {listeVendeuseNonAutorise.length !== 0 ? (
                  <>
                    <select
                      className="form-select mb-3 mt-4"
                      aria-label="select example"
                      onChange={handleChoixVendeuse}
                    >
                      <option selected value="">
                        (Sélectionner une vendeuse){" "}
                      </option>
                      {listeVendeuseNonAutorise.map((vendeuse, index) => (
                        <option value={vendeuse.id} key={index}>
                          {vendeuse.nom} {vendeuse.prenoms}
                        </option>
                      ))}
                    </select>
                    {!ajoutLoading ? (
                      <div
                        style={{ cursor: "pointer" }}
                        className="px-3 py-2 d-block text-center bg-success text-white rounded"
                        onClick={AutoriserVendeuse}
                      >
                        Autoriser
                      </div>
                    ) : (
                      <Oval
                        width={40}
                        height={40}
                        color="#CE5D1D"
                        visible={ajoutLoading}
                      />
                    )}
                  </>
                ) : (
                  <p className="text-center mt-3">
                    Aucune vendeuse à autoriser
                  </p>
                )}
              </div>
              <div className="w-50 px-3">
                <h6 className="text-center">Vendeuses autorisées</h6>
                {listeVendeuseAutorise.length !== 0 ? (
                  listeVendeuseAutorise.map((item, index) => (
                    <div className="position-relative card bg-rose px-3 mt-4">
                      <div className="d-flex align-items-center gap-1">
                        <span>Nom & prénoms: </span>
                        <span>
                          {item.nom ? item.nom : "..."}{" "}
                          {item.prenoms ? item.prenoms : "..."}
                        </span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <span>Adresse: </span>
                        <span>{item.adresse ? item.adresse : "..."}</span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <span>Numéro: </span>
                        <span>{item.numero ? item.numero : "..."}</span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="position-absolute end-0 bottom-0 px-2 py-1 d-inline bg-danger text-white rounded"
                        onClick={() => retirerVendeuseAutorise(item.id)}
                      >
                        <i className="fa fa-trash white"></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center mt-3">Aucune vendeuse autorisée</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendeuseAutorise;
