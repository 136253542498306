import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import { Oval } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import { DecodeToken } from "../../DecodeToken";
import AddEntreprise from "./AddEntreprise";
import HeaderNomResto from "../HeaderNomResto";
import {
  rechercherentreprises,
  rechercherentreprisesparparam,
} from "../../services/entreprises/entrepriseRequest";
import AddSite from "./AddSite";
import {
  rechercherSites,
  recherchersitepardesignationetentreprise,
} from "../../services/entreprises/entrepriseRequest";
import VendeuseAutorise from "./VendeuseAutorise";
import RestaurantAutorise from "./RestaurantAutorise";

function Entreprise() {
  const [infoentreprise, setinfoentreprise] = useState({});
  const [loading, setloading] = useState(false);
  const [listeEntreprise, setlisteEntreprise] = useState([]);
  const [param, setparam] = useState("");
  const [composant, setComposant] = useState("entreprise");
  const [context, setContext] = useState("Entreprises enregistrées");
  let [listeSite, setListeSite] = useState([]);
  let [infoSite, setInfoSite] = useState({});
  let [entrepriseId, setEntrepriseId] = useState("");
  let [designation, setDesignation] = useState("");

  const afficherEntreprise = () => {
    setloading(true);
    rechercherentreprises()
      .then((response) => {
        setloading(false);
        setlisteEntreprise(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const afficherEntrepriseFiltrer = (parametre) => {
    setloading(true);
    rechercherentreprisesparparam(parametre)
      .then((response) => {
        setloading(false);
        setlisteEntreprise(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const afficherLaListeSite = () => {
    rechercherSites()
      .then((response) => {
        setloading(false);
        listeSite = response.data.donnee;
        setListeSite(listeSite);
        console.log(listeSite);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const rowListeEntreprise = listeEntreprise.map((item, index) => (
    <tr key={index}>
      <td>{item.raisonSociale}</td>
      <td>{item.email}</td>
      <td>{item.adresse}</td>
      <td>{item.numero}</td>
      <td className="d-flex gap-2">
        <button
          data-bs-target="#entreprise"
          data-bs-toggle="modal"
          onClick={() => setinfoentreprise(item)}
          className="btn bg-secondary btn-sm text-white"
        >
          Modifier
        </button>
        <button
          data-bs-target="#vendeuse"
          data-bs-toggle="modal"
          onClick={() => setinfoentreprise(item)}
          className="btn bg-secondary btn-sm text-white mx-1"
        >
          Vendeuse
        </button>
        <button
          data-bs-target="#restaurant"
          data-bs-toggle="modal"
          onClick={() => setinfoentreprise(item)}
          className="btn bg-secondary btn-sm text-white"
        >
          Restaurant
        </button>
      </td>
    </tr>
  ));

  const rowListeSite = listeSite.map((item, index) => (
    <tr key={index}>
      <td>{item.nom != null && item.nom}</td>
      <td>{item.localisation}</td>
      <td>{item.longitude}</td>
      <td>{item.latitude}</td>
      <td>{item.code}</td>
      <td>{item.entreprise != null && item.entreprise.raisonSociale}</td>
      <td>
        <button
          data-bs-target="#site"
          data-bs-toggle="modal"
          onClick={() => setInfoSite(item)}
          className="btn bg-secondary btn-sm text-white"
        >
          Modifier
        </button>
      </td>
    </tr>
  ));

  const filterparselection = (e) => {
    //filtresaisie=filtreparsaisie();
    entrepriseId = e.target.value;
    setEntrepriseId(entrepriseId);
    let object = { designation: designation, entrepriseId: entrepriseId };
    recherche(object);
  };

  const recherche = (object) => {
    recherchersitepardesignationetentreprise(object)
      .then((response) => {
        setloading(false);
        setListeSite(response.data.donnee);
      })
      .catch(() => {
        setloading(false);
      });
  };

  const filtreparsaisie = (e) => {
    designation = e.target.value;
    setDesignation(designation);
    let object = { designation: designation, entrepriseId: entrepriseId };
    recherche(object);
  };

  useEffect(() => {
    afficherEntreprise();
    afficherLaListeSite();
  }, []);

  return (
    <Layout>
      {DecodeToken().profil !== "ROLE_ADMIN" && <Navigate to="/" replace />}
      <div>
        <HeaderNomResto />
        <nav className="nav">
          <div
            className="nav-link el active"
            style={{ cursor: "pointer" }}
            aria-current="page"
            onClick={() => {
              setComposant("entreprise");
              setContext("Entreprises enregistrées");
              document.querySelector(".el").classList.add("active");
              document.querySelector(".f").classList.remove("active");
            }}
          >
            Entreprises
          </div>
          <div
            className="nav-link f"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setComposant("site");
              setContext("Sites enregistrés");
              document.querySelector(".f").classList.add("active");
              document.querySelector(".el").classList.remove("active");
            }}
          >
            Sites
          </div>
        </nav>
        <p className="mb-3 mt-3 fw-bold">{context} </p>
        {composant === "entreprise" && (
          <div className="bg-white rounded p-2">
            <div className="d-flex justify-content-between">
              <div className="">
                <div className="input-group bg-gris rounded mt-1 mb-3">
                  <div className="input-group-text">
                    <i className="fa fa-search text-secondary"></i>
                  </div>
                  <input
                    type="search"
                    onChange={(e) => afficherEntrepriseFiltrer(e.target.value)}
                    placeholder="Recherche..."
                    name=""
                    id=""
                    className="form-control bg-gris form-control-sm loginborderleft"
                  />
                </div>
              </div>
              <div className="">
                <button
                  onClick={() => setinfoentreprise({})}
                  className="btn btn-sm btn-rose text-white"
                  data-bs-target="#entreprise"
                  data-bs-toggle="modal"
                >
                  AJOUTER
                </button>
              </div>
              <AddEntreprise
                infoentreprise={infoentreprise}
                actualisteListe={afficherEntreprise}
              />
            </div>
            <table className="table">
              <thead>
                <tr className="gray-table rounded">
                  <th>Raison sociale</th>
                  <th width="20%">Adresse email</th>
                  <th width="17%">Adresse</th>
                  <th width="15%">Numéro</th>
                  <th width="20%">Actions</th>
                </tr>
              </thead>
              <tbody>{!loading && rowListeEntreprise}</tbody>
              {/* <tfoot>
                <tr>
                  <th colSpan="6">
                    <div className="d-flex justify-content-center">
                      <Paginate
                        pageActive={pageActive}
                        handlePageChange={handlePageChange}
                        nbreElementParPage={totalElementByPage}
                        nbreTotalElmentPage={totalElement}
                      />
                    </div>
                  </th>
                </tr>
              </tfoot> */}
            </table>
            <div className="d-flex justify-content-center">
              <Oval width={40} height={40} color="#CE5D1D" visible={loading} />
            </div>

            <VendeuseAutorise
              infoentreprise={infoentreprise}
              actualisteListe={afficherEntreprise}
            />
            <RestaurantAutorise
              infoentreprise={infoentreprise}
              actualisteListe={afficherEntreprise}
            />
          </div>
        )}
        {composant === "site" && (
          <div className="bg-white rounded p-2">
            <div className="d-flex justify-content-between">
              <div className="">
                <div className="input-group bg-gris rounded mt-1 mb-3">
                  <div className="input-group-text">
                    <i className="fa fa-search text-secondary"></i>
                  </div>
                  <input
                    type="search"
                    onChange={filtreparsaisie}
                    placeholder="Recherche..."
                    id=""
                    className="form-control bg-gris form-control-sm loginborderleft"
                  />
                </div>
              </div>
              <div className="">
                <select
                  className="form-select"
                  aria-label=" select example"
                  onChange={filterparselection}
                >
                  <option selected value="">
                    (Sélectionner une entreprise){" "}
                  </option>
                  {listeEntreprise.map((entreprise, index) => (
                    <option value={entreprise.id} key={index}>
                      {entreprise.raisonSociale}
                    </option>
                  ))}
                </select>
              </div>

              <div className="">
                <button
                  onClick={() => setInfoSite({})}
                  className="btn btn-sm btn-rose text-white"
                  data-bs-target="#site"
                  data-bs-toggle="modal"
                >
                  AJOUTER
                </button>
              </div>
              <AddSite
                infoentreprise={infoentreprise}
                listeEntreprise={listeEntreprise}
                actualisteListe={afficherLaListeSite}
                infoSite={infoSite}
              />
            </div>
            <table className="table">
              <thead>
                <tr className="gray-table rounded">
                  <th width="20%">Nom du site</th>
                  <th width="20%">Localisation</th>
                  <th width="20%">Longitude</th>
                  <th width="15%">Latitude</th>
                  <th width="15%">Code du site</th>
                  <th width="15%">Entreprise</th>
                  <th width="15%">Actions</th>
                </tr>
              </thead>
              <tbody>{!loading && rowListeSite}</tbody>
              {/* <tfoot>
                            <tr>
                                <th colSpan="6">
                                    <div className='d-flex justify-content-center'>
                                        <Paginate pageActive={pageActive} handlePageChange={handlePageChange} nbreElementParPage={totalElementByPage} nbreTotalElmentPage={totalElement} />
                                    </div>
                                </th>
                            </tr></tfoot> */}
            </table>
            {
              <div className="d-flex justify-content-center">
                <Oval
                  width={40}
                  height={40}
                  color="#CE5D1D"
                  visible={loading}
                />
              </div>
            }
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Entreprise;
