import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

export const rechercherentreprises = async() =>{
    return await axios.get(baseUrl('admin/rechercherentreprises'));
}

export const enregistrerentreprise = async(values) =>{
    return await axios.post(baseUrl('admin/enregistrerentreprise'), values);
}

export const rechercherentreprisesparparam = async(param) =>{
    return await axios.post(baseUrl(`admin/rechercherentreprisesparparam?param=${param}`),{});
}
export const enregistrersubventionpargrade=async(param)=>{
    return await axios.post(baseUrl(`entreprise/enregistrerSubventionParGrade`),param);
}
export const recherchesubventionspargrade =async()=>{
    return await axios.get(baseUrl(`entreprise/recherchersubventionspargrade`));
}
export const rechercherentreprisespartenaires=async()=>{
    return await axios.get(baseUrl(`restauratrice/rechercherentreprisespartenaires`))
}
// site request
export const enregistrerSite= async(param)=>{
    return await axios.post(baseUrl(`admin/enregistrersite`),param);
}
export const rechercherSites=async(param)=>{
    return await axios.get(baseUrl(`rechercherSites`));
}
export const recherchersitepardesignationetentreprise= async(param)=>{
    return await axios.post(baseUrl(`recherchersitepardesignationetparentreprise`),param);
}

export const recherchersiteparentreprise=async(id)=>{
    return await axios.get(baseUrl(`recherchersiteparentreprise/${id}`))
}

export const enregistreVersionDeploiement= async(param)=>{
    return await axios.post(baseUrl(`admin/enregistrerparametre`),param);
}

export const enregistreModeSubvention = async(param)=>{
    return await axios.post(baseUrl(`enregistrerparametragemodeutilisationSubvention`),param);
}

export const rechercherModeSubvention = async(id)=>{
    return await axios.get(baseUrl(`rechercherrerparametragemodeutilisationSubvention/${id}`), {});
}

export const recupereVersionDeploiement= async()=>{
    return await axios.get(baseUrl(`admin/rechercherParametre/VERSION_EN_DEPLOIEMENT`));
}

export const recupereListeVendeuseNotAutorise = async (entrepriseId)=>{
    return await axios.get(baseUrl(`admin/recherchervendeusnoneautoriseparentreprise/${entrepriseId}`));
}

export const recupereListeVendeuseAutorise = async (entrepriseId)=>{
    return await axios.get(baseUrl(`admin/recherchervendeuseautoriseparentreprise/${entrepriseId}`));
}

export const autoriserVendeusePourEntreprise = async (vendeuseId, entrepriseId)=>{
    return await axios.post(baseUrl(`admin/autoriservendeuse/${vendeuseId}/${entrepriseId}`));
}

export const retirerVendeuseAutorisePourEntreprise = async (vendeuseId, entrepriseId)=>{
    return await axios.post(baseUrl(`admin/retirervendeuse/${vendeuseId}/${entrepriseId}`));
}




export const recupereListeRestaurantNotAutorise = async (entrepriseId)=>{
    return await axios.get(baseUrl(`admin/rechercherrestaurantnonautoriseparentreprise/${entrepriseId}`));
}

export const recupereListeRestaurantAutorise = async (entrepriseId)=>{
    return await axios.get(baseUrl(`admin/rechercherrestaurantautoriseparentreprise/${entrepriseId}`));
}

export const autoriserRestaurantPourEntreprise = async (vendeuseId, entrepriseId)=>{
    return await axios.post(baseUrl(`admin/autoriserrestaurant/${vendeuseId}/${entrepriseId}`));
}

export const retirerRestaurantAutorisePourEntreprise = async (vendeuseId, entrepriseId)=>{
    return await axios.post(baseUrl(`admin/retirerrestaurant/${vendeuseId}/${entrepriseId}`));
}


