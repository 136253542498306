import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

const NBRE_ELEMENT=7

export const rechercherUtilisateurs = async(page) =>{
    return await axios.get(baseUrl(`rechercherutilisateurs?page=${page}&size=${NBRE_ELEMENT}`));
}
export const rechercherUtilisateursparparam = async(page,param) =>{
    return await axios.post(baseUrl(`rechercherutilisateurparpam?page=${page}&param=${param}&size=${NBRE_ELEMENT}`));
}

export const enregistrerUtilisateur = async(values) =>{
    return await axios.post(baseUrl('admin/registeruser'), values);
}

export const enregistrerUtilisateurPourComptable = async(values) =>{
    return await axios.post(baseUrl('entreprise/registeruser'), values);
}

export const changerstatutcompte=async(id)=>{
    return await axios.post(baseUrl(`changerstatutcompte/${id}`),{})
}
export const supprimercompte=async(id)=>{
    return await axios.delete(baseUrl(`admin/supprimercompte/${id}`))
}
export const enregistrerRestauratriceUtilisateurs=async(values)=>{
    return await axios.post(baseUrl(`restauratrice/registeruser`),values)
}

export const updatePasswordUtilisateurs=async(values)=>{
    return await axios.post(baseUrl(`admin/modifiermotdepasse`),values)
}