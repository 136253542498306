import axios from "axios"
import { headers } from "../../headers"
import { baseUrl } from "../BaseUrl"

const NBRE_ELEMENT=7

export const rechercherCaractereSubvention = async(page) =>{
    return await axios.get(baseUrl(`admin/rechercherparametragecaracteres?page=${page}&size=${NBRE_ELEMENT}`));
}

export const enregistrerCaratereSubvention = async(values) =>{
    return await axios.post(baseUrl('admin/enregistrerparametragecaractere'), values);
}

export const rechercherDelaiService = async(restaurant) =>{
    return await axios.get(baseUrl(`rechercherdelaideserviceparrestaurant?restaurantId=${restaurant}`), {});
}

export const enregistrerDelaiService = async(values) =>{
    return await axios.post(baseUrl('modifierdelaiservice'), values);
}

export const supprimerCaractereSubvention = async(id) =>{
    return await axios.delete(baseUrl(`admin/supprimerparametragecaractere/${id}`), {});
}